import { FC, useState, useEffect } from 'react';
import { useModal } from 'react-hooks-use-modal';

import BillingList from './BillingList';
import plus from '../../../../assets/icons/Plus.png';
import { Main } from './style';
import { IPageQueries } from 'models';
import BillingCreateDialog from './BillingCreate';
import { useLogicBill } from './hook';
import billService from '../../../../services/billService';
import workersService from '../../../../services/wokersService';
import clientCompanyService from '../../../../services/clientCompanyService';
import EditBillingDialog from './edit';

type IProps = {
    project_id: string | undefined;
    project: any | undefined;
};

const Billings: FC<IProps> = ({ project_id, project }) => {
    // const { id } = useBilling();
    const { status, id } = useLogicBill();
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });

    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [openEdit, setOpenEdit] = useState<boolean>(false);
    const [workers, setWorkers] = useState<any[]>([]);
    const [companies, setCompanies] = useState<any[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [bills, setBills] = useState<any[]>([]);
    const [selectedBill, setSelectedBill] = useState<any>(null);

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );

    const onDeleteBill = () => {};

    const getBills = async ({ page = 1, per_page = 0 }) => {
        try {
            const responseEstimation = await billService.getListBill({
                page,
                per_page,
                project_id: Number(id),
            });
            if (
                responseEstimation !== null &&
                responseEstimation !== undefined
            ) {
                setTotalCount(responseEstimation.meta.total_count);
                setBills(responseEstimation.bills);
            }
        } catch (err) {}
    };

    useEffect(() => {
        getListWorkers();
        getListCompanies();
    }, []);

    const getListWorkers = async () => {
        try {
            const responseWorker = await workersService.getListWorkers();
            if (responseWorker !== null && responseWorker !== undefined) {
                setWorkers(responseWorker.workers);
            }
        } catch (error) {
            // empty
        }
    };

    const getListCompanies = async () => {
        try {
            const responseCompanies =
                await clientCompanyService.getListCompanies();
            if (responseCompanies !== null && responseCompanies !== undefined) {
                setCompanies(responseCompanies.client_companies);
            }
        } catch (error) {
            // empty
        }
    };

    useEffect(() => {
        if (!openEdit) {
            setSelectedBill(null);
        }
    }, [openEdit]);

    return (
        <Main>
            <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row searchRow">
                        <div className="actionArea">
                            <button
                                className="button-create"
                                onClick={() => {
                                    setOpenCreate(true);
                                }}
                            >
                                <img
                                    width={'16px'}
                                    height={'16px'}
                                    src={plus}
                                />
                                新規作成
                            </button>
                        </div>
                    </div>
                    <BillingList
                        bills={bills}
                        getBills={getBills}
                        setSelectedBill={setSelectedBill}
                        setOpenEdit={setOpenEdit}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        totalCount={totalCount}
                    />
                </div>

                <ModalDelete>
                    <div
                        className="modal-create-user d-flex flex-column"
                        style={{ height: '152px' }}
                    >
                        <div className="modal-create-user-title d-flex flex-row">
                            本当に削除しますか?
                        </div>
                        <div
                            className="modal-create-user-buttons d-flex flex-row"
                            style={{ marginTop: '50px' }}
                        >
                            <button
                                className="modal-create-user-button1 d-flex flex-row"
                                onClick={closeDelete}
                            >
                                キャンセル
                            </button>
                            <button
                                className="modal-create-user-button2 d-flex flex-row"
                                onClick={onDeleteBill}
                            >
                                はい
                            </button>
                        </div>
                    </div>
                </ModalDelete>

                {openCreate && (
                    <BillingCreateDialog
                        open={openCreate}
                        setOpen={setOpenCreate}
                        project_id={id}
                        onFinishCreate={() => {
                            getBills({
                                page: 1,
                                per_page: pageQueries.per_page,
                            });
                            setOpenCreate(false);
                        }}
                        companies={companies}
                        workers={workers}
                        client_id={project?.client_company?.id}
                    />
                )}

                {openEdit && selectedBill && (
                    <EditBillingDialog
                        open={openEdit}
                        setOpen={setOpenEdit}
                        project_id={id}
                        selectedBill={selectedBill}
                        companies={companies}
                        workers={workers}
                        onFinishEdit={() => {
                            getBills({
                                page: 1,
                                per_page: pageQueries.per_page,
                            });
                            setOpenEdit(false);
                        }}
                    />
                )}
            </div>
        </Main>
    );
};

export default Billings;
