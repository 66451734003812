import { convertNestedObjToSearchParamsArray } from 'utils/helper/helpers';
import fetch from './api/config';

const API = {
    WORKING_TIMES: () => `/working_time_managements`,
    WORKING_TIMES_WORKERS: () => `/working_time_managements/workers`,
    WORKING_TIME_DETAIL: (id) => `/working_time_managements/${id}`,
    WORKING_TIME_CHECKIN: () => `/sp/working_time_managements/check_in`,
    WORKING_TIME_CHECKOUT: () => `/sp/working_time_managements/check_out`,
    WORKING_TIME_TODAY: () => `/sp/working_time_managements/today`,
};
export default class WorkingTimeService {
    static createWorkingTimes = (params: any) =>
        fetch.post(API.WORKING_TIMES(), params);

    static updateWorkingTimes = (id, params: any) =>
        fetch.put(API.WORKING_TIME_DETAIL(id), params);

    static deleteWorkingTimes = (id, params: any) =>
        fetch.delete(API.WORKING_TIME_DETAIL(id), params);

    static checkin = (params: any) =>
        fetch.post(API.WORKING_TIME_CHECKIN(), params);

    static checkout = (params: any) =>
        fetch.post(API.WORKING_TIME_CHECKOUT(), params);

    static getWorkingTimeToday = (params: any) =>
        fetch.get(API.WORKING_TIME_TODAY(), params);

    static getWorkingTime = (params: any) => {
        const url = `/working_time_managements`;
        return fetch.get(
            `${url}?${convertNestedObjToSearchParamsArray(params)}`,
            {},
        );
    }
    static getWorkingTimeDetail = (id, params) =>
        fetch.get(API.WORKING_TIME_DETAIL(id), params);

    static getWorkingTimeWorker = (params) => {
        const url = `/working_time_managements/workers`
        return fetch.get(`${url}?${convertNestedObjToSearchParamsArray(params)}`, {});
    }
}
