import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import orderBy from 'lodash/orderBy';

import { RootState } from 'store';
import messageService from 'services/messageService';

export type ReceivedProps = {
    chatRoom: any;
};

interface IValue {
    message: string | null | undefined;
    file: File[];
}

const useChatBox = (props: ReceivedProps) => {
    const location = useLocation();
    const { userData } = useSelector((state: RootState) => state.users);
    const workerId = (userData as any)?.worker?.id;
    const [loading, setLoading] = useState(false)
    const [listMessage, setListMessage] = useState<any[]>([]);
    //const [listImageMessage, setListImageMessage] = useState<any[]>([]);
    const [updateChat, setUpdateChat] = useState<any>(false);
    const [removeText, setRemoveText] = useState<any>(false);
    const refInput = useRef<any>(null);
    const [message, setMessage] = useState<any>('');
    const [typePreview, setTypeReivew] = useState('image');

    const removeImage = (idx: number, values: IValue, setFieldValue: (field: string, value: File[], shouldValidate?: boolean) => void) => {
        let current = [...values.file];
        current = current.filter((item, index) => index !== idx);
        setFieldValue('file', current);
    };

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>, values: IValue, setFieldValue: (field: string, value: File[], shouldValidate?: boolean) => void) => {
        if(!e?.target?.files) return;
        if (e?.target?.files.length) {
            const files: File[] = Array.from(e.target.files);
            let current = [...values.file];
            if (files.length > 0) {
                if (current.length > 0) {
                    current = current.concat(files);
                } else {
                    current = files;
                }
                setFieldValue('file', current);
            }
        }
    };

    const onSubmit = async (response: any) => {
        const { message, file } = response;
        if(!message && !file?.length) return
        if (!userData.worker) return;
        if(file?.filter((f: {size: number}) => f.size > 10 * 1024 * 1024)?.length > 0) return;
        let formData = new FormData();
        formData.append('chat_id', props.chatRoom?.id);
        formData.append('worker_id', workerId);
        setLoading(true);
        
        if (message) {
            formData.append('body', message ? message : '');
        }
        if (file.length > 0) {
            for (let x = 0; x < file.length; x++) {
                if(file[x].type.includes('video/')) {
                    formData.append('videos[]', file[x]!)
                } else {
                    formData.append('images[]', file[x]!);
                }
            }
        }

        try {
            let data = await messageService.createMessage(formData);
            setLoading(false);
            if (data) setUpdateChat(!updateChat);
            setMessage('');
        } catch (error) {
            //
        } finally {
            //setListImageMessage([]);
            setRemoveText(!removeText);
        }
    };

    const handleReceived = async (response) => {
        try {
            const { data } = response;
            const { message } = await messageService.getMessagesById(data.id);
            setListMessage((preState) => [...preState, message]);
        } catch (error) {}
    };

    useEffect(() => {
        var elements = document.getElementsByClassName('rcw-input');
        Array.from(elements).forEach((element) => (element.innerHTML = ''));
    }, [removeText]);
    useEffect(() => {
        (async () => {
            try {
                if (props.chatRoom?.id) {
                    const { messages } = await messageService.getMessages({
                        page: 1,
                        per_page: 99999,
                        chat_id: props.chatRoom?.id,
                    });
                    setListMessage(orderBy(messages, 'id', 'asc'));
                }
            } catch (error) {
                //
            }
        })();
    }, [location.pathname, props.chatRoom?.id, updateChat]);

    return {
        ...props,
        workerId,
        listMessage,
        setListMessage,
        onSubmit,
        //setListImageMessage,
        //listImageMessage,
        handleFileChange,
        refInput,
        removeImage,
        setMessage,
        handleReceived,
        setUpdateChat,
        message,
        chatRoomId: props.chatRoom?.id,
        typePreview,
        setTypeReivew,
        loading
    };
};

export type Props = ReturnType<typeof useChatBox>;

export default useChatBox;
