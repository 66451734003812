// Lib
import { requiredOnly } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createWorkingLogValidation = (t: TypeOfT) => ({
    name: () => ({
        required: requiredOnly(t),
    }),
    worked_at: () => ({
        required: requiredOnly(t),
    }),

    plan_id: () => ({
        required: requiredOnly(t),
    }),
    worker: () => ({
        required: requiredOnly(t),
    }),
    note: () => ({
        required: requiredOnly(t),
    }),
});

export default createWorkingLogValidation;
