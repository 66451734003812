export const positionValidation = (values: any) => {
    let errors = {};

    if (!values.name) {
        errors = {
            ...errors,
            name: '役職名は必須です。',
        };
    }

    return errors;
};
