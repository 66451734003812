import { useState, useEffect, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Button, { ButtonProps } from '@mui/material/Button';

const BaseButton = styled(Button)<ButtonProps>(({ theme }) => ({
    color: '#1C1E21',
}));

interface ChartButtonProps extends ButtonProps {
}

const ChartButton = (props: ChartButtonProps) => {
    const { children } = props
    return (
        <BaseButton {...props}>
            {children}
        </BaseButton>
    )
}
export default ChartButton