import styled from 'styled-components';

export const StylesUpdateProject = styled.div`
    .container-project-create-detail {
        /* width: calc(100% - 56px); */
        /* height: calc(100% - 120px); */
        /* height: 100%; */
        background-color: #e3e6e8;
        padding: 28px;
        /* overflow: hidden; */
        /* overflow-y: scroll; */
    }
    .custom-styles {
        border: 1px solid #CDD1D5;
        border-radius: 8px;
        input {
            border: none !important;
            
        }
    }
    .project-create-content {
        /* width: calc(100% - 56px);
    height: calc(100% - 56px); */
        width: calc(100%);
        background: #ffffff;
        border-radius: 12px;
        padding: 28px;
        overflow-y: auto;
        justify-content: flex-start;
    }
    .create-project-button1 {
        width: 100%;
        /* height: 8%; */
        justify-content: space-between;
        align-items: flex-start;
    }

    .create-project-title1 {
        font-family: 'Zen Kaku Gothic Antique';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #1c1e21;
    }

    .create-project-button2 {
        justify-content: center;
        align-items: center;
        border-color: #215493;
        font-family: 'Zen Kaku Gothic Antique';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #215493;
        padding: 10px 18px;
        gap: 8px;
        /* position: absolute; */
        width: 140px;
        height: 36px;
        border: 1px solid rgba(33, 84, 147, 0.4);
        filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
        border-radius: 6px;
        padding: 0;
    }

    .dropdown-create-user2 {
        width: calc(100%);
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 8px;
        height: 36px;
        background: #ffffff;
        font-family: 'FontAwesome';
    }

    .auto-height {
        margin-bottom: 12px;
        height: auto !important;
    }

    .target-datepicker-wrap {
        width: 100%;
    }

    .target-datepicker-wrap span {
        padding: 5px;
    }

    .button-create-project-field {
        width: 100%;
        justify-content: center;
        align-items: center;
        margin-top: 28px;
    }

    .create-project-input {
        width: 343px;
        /* height: 60px; */
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 12px;
    }

    .select-search {
        position: relative;
    }

    .button-create-client {
        position: absolute;
        right: -160px;
        bottom: 0;
        background-color: #215493;
        color: #ffffff;
    }
    .project-create-multiple-select-style {
        width: 100%;
        min-height: 38px;
        height: auto;
        border: 1px solid #CDD1D5;
        border-radius: 8px;
        padding: 0px;
        input {
            border: none !important;
        }
    }
    .project-create-multiple-select-style .MuiOutlinedInput-root {
        min-height: 38px;
        height: auto;
        padding: 0px !important;
    }

    .project-create-multiple-select-style .autocomplete-custom-textfield input {
        padding-left: 10px !important;
    }

    .project-create-multiple-select-style .MuiAutocomplete-endAdornment {
        display: none;
    }

    .project-create-multiple-select-style .MuiAutocomplete-root {
        height: 36px;
        padding: 0px !important;
    }

    .project-create-multiple-select-style fieldset {
        border-radius: 8px;
    }
`;
