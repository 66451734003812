import Api from './api/authApi';

const login = async(body: any) => {
    var response=await Api().post('login', body);
    localStorage.setItem("user", JSON.stringify(response.data));
    return response.data;
}
const register = async(body: any) =>{
    var response = await Api().post('register', body);
    return response.data;
}
const refreshToken = async(body: any) =>{
    var response = await Api().post('refresh-token', body);
    return response.data;
}

const logout = () => {
    localStorage.removeItem("user");
  };

const authService = {
    login,
    register,
    refreshToken,
    logout
};

export default authService;
