import styled from "styled-components";

const StyledUserListTable = styled.div`
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    overflow: auto;
    min-height: 100px;

    .update-user-btn,
    .delete-user-btn {
      width: 72px;
      height: 32px;
      border: none;
      border-radius: 6px;
      font-family: 'Zen Kaku Gothic Antique';
      font-style: normal;
      font-weight: 100;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      margin: 5px;
    }

    .update-user-btn {
      background: #215493;
    }
    
    .delete-user-btn {
      background: #FF5045;
    }
`;

export default StyledUserListTable;