import Api from './api/planApi';
import pick from 'lodash/pick';
// import planLists from '../shared/mocks/planLists.json';
import moment from 'moment';

const createPlan = async (input) => {
    console.log(input)
    let data = pick(input, [
        'assigned_worker_id',
        'name',
        'note',
        'project_id',
        'progress_percentage',
    ]);
    // if (input.assigned_worker_id)
    //     data.assigned_worker_id = input.assigned_worker_id.value;
    if (input.status) data.status_type = input.status.value;
    if (input?.start_at) {
        data.start_at = moment(input.start_at)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD');
    }
    if (input?.end_at) {
        data.end_at = moment(input.end_at)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD');
    }
    if (input?.start_at_actual) {
        data.start_at_actual = moment(input.start_at_actual)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD');
    }
    if (input?.end_at_actual) {
        data.end_at_actual = moment(input.end_at_actual)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD');
    }
    console.log(data)
    return await Api()
        .post('', data, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updatePlan = async (id, input) => {
    let data = pick(input, [
        'name',
        'note',
        'project_id',
        'progress_percentage',
        'assigned_worker_id'
    ]);
    if (input.status) data.status_type = input.status.value;
    if (input?.start_at) {
        data.start_at = moment(input.start_at)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD');
    }
    if (input?.end_at) {
        data.end_at = moment(input.end_at)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD');
    }
    if (input?.start_at_actual) {
        data.start_at_actual = moment(input.start_at_actual)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD');
    } else {
      data.start_at_actual = null;
    }
    if (input?.end_at_actual) {
        data.end_at_actual = moment(input.end_at_actual)
            .endOf('day')
            .utc()
            .format('YYYY-MM-DD');
    } else {
      data.end_at_actual = null;
    }

    return await Api()
        .put(`${id}`, data, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deletePlan = async (id, project_id: number) => {
    return await Api()
        .delete(`${id}?project_id=${project_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getPlan = async (id: number, project_id: number) => {
    return await Api()
        .get(`${id}?project_id=${project_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getListPlan = async (project_id: number, input?: any) => {
    // return planLists;
    let data = pick(input, ['page', 'per_page']);
    if (project_id) {
      data.project_id = project_id;
    }
    const params = new URLSearchParams(data);
    return await Api()
        .get(`?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const plansService = {
    createPlan,
    updatePlan,
    deletePlan,
    getPlan,
    getListPlan,
};

export default plansService;
