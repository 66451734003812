import { useEffect, useState } from 'react';
import 'react-dropdown/style.css';
import { useModal } from 'react-hooks-use-modal';
import { useNavigate } from 'react-router-dom';
import BudgetService from '../../services/budgetService';
import './budget.css';
import { Pagination, Space, Table } from 'antd';
import { StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { useLogicBudget } from 'pages/project/projectPlan/budgetAction/hooks';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import calendarIcon from '../../assets/icons/calendar.png';
import projectsService from 'services/projectsService';
import workersService from 'services/wokersService';
import CustomPagination from 'components/CustomPagination';

const Budget = () => {
    const navigate = useNavigate();
    const {
        register,
        control,
        handleSubmit,
        watch,
        reset,
        setError,
        setValue,
        getValues,
        formState: { errors },
    } = useForm();
    const [budgetsList, setBudgetList] = useState<any>(null);

    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState({
        page: 1,
        per_page: 30,
    });
    const [searchedState, setSearchedState] = useState({
        cooperative_company_id: '',
        department_id: undefined,
        free_word: '',
        gender_type: undefined,
        position_id: undefined,
    });

    const [BudgetDelete, setBudgetDelete] = useState<any>(null);

    const onSearch = (data) => {
        setSearchedState({ ...data });
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        getListBudget({
            page: 1,
            per_page: pageQueries.per_page,
            data: {
                ...data,
                project_id: data?.project_id?.value || '',
                assigned_worker_id: data?.assigned_worker_id?.value || '',
                approver_worker_id: data?.approver_worker_id?.value || '',
                status: data?.status?.value || '',
            },
        });
    };

    const getListBudget = async ({ page = 1, per_page = 30, data }: any) => {
        try {
            const response = await BudgetService.getListBudget({
                page,
                per_page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                setBudgetList(response.budgets);
                setTotalCount(response.meta.total_count);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const deleteBudget = async () => {
        try {
            const response = await BudgetService.deleteBudget(
                BudgetDelete?.project?.id,
                BudgetDelete?.id,
            );
            if (pageQueries.page !== 1) {
                setPageQueries((prevState) => ({ ...prevState, page: 1 }));
            }
            getListBudget({
                page: 1,
                per_page: pageQueries.per_page,
                data: { ...searchedState },
            });
            setBudgetDelete(null);
            closeDelete();
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        getListBudget({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            // //closeOnOverlayClick: false,
        },
    );
    const { statusOptions: estimateBudget } = useLogicBudget();
    const renderStatusItem = (statusProps = '') => {
        const item = estimateBudget.find((i) => i.value === statusProps);
        return item?.label || '';
    };
    const columns = [
        {
            title: '番号',
            dataIndex: 'id',
            key: 'id',
            render: (text) => <div>{text}</div>,
        },
        {
            title: '案件名',
            dataIndex: 'project',
            key: 'project',
            render: (text) => <div>{text?.name}</div>,
        },
        {
            title: '見積担当',
            dataIndex: 'assigned_worker',
            key: 'assigned_worker',
            render: (text) => <div>{text?.name}</div>,
        },
        {
            title: '承認日',
            dataIndex: 'approve_expired_at',
            key: 'approve_expired_at',
            render: (row) => (
                <div>{row ? moment(row).format('YYYY/MM/DD') : ''}</div>
            ),
        },
        {
            title: '作成日',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (row) => (
                <div>{row ? moment(row).format('YYYY/MM/DD') : ''}</div>
            ),
        },
        {
            title: '承認担当',
            dataIndex: 'approver_worker',
            key: 'approver_worker',
            render: (text) => <div>{text?.name}</div>,
        },
        {
            title: '状態',
            dataIndex: 'status',
            key: 'status',
            render: (text) => <div>{renderStatusItem(text)}</div>,
        },
        {
            title: '操作',
            key: 'action',
            render: (row) => (
                <Space size="middle" className="flex justify-center !gap-[0px]">
                    <button
                        className="button-tb"
                        style={{
                            width: '72px',
                            margin: '5px',
                        }}
                        onClick={() =>
                            navigate(
                                `/projects/budget/${row?.project?.id}/edit/${row?.id}?type=detail&from=list`,
                            )
                        }
                    >
                        詳細
                    </button>
                    <button
                        className="button-tb"
                        style={{
                            width: '72px',
                            margin: '5px',
                        }}
                        onClick={() =>
                            navigate(
                                `/projects/budget/${row?.project?.id}/edit/${row?.id}?from=list`,
                            )
                        }
                        // onClick={() => { navigate(`/workers/edit/${row.id}`) }}
                    >
                        編集
                    </button>
                    <button
                        className="button-tb"
                        style={{
                            width: '72px',
                            margin: '5px',
                            background: '#FF5045',
                        }}
                        onClick={() => {
                            openDelete();
                            setBudgetDelete(row);
                        }}
                    >
                        削除
                    </button>
                </Space>
            ),
        },
    ];

    const [departmentOption, setDepartmentOption] = useState<any>([]);
    const [workerOptions, setWorkerOptions] = useState<any>([]);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const handleStartAtChange = (date: any) => {
        setStartDate(date);
    };
    const handleEndAtChange = (date: any) => {
        setEndDate(date);
    };
    const fetchListWorkers = async () => {
        try {
            const response = await workersService.getListWorkers({
                page: 1,
                per_page: 0,
            });
            setWorkerOptions(
                response?.workers.map((worker) => ({
                    label: worker.name,
                    value: worker.id,
                })),
            );
        } catch (error) {}
    };
    const fetchListProjects = async () => {
        try {
            const response = await projectsService.getListProjects({
                page: 1,
                per_page: 0,
            });
            setDepartmentOption(
                response?.projects?.map((project) => ({
                    label: project?.name,
                    value: project?.id,
                })),
            );
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchListWorkers();
        fetchListProjects();
    }, []);
    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column">
                <form onSubmit={handleSubmit(onSearch)}>
                    <div className="search-workers d-flex flex-column">
                        <div className="search-workers-row d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>案件名</p>
                                    <Controller
                                        name="project_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    {
                                                        label: '全て',
                                                        value: '',
                                                    },
                                                    ...departmentOption,
                                                ]}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column !items-center">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>見積担当</p>
                                    <Controller
                                        name="assigned_worker_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    {
                                                        label: '全て',
                                                        value: '',
                                                    },
                                                    ...workerOptions,
                                                ]}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column !items-end">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>承認担当</p>
                                    <Controller
                                        name="approver_worker_id"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    {
                                                        label: '全て',
                                                        value: '',
                                                    },
                                                    ...workerOptions,
                                                ]}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="search-workers-row d-flex flex-row">
                            <div
                                className="input-add-user d-flex flex-column !max-w-none"
                                style={{ width: '33.33%' }}
                            >
                                <p style={{ marginTop: '1px' }}>作成日</p>
                                <div className="datepicker-bill max-w-[270px]">
                                    <div className="target-datepicker-wrap d-flex flex-row">
                                        <div
                                            className="input-date"
                                            style={{
                                                position: 'relative',
                                                width: '50%',
                                            }}
                                        >
                                            <Controller
                                                name="start_at"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={startDate}
                                                        {...field}
                                                        onChange={(date) => {
                                                            field?.onChange(
                                                                date,
                                                            );
                                                            handleStartAtChange(
                                                                date,
                                                            );
                                                        }}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsStart
                                                        maxDate={endDate}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                )}
                                            />
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </div>
                                        <span className="symbol">~</span>
                                        <div
                                            className="input-date"
                                            style={{
                                                position: 'relative',
                                                width: '50%',
                                            }}
                                        >
                                            <Controller
                                                name="end_at"
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        selected={endDate}
                                                        {...field}
                                                        onChange={(date) => {
                                                            field?.onChange(
                                                                date,
                                                            );
                                                            handleEndAtChange(
                                                                date,
                                                            );
                                                        }}
                                                        startDate={startDate}
                                                        endDate={endDate}
                                                        selectsEnd
                                                        minDate={startDate}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                )}
                                            />
                                            <img
                                                className="input-date-img"
                                                width={'16px'}
                                                height={'16px'}
                                                src={calendarIcon}
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="search-workers-row-dropdown margin-left d-flex flex-column !items-center">
                                <div className="d-flex flex-column input-add-user">
                                    <p style={{ marginTop: 0 }}>状態</p>
                                    <Controller
                                        name="status"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    {
                                                        label: '全て',
                                                        value: '',
                                                    },
                                                    ...estimateBudget,
                                                ]}
                                                {...field}
                                                placeholder=""
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-button margin-left d-flex flex-column">
                                <button
                                    className="search-user-button d-flex flex-column"
                                    type="submit"
                                    style={{
                                        width: '150px',
                                        height: '36px',
                                        marginBottom: '12px',
                                    }}
                                >
                                    検索
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                <Table
                    className="table-budget w-full"
                    columns={columns}
                    dataSource={budgetsList}
                    pagination={false}
                    scroll={{
                        x: budgetsList ? 1200 : undefined,
                    }}
                />
                <ModalDelete>
                    <div
                        className="modal-create-user d-flex flex-column"
                        style={{ height: '152px' }}
                    >
                        <div className="modal-create-user-title d-flex flex-row">
                            本当に削除しますか?
                        </div>
                        <div
                            className="modal-create-user-buttons d-flex flex-row"
                            style={{ marginTop: '50px' }}
                        >
                            <button
                                className="modal-create-user-button1 d-flex flex-row"
                                onClick={closeDelete}
                            >
                                キャンセル
                            </button>
                            <button
                                onClick={deleteBudget}
                                className="modal-create-user-button2 d-flex flex-row"
                            >
                                はい
                            </button>
                        </div>
                    </div>
                </ModalDelete>
                {budgetsList && budgetsList.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getListBudget({
                                page,
                                per_page: pageQueries.per_page,
                                data: { ...searchedState },
                            })
                        }
                    />
                )}
                {/* <Pagination
          className="flex gap-3 justify-center !mt-[30px]"
          itemRender={itemRender}
          total={totalCount}
          current={currentPage}
          onChange={onChange}
          pageSize={30}
        /> */}
            </div>
        </div>
    );
};

export default Budget;
