import axios, { AxiosRequestConfig } from 'axios';
const api = process.env.REACT_APP_SERVER_API;

export default function () {
    axios.defaults.baseURL = api;
    axios.interceptors.request.use(
        (config: AxiosRequestConfig): AxiosRequestConfig => {
            let storage = localStorage.getItem('user');
            const user = storage !== null ? JSON.parse(storage) : null;
            if (
                config?.headers &&
                user.access_token !== undefined &&
                user.access_token !== null
            ) {
                config.headers = {
                    ...config.headers,
                    Authorization: `Bearer ${user.access_token}`,
                };
            }

            return config;
        },
        (err) => Promise.reject(err),
    );
}
