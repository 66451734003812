import Api from './api/budgetItemApi';
import pick from 'lodash/pick';

const createBudgetItem = async (payload) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateBudgetItem = async (budgetId: number, payload: any) => {
    return await Api()
        .put(`${budgetId}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteBudgetItem = async (id?: any, budget_id?: any) => {
    return await Api()
        .delete(`${id}`, {
            data: { budget_id: budget_id },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const importBudgetItem = async (payload) => {
    return await Api()
        .post('/import', payload, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getDetailBudgetItem = async (input?: any) => {
    let data = pick(input, ['project_id', 'budget_id']);
    const params = new URLSearchParams(data);

    return await Api()
        .get(`/${input?.id}?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getListBudgetItem = async (input?: any) => {
    let data = pick(input, ['page', 'per_page', 'budget_id']);

    const params = new URLSearchParams(data);

    return await Api()
        .get(`?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const budgetItemService = {
    createBudgetItem,
    // createChildEstimateItem,
    updateBudgetItem,
    deleteBudgetItem,
    getDetailBudgetItem,
    getListBudgetItem,
    importBudgetItem,
};

export default budgetItemService;
