import styled from 'styled-components'

const StyledDetailWrapper = styled.div`
  width: 500px;
  border-radius: 12px;
  padding: 28px;

  .dialogTitle {
    width: 100%;
    font-family: 'Zen Kaku Gothic Antique';
    font-weight: 700;
    font-size: 18px;
    text-align: center;
  }

  .detailRow {
    width: 100%;
    display: flex;
    flex-direction: column;

    p {
      font-weight: 500;
      font-size: 14px;
      color: #344054;
      margin-bottom: 4px;
      margin-top: 12px;
    }

    .textfield-style {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      border: 1px solid #CDD1D5;
      .MuiOutlinedInput-notchedOutline {
        border: 0;
      }
      input {
        height: 36px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        padding-left: 12px;
        width: 210px;
        border-radius: 8px;

        &.Mui-disabled {
          -webkit-text-fill-color: #000;
        }
      }
    }

    .textarea {
      padding: 10px 12px;
      width: 100%;
      height: 84px;
      background: #FFFFFF;
      border: 1px solid #CDD1D5;
      border-radius: 8px;
      resize: vertical;
    }

    .imagesContainer {
      display: flex;
      flex-wrap: wrap;
      gap: 20px;

      .imageWrapper {
        width: 122px;
        height: 110px;
        position: relative;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;    
    margin-top: 20px;

    .cancelBtn {
      width: 190px;
      height: 44px;
      border-radius: 8px;
      border: 1px solid #215493;
      color:  #215493;
    }
  }
`

export default StyledDetailWrapper;