import { Dialog } from '@mui/material';
import { alertError } from 'utils/helper/appHelper';
import StyledDeleteDialogWrapper from './style';
import departmentsService from 'services/departmentsService';

const DeleteDepartmentDialog = ({
    selectedDepartment,
    open,
    setOpen,
    onFinish,
}) => {
    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteDepartment = async () => {
        try {
            const data = await departmentsService.deleteDepartments(
                selectedDepartment.id,
            );
            if (data) {
                onFinish();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || '';
            setOpen(false);
            alertError(errorMessage);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
            <StyledDeleteDialogWrapper>
                <div className="delete-popup-title">本当に削除しますか?</div>
                <div className="delete-popup-button-group">
                    <button className="return-button" onClick={handleClose}>
                        キャンセル
                    </button>
                    <button
                        className="register-button"
                        onClick={handleDeleteDepartment}
                    >
                        はい
                    </button>
                </div>
            </StyledDeleteDialogWrapper>
        </Dialog>
    );
};

export default DeleteDepartmentDialog;
