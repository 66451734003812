import { useEffect, useState } from 'react';
import inspectionService from 'services/inspectionRecordService';
import ProjectService from 'services/projectServiceSP';
import { IInspectionRecord, IMachineModel, IMachineModelInspectItem, IPurchase, IInspectionRecordsMapMachineModel, IInpesctRecordUpdate } from './type';
import { IProject } from 'models';

export type ReceivedProps = Record<string, never>;

const useInspectionRecord = (props: ReceivedProps) => {
    const [data, setData] = useState<IPurchase[]>([]);
    const [machineModels, setMachineModels] = useState<IMachineModel[]>([]);
    const [inspectionRecords, setInspectionRecords] = useState<IInspectionRecord[]>([]);
    const [machineModelInspectItems, setMachineModelInspectItems] = useState<IMachineModelInspectItem[]>([])
    const [projectsList, setProjectsList] = useState<IProject[]>([]);
    const [valueProject, setValueProject] = useState<IProject | null>(null);
    const [valueDate, setValueDate] = useState("");
    const [valueMachineModel, setValueMachineModel] = useState<IMachineModel | null>();
    const [isShowButton, setIsShowButton] = useState(true);
    const [inspectionRecordsNotChange, setInspectionRecordNotChange] = useState<IInspectionRecord[]>([]);

    const getListProjects = async () => {
        try {
            const res = await ProjectService.getProjectLists({
                page: 1,
                per_page: 99999,
                ...data,
            });
            if (!(res as { projects: IProject[] })?.projects) return;
            const projects = (res as { projects: IProject[] })?.projects;
            setProjectsList(projects);
        } catch (err) { }
    };

    const fetchMachineModels = async (params = {}) => {
        try {
            const res =
                await inspectionService.getMachineModels(
                    {
                        page: 1,
                        per_page: 99999,
                        ...params
                    },
                );
            if (!(res as { machine_models: IMachineModel[] })?.machine_models) return;
            const machine_models = (res as { machine_models: IMachineModel[] })?.machine_models;
            setMachineModels(machine_models);
            //if (res) {
            //    fetchMachineModelInspectItem(machine_models[0].id)
            //    setValueMachineModel(machine_models[0])
            //}
        } catch (error) {
            console.log(error)
        }
    };

    const fetchMachineModelInspectItem = async () => {
        if (!valueDate || !valueProject || !valueMachineModel) return;
        try {
            const res =
                await inspectionService.getListMachineModelInspectItems(
                    {
                        machine_model_id: valueMachineModel?.id,
                    },
                );
            if (!(res as { machine_model_inspect_item: IMachineModelInspectItem[] })?.machine_model_inspect_item) return;
            const machine_model_inspect_item = (res as { machine_model_inspect_item: IMachineModelInspectItem[] })?.machine_model_inspect_item;
            setMachineModelInspectItems(machine_model_inspect_item);
        } catch (error) {
            console.log(error)
        }
    };

    const fetchInspectionRecords = async (params = {}) => {
        if (!valueDate || !valueProject || !valueMachineModel) return;
        const date = new Date(valueDate)
        let data = Object.assign({},
            { page: 1, per_page: 99999 },
            !valueDate ? null : { inspection_day: date?.getDate(), inspection_month: date?.getMonth() + 1, inspection_year: date?.getFullYear() },
            !valueProject ? null : { project_id: valueProject?.id },
            !valueMachineModel ? null : { machine_model_id: valueMachineModel?.id },
        );
        try {
            const res =
                await inspectionService.getListInspectionRecordsSP(
                    {
                        ...data,
                        ...params
                    },
                );
            if (!(res as { inspection_records: IInspectionRecord[] })?.inspection_records) return;
            const inspection_records = (res as { inspection_records: IInspectionRecord[] })?.inspection_records;
            setInspectionRecords(inspection_records);
            setInspectionRecordNotChange(inspection_records);
        } catch (error) {
            console.log(error)
        }
    };

      //map data per row
      let listDataRender: IInspectionRecordsMapMachineModel[] = [];
      if (inspectionRecords !== null) {
          for (let i = 0; i < inspectionRecords.length; i++) {
              for (let j = 0; j < machineModelInspectItems.length; j++) {
                  if (inspectionRecords[i].machine_model_inspect_item_id === machineModelInspectItems[j].id) {
                      listDataRender.push({ ...inspectionRecords[i], inspect_item: machineModelInspectItems[j].inspect_item })
                  }
              }
          }
      }
      listDataRender?.sort(function(a, b) {
        return a?.machine_model_inspect_item_id - b?.machine_model_inspect_item_id;
      });

    const onSubmit = async () => {
        if (listDataRender?.length > 0) {
            let arr: IInpesctRecordUpdate[] = [];
            for(let i = 0; i < listDataRender?.length; i++) {
                for(let j = 0; j < inspectionRecordsNotChange?.length; j++) {
                    if(listDataRender[i].id === inspectionRecordsNotChange[j].id && listDataRender[i].inspection_status !== inspectionRecordsNotChange[j].inspection_status) {
                        arr.push({id: listDataRender[i]?.id, inspection_status: listDataRender[i]?.inspection_status})
                    }
                }
            }
            const resUpdate = await inspectionService.updateInspectionRecords({inspection_records: arr});
            if(resUpdate) {
                fetchInspectionRecords();
                setIsShowButton(false);
            }
        } else {
            if (!valueDate || !valueProject || !valueMachineModel) return;
            let dataInspectionRecords = machineModelInspectItems?.map(item => {
                return { machine_model_inspect_item_id: item?.id, inspection_status: item?.inspection_status || "" }
            })
            const paramsCreate = {
                inspection_date: valueDate,
                project_id: valueProject.id,
                company_id: valueProject.client_company?.company?.id,
                inspection_records: dataInspectionRecords
            }

            const res = await inspectionService.createInspectionRecords(paramsCreate);
            if (res) {
                fetchInspectionRecords();
                setIsShowButton(false);
            }
        }


    }

    useEffect(() => {
        fetchMachineModels();
        getListProjects();
    }, []);

    useEffect(() => {
        fetchInspectionRecords();
        fetchMachineModelInspectItem()
    }, [valueDate, valueProject, valueMachineModel]);

    return {
        ...props,
        machineModels,
        inspectionRecords,
        machineModelInspectItems,
        setMachineModelInspectItems,
        setInspectionRecords,
        projectsList,
        valueProject,
        setValueProject,
        valueDate,
        setValueDate,
        valueMachineModel,
        setValueMachineModel,
        onSubmit,
        fetchMachineModelInspectItem,
        fetchInspectionRecords,
        listDataRender,
        isShowButton,
        setIsShowButton
    };
};

export type Props = ReturnType<typeof useInspectionRecord>;

export default useInspectionRecord;
