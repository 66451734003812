import { FC } from 'react';

type IProps = {
    strokeColor?: string;
};

const ClipboardText: FC<IProps> = ({ strokeColor = '#CDD1D5' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 14.25h6M9 11.25h6M15 3.75h3.75a.75.75 0 01.75.75v15.75a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V4.5a.75.75 0 01.75-.75H9"
            ></path>
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 6.75V6a3.75 3.75 0 017.5 0v.75h-7.5z"
            ></path>
        </svg>
    );
};

export default ClipboardText;
