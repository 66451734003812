// Lib
import { requiredOnly } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createManifestValidation = (t: TypeOfT) => ({
    index: () => ({
      required: requiredOnly(t),
    }),
    date_at: () => ({
      required: requiredOnly(t),
    }),
    manifest_no: () => ({
      required: requiredOnly(t),
    }),
    order_item: () => ({
      required: requiredOnly(t),
    }),
    waste_type: () => ({
      required: requiredOnly(t),
    }),
    amount: () => ({
      required: requiredOnly(t),
    }),
    unit: () => ({
      required: requiredOnly(t),
    }),
    volume: () => ({
      required: requiredOnly(t),
    }),
    step: () => ({
      required: requiredOnly(t),
    }),
    collect_company: () => ({
      required: requiredOnly(t),
    }),
    disposal_company: () => ({
      required: requiredOnly(t),
    }),
    final_disposal_company: () => ({
      required: requiredOnly(t),
    }),
    final_disposal_at: () => ({
      required: requiredOnly(t),
    }),
});

export default createManifestValidation;
