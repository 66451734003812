export const DeleteIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.75 5.25H4.25"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.25 9.75V15.75"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.75 9.75V15.75"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M19.25 5.25V19.5C19.25 19.6989 19.171 19.8897 19.0303 20.0303C18.8897 20.171 18.6989 20.25 18.5 20.25H6.5C6.30109 20.25 6.11032 20.171 5.96967 20.0303C5.82902 19.8897 5.75 19.6989 5.75 19.5V5.25"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.25 5.25V3.75C16.25 3.35218 16.092 2.97064 15.8107 2.68934C15.5294 2.40804 15.1478 2.25 14.75 2.25H10.25C9.85218 2.25 9.47064 2.40804 9.18934 2.68934C8.90804 2.97064 8.75 3.35218 8.75 3.75V5.25"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
