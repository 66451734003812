import fetch from './api/config';

const API = {
    GET_LIST_VEHICLE_INSPECTION: () => `/checklists`,
    GET_LIST_VEHICLE_INSPECTION_SP: () => `sp/checklists`,
    DELETE_WORKING_LOG: (id: number) => `/sp/working_logs/${id}`,
    UPLOAD_WORKING_LOG_IMAGE: (id) => `/sp/working_logs/${id}/add_images`,
    VEHICLE_INSPECTION_DETAIL: (id) => `/checklists/${id}`,
};

export default class VehicleInspectionService {
    static getListVehicleInspection = (params: any) =>
        fetch.get(API.GET_LIST_VEHICLE_INSPECTION(), params);

    static getListVehicleInspectionSP = (params: any) =>
        fetch.get(API.GET_LIST_VEHICLE_INSPECTION_SP(), params);
        
    static deleteWorkingLog = (id: number, project_id: number) =>
        fetch.delete(API.DELETE_WORKING_LOG(id), { project_id: project_id });

    static uploadImage = (id: number, params: any) =>
        fetch.put(API.UPLOAD_WORKING_LOG_IMAGE(id), params);

    static deleteVehicleInspection = (id: number) =>
        fetch.delete(API.VEHICLE_INSPECTION_DETAIL(id), {});

    static createVehicleInspection = (params: any) =>
        fetch.post(API.GET_LIST_VEHICLE_INSPECTION(), params);

    static getVehicleInspectionDetail = (id: number) =>
        fetch.get(API.VEHICLE_INSPECTION_DETAIL(id), {});

    static updateVehicleInspection = (id: number, params: any) =>
        fetch.put(API.VEHICLE_INSPECTION_DETAIL(id), params);
}
