import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ProjectService from 'services/projectServiceSP';
import sugorokuService from 'services/sugorokuService';
import ProgressStep from '../projectPlan/progressStep';

const ProjectTabelRow = (props) => {
    const { title, value } = props;
    return (
        <div className="project-detail-inf-row-sp d-flex flex-row">
            <div className="project-detail-inf-row-title-sp">{title}</div>
            <div className="project-detail-inf-row-content-sp">{value}</div>
        </div>
    );
};

const ProjectDetailSP = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [project, setProject] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [sugorokuList, setSugorokuList] = useState([]);
    const [accessSugoroku, setAccessSugoroku] = useState<boolean>(false);

    const checkSugorokuPermission = async () => {
        try {
            const { access_sugoroku }: any =
                await ProjectService.checkPermissions(Number(id));
            setAccessSugoroku(access_sugoroku);
        } catch (error) {}
    };

    const getDetailProject = async () => {
        if (!id) return;
        try {
            let sugoroku;
            const response: any = await ProjectService.getProjectDetail(
                Number(id),
            );
            setProject(response.project);
            if (response?.project?.business_type == '解体') {
                sugoroku = await sugorokuService.getListOfSugoroku(Number(id));
            } else {
                sugoroku = await sugorokuService.getListOfWasteTypeSugoroku(
                    Number(id),
                );
            }
            setSugorokuList(sugoroku?.sugoroku_events);
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            navigate('/sp/projects');
        }
    };
    useEffect(() => {
        getDetailProject();
        checkSugorokuPermission();
    }, []);

    return (
        <Box
            sx={{
                '.MuiTypography-root, button': {
                    fontFamily: 'Zen Kaku Gothic Antique',
                },
            }}
            padding="16px"
        >
            {isLoading ? (
                <Box display="flex" justifyContent="center" marginTop="3rem">
                    <CircularProgress />
                </Box>
            ) : (
                <>
                    <Typography
                        fontWeight="700"
                        fontSize="22px"
                        lineHeight="26px"
                        color="#1C1E21"
                        sx={{ marginBottom: '24px' }}
                    >
                        {project?.name}
                    </Typography>
                    {accessSugoroku && (
                        <Box
                            sx={{
                                width: '100%',
                                overflowX: 'scroll',
                                pt: '30px',
                                '.step-progress-container': {
                                    width: '901px',
                                },
                                '.step-progress-container-top, .step-progress-container-bottom ':
                                    {
                                        width: '1000px',
                                    },
                                '.MuiStepLabel-label': {
                                    width: 'max-content',
                                    minWidth: '60px',
                                },
                            }}
                        >
                            <ProgressStep
                                // readOnly
                                business_type={project?.business_type == '産廃'}
                                project_id={Number(id)}
                            />
                        </Box>
                    )}

                    <Grid
                        container
                        sx={{
                            border: '1px solid #CDD1D5',
                            borderRadius: '5px',
                            mb: '47px',
                            '.MuiGrid-item': {
                                borderBottom: '1px solid #f5f5f5',
                            },
                            '.MuiGrid-item:nth-child(7)': {
                                border: 'none',
                            },
                            '.MuiGrid-item:nth-child(8)': {
                                border: 'none',
                            },
                        }}
                    >
                        <Grid item xs={6}>
                            <ProjectTabelRow
                                title="顧客"
                                value={project?.client_company?.company?.name}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ProjectTabelRow
                                title="見積担当"
                                value={project?.management_charge}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ProjectTabelRow
                                title="工期"
                                value={project?.start_at === null && project?.end_at === null ? '' : `${project?.start_at || ''} ~ ${project?.end_at || ''}`}
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <ProjectTabelRow
                                title="協力会社"
                                value={
                                    <ul className="cooperative-companies-list">
                                        {project &&
                                        project?.cooperative_companies ? (
                                            project?.cooperative_companies.map(
                                                (item) => (
                                                    <li>{item.company.name}</li>
                                                ),
                                            )
                                        ) : (
                                            <></>
                                        )}
                                    </ul>
                                }
                            />
                        </Grid>

                        <Grid item xs={6}>
                            <ProjectTabelRow
                                title="場所"
                                value={project?.location_address}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ProjectTabelRow
                                title="金額"
                                value={project?.total_amount}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ProjectTabelRow
                                title="管理者"
                                value={project?.manager_worker?.name}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ProjectTabelRow
                                title="業種"
                                value={project?.business_type}
                            />
                        </Grid>
                    </Grid>
                    <Typography
                        fontWeight="500"
                        fontSize="16px"
                        lineHeight="20px"
                        color="#344054"
                        sx={{ marginBottom: '12px' }}
                    >
                        {project?.start_at && moment(project?.start_at).format('YYYY年M月D日')}
                    </Typography>
                    <Box paddingTop="12px" borderTop="1px solid #CDD1D5">
                        <Grid
                            sx={{
                                '.MuiTypography-root': {
                                    fontWeight: '500',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                },
                            }}
                            container
                            columnSpacing={4}
                        >
                            {project?.plans?.map((plan, indx) => (
                                <>
                                    <Grid marginBottom="12px" item xs={5}>
                                        <Typography color="#344054">
                                            {plan?.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Typography color="#344054">
                                            {`${
                                                plan?.progress_percentage
                                            }% ~${moment(
                                                project?.end_at,
                                            ).format('M月D日')}`}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        sx={{ paddingLeft: '10px !important' }}
                                        textAlign="right"
                                        item
                                        xs={2}
                                    >
                                        <Typography color="#FF5045">
                                            {plan?.status_type}
                                        </Typography>
                                    </Grid>
                                </>
                            ))}
                        </Grid>
                    </Box>
                    <Box
                        sx={{
                            mt: '48px',
                            button: {
                                backgroundColor: '#215493',
                                padding: '10px 18px',
                                width: '100%',
                                marginBottom: '16px',
                                fontSize: '16px',
                                fontWeight: '500',
                                lineHeight: '24px',
                                boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                borderRadius: '8px',
                            },
                        }}
                    >
                        <Link to={`/sp/projects/${id}/plans`}>
                            <Button variant="contained">工程確認</Button>
                        </Link>
                        {/* <Link to={`/sp/working_logs/create/${id}`}>
                            <Button variant="contained">作業登録（日報）</Button>
                        </Link> */}
                        <Link to={`/sp/project/${id}/chats`}>
                            <Button variant="contained">チャット</Button>
                        </Link>
                        <Link to={`/sp/project/${id}/weekly-report`}>
                            <Button variant="contained">週報</Button>
                        </Link>
                    </Box>
                </>
            )}
        </Box>
    );
};
export default ProjectDetailSP;
