import { Dialog } from '@mui/material';
import { alertError } from 'utils/helper/appHelper';
import NewsService from 'services/newsService';
import StyledDeleteNewsWrapper from './style';

const DeleteNewsDialog = ({
    selectedNews,
    open,
    setOpen,
    onFinish
  }) => {
  const handleClose = () => {
    setOpen(false);
  }

  const handleDeleteNews = async () => {
    try {
      const data = await NewsService.deleteNews(selectedNews.id);
      if (data) {
        onFinish();
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error || "";
      setOpen(false);
      alertError(errorMessage);
    }
  }
  

  return (
    <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
      <StyledDeleteNewsWrapper>
        <div className="delete-popup-title">本当に削除しますか?</div>
        <div className="delete-popup-button-group">
            <button className="return-button" onClick={handleClose}>キャンセル</button>
            <button className='register-button' onClick={handleDeleteNews}>削除</button>
        </div>
      </StyledDeleteNewsWrapper>
    </Dialog>
  )
}

export default DeleteNewsDialog
