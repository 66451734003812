import { useState, useEffect } from 'react';
import './WorkingReport.css';
import plus from '../../../../assets/icons/Plus.png';
import { RootState, useAppDispatch } from '../../../../store/index';
import { useSelector } from 'react-redux';
import xCircle from 'assets/icons/XCircle.svg';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { Worker, Viewer, OpenFile } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import {
    setAddSuccess,
    setEditSuccess,
    setFetchingLog,
    setLogs,
    setShowModalCreate,
    setShowModalDetail,
    setShowModalUpdate,
} from 'store/workingReport/workingReport';
import workingReportsService from 'services/workingReportsService';
import moment from 'moment';
import { useParams, useNavigate } from 'react-router-dom';
import WorkingLogServiceSP from 'services/workingLogServiceSP';
import { formatDateTime } from 'utils/helper/dateHelper';
import Dialog from '@mui/material/Dialog';
import calendarIcon from '../../../../assets/icons/calendar.png';
import DatePicker from 'react-datepicker';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Button,
} from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import WorkingReportLog from './WorkingReportLog';
import { useModal } from 'react-hooks-use-modal';
import createWorkingReportValidation from 'utils/validation/registers/workingReports/createWorkingReportValidation';
import _ from 'lodash';
import projectsService from 'services/projectsService';
import TrashIcon from 'assets/icons/delete_ic.svg';
import ViewIcon from 'assets/icons/view_ic.svg';
import EditIcon from 'assets/icons/edit_ic.svg';
import ReportServiceSP from 'services/reportServiceSP';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '8px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const printPdfFile = function (url: any) {
    const iframe = document.createElement('iframe');
    document.body.appendChild(iframe);
    iframe.style.display = 'none';
    iframe.src = url;
    iframe.onload = function () {
        setTimeout(function () {
            iframe.focus();
            console.log(iframe?.contentWindow);
            iframe?.contentWindow?.print();
        }, 100);
    };
};

const WorkingReportCreateSP = ({}) => {
    const { t } = useTranslation();
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            // `file.name` is the URL of opened file
            const fileName = file.name.substring(
                file.name.lastIndexOf('/') + 1,
            );
            return `report-${fileName}.pdf`;
        },
    });
    const { Download } = getFilePluginInstance;
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
    } = useForm();
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const {
        showModalCreate = false,
        showModalDetail = false,
        showModalUpdate = false,
        selectedReport = {} as any,
        workers = [],
        logs = [] as any[],
    } = useSelector((state: RootState) => state.workingReport);
    const [currentProject, setCurrentProject] = useState<any>(null);

    const getDetailProject = async (id: number) => {
        if (!id) return;
        try {
            const response = await projectsService.getProject(id);
            setCurrentProject(response.project);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (id) {
            getDetailProject(Number(id));
        }
    }, [id]);

    const [selectedRow, setSelectedRow] = useState<any>();

    const [ModalCreate, openCreate, closeCreate] = useModal('root', {
        preventScroll: false,
        //closeOnOverlayClick: false,
    });
    const [ModalUpdate, openUpdate, closeUpdate] = useModal('root', {
        preventScroll: false,
        //closeOnOverlayClick: false,
    });
    const [disable, setDisable] = useState(false);

    const onDelete = (item: any) => {
        dispatch(setLogs(logs.filter((record) => record !== item)));
    };

    const onClose = () => {
        navigate(`/sp/project/${id}/weekly-report`);
        reset();
        dispatch(setLogs([]));
        if (showModalDetail) {
            dispatch(setShowModalDetail(false));
        } else if (showModalCreate) {
            dispatch(setShowModalCreate(false));
        } else if (showModalUpdate) {
            dispatch(setShowModalUpdate(false));
        }
    };

    const handleCreate = async (body) => {
        try {
            const response = await ReportServiceSP.createReport(body);
            if (response) {
                navigate(`/sp/project/${id}/weekly-report`);
                reset();
                dispatch(setShowModalCreate(false));
                dispatch(setAddSuccess(true));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleEdit = async (body) => {
        try {
            const response = await ReportServiceSP.updateReport(
                selectedReport?.id,
                body,
            );
            if (response) {
                navigate(`/sp/project/${id}/weekly-report`);
                reset();
                dispatch(setShowModalUpdate(false));
                dispatch(setEditSuccess(true));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onSubmit = (value) => {
        const formData: any = new FormData();
        const working_log_ids = logs?.map((log) => log?.id) || [];

        working_log_ids?.forEach((item) =>
            formData.append(`working_log_ids[]`, item),
        );
        formData.append('manager_worker_id', value?.manager_worker_id?.value);
        formData.append(
            'published_at',
            moment(value?.published_at).format('YYYY-MM-DD'),
        );
        formData.append('submitted_at', '');
        formData.append('from_at', moment(value?.from_at).format('YYYY-MM-DD'));
        formData.append('to_at', moment(value?.to_at).format('YYYY-MM-DD'));
        formData.append('project_id', id);
        formData.append('note', value?.note || '');
        formData.append('issue_note', value?.issue_note || '');
        formData.append('improve_note', value?.improve_note || '');

        if (showModalCreate) {
            handleCreate(formData);
        } else if (showModalUpdate) {
            handleEdit(formData);
        }
    };

    const [mergedPdfUrl, setMergedPdfUrl] = useState<any>();

    const onPrintPdf = async (event) => {
        setDisable(true);
        const {
            manager_worker_id,
            submitted_at,
            published_at,
            from_at,
            to_at,
            note,
            issue_note,
            improve_note,
        } = getValues();
        const body = {
            manager_worker_id: manager_worker_id.value,
            published_at: moment(published_at).format('YYYY-MM-DD'),
            submitted_at: moment(submitted_at).format('YYYY-MM-DD'),
            from_at: moment(from_at).format('YYYY-MM-DD'),
            to_at: moment(to_at).format('YYYY-MM-DD'),
            project_id: id,
            working_log_ids: logs?.map((log) => log?.id) || [],
            note: note || '',
            issue_note: issue_note || '',
            improve_note: improve_note || '',
            working_report_id: showModalCreate ? '' : selectedReport?.id,
        };

        try {
            const response: any = await workingReportsService.printPdf({
                ...body,
            });
            if (response) {
                setDisable(false);
                const newBlob = new Blob([response.data], {
                    type: 'application/pdf',
                });
                console.log(newBlob);
                const file = window.URL.createObjectURL(newBlob);
                console.log(file);
                setMergedPdfUrl(file);
            }
        } catch (e) {
            setDisable(false);
            console.log(e);
        }
    };

    const handleDownloadPdf = () => {
        const link = document.createElement('a');
        link.href = mergedPdfUrl;
        link.setAttribute('download', `report.pdf`);
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link);
        }, 100);
    };

    const footer = (
        <div className="mt-[35px]">
            <div className="flex justify-between gap-x-[8px] my-[10px] w-full">
                <button
                    disabled={disable}
                    type="button"
                    onClick={onPrintPdf}
                    className="bg-main px-[20px] py-[10px] rounded-[6px] font-medium text-white w-1/2"
                >
                    PDF確認
                </button>
                <button
                    type="submit"
                    className="bg-main px-[20px] py-[10px] rounded-[6px] font-medium text-white w-1/2"
                    disabled={showModalDetail}
                >
                    登録
                </button>
            </div>
            <div className="flex justify-between gap-x-[8px] my-[10px] w-full">
                <button
                    type="button"
                    className="border-main border-[1px] text-main px-[20px] font-medium py-[10px] rounded-[6px] w-full"
                    style={{ color: '#215493' }}
                    onClick={onClose}
                >
                    キャンセル
                </button>
            </div>
        </div>
    );

    const covertOptions = (workers) => {
        return workers?.map((worker) => {
            return {
                label: worker?.name,
                value: worker?.id,
            };
        });
    };

    const getListWorkingLog = async (page = 1, data = {}) => {
        try {
            dispatch(setFetchingLog(true));
            const response: any = await WorkingLogServiceSP.getListWorkingLogs({
                page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                dispatch(setLogs(response.working_logs));
            }
        } catch (err) {
            console.log('err', err);
        } finally {
            dispatch(setFetchingLog(false));
        }
    };

    const getLogs = (data) => {
        getListWorkingLog(1, {
            project_id: id,
            start_at: data?.start_at,
            end_at: data?.end_at,
            // worker_id: data?.worker_id,
        });
    };

    const refreshLogs = () => {
        const { from_at, to_at, manager_worker_id } = getValues();
        const start_at = from_at || '';
        const end_at = to_at || '';
        const worker_id = manager_worker_id?.value;
        selectedReport?.manager_worker?.id;
        getLogs({ start_at, end_at, worker_id });
    };

    const setWeek = () => {
        setValue(
            'from_at',
            moment()
                .subtract(1, 'week')
                .startOf('week')
                .add(1, 'day')
                .toDate() || null,
        );
        setValue(
            'to_at',
            moment()
                .subtract(1, 'week')
                .startOf('week')
                .add(5, 'day')
                .toDate() || null,
        );
    };

    useEffect(() => {
        if (showModalCreate) {
            if (currentProject) {
                if (currentProject.manager_worker) {
                    setValue('manager_worker_id', {
                        label: currentProject.manager_worker?.name || '',
                        value: currentProject.manager_worker?.id || '',
                    });
                } else {
                    setValue('manager_worker_id', null);
                }
            }
        }
    }, [showModalCreate]);

    useEffect(() => {
        if (showModalUpdate || showModalDetail) {
            setValue(
                'from_at',
                moment(selectedReport?.from_at).toDate() || null,
            );
            setValue('to_at', moment(selectedReport?.to_at).toDate() || null);
            setValue(
                'published_at',
                moment(selectedReport?.published_at).toDate() || null,
            );
            setValue(
                'submitted_at',
                moment(selectedReport?.submitted_at).toDate() || null,
            );
            setValue('note', selectedReport?.note);
            setValue('issue_note', selectedReport?.issue_note);
            setValue('improve_note', selectedReport?.improve_note);
            const worker: any = workers?.find(
                (worker: any) =>
                    worker?.id === selectedReport?.manager_worker?.id,
            );
            setValue('manager_worker_id', {
                label: worker?.name,
                value: worker?.id,
            });
            // const start_at = moment(selectedReport?.from_at).toDate();
            // const end_at = moment(selectedReport?.to_at).toDate();
            // getLogs({ start_at, end_at });
            // getLogs({});
            dispatch(setLogs(selectedReport?.working_logs));
        }
    }, [showModalUpdate, showModalDetail]);
    return (
        <>
            {mergedPdfUrl && (
                <Dialog
                    onClose={() => setMergedPdfUrl(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: '100%',
                            height: '100%',
                            margin: '0px',
                        },
                        '.MuiDialogContent-root': {
                            padding: '0px 0px 20px 0px',
                        },
                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!mergedPdfUrl}
                >
                    <DialogTitle>
                        <button
                            type="button"
                            className="border-main border-[1px] text-sm px-[10px] py-[5px] rounded-[6px] whitespace-nowrap"
                            style={{ color: '#215493' }}
                            onClick={() => setMergedPdfUrl(null)}
                        >
                            閉じる
                        </button>
                        <Download>
                            {(props: RenderDownloadProps) => (
                                <button
                                    type="button"
                                    className="border-main ml-2 bg-main border-[1px] text-white text-sm px-[10px] py-[5px] rounded-[6px] whitespace-nowrap"
                                    // onClick={handleDownloadPdf}
                                    onClick={props.onClick}
                                >
                                    ダウンロード
                                </button>
                            )}
                        </Download>
                    </DialogTitle>
                    <DialogContent>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer
                                plugins={[getFilePluginInstance]}
                                fileUrl={mergedPdfUrl}
                            />
                        </Worker>
                    </DialogContent>
                </Dialog>
            )}

            <div className="bg-[#ffffff] flex flex-col rounded-[12px] justify-center items-center p-[15px] mx-auto">
                <div className="modal-create-user-title flex flex-row !justify-start pt-[6px] pb-[20px]">
                    {showModalCreate && '作業報告書作成(週報)'}
                    {showModalDetail && '作業報告書(週報)詳細'}
                    {showModalUpdate && '作業報告書(週報)編集'}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col overflow-auto">
                        <span className="flex items-center gap-[16px] mb-[16px]">
                            <span className="text-[14px] font-medium text-[#344054]">
                                作業所名
                            </span>
                            <span className="text-[16px]">
                                {showModalCreate
                                    ? currentProject?.name
                                    : selectedReport?.project?.name}
                            </span>
                        </span>
                        <div className="w-full flex flex-col gap-y-[14px]">
                            <div className="flex flex-col w-full">
                                <Controller
                                    name="manager_worker_id"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex-1">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                管理者名
                                            </p>
                                            <Autocomplete
                                                disablePortal
                                                options={covertOptions(workers)}
                                                {...register(
                                                    'manager_worker_id',
                                                    createWorkingReportValidation(
                                                        t,
                                                    ).manager_worker_id(),
                                                )}
                                                {...field}
                                                value={
                                                    field.value
                                                        ? {
                                                              label: field.value
                                                                  ?.label,
                                                              value: field.value
                                                                  ?.value,
                                                          }
                                                        : null
                                                }
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setValue(
                                                            'manager_worker_id',
                                                            newValue,
                                                        );
                                                    } else {
                                                        setValue(
                                                            'manager_worker_id',
                                                            null,
                                                        );
                                                    }
                                                }}
                                                classes={{
                                                    root: 'input-select-style maxw-full',
                                                    input: 'input-select-style maxw-full',
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label=""
                                                    />
                                                )}
                                                disabled={showModalDetail}
                                                noOptionsText="該当なし"
                                            />
                                        </div>
                                    )}
                                />
                                {errors.manager_worker_id && (
                                    <span className="error">
                                        {errors.manager_worker_id.message?.toString()}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-col">
                                <Controller
                                    rules={{ required: true }}
                                    name="published_at"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex-1 possition-relative">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                発行日
                                            </p>
                                            <DatePicker
                                                {...field}
                                                {...register(
                                                    'published_at',
                                                    createWorkingReportValidation(
                                                        t,
                                                    ).published_at(),
                                                )}
                                                className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px] mt-[3px]"
                                                selected={getValues(
                                                    'published_at',
                                                )}
                                                onChange={(date) => {
                                                    setValue(
                                                        'published_at',
                                                        date,
                                                    );
                                                }}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                locale="ja"
                                                disabled={showModalDetail}
                                                id="published_at"
                                            />
                                            <label htmlFor="published_at">
                                                <img
                                                    className="absolute-bottom"
                                                    width={'16px'}
                                                    height={'16px'}
                                                    src={calendarIcon}
                                                />
                                            </label>
                                        </div>
                                    )}
                                />
                                {errors.published_at && (
                                    <span className="error">
                                        {errors.published_at.message?.toString()}
                                    </span>
                                )}
                            </div>
                            <div className="mt-[12px]">
                                <p className="text-[14px] font-medium mb-[4px] text-[#344054]">
                                    作業日
                                </p>
                                <div className="flex items-baseline justify-between gap-x-[8px]">
                                    <div className="flex flex-col">
                                        <Controller
                                            rules={{ required: true }}
                                            name="from_at"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <div className="flex-1 relative">
                                                        <DatePicker
                                                            {...field}
                                                            {...register(
                                                                'from_at',
                                                                createWorkingReportValidation(
                                                                    t,
                                                                ).from_at(),
                                                            )}
                                                            className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                                            selected={getValues(
                                                                'from_at',
                                                            )}
                                                            onChange={(
                                                                date,
                                                            ) => {
                                                                setValue(
                                                                    'from_at',
                                                                    date,
                                                                );
                                                            }}
                                                            dateFormat="yyyy/MM/dd"
                                                            autoComplete="off"
                                                            locale="ja"
                                                            disabled={
                                                                showModalDetail
                                                            }
                                                            id="from_at"
                                                        />
                                                        <label htmlFor="from_at">
                                                            <img
                                                                className="absolute-bottom"
                                                                width={'16px'}
                                                                height={'16px'}
                                                                src={
                                                                    calendarIcon
                                                                }
                                                            />
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                        />
                                        {errors.from_at && (
                                            <span className="error">
                                                {errors.from_at.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                    <span className="flex-0 pb-[6px]">~</span>
                                    <div className="flex flex-col">
                                        <Controller
                                            name="to_at"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({ field }) => (
                                                <div className="flex-1 possition-relative">
                                                    <label htmlFor="to_at"></label>
                                                    <DatePicker
                                                        {...field}
                                                        {...register(
                                                            'to_at',
                                                            createWorkingReportValidation(
                                                                t,
                                                            ).to_at(),
                                                        )}
                                                        className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                                        selected={getValues(
                                                            'to_at',
                                                        )}
                                                        onChange={(date) => {
                                                            setValue(
                                                                'to_at',
                                                                date,
                                                            );
                                                        }}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                        id="to_at"
                                                        disabled={
                                                            showModalDetail
                                                        }
                                                    />
                                                    <label htmlFor="to_at">
                                                        <img
                                                            className="absolute-bottom"
                                                            width={'16px'}
                                                            height={'16px'}
                                                            src={calendarIcon}
                                                        />
                                                    </label>
                                                </div>
                                            )}
                                        />
                                        {errors.to_at && (
                                            <span className="error">
                                                {errors.to_at.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="text-right mt-[16px]">
                                    <button
                                        className="bg-main px-[24px] py-[6px] rounded-[6px] text-white whitespace-nowrap"
                                        type="button"
                                        onClick={setWeek}
                                        disabled={showModalDetail}
                                    >
                                        先週
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-between mt-[46px]">
                            <div className="flex items-center gap-x-[22px]">
                                <span className="font-bold text-[16px] text-[#1C1E21]">
                                    作業内容
                                </span>
                                <button
                                    disabled={showModalDetail}
                                    type="button"
                                    className="bg-main px-[20px] py-[6px] rounded-[6px] text-white"
                                    onClick={refreshLogs}
                                >
                                    読込
                                </button>
                            </div>
                            <button
                                disabled={showModalDetail}
                                type="button"
                                className="border-main border-[1px] text-main px-[18px] py-[4px] rounded-[6px] flex items-center"
                                onClick={() => openCreate()}
                            >
                                <img src={plus} alt="plus" />
                                <span className="button-plus-wrp">追加</span>
                            </button>
                        </div>
                        <div className="table-workers d-flex flex-column mt-[35px]">
                            <div className="table-content d-flex flex-column">
                                <TableContainer
                                    component={Paper}
                                    table-layout={'auto'}
                                >
                                    <Table aria-label="customized">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    作業日
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    作業名
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    操作
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {logs !== null &&
                                                logs.map((record, ind) => (
                                                    <StyledTableRow key={ind}>
                                                        <StyledTableCell
                                                            className="text-overflow"
                                                            align="center"
                                                        >
                                                            {formatDateTime(
                                                                record?.worked_at,
                                                                'YYYY/MM/DD',
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            className="text-overflow"
                                                            align="center"
                                                        >
                                                            {record?.name}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            className="text-overflow"
                                                            align="center"
                                                        >
                                                            <Grid
                                                                className="list-icon"
                                                                xs={3}
                                                                item
                                                            >
                                                                <Box
                                                                    className="view-ic"
                                                                    onClick={() => {
                                                                        setSelectedRow(
                                                                            record,
                                                                        );
                                                                        openCreate();
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            ViewIcon
                                                                        }
                                                                    />
                                                                </Box>
                                                                <Box
                                                                    className="edit-ic"
                                                                    onClick={() => {
                                                                        setSelectedRow(
                                                                            record,
                                                                        );
                                                                        openCreate();
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            EditIcon
                                                                        }
                                                                    />
                                                                </Box>
                                                                <Box
                                                                    className="delete-ic"
                                                                    onClick={() => {
                                                                        onDelete(
                                                                            record,
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        src={
                                                                            TrashIcon
                                                                        }
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            {/* {logs?.length === 0 && (
                                                <StyledTableRow>
                                                    <StyledTableCell
                                                        className="text-overflow"
                                                        align="center"
                                                        colSpan={4}
                                                    >
                                                        データなし
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )} */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <Controller
                            name="note"
                            control={control}
                            render={({ field }) => (
                                <div className="w-full flex flex-col mt-[22px]">
                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                        備考
                                    </p>
                                    <textarea
                                        disabled={showModalDetail}
                                        {...register('note')}
                                        {...field}
                                        className="rounded-[8px] border-[1px] border-[#CDD1D5] p-[16px]"
                                        rows={3}
                                    ></textarea>
                                </div>
                            )}
                        />

                        <Controller
                            name="issue_note"
                            control={control}
                            render={({ field }) => (
                                <div className="w-full flex flex-col mt-[16px]">
                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                        是正箇所
                                    </p>
                                    <textarea
                                        disabled={showModalDetail}
                                        {...register('issue_note')}
                                        {...field}
                                        className="rounded-[8px] border-[1px] border-[#CDD1D5] p-[16px]"
                                        rows={3}
                                    ></textarea>
                                </div>
                            )}
                        />

                        <Controller
                            name="improve_note"
                            control={control}
                            render={({ field }) => (
                                <div className="w-full flex flex-col mt-[16px]">
                                    <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                        改善方法
                                    </p>
                                    <textarea
                                        disabled={showModalDetail}
                                        {...register('improve_note')}
                                        {...field}
                                        className="rounded-[8px] border-[1px] border-[#CDD1D5] p-[16px]"
                                        rows={3}
                                    ></textarea>
                                </div>
                            )}
                        />
                    </div>
                    {footer}
                </form>
            </div>
            <ModalCreate>
                <WorkingReportLog
                    id={selectedRow?.id}
                    project_id={id}
                    onClose={() => {
                        closeCreate();
                    }}
                    onAdd={() => {
                        closeCreate();
                    }}
                />
            </ModalCreate>
            <ModalUpdate>
                <WorkingReportLog
                    id={selectedRow?.id}
                    project_id={id}
                    onClose={() => {
                        closeCreate();
                    }}
                    onAdd={() => {
                        closeCreate();
                    }}
                />
            </ModalUpdate>
        </>
    );
};

export default WorkingReportCreateSP;
