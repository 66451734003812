import React from 'react';
import ReactDOM from 'react-dom';
import setupAxios from './config/axiosConfig';
import i18n from './i18n';
import './index.css';
import App from './App';
import Line from './Line';
import reportWebVitals from './reportWebVitals';
import store from './store';
import { Provider } from 'react-redux';
import 'font-awesome/css/font-awesome.min.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { I18nextProvider } from 'react-i18next';
import moment from 'moment';
import { ActionCableProvider } from 'react-actioncable-provider';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ConfigProvider } from 'antd';
import ja_JP from 'antd/es/locale/ja_JP';

setupAxios();
moment.locale('ja');
let persistor = persistStore(store);
const API = process.env.REACT_APP_SOCKET_API;
const user: any = localStorage.getItem('user');
const access_token: any = JSON.parse(user)?.access_token;

const url = API + '?access_token=' + access_token;

const inLINERoutes =
    location.pathname.includes('line') ||
    location.pathname.includes('sp/quote/create') ||
    location.pathname.includes('sp/quote/history') ||
    location.pathname.includes('sp/quote/detail') ||
    location.pathname.includes('sp/invoice') ||
    location.pathname.includes('sp/quote/edit') ||
    location.pathname.includes('sp/contact');

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ConfigProvider locale={ja_JP}>
                <PersistGate persistor={persistor}>
                    <I18nextProvider i18n={i18n}>
                        <ActionCableProvider url={url}>
                            {inLINERoutes ? <Line /> : <App />}
                        </ActionCableProvider>
                    </I18nextProvider>
                </PersistGate>
            </ConfigProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
);

reportWebVitals();
