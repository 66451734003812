import { FC, memo, useMemo, useEffect, useState, useRef } from 'react';
import moment from 'moment';
import 'react-chat-widget/lib/styles.css';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { BiImageAdd, BiSend } from 'react-icons/bi';
import { TiCancel } from 'react-icons/ti';
import { Main } from './style';
import useChatBox, { Props, ReceivedProps } from './hook';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import carretDown from '../../../assets/icons/carret_down.svg';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
} from '@chatscope/chat-ui-kit-react';
import { useFormik, Formik, Form, Field } from 'formik';
import { Typography, Box, Button, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const WhoCanJoin = ({ chat }) => {
    const [whoCanJoinToggle, setWhoCanJoinToggle] = useState(false);
    return (
        <Box marginTop="25px">
            {whoCanJoinToggle ? (
                <Box
                    sx={{
                        border: '1px solid #E3E5E8',
                        padding: '13px',
                        borderRadius: '10px',
                        position: 'relative',
                        height: '100%',
                        overflow: 'auto',
                        maxHeight: '100px',
                        marginBottom: '12px',
                        display: 'flex',
                    }}
                >
                    <Typography
                        color="#215493"
                        fontWeight="500"
                        width="90%"
                        fontSize={16}
                    >
                        {chat?.who_can_join}
                    </Typography>
                    <Box
                        sx={{
                            backgroundColor: '#215493',
                            width: '30px',
                            height: '30px',
                            borderRadius: '6px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'sticky',
                            right: '10px',
                            top: '0px',
                        }}
                    >
                        <img
                            onClick={() => setWhoCanJoinToggle(false)}
                            style={{ transform: 'rotate(180deg)' }}
                            src={carretDown}
                            alt="carretDown"
                        />
                    </Box>
                </Box>
            ) : (
                <Button
                    onClick={() => setWhoCanJoinToggle(true)}
                    sx={{
                        backgroundColor: '#215493',
                        color: '#fff',
                        width: '160px',
                        marginBottom: '12px',
                    }}
                >
                    参加者を表示する
                </Button>
            )}
        </Box>
    );
};
type TVideo = {
    signed_id: string;
    video_path: string;
}
type TImage = {
    signed_id: string;
    image_path: string;
}

interface ISender {
    id: number;
    name: string;
}
interface IMessage {
    body: string | undefined;
    id: number;
    images: TImage[] | null;
    is_owner: boolean | null;
    is_read: boolean | null;
    is_system: boolean | null;
    publish_at: string | undefined;
    sender: ISender;
    videos: TVideo[] | File[] | null;
}
const ChatBoxLayout: FC<Props> = ({
    workerId,
    listMessage,
    onSubmit,
    handleFileChange,
    listImageMessage,
    refInput,
    removeImage,
    chatRoomId,
    message,
    setMessage,
    handleReadChat,
    chat,
    whoCanJoinToggle,
    setWhoCanJoinToggle,
    setListMessage,
    handleReceived,
    getLanguage,
    globalChat,
    unreadFlag,
    loading
}) => {
    // const [messageInputValue, setMessageInputValue] = useState('');

    // const submit = () => {
    //     onSubmit(message);
    //     setMessage('');
    // };
    const { t } = useTranslation();
    const navigate = useNavigate();
    const messageInputRef: any = useRef(null);
    const [limit, setLimit] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    
    const onYReachStart = () => {
        if (loadingMore === true) {
        return;
        }
        setLoadingMore(true);
        setTimeout(() => {
        setLimit(limit+5);
        setLoadingMore(false);
        }, 1500);
    };

    const formik = useFormik({
        initialValues: {
            message: '',
            files: [],
        },
        onSubmit: (values) => {
            onSubmit(values);
            formik.resetForm();
        },
    });

    useEffect(() => {
        const element = document.getElementsByClassName('cs-button--send');
        element[0]?.removeAttribute('disabled');
    }, []);

    const handleButtonClick = () => {
        const enterKeyEvent = new KeyboardEvent('keydown', { key: 'Enter' });
        messageInputRef?.current?.inputRef?.current.dispatchEvent(
            enterKeyEvent,
        );
    };

    const { userData }: any = useSelector((state: RootState) => state.users);
    const listMessageItem = () => {
        return listMessage?.slice(-limit)?.map((message: IMessage) => (
            <>
                {message.id === unreadFlag && (
                    <div
                        onClick={handleReadChat}
                        className="flex mb-2 items-center cursor-pointer text-sm bg-[#E3E5E8] py-[5px] px-[14px] rounded-[16px] justify-center relative"
                    >
                        <img
                            className="absolute left-[14px]"
                            src="/img/tag.svg"
                            alt="unread_tag"
                        />
                        未読
                    </div>
                )}
                {!message?.is_system ? (
                    <>
                        <Message
                            key={message.id}
                            model={{
                                message: message.body,
                                sender: message?.sender?.name,
                                sentTime: message?.publish_at,
                                direction:
                                    message?.sender?.id === userData?.worker?.id
                                        ? 'outgoing'
                                        : 'incoming',
                                position: 'first',
                                type: 'custom',
                            }}
                        >
                            <Message.CustomContent>
                                <div className="message">
                                    <p className="mb-0">
                                        {message?.body || ''}
                                    </p>

                                    {message?.images &&
                                        Object.values(message?.images).map(
                                            (img: any, id) => (
                                                <img
                                                    src={img?.image_path}
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '324px',
                                                    }}
                                                    key={id}
                                                />
                                            ),
                                        )}
                                    {message?.videos &&
                                        Object.values(message?.videos).map(
                                            (video, id) => (
                                                <video
                                                    controls
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '324px',
                                                        cursor: 'pointer',
                                                    }}
                                                    key={id}
                                                >
                                                    <source
                                                        src={video?.video_path || URL.createObjectURL(video)}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            ),
                                        )}
                                </div>
                            </Message.CustomContent>
                        </Message>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent:
                                    message?.sender?.id === userData?.worker?.id
                                        ? 'end'
                                        : 'start',
                            }}
                            className="message-footer"
                        >
                            <p>{message?.sender?.name || ''}</p>
                            <p>
                                {message.publish_at &&
                                    moment(message.publish_at)
                                        .zone('+09:00')
                                        .format('YYYY/MM/DD HH:mm:ss')}
                            </p>
                        </div>
                    </>
                ) : (
                    <div className="flex justify-center mb-2">
                        <div className="message bg-[#f4f4f4] py-1 px-2 text-[#666666] rounded-md flex text-xs justify-center flex-col items-center">
                            <p className="mb-0">
                                {message.publish_at &&
                                    moment(message.publish_at)
                                        .zone('+09:00')
                                        .format('YYYY/MM/DD HH:mm:ss')}
                            </p>
                            <p className="mb-0">{message?.body || ''}</p>
                        </div>
                    </div>
                )}
            </>
        ));
    };

    const renderError = (values: File[]) => {
        if(!values) return;
        let listError = values?.filter(f => f.size > 10 * 1024 * 1024);
        if(listError?.length > 0) {
            return <p className='text-[#ff3333] mt-[2em] text-[10px]'>10MB以上がアップロードできません。</p>
        } else {
            return '';
        }
    }

    return (
        <Main className="chat-box" style={{ position: 'relative' }}>
            <ActionCableConsumer
                channel={{
                    channel: 'ChatroomChannel',
                    chat_id: chatRoomId,
                }}
                onReceived={handleReceived}
            ></ActionCableConsumer>
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '32px',
                    }}
                >
                    <Typography fontWeight="bold" fontSize={20}>
                        {chat?.project?.name}
                    </Typography>
                    <Button
                        onClick={() => {
                            if (globalChat) {
                                navigate(`/sp/chats`);
                            } else {
                                navigate(
                                    `/sp/project/${chat?.project?.id}/chats`,
                                );
                            }
                        }}
                        sx={{
                            color: '#215493',
                            width: '100px',
                            fontWeight: 600,
                        }}
                        variant="outlined"
                    >
                        戻る
                    </Button>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#F3F3F3',
                        padding: '14px',
                        borderRadius: '10px',
                    }}
                >
                    <Typography fontWeight="500" fontSize={16}>
                        {chat?.title}
                    </Typography>
                </Box>
                <WhoCanJoin chat={chat} />
            </Box>
            {/*<ActionCableConsumer
                channel={{
                    channel: 'ChatroomChannel',
                    chat_id: chatRoomId,
                }}
                onReceived={handleReceived}
            >*/}
                <div style={{ position: 'relative', height: '400px' }}>
                    <MainContainer>
                        <ChatContainer>
                            <MessageList autoScrollToBottomOnMount={true} loadingMore={loadingMore} onYReachStart={onYReachStart}>{listMessageItem()}</MessageList>
                        </ChatContainer>
                    </MainContainer>
                    <div className="action-container">
                        <Formik
                            onSubmit={(values, { resetForm }) => {
                                onSubmit(values);
                                resetForm();
                            }}
                            initialValues={{
                                message: '',
                                files: [],
                            }}
                        >
                            {({ values, handleSubmit, setFieldValue }) => (
                                <div className="w-full">
                                    <Stack direction="row" className="options">
                                        {[...Array(7).keys()].map((_, i) => (
                                            <span
                                                key={i}
                                                className="item"
                                                onClick={() => {
                                                    setFieldValue(
                                                        'message',
                                                        t(
                                                            `chat.ja.text${i + 1
                                                            }`,
                                                        ),
                                                    );
                                                }}
                                            >
                                                {t(
                                                    `chat.${getLanguage(
                                                        navigator.language,
                                                    )}.text${i + 1}`,
                                                )}
                                            </span>
                                        ))}
                                    </Stack>
                                    <Form className="w-full justify-between flex items-center">
                                        <label
                                            className="upload-image-chat pr-[14px]"
                                            htmlFor="fileInput1"
                                        >
                                            <input
                                                onChange={(e) =>
                                                    handleFileChange(
                                                        e,
                                                        values,
                                                        setFieldValue,
                                                    )
                                                }
                                                id="fileInput1"
                                                name="file"
                                                type="File"
                                                multiple
                                                //ref={refInput}
                                                accept="image/*, video/*"
                                                style={{ display: 'none' }}
                                                onClick={(e) => (e.target as HTMLInputElement).value = ""}
                                            ></input>
                                            <BiImageAdd size={20} />
                                        </label>
                                        <Field
                                            onKeyPress={(e) => {
                                                if (e.code === 'Enter') {
                                                    e.preventDefault();
                                                    if(values?.files?.filter((f: {size: number}) => f.size > 10 * 1024 * 1024)?.length > 0) return;
                                                    handleSubmit();
                                                }
                                            }}
                                            className="input-custom-chat"
                                            type="text"
                                            placeholder="メッセージを入力..."
                                            autoFocus
                                            name="message"
                                        />
                                        <button
                                            disabled={loading || values?.files?.filter((f: {size: number}) => f.size > 10 * 1024 * 1024)?.length > 0}
                                            style={{
                                                bottom: '16px',
                                                zIndex: 100000,
                                                right: '16px',
                                            }}
                                            className="pl-[14px] btnSend"
                                            type="button"
                                            onClick={() => handleSubmit()}
                                        >
                                            <BiSend size={25} />
                                        </button>
                                        <div className="preview-img-upload">
                                            {values?.files?.length > 0 &&
                                                values?.files.map(
                                                    (item: File, id) => {
                                                        return (
                                                            <div className="img-upl-ct">
                                                                {
                                                                    item?.type?.includes('image/')
                                                                        ?
                                                                        <img
                                                                            src={URL.createObjectURL(
                                                                                item,
                                                                            )}
                                                                            key={id}
                                                                        />
                                                                        :
                                                                        <video
                                                                            className="img-container-edit"
                                                                            controls
                                                                        >
                                                                            <source
                                                                                src={URL.createObjectURL(item)}
                                                                                type="video/mp4"
                                                                                className="image-ads-edit"
                                                                            />
                                                                        </video>
                                                                }
                                                                <TiCancel
                                                                    onClick={() =>
                                                                        removeImage(id, values, setFieldValue)
                                                                    }
                                                                    size={15}
                                                                    className="delete-image-upl"
                                                                />
                                                            </div>
                                                        );
                                                    },
                                                )}
                                            {
                                                renderError(values?.files)
                                            }
                                        </div>
                                    </Form>
                                </div>
                            )}
                        </Formik>
                    </div>
                </div>
            {/*</ActionCableConsumer>*/}
        </Main>
    );
};

const ChatBox: FC<ReceivedProps> = (props) => (
    <ChatBoxLayout {...useChatBox(props)} />
);

export default memo(ChatBox);
