import Api from './api/suggestionApi';
import pick from 'lodash/pick';

const getSuggestions = async (input?: any) => {
    let data = pick(input, ['page', 'per_page', 'free_word']);
    const params = new URLSearchParams(data);

    try {
        return await Api()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        window.location.href = '/login';
        return null;
    }
};

const getSuggestion = async (id: string | number) => {
    try {
        return await Api()
            .get(`${id}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        window.location.href = '/login';
        return null;
    }
};

const createSuggestion = async (payload: any) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateSuggestion = async (id: string | number, payload: any) => {
    return await Api()
        .put(`${id}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteSuggestion = async (id: number) => {
    return await Api().delete(`${id}`);
};

const suggestionService = {
    updateSuggestion,
    getSuggestions,
    getSuggestion,
    createSuggestion,
    deleteSuggestion,
};

export default suggestionService;
