import fetch from './api/config';

const API = {
    LOGIN: () => `/sp/login`,
    LOGOUT: () => `/sp/logout`,
};

export default class AuthServiceSP {
    static login = (params: any) => fetch.post(API.LOGIN(), params);
    static logout = () => fetch.delete(API.LOGOUT(), {});
}
