import { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { validation } from './schema';
import purchaseRequestService from 'services/purchaseRequestService';
import { ObjectToFormData } from 'utils/helper/helpers';
import { IImageP, IPurchase } from '../type';

export type ReceivedProps = Record<string, never>;

const useCreatePR = (props: ReceivedProps) => {
    const [fileUpload, setFileUpload] = useState<File[] | IImageP[]>([]);
    
    const navigate = useNavigate();
    const onDrop = useCallback(async (acceptedFiles) => {
        setFileUpload((preState) => [...preState, ...acceptedFiles]);
    }, []);
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            'image/*': ['.jpeg', '.png', '.gif', '.jpg', '.svg']
        }
    });
    const [initialValues, setInitialValues] = useState({
        name: '',
        content: '',
    });
    const { id } = useParams();

    const handleCreatePR = async (values: {name: string; content: string}) => {
        try {
            const params = ObjectToFormData({
                ...values,
            });
            fileUpload?.forEach((file) => {
                params.append('images[]', file);
            });
            await purchaseRequestService.createPurchaseReuqest(
                params,
            );
            navigate('/sp/purchase-request');
        } catch (error) {
            console.log(error);
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validation,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleCreatePR(values);
        },
    });
   

    const removeImage = (idx: number) => {
        let current = [...fileUpload];
        current = current.filter((item, index) => index !== idx);
        setFileUpload(current);
    };

    const fetchPRDetail = async () => {
        if(!id) return
        try {
            const res =
                await purchaseRequestService.getPRDetail(id);
            if(!(res as {purchase_request: IPurchase})?.purchase_request) return;
            const purchase_request = (res as {purchase_request: IPurchase})?.purchase_request;
            setInitialValues({
                name: purchase_request?.name,
                content: purchase_request?.content,
            });
            setFileUpload(purchase_request?.images_list);
        } catch (error) {
            console.log(error);
        }
    };
    const onCreate = () => {};

    useEffect(() => {
        fetchPRDetail();
    }, []);

    return {
        ...props,
        onCreate,
        fileUpload,
        setFileUpload,
        removeImage,
        acceptedFiles,
        getRootProps,
        getInputProps,
        formik,
        navigate,
        id,
    };
};

export type Props = ReturnType<typeof useCreatePR>;

export default useCreatePR;
