import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridSizeChangedEvent, GridApi } from 'ag-grid-community';
import { ColDef } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import reportsService from 'services/reportsService';
import { IPageQueries } from 'models';
import { columns } from './utility';
import chatService from 'services/chatService';
import { useFormik } from 'formik';
import workingLogService from 'services/workingLogService';
import { useModal } from 'react-hooks-use-modal';
import { RootState, useAppDispatch } from 'store';
import {
    setFilter,
    setShowModalCreate,
    setWorkers,
    setWorkingLogs,
    setAddSuccess,
    setEditSuccess,
    setDeleteSuccess,
    setFetching,
    setReports,
    setSelectedReport,
    setShowModalDelete,
    setShowModalDetail,
    setShowModalUpdate,
    setTempFilterReset,
} from 'store/workingReport/workingReport';
import { useDispatch, useSelector } from 'react-redux';
// export type ReceivedProps = {
//     queryFilter: Record<string, any>;
// };

export type ReceivedProps = Record<string, never>;
export const ROW_HEIGHT = 50;
export const HEADER_HEIGHT = 40;

const useWeeklyReport = (props: ReceivedProps) => {
    const columnDefsSetting = columns().map((item) => ({
        ...item,
        filterParams: {
            textMatcher: ({ value, filterText }: any) => {
                const filterTextLowerCase = filterText.toLowerCase();
                const valueLowerCase = value.toString().toLowerCase();
                return valueLowerCase.includes(filterTextLowerCase);
            },
        },
    }));
    const [gridApi, setGridApi] = useState<GridApi>();
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });
    const [queryFilter, setQueryFilter] = useState<any>({});
    const [rowData, setRowData] = useState<any[]>([]);
    const [columnDefs] = useState<any[]>(columnDefsSetting);
    const [total, setTotal] = useState<number>(0);
    const [chatId, setChatId] = useState<number | null>(null);
    const gridRef: any = useRef();
    const [workingLogs, setWorkingLogs] = useState<any[]>([]);
    const {
        showModalDetail = false,
        showModalCreate = false,
        showModalUpdate = false,
        showModalDelete = false,
        addSuccess = false,
        editSuccess = false,
        deleteSuccess = false,
    } = useSelector((state: RootState) => state.workingReport);

    const [Modal, open, close, isOpen] = useModal('root', {
        //closeOnOverlayClick: false,
    });
    const [ModalDelete, openDeleteModal, closeDeleteModal, isOpenDelete] =
        useModal('root', {
            //closeOnOverlayClick: false,
        });
    const dispatch = useDispatch();
    const openCreate = () => {
        // dispatch(setShowModalCreate(true));
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            working_log: {
                label: '',
                value: '',
            },
            from_at: '',
            to_at: '',
            start_published_at: '',
            end_published_at: '',
            start_submitted_at: '',
            end_submitted_at: '',
        },
        onSubmit: (values: any) => {
            setQueryFilter(values);
        },
    });

    const containerStyle = useMemo(
        () => ({
            width: '100%',
            height: `${
                isEmpty(rowData)
                    ? 600
                    : rowData.length * ROW_HEIGHT + HEADER_HEIGHT + 1
            }px`,
        }),
        [rowData],
    );
    const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);
    const defaultColDef = useMemo<ColDef>(() => {
        return {
            flex: 1,
            resizable: false,
            floatingFilter: false,
            sortable: false,
        };
    }, []);

    const isFullWidthRow = useCallback((params) => {
        return params.rowNode.data.fullWidth;
    }, []);

    const onVirtualColumnsChanged = () => {
        const element = document.querySelectorAll('.ag-text-field-input');

        element.forEach((item: any) => {
            item.placeholder = '検索';
        });
    };

    const onGridReady = (params: GridSizeChangedEvent) => {
        params.api.sizeColumnsToFit();
        setGridApi(params.api);
    };
    const fetchWeeklyReport = useCallback(async () => {
        try {
            const { working_reports, meta } =
                await reportsService.getListReports({
                    ...pageQueries,
                    ...queryFilter,
                });

            setRowData(working_reports);
            setTotal(meta.total_count);
        } catch (error) {
            //
        }
    }, [pageQueries, queryFilter]);
    useEffect(() => {
        if (showModalCreate || showModalDetail || showModalUpdate) {
            open();
        } else {
            close();
        }
    }, [showModalCreate, showModalDetail, showModalUpdate]);
    const onDeleteChat = async (closeDelete: () => void) => {
        if (!chatId) return;

        try {
            await chatService.deleteChat(chatId);
            fetchWeeklyReport();
            closeDelete();
        } catch (error) {
            //
        }
    };

    const getListWorkingLogs = async () => {
        try {
            const response = await workingLogService.getListWorkingLogs();
            setWorkingLogs(response?.working_logs);
        } catch (err) {
            console.log('err', err);
        }
    };

    const openShow = async (record) => {
        try {
            const response = await reportsService.getReport(record?.id, {
                project_id: record?.project_id,
            });
            if (response) {
                // dispatch(
                //     setSelectedReport({
                //         ...response.working_report,
                //         project: record?.project,
                //     }),
                // );
                // dispatch(setShowModalDetail(true));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const openEdit = async (record) => {
        try {
            const response = await reportsService.getReport(record?.id, {
                project_id: record?.project_id,
            });
            if (response) {
                // dispatch(
                //     setSelectedReport({
                //         ...response.working_report,
                //         project: record?.project,
                //     }),
                // );
                // dispatch(setShowModalUpdate(true));
            }
        } catch (e) {
            console.error(e);
        }
    };

    const covertOptions = (workingLogs) => {
        return workingLogs?.map((log) => {
            return {
                label: log?.name,
                value: log?.id,
            };
        });
    };

    useEffect(() => {
        // fetchWeeklyReport();
    }, [fetchWeeklyReport]);

    useEffect(() => {
        if (deleteSuccess) {
            // dispatch(setDeleteSuccess(false));
            if (pageQueries.page !== 1) {
                setPageQueries((prevState) => ({ ...prevState, page: 1 }));
            }
            fetchWeeklyReport();
        }
    }, [deleteSuccess]);

    useEffect(() => {
        getListWorkingLogs();
    }, []);
    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
        const element = document.querySelectorAll('.ag-text-field-input');

        element.forEach((item: any) => {
            item.placeholder = '検索';
        });
    }, [rowData, gridApi]);

    const openDelete = (record) => {
        // dispatch(setSelectedReport(record));
        // dispatch(setShowModalDelete(true));
    };

    useEffect(() => {
        if (addSuccess) {
            // dispatch(setAddSuccess(false));
            if (pageQueries.page !== 1) {
                setPageQueries((prevState) => ({ ...prevState, page: 1 }));
            }
            fetchWeeklyReport();
            // dispatch(setTempFilterReset(true));
        }
    }, [addSuccess, editSuccess]);

    useEffect(() => {
        if (showModalDelete) {
            openDeleteModal();
        } else {
            closeDeleteModal();
        }
    }, [showModalDelete]);

    return {
        ...props,
        containerStyle,
        gridStyle,
        defaultColDef,
        columnDefs,
        gridRef,
        rowData,
        workingLogs,
        pageQueries,
        total,
        formik,
        onDeleteChat,
        setChatId,
        setPageQueries,
        onGridReady,
        openShow,
        openCreate,
        isFullWidthRow,
        covertOptions,
        onVirtualColumnsChanged,
        Modal,
        open,
        close,
        isOpen,
        ModalDelete,
        openDeleteModal,
        closeDeleteModal,
        isOpenDelete,
        openDelete,
        openEdit,
    };
};

export type Props = ReturnType<typeof useWeeklyReport>;

export default useWeeklyReport;
