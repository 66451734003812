import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { useFormik } from 'formik';

import { RootState } from 'store';
import workersService from 'services/wokersService';
import chatService from 'services/chatService';
import projectsService from 'services/projectsService';
import PositionsService from 'services/positionService';
import { validate } from './validation';

const useChat = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id } = useParams();
    const [searchParams] = useSearchParams();
    const isCreate = location?.pathname === '/chats/create';

    const { userData } = useSelector((state: RootState) => state.users);

    const [projectOptions, setProjectOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [workerOptions, setWorkerOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [positionOptions, setPositionOptions] = useState<
        {
            id: any;
            name: string;
        }[]
    >();
    const [data, setData] = useState<any>({});

    const initialValues = isCreate
        ? {
              title: '',
              project_id: null,
              chat_worker_ids: [],
              chat_position_ids: [],
          }
        : {
              title: '',
              project_id: null,
              chat_worker_ids: [],
              chat_position_ids: [],
          };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validate,
        onSubmit: (values) => submitForm(values),
    });

    const fetchProjects = useCallback(async () => {
        try {
            const { projects } = await projectsService.getListProjects({
                page: 1,
                per_page: 9999,
            });
            setProjectOptions(
                projects.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    const fetchWorkers = useCallback(async () => {
        try {
            const { workers } = await workersService.getListWorkers({
                page: 1,
                per_page: 9999,
            });
            setWorkerOptions(
                workers.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, [userData.worker]);

    const fetchPositions = useCallback(async () => {
        try {
            const { positions } = await PositionsService.getListPositions({
                page: 1,
                per_page: 9999,
            });
            setPositionOptions(
                positions.map((item) => ({
                    id: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            //
        }
    }, []);

    // const fetchChat = useCallback(async () => {
    //     if (!id) return;

    //     try {
    //         const { chat } = await chatService.getChat(id);
    //         setData(chat);

    //         formik.setValues({
    //             title: chat.title,
    //             project_id: chat.project,
    //             chat_worker_ids: chat.workers,
    //             chat_position_ids: chat.positions,
    //         });
    //     } catch (error) {}
    // }, [id]);

    const submitForm = async (response: Record<string, any>) => {
        const payload = {
            ...response,
            chat_position_ids: response.chat_position_ids.map(
                (item) => item.id,
            ),
            chat_worker_ids: response.chat_worker_ids.map((item) => item.id),
            project_id: id,
        };

        try {
            const { chat } = await projectsService.createChatSP(id, {
                ...payload,
                chat_worker_ids: [
                    ...payload.chat_worker_ids,
                    (userData.worker as any).id,
                ],
            });

            formik.resetForm();
            // navigate(`/chats/detail/${chat.id}`);
            navigate(`/sp/project/${id}/chats/chat/${chat.id}`);
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        // fetchChat();
        fetchProjects();
        fetchWorkers();
        fetchPositions();
    }, []);

    return {
        initialValues,
        formik,
        isCreate,
        positionOptions,
        workerOptions,
        projectOptions,
        data,
        searchParams,
    };
};

export type Props = ReturnType<typeof useChat>;

export default useChat;
