import { useFormik } from 'formik';
import { Dispatch, SetStateAction } from 'react';

type IOptions = {
    id: any;
    name: string;
}[];

export type ReceivedProps = {
    positionOptions: IOptions;
    workerOptions: IOptions;
    departmentOptions: IOptions;
    setSearchParam: Dispatch<SetStateAction<any>>;
};

const useFilterBox = (props: ReceivedProps) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            department_id: null,
            position_id: null,
            worker_id: null,
            free_word: null,
        },
        onSubmit: (values: any) => {
            props.setSearchParam({
                ...values,
                department_id: values?.department_id?.id,
                position_id: values?.position_id?.id,
                worker_id: values?.worker_id?.id,
            });
        },
    });

    return {
        ...props,
        formik,
    };
};

export type Props = ReturnType<typeof useFilterBox>;

export default useFilterBox;
