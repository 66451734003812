import axios from 'axios';
const api = process.env.REACT_APP_SERVER_API;

const axiosInstance = axios.create({
    baseURL: api,
});

axiosInstance.interceptors.request.use(
    (config) => {
        let storage = localStorage.getItem('user');
        const user = storage !== null ? JSON.parse(storage) : null;

        return {
            ...config,
            headers: {
                ...config.headers,
                ...(user
                    ? {
                          Authorization: 'Bearer ' + user.access_token,
                      }
                    : {}),
            },
        };
    },
    (error) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        return Promise.reject(error.response.data);
    },
);

export const getCompare = (params: {
    project_id: number;
    budget_id: number;
    estimate_id: number;
}) => {
    return axiosInstance.get(`compares`, {
        params,
    });
};

export const postCompare = (data: any) => {
    return axiosInstance.post('compares', data);
};
