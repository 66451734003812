import Api from "./api/quoteApi";
import pick from 'lodash/pick';

const getQuoteList = async (input) => {
  let paramsData = pick(input, ["page", "per_page", "status", "quote_type", "filter_value"]);
  const params = new URLSearchParams(paramsData);
  try {
    return await Api()
      .get(`quotes?${params.toString()}`)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      });
  } catch (error) {
    return error;
  }
};

const quoteService = {
  getQuoteList,
};

export default quoteService;