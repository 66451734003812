import { FC } from "react";
import { Dialog, styled } from "@mui/material";
import PlanUpdate from "pages/project/projectPlan/components/planUpdate";
import useProjectPlanDialog, { Props, ReceivedProps } from "./hook";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root' : {
    padding: '20px',
  }
}));

const ProjectPlanDialogLayout: FC<Props> = ({ 
  open, 
  setOpen, 
  plan_id, 
  project_id, 
  currentPlan,
  projectPlanChange,
  setProjectPlanChange,
  fetchSchedules,
}) => {
  
  const handleUpdatePlan = () => {
    setProjectPlanChange((prev) => !prev);
    fetchSchedules();
    setOpen(false);
  }

  return (
    <BootstrapDialog 
      open={open} 
      onClose={() => setOpen(false)}
      className="customBootstrapDialog projectPlanDialog"
      aria-labelledby="customized-dialog-title"
    >
      <PlanUpdate
        id={plan_id}
        project_id={project_id}
        originPlan={currentPlan}
        setChangeProject={setProjectPlanChange}
        isChangeProject={projectPlanChange}
        onClose={handleUpdatePlan}
        onCancel={() => setOpen(false)}
      />
    </BootstrapDialog>
  )
}

const ProjectPlanDialog: FC<ReceivedProps> = (props) => (
  <ProjectPlanDialogLayout {...useProjectPlanDialog(props)}  />
)

export default ProjectPlanDialog;