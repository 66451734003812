import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import workersService from 'services/wokersService';
import WorkingTimeService from 'services/workingTimesService';
import { alertError } from 'utils/helper/appHelper';
import { ObjectToFormData } from 'utils/helper/helpers';

const useWorkingTimesManagementsCreate = (props: any) => {
    const [workerOptions, setWorkerOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const { id } = useParams();
    const fetchWorkers = async () => {
        try {
            const { workers } = await workersService.getListWorkers();
            if (workers) {
                setWorkerOptions(
                    workers?.map((worker) => ({
                        label: worker.name,
                        value: worker.id,
                    })),
                );
            }
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const formik = useFormik({
        initialValues: {},
        onSubmit: (values) => createWorkingTime(values),
    });

    const createWorkingTime = async (params) => {
        try {
            if(!id) {
                const formData = ObjectToFormData({ ...params, worker_id: params?.worker_id?.value });
                await WorkingTimeService.createWorkingTimes(formData);
            } else {
                const formData = ObjectToFormData({ ...params, worker_id: params?.worker_id?.value, id: id });
                await WorkingTimeService.updateWorkingTimes(id, formData);
            }
            navigate('/working_times_management');
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchWorkingTimeDetail = async () => {
        if (!id) return;
        try {
            setLoading(true)
            const { working_time_management }: any =
                await WorkingTimeService.getWorkingTimeDetail(id, {
                    worker_id: searchParams?.get('worker_id'),
                });
            formik.resetForm({
                values: {
                    checkin_location: working_time_management?.checkin_location,
                    checkout_location: working_time_management?.checkout_location,
                    start_time: working_time_management?.start_time,
                    end_time: working_time_management?.end_time,
                    submit_date: working_time_management?.submit_date,
                    worker_id: {
                        label: working_time_management?.worker?.name,
                        value: working_time_management?.worker?.id
                    }
                }
            });
            setLoading(false)
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    useEffect(() => {
        fetchWorkers();
    }, []);

    useEffect(() => {
        fetchWorkingTimeDetail();
    }, []);

    return {
        ...props,
        formik,
        navigate,
        workerOptions,
        loading,
    };
};

export type Props = ReturnType<typeof useWorkingTimesManagementsCreate>;

export default useWorkingTimesManagementsCreate;
