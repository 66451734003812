import styled from 'styled-components';

const CustomPaginationStyle = styled.div`
  margin: 20px auto 0;
  .MuiPagination-root {
    .MuiPagination-ul {
      li {
        button {
          margin: 0;
          &.Mui-disabled {
            opacity: 1;
          }          
          &.Mui-selected {
            background: none;
            border: 1px solid #215493;
            color: #215493;
          }
        }
      }
    }
  }
`;

export default CustomPaginationStyle;