/*
 * function convert object to dropdown options
 *
 */
export const convertObjectToDropdownOptions = (
    array: Array<any> = [],
): Array<{
    value: string;
    label: string;
    name: string;
}> => {
    return array.map((item) => ({
        value: item.id,
        label: item.label || item.name,
        name: item.label || item.name,
    }));
};

/**
 *
 * @param from number
 * @param to number
 * @param step number
 * @param format boolean
 * @returns any
 */
export const rangeDropdownOptions = (
    from: number = 2000,
    to: number = 2050,
    step: number = 1,
    format: boolean = false,
): Array<{
    value: string;
    label: string;
}> => {
    const listYears: Array<any> = [];
    for (let i = from; i <= to; i = i + step) {
        listYears.push({
            value: format ? String(i).padStart(2, '0') : i,
            label: format ? String(i).padStart(2, '0') : i,
        });
    }
    return listYears;
};
