import { FC, useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Box } from '@mui/material';
import useTodo, { ReceivedProps, Props } from './hook';
import { StylesTarget } from './styled';
import 'react-spring-bottom-sheet/dist/style.css';
import moment from 'moment';
import { Typography } from '@mui/material';
import TargetList from './TargetList';
import { useNavigate } from 'react-router-dom';
import scheduleService from '../../services/scheduleService';

const SPTargetLayout: FC<Props> = ({ data, fetchSchedules }) => {
    const navigate = useNavigate();

    const [date, setDate] = useState(moment().format('YYYY-MM-DD'));

    useEffect(() => {
        fetchSchedules(date);
    }, [date]);

    const nextDate = () => {
        setDate((prev) => {
            return moment(prev, 'YYYY-MM-DD')
                .add(1, 'day')
                .format('YYYY-MM-DD');
        });
    };

    const prevDate = () => {
        setDate((prev) => {
            return moment(prev, 'YYYY-MM-DD')
                .add(-1, 'day')
                .format('YYYY-MM-DD');
        });
    };

    const changeStatus = (id, status) => {
        scheduleService
            .updateScheduleSP(id, {
                status: status,
            })
            .then(() => {
                fetchSchedules(date);
            });
    };

    return (
        <StylesTarget>
            <Typography className="title">目標</Typography>
            <Box className="d-flex flex-row">
                <Box className="search-button-wrapper">
                    <button onClick={prevDate}>⟪ 昨日</button>
                </Box>
                <Box className="flex-grow-1 d-flex date-wrapper">
                    {moment(date, 'YYYY-MM-DD').format('YYYY年MM月DD日')}
                </Box>
                <Box>
                    <button onClick={nextDate}>明日 ⟫</button>
                </Box>
            </Box>
            <Box>
                <TargetList data={data} handleChange={changeStatus} />
            </Box>
            <Box className="mt-[42px]">
                <button className="btn-back" onClick={() => navigate('/')}>
                    戻る
                </button>
            </Box>
        </StylesTarget>
    );
};
const SPTarget: FC<ReceivedProps> = (props) => {
    return <SPTargetLayout {...useTodo(props)} />;
};
export default SPTarget;
