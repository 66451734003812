import { convertDateToJapan } from "utils/helper/convertDate"
import StyledCardWrapper from "./style"

const ProjectCard = ({
  name,
  imagePath,
  startDate,
  onClick
}) => {
  return (
    <StyledCardWrapper onClick={onClick}>
      {imagePath ?
        (<img className="projectImage" src={imagePath} alt="project image" />)
        : (<img className="projectImage" src={process.env.PUBLIC_URL + '/img/login-background.png'} alt="default image" />)
      }
      <div className="startDate">{startDate ? convertDateToJapan(startDate) : ''}</div>
      <h3 className="projectName">{name}</h3>
    </StyledCardWrapper>
  )
}

export default ProjectCard
