export const validate = (values: any) => {
    let errors = {};

    if (!values.title) {
        errors = {
            ...errors,
            title: '必須です。',
        };
    }
    // if (!values.project_id) {
    //     errors = {
    //         ...errors,
    //         project_id: '必須です。',
    //     };
    // }

    return errors;
};
