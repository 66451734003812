import React, { useEffect, useState } from "react";
import moment from "moment";
import _ from "lodash";
import { Link } from "react-router-dom";

import { Table, MenuItem, TextField, Select } from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#C7DCF5",
    color: "#1C1E21",
    paddingTop: "8px",
    paddingBottom: "8px",
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Zen Kaku Gothic Antique",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C1E21",
    backgroundColor: "#FFFFFF",
    padding: "4px 16px",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const ListBox = ({ data, id, dataStatus, openDelete }) => {
  const [dataList, setDataList] = useState<any[]>([]);

  useEffect(() => {
    setDataList(data);
  }, [data]);

  const renderStatusItem = (status = "") => {
    const item = dataStatus.find((i) => i.value === status);
    return item?.label || "";
  };
  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column">
        <TableContainer component={Paper} table-layout={"auto"}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: "10%" }}
                >
                  <div style={{ whiteSpace: "nowrap" }}>作成日</div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: "10%" }}
                >
                  <div style={{ whiteSpace: "nowrap" }}>更新日</div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: "10%" }}
                >
                  <div>見積担当</div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: "10%" }}
                >
                  <div>承認担当</div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: "10%" }}
                >
                  <div>状態</div>
                </StyledTableCell>
                <StyledTableCell
                  className="text-overflow1"
                  align="center"
                  style={{ width: "10%" }}
                >
                  <div>操作</div>
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {dataList &&
                dataList.map((row, ind) => {
                  return (
                    <StyledTableRow key={ind}>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ width: "10%" }}
                      >
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.created_at
                            ? moment(row.created_at).format("YYYY/MM/DD")
                            : ""}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell
                        align="center"
                        component="th"
                        scope="row"
                        style={{ width: "10%" }}
                      >
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row.updated_at
                            ? moment(row.updated_at).format("YYYY/MM/DD")
                            : ""}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: "10%" }}>
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row?.assigned_worker?.name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: "10%" }}>
                        <div
                          className="text-overflow1"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {row?.approver_worker?.name}
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center" style={{ width: "10%" }}>
                        <div className="text-overflow1">
                          {renderStatusItem(row?.status)}
                        </div>
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{ width: "255px", minWidth: "255px" }}
                      >
                        <Link
                          className="button-tb"
                          to={`/projects/budget/${id}/edit/${row?.id}?type=detail`}
                        >
                          詳細
                        </Link>
                        <Link
                          className="button-tb"
                          to={`/projects/budget/${id}/edit/${row?.id}`}
                        >
                          編集
                        </Link>
                        <button
                          className="button-tb"
                          style={{
                            width: "72px",
                            margin: "5px",
                            background: "#FF5045",
                          }}
                          onClick={() => openDelete(row)}
                        >
                          削除
                        </button>
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default ListBox;
