import { FC, useState } from 'react';
import { Tabs, Progress } from 'antd';
import 'antd/dist/antd.css';
import TodoList from './Todolist';
import useTodo, { ReceivedProps, Props } from './hook';
import { StylesTodo } from './styled';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import 'react-spring-bottom-sheet/dist/style.css';
import moment from 'moment';

const SPTodoLayout: FC<Props> = ({
    open,
    currentTodo,
    data,
    checkIsThreeDays,
    checkIsInThisWeek,
    handleClose,
    handleOpen,
}) => {
    return (
        <StylesTodo>
            {/* <BottomSheet onDismiss={handleClose} open={open}>
                <div className="p-3" style={{ height: 300 }}>
                    <p className="text-[#225594] mb-0 font-bold">
                        {currentTodo?.title}
                    </p>
                    <p className="text-[#6D6E70] mb-0">
                        {currentTodo?.content}
                    </p>
                    <div className="flex items-center">
                        <Calendar />
                        <span className="todo-time ml-1 mt-[1px]">
                            {moment(currentTodo?.end_date)?.format('MM日DD月')}
                        </span>
                    </div>
                </div>
            </BottomSheet> */}
            {/* <div className="progress-wrapper">
                <div className="flex items-center w-full">
                    <span className="text-white w-[40px] text-xs">今日</span>
                    <Progress
                        percent={75}
                        showInfo={false}
                        trailColor="#215493"
                        strokeColor="#fff"
                    />
                </div>
                <div className="flex items-center w-full">
                    <span className="text-white w-[40px] text-xs">3日</span>
                    <Progress
                        percent={30}
                        showInfo={false}
                        trailColor="#215493"
                        strokeColor="#fff"
                    />
                </div>
                <div className="flex items-center w-full">
                    <span className="text-white w-[40px] text-xs">今週</span>
                    <Progress
                        percent={50}
                        showInfo={false}
                        trailColor="#215493"
                        strokeColor="#fff"
                    />
                </div>
            </div> */}
            <Tabs defaultActiveKey="tab1">
                <Tabs.TabPane tab="今日" key="今日">
                    <TodoList
                        handleOpen={handleOpen}
                        type={1}
                        data={data?.filter((schedule) =>
                            moment().isBetween(
                                schedule?.start_date,
                                schedule?.end_date,
                                'days',
                                '[]',
                            ),
                        )}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="3日" key="3日">
                    <TodoList
                        handleOpen={handleOpen}
                        type={2}
                        data={data?.filter((schedule) =>
                            checkIsThreeDays(schedule),
                        )}
                    />
                </Tabs.TabPane>
                <Tabs.TabPane tab="今週" key="今週">
                    <TodoList
                        handleOpen={handleOpen}
                        type={3}
                        data={data?.filter((schedule) =>
                            checkIsInThisWeek(schedule),
                        )}
                    />
                </Tabs.TabPane>
            </Tabs>
        </StylesTodo>
    );
};
const SPTodo: FC<ReceivedProps> = (props) => {
    return <SPTodoLayout {...useTodo(props)} />;
};
export default SPTodo;
