import { Box, CardMedia, Typography } from '@mui/material';
import { ClipboardText, SignOut, Calendar } from 'assets/menuIcons';
import { logoutSP } from 'store/auth/auth';
import { useAppDispatch, RootState } from '../../store/index';
import { useSelector } from 'react-redux';
import { Link, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';

interface ISPSidebar {
    setIsOpenMenu: (isOpen: boolean) => void;
    isOpenMenu: boolean;
}

const menuItems = [
    {
        title: '案件一覧',
        path: '/sp/projects',
        icon: <ClipboardText />,
        child: [],
    },
    {
        title: 'カレンダー',
        path: '/sp/calendar',
        icon: <Calendar />,
        child: [],
    },
];

const SPSidebar = ({ setIsOpenMenu, isOpenMenu }: ISPSidebar) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const [searchParams] = useSearchParams();
    const handleLogout = () => {
        dispatch(logoutSP());
    };
    const { userData }: any = useSelector((state: RootState) => state.users);
    useEffect(() => {
        if (searchParams.get('line')) {
            localStorage.setItem('back_url', location.pathname);
        }
    }, []);
    return (
        <Box
            zIndex={1000}
            position="absolute"
            width="100%"
            height="100%"
            bgcolor="#215493"
            display={isOpenMenu ? 'block' : 'none'}
        >
            <Box
                display="flex"
                marginBottom="22px"
                alignItems="center"
                justifyContent="center"
                height={44}
                width="100%"
            >
                <CardMedia
                    component="img"
                    onClick={() => setIsOpenMenu(false)}
                    sx={{
                        width: '24px',
                        height: '24px',
                        position: 'absolute',
                        left: '0px',
                        ml: '16px',
                        cursor: 'pointer',
                    }}
                    src={process.env.PUBLIC_URL + '/img/close_ic.svg'}
                    alt="close_menu_icon"
                />
                <Typography
                    fontWeight={500}
                    fontFamily="Zen Kaku Gothic Antique"
                    color="#fff"
                >
                    {process.env.REACT_APP_PROJECT_TITLE}
                </Typography>
            </Box>
            <Typography
                fontWeight={700}
                margin="0px 16px"
                paddingBottom="12px"
                paddingLeft="12px"
                borderBottom="1px solid #919191"
                fontFamily="Zen Kaku Gothic Antique"
                color="#fff"
            >
                {userData?.worker?.name}
            </Typography>
            <Box padding="4px 28px 0px 28px">
                {menuItems?.map((item, index) => (
                    <Link to={item?.path} key={index}>
                        <Box
                            onClick={() => setIsOpenMenu(false)}
                            height="40px"
                            display="flex"
                            alignItems="center"
                        >
                            {item.icon}
                            <Typography
                                marginLeft="12px"
                                fontWeight="700"
                                color="#fff"
                            >
                                {item?.title}
                            </Typography>
                        </Box>
                    </Link>
                ))}
                <Box
                    onClick={handleLogout}
                    height="40px"
                    display="flex"
                    alignItems="center"
                >
                    <SignOut />
                    <Typography
                        marginLeft="12px"
                        fontWeight="700"
                        color="#FF594F"
                    >
                        ログアウト
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};
export default SPSidebar;
