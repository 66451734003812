import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

import userService from "services/userService";
import projectsService from "services/projectsService";
import workersService from "services/wokersService";
import budgetActionService from "services/budgetActionService";
import budgetItemService from "services/budgetItemService";

import { convertUserConfigToDropdownOptions } from "utils/helper/userConfigHelper";
import { convertObjectToDropdownOptions } from "utils/helper/dropdownHelper";
import { sizeBoolean } from "@fluentui/react";

export function useLogicBudget() {
  const { id = null } = useParams();

  const [statusOptions, setStatusOptions] = useState<any>([]);
  const [info, setInfo] = useState<any>({});
  const [project, setProject] = useState<any>({});
  const [workers, setWorker] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [budget, setBudget] = useState<any>({});
  const [detailBudgetItem, setDetailBudgetItem] = useState<any>({});

  useEffect(() => {
    getListConfiguration();
  }, []);

  const getListConfiguration = async () => {
    try {
      const response = await userService.getListConfiguration();
      if (response !== null && response !== undefined) {
        setStatusOptions(
          convertUserConfigToDropdownOptions(
            response.configurations?.estimate?.statuses
          )
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const getProjectDetail = async (projectId: number) => {
    try {
      const response = await projectsService.getProject(projectId);
      if (response !== null && response !== undefined) {
        setProject(response.project);
      }
    } catch (err) {
      console.log("err", err);
    }
  };
  const getListWorkers = async () => {
    try {
      const response = await workersService.getListWorkers();
      if (response !== null && response !== undefined) {
        setWorker(convertObjectToDropdownOptions(response.workers));
      }
    } catch (err) {
      console.log("err", err);
    }
  };

  const getDetailBudget = async (budgetId: number) => {
    await budgetActionService
      .getDetailBudget(budgetId, Number(id))
      .then((response) => {
        setBudget(response.budget);
      })
      .catch((error) => {});
  };

  const getDetailBudgetItem = async (input?: any) => {
    await budgetItemService
      .getDetailBudgetItem(input)
      .then((response) => {
        setDetailBudgetItem(_.get(response, "budget_item", {}));
      })
      .catch((error) => {
        setDetailBudgetItem({});
      });
  };

  const getProfileUser = async () => {
    await userService
      .getMe()
      .then((response) => {
        setInfo(_.get(response, "user", {}));
      })
      .catch((error) => {
        setInfo([]);
        console.error(error);
      });
  };

  return {
    id,
    info,
    statusOptions,
    project,
    workers,
    loading,
    budget,
    detailBudgetItem,

    setLoading,
    getProfileUser,
    getListWorkers,
    getProjectDetail,
    getDetailBudget,
    getDetailBudgetItem,
  };
}
