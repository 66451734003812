import styled from 'styled-components';

export const StylesTimeKeeping = styled.div`
    margin: 24px;
    .time--title {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .timekeeping {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #000;
        font-weight: 500;
        font-size: 16px;
    }
`;
