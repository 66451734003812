import styled from 'styled-components';

export const StyledTopSPv2 = styled.div`
    .banner-group {
      position: relative;
      .banner-img, .banner-img-trasparent {
        width: 100%;
        height: auto;
        position: absolute;
      }
      .banner-title {
        color: #ffffff;
        position: absolute;
        top: 67.19px;
        left: 50%;
        transform: translateX(-50%);
      }
      .items {
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0px 4px 4px 0px #0000000D;
        margin: 0 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        position: absolute;
        z-index: 1;
        top: 59px;
        padding: 16px 16px;
      }
      .item {
        width: calc((100% - (10px * 3)) / 4);
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        color: #676767;
        font-size: 12px;
        line-height: 12px;
        cursor: pointer;
        text-align: center;
      }
      .label {
        min-height: 24px;
      }
    }
    .todo-item {
      padding: 17px 0;
      border-bottom: 1px solid #B6E3F6;
    }
    .todo-title {
      color: #040404;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
    }
    .item-title {
      color: #1A1A1A;
      font-weight: 400;
      font-size: 12px;
      line-height: 14.46px;
      margin-bottom: 0px;
    }
    .delete-border {
      border-bottom: none;
    }
`;

export const StyledItemComp = styled.div`
  .todo {
    margin-left: 16px;
    margin-right: 16px;
    min-height: 220px;
    position: relative;
    img {
      background: linear-gradient(90deg, #FFFFFF 36.65%, #DDF5FF 108.1%);
      border-radius: 12px;
      position: absolute;
    }
    .todo-content {
      position: absolute;
      padding: 25px 16px;
      width: 100%;
    }
    .todo-title {
      color: #040404;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
    }
    .item-title {
      color: #1A1A1A;
      font-weight: 400;
      font-size: 12px;
      line-height: 14.46px;
      margin-bottom: 0px;
    }
    .todo-item {
      padding: 17px 0;
      border-bottom: 1px solid #B6E3F6;
    }
    .delete-border {
      border-bottom: none;
    }
    .icon-target {
      width: 10px;
      height: 10px;
      img {
        //width: 20px;
        //height: 20px;
        background: none;
      }
    }
  }
`

