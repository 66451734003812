import Api from './api/companyInfoApi';

const createCompanyInfo = async (payload) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getCompanyInfo = async () => {
    return await Api()
        .get('')
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const companyInfoService = {
    createCompanyInfo,
    getCompanyInfo,
};

export default companyInfoService;
