import { useEffect, useState } from "react";

interface ListData {
  id: number;
  account_name: string;
  avatar: string;
  address: string;
  status: string;
  type: string;
  created_at: string;
}

export type ReceivedProps = Record<never, never>;
const useSPQuoteList = (props: ReceivedProps) => {
    const [quoteList, setQuoteList] = useState<ListData[]>([]);

    const fetchQuoteList = async () => {
      try {
        setQuoteList([]);
      } catch (error) {
        // 
      }
    }

    useEffect(() => {
        fetchQuoteList();
    }, []);

    return {
        ...props,
        quoteList,
    };
};

export type Props = ReturnType<typeof useSPQuoteList>;

export default useSPQuoteList;