import { Dispatch, FC, SetStateAction } from 'react';
import classNames from 'classnames';

import TabWrapper from './style';
import { getRole } from 'utils/helper/userConfigHelper';

type IProps = {
    activeTab: number;
    setActiveTab: Dispatch<SetStateAction<number>>;
};

const TabBox: FC<IProps> = ({ activeTab, setActiveTab }) => {
    const role = getRole()
    const TAB_DATA = role === 'admin' ? [
      {
          title: '自分のみ',
          id: 0,
      },
      {
          title: '全員',
          id: 1,
      },
    ] : [
      {
          title: '自分のみ',
          id: 0,
      },
    ];
    return (
        <TabWrapper>
            {TAB_DATA.map((item, index) => (
                <button
                    className={classNames(activeTab === item.id ? 'active' : '')}
                    key={item.id}
                    onClick={() => setActiveTab(item.id)}
                    type="button"
                >
                    {item.title}
                </button>
            ))}
        </TabWrapper>
    );
};

export default TabBox;
