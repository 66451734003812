import Api from './api/messageApi';
import ApiSP from './api/messageApiSP';
import pick from 'lodash/pick';

const createMessage = async (payload: any) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const createMessageSP = async (payload: any) => {
    return await ApiSP()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getMessages = async (input: any) => {
    let data = pick(input, ['page', 'per_page', 'chat_id']);

    const params = new URLSearchParams(data);

    try {
        return await Api()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const getMessagesById = async (id: number) => {
    try {
        return await Api()
            .get(`${id}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const getMessagesSP = async (input: any) => {
    let data = pick(input, ['page', 'per_page', 'chat_id']);

    const params = new URLSearchParams(data);

    try {
        return await ApiSP()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const messageService = {
    createMessage,
    getMessages,
    getMessagesById,
    createMessageSP,
    getMessagesSP,
};

export default messageService;
