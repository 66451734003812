import styled from 'styled-components'

const StyledToggleWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;

  .toggleBtn {
    border: 1px solid #215493;
    padding: 0px 4px;
    cursor: pointer;

    &.active {
      background-color: #215493;
    }
  }

  .list-mode {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .card-mode {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
`;

export default StyledToggleWrapper