import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import styled from 'styled-components';

export const Styles = styled.div`
    padding: 15px;
    .btn-group {
        display: flex;
        align-items: center;
        padding: 10px;

        button {
            min-width: 130px;

            > svg {
                transform: rotate(135deg);
            }
        }

        .btn-clear {
            min-width: 100px;
            margin-right: 20px;
        }
    }

    .table-wrapper {
        border: 1px solid rgba(224, 224, 224, 1);
        border-radius: 4px;
    }

    nav {
        display: flex;
        justify-content: center;
    }
`;


