import { useEffect, useState, useRef, ChangeEvent } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import orderBy from 'lodash/orderBy';
import chatService from 'services/chatService';
import { RootState } from 'store';
import messageService from 'services/messageService';
import projectsService from 'services/projectsService';
import MessageServiceSP from 'services/messageServiceSP';

export type ReceivedProps = {
    // chatRoom: any;
    globalChat?: boolean;
};

interface IValue {
    message: string | null | undefined;
    files: File[];
}


const useChatBox = (props: ReceivedProps) => {
    const location = useLocation();
    const { userData } = useSelector((state: RootState) => state?.users);
    const workerId = (userData as any)?.worker?.id;
    const [listMessage, setListMessage] = useState<any[]>([]);
    const [listImageMessage, setListImageMessage] = useState<any[]>([]);
    const [updateChat, setUpdateChat] = useState<any>(false);
    const [removeText, setRemoveText] = useState<any>(false);
    const refInput = useRef<any>(null);
    const [message, setMessage] = useState<any>('');
    const [unreadFlag, setUnreadFlag] = useState<any>();
    const [whoCanJoinToggle, setWhoCanJoinToggle] = useState(false);
    const { chat_id, id } = useParams();
    const [chat, setChat] = useState<any>({});
    const [loading, setLoading] = useState(false);

    const removeImage = (idx: number, values: IValue, setFieldValue: (field: string, value: File[], shouldValidate?: boolean) => void) => {
        let current = [...values.files];
        current = current.filter((item, index) => index !== idx);
        setFieldValue('files', current);
    };
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>, values: IValue, setFieldValue: (field: string, value: File[], shouldValidate?: boolean) => void) => {
        if(!e?.target?.files) return;
        if (e?.target?.files.length) {
            const files: File[] = Array.from(e.target.files);
            let current = [...values.files];
            if (files.length > 0) {
                if (current.length > 0) {
                    current = current.concat(files);
                } else {
                    current = files;
                }
                setFieldValue('files', current);
            }
            refInput.current.value = '';
        }
    };

    const getLanguage = (lang) => {
        let result = 'ja';
        if (lang.startsWith('ko')) {
            result = 'ko';
        } else if (lang.startsWith('km') || lang.startsWith('kh')) {
            result = 'km';
        } else if (lang.startsWith('en')) {
            result = 'en';
        } else if (lang.startsWith('vi') || lang.startsWith('vn')) {
            result = 'vi';
        } else if (lang.startsWith('zh')) {
            result = 'zh';
        } else if (
            lang.startsWith('si') ||
            lang.startsWith('ta') ||
            lang.startsWith('lk')
        ) {
            result = 'si';
        } else if (lang.startsWith('ja')) {
            result = 'ja';
        }
        return result;
    };

    const onSubmit = async (response: any) => {
        const { message, files } = response;
        if(!message && !files?.length) return
        if (!userData.worker) return;
        if(files?.filter((f: {size: number}) => f.size > 10 * 1024 * 1024)?.length > 0) return;
        let formData = new FormData();
        formData.append('chat_id', chat_id || '');
        formData.append('worker_id', workerId);
        setLoading(true);
        if (message) {
            formData.append('body', message ? message : '');
        }
        if (files.length > 0) {
            for (let x = 0; x < files.length; x++) {
                if(files[x].type.includes('video/')) {
                    formData.append('videos[]', files[x]!)
                } else {
                    formData.append('images[]', files[x]!);
                }
            }
        }

        try {
            let data = await messageService.createMessage(formData);
            setLoading(false);
            if (data) setUpdateChat(!updateChat);
            setMessage('');
        } catch (error) {
            //
        } finally {
            //setListImageMessage([]);
            setRemoveText(!removeText);
        }
    };

    const handleReceived = async (response) => {
        try {
            const { data } = response;
            const { message } = await messageService.getMessagesById(data.id);
            setListMessage((preState) => [...preState, message]);
        } catch (error) {}
    };

    useEffect(() => {
        var elements = document.getElementsByClassName('rcw-input');
        Array.from(elements).forEach((element) => (element.innerHTML = ''));
    }, [removeText]);

    const fetchChat = async () => {
        if (!chat_id) return;
        try {
            if (props?.globalChat) {
                const { chat } = await chatService.getChat(chat_id);
                setChat(chat);
            } else {
                const { chat } = await projectsService.getChatSP(id, chat_id);
                setChat(chat);
            }
        } catch (error) {}
    };

    const fetchMessages = async () => {
        try {
            if (chat_id) {
                const { messages } = await messageService.getMessagesSP({
                    page: 1,
                    per_page: 99999,
                    chat_id: chat_id,
                });
                for (let index = 0; index < orderBy(messages, 'id', 'asc').length; index++) {
                    const element = orderBy(messages, 'id', 'asc')[index];
                    if (!element.is_read) {
                        setUnreadFlag(element.id);
                        break;
                    } else {
                        setUnreadFlag(null);
                    }
                }
                setListMessage(orderBy(messages, 'id', 'asc'));
            }
        } catch (error) {
            //
        }
    };
    useEffect(() => {
        fetchChat();
    }, [chat_id]);
    useEffect(() => {
        fetchMessages();
    }, [location.pathname, chat_id, updateChat]);
    const handleReadChat = async () => {
        try {
            await MessageServiceSP.reaAllMessages(Number(chat_id), {
                worker_id: workerId,
            });
            fetchMessages();
        } catch (error) {
            //
        }
    };
    return {
        ...props,
        workerId,
        listMessage,
        setListMessage,
        onSubmit,
        setListImageMessage,
        listImageMessage,
        handleFileChange,
        refInput,
        removeImage,
        setMessage,
        message,
        chatRoomId: chat_id,
        chat,
        handleReceived,
        handleReadChat,
        whoCanJoinToggle,
        setWhoCanJoinToggle,
        unreadFlag,
        getLanguage,
        loading
    };
};

export type Props = ReturnType<typeof useChatBox>;

export default useChatBox;
