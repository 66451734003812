export const EditIcon = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.50001 20.25H5C4.80109 20.25 4.61033 20.171 4.46967 20.0303C4.32902 19.8897 4.25 19.6989 4.25 19.5V15.3094C4.24966 15.212 4.26853 15.1155 4.30553 15.0254C4.34253 14.9353 4.39694 14.8534 4.46563 14.7844L15.7156 3.53437C15.7854 3.46351 15.8686 3.40723 15.9603 3.36882C16.0521 3.33041 16.1505 3.31062 16.25 3.31062C16.3495 3.31062 16.4479 3.33041 16.5397 3.36882C16.6314 3.40723 16.7146 3.46351 16.7844 3.53437L20.9656 7.71562C21.0365 7.78541 21.0928 7.86859 21.1312 7.96033C21.1696 8.05207 21.1894 8.15054 21.1894 8.24999C21.1894 8.34945 21.1696 8.44792 21.1312 8.53966C21.0928 8.6314 21.0365 8.71458 20.9656 8.78437L9.50001 20.25Z"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M20.75 20.25H9.5"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.25 6L18.5 11.25"
      stroke="black"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
