import { useEffect, useState } from 'react';
import { TextField, Autocomplete, CircularProgress } from '@mui/material';
import DatePicker from 'react-datepicker';
import pick from 'lodash/pick';
import calendarIcon from 'assets/icons/calendar.png';
import DialogHeader from '../../common/DialogHeader';
import { StyledTextField } from 'components/StyledTextField';
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from 'utils/constants';
import CommonStyledSugorkuDialogWrapper from '../../common/commonSugorokuDialogStyle';
import { SugorokuIndexEnum } from 'utils/enums';
import { useFormik, FormikProps } from 'formik';
import { useParams } from 'react-router-dom';
import sugorokuService from 'services/sugorokuService';
import { formatDateTime } from 'utils/helper/dateHelper';
import { IRequestForQuotationPayload } from 'models';
import isEmpty from 'lodash/isEmpty';
interface IProps {
    step: number;
    onClose?: () => void;
    data_id?: number;
    workerOptions: any[];
    companyOptions: any[];
    onFinish: () => void;
}

interface IRequestQuotationFormikValues {
    submitted_at?: Date | null;
    company?: any;
    assigned_worker?: any;
    person_in_charge_worker?: any;
    content?: string | null;
    completed_at?: Date | null;
}

const RequestQuotationModal = (props: IProps) => {
    const { step, onClose, data_id, workerOptions, companyOptions, onFinish } =
        props;
    const [mode, setMode] = useState(VIEW_SUGOROKU_MODE);
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<any>(true);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const hasData = !!data_id;
    const { id } = useParams();

    const formik: FormikProps<IRequestQuotationFormikValues> =
        useFormik<IRequestQuotationFormikValues>({
            initialValues: {
                submitted_at: null,
                company: null,
                person_in_charge_worker: null,
                content: '',
                assigned_worker: null,
                completed_at: null,
            },
            onSubmit: (values: IRequestQuotationFormikValues) => {
                setSubmitLoading(true);
                let data: IRequestForQuotationPayload = pick(values, [
                    'content',
                ]);
                if (!id) {
                    setSubmitLoading(false);
                    return;
                }

                data.project_id = +id;
                data.sugoroku_index =
                    SugorokuIndexEnum.REQUEST_FOR_QUOTATION.value;

                if (values.completed_at) {
                    data.completed_at = formatDateTime(
                        values.completed_at,
                        'yyyy-MM-DD',
                    );
                } else {
                    data.completed_at = null;
                }

                if (values.submitted_at) {
                    data.submitted_at = formatDateTime(
                        values.submitted_at,
                        'yyyy-MM-DD',
                    );
                } else {
                    data.submitted_at = null;
                }

                if (values.company) {
                    data.company_id = +values.company.value;
                } else {
                    data.company_id = null;
                }

                if (values.assigned_worker) {
                    data.assigned_worker_id = +values.assigned_worker.value;
                } else {
                    data.assigned_worker_id = null;
                }

                if (values.person_in_charge_worker) {
                    data.person_in_charge_worker_id =
                        +values.person_in_charge_worker.value;
                } else {
                    data.person_in_charge_worker_id = null;
                }

                if (hasData) {
                    updateSugoroku(data);
                } else {
                    createSugoroku(data);
                }
            },
        });

    const fetchData = async () => {
        try {
            setFetchLoading(true);
            const data = await sugorokuService.getSugorokuEventDetail(
                data_id,
                Number(id),
            );
            if (data && data.sugoroku_event) {
                const sugorokuData = data.sugoroku_event;

                formik.setValues({
                    submitted_at: sugorokuData.submitted_at
                        ? new Date(sugorokuData.submitted_at)
                        : null,
                    company: sugorokuData.company
                        ? {
                              value: String(sugorokuData.company.id),
                              label: sugorokuData.company.name,
                              name: sugorokuData.company.name,
                          }
                        : null,
                    assigned_worker: sugorokuData.assigned_worker
                        ? {
                              value: String(sugorokuData.assigned_worker.id),
                              label: sugorokuData.assigned_worker.name,
                              name: sugorokuData.assigned_worker.name,
                          }
                        : null,
                    person_in_charge_worker:
                        sugorokuData.person_in_charge_worker
                            ? {
                                  value: String(
                                      sugorokuData.person_in_charge_worker.id,
                                  ),
                                  label: sugorokuData.person_in_charge_worker
                                      .name,
                                  name: sugorokuData.person_in_charge_worker
                                      .name,
                              }
                            : null,
                    content: sugorokuData.content,
                    completed_at: sugorokuData.completed_at
                        ? new Date(sugorokuData.completed_at)
                        : null,
                });
                setIsEmpty(false);
            } else {
                setIsEmpty(true);
            }
        } catch (error) {
            // error
        } finally {
            setFetchLoading(false);
        }
    };

    const createSugoroku = async (data: IRequestForQuotationPayload) => {
        try {
            const responseData = await sugorokuService.createSugorokuEvent(
                data,
            );
            if (responseData) {
                setSubmitLoading(false);
                onFinish();
            }
        } catch (error) {
            setSubmitLoading(false);
            // empty
        }
    };

    const updateSugoroku = async (data: IRequestForQuotationPayload) => {
        try {
            const responseData = await sugorokuService.updateSugorokuEvent(
                data_id,
                data,
            );
            if (responseData) {
                setSubmitLoading(false);
                onFinish();
            }
        } catch (error) {
            setSubmitLoading(false);
            // epmty
        }
    };

    useEffect(() => {
        if (data_id && id) {
            fetchData();
        }
    }, []);

    return (
        <CommonStyledSugorkuDialogWrapper>
            <DialogHeader
                step={step}
                title={SugorokuIndexEnum.REQUEST_FOR_QUOTATION.title}
                onClose={onClose}
                mode={mode}
                setMode={setMode}
                completed={!!formik.values.completed_at}
            />

            {fetchLoading && (
                <div className="d-flex justify-center">
                    <CircularProgress />
                </div>
            )}

            {mode === VIEW_SUGOROKU_MODE && !fetchLoading && !isEmpty && (
                <>
                    <div className="dialog-field-row">
                        <div className="field-label">日付</div>
                        <div className="value">
                            {formatDateTime(
                                formik.values.submitted_at,
                                'yyyy-MM-DD',
                            )}
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">企業名</div>
                        <div className="value">
                            {formik.values.company?.label || ''}
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">担当者</div>
                        <div className="value">
                            {formik.values.person_in_charge_worker?.label || ''}
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">内容</div>
                        <div className="value">{formik.values.content}</div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">受けた人</div>
                        <div className="value">
                            {formik.values.assigned_worker?.name || ''}
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">完了日付</div>
                        <div className="value">
                            {formatDateTime(
                                formik.values.completed_at,
                                'yyyy-MM-DD',
                            )}
                        </div>
                    </div>
                </>
            )}

            {mode === EDIT_SUGOROKU_MODE && !fetchLoading && (
                <form onSubmit={formik.handleSubmit}>
                    <div className="dialog-field-row">
                        <div className="field-label">日付</div>
                        <div className="datepicker-wrapper">
                            <DatePicker
                                id="submitted_at"
                                selected={formik.values.submitted_at}
                                onChange={(value) => {
                                    formik.setFieldValue('submitted_at', value);
                                }}
                                dateFormat="yyyy-MM-dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <label htmlFor="submitted_at">
                                <img
                                    className="calendar-datepicker-icon"
                                    src={calendarIcon}
                                ></img>
                            </label>
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">企業名</div>
                        <div className="autocomplete-wrapper">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                value={formik.values.company}
                                options={companyOptions?.map((item) => ({
                                    label: item.label,
                                    value: String(item.value),
                                    name: item.name,
                                }))}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue('company', newValue);
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                classes={{
                                    root: 'autocomplete-select-style',
                                    // input: 'autocomplete-select-INPUT-style',
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" />
                                )}
                                noOptionsText="該当なし"
                            />
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">担当者</div>
                        <div className="autocomplete-wrapper">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={workerOptions.map((item) => ({
                                    label: item.label,
                                    value: String(item.value),
                                    name: item.name,
                                }))}
                                value={formik.values.person_in_charge_worker}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'person_in_charge_worker',
                                        newValue,
                                    );
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                classes={{
                                    root: 'autocomplete-select-style',
                                    // input: 'input-select-style',
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" />
                                )}
                                noOptionsText="該当なし"
                            />
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">内容</div>
                        <StyledTextField
                            className="textfield-style"
                            name="content"
                            value={formik.values.content}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">受けた人</div>
                        <div className="autocomplete-wrapper">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={workerOptions.map((item) => ({
                                    label: item.label,
                                    value: String(item.value),
                                    name: item.name,
                                }))}
                                value={formik.values.assigned_worker}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'assigned_worker',
                                        newValue,
                                    );
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                classes={{
                                    root: 'autocomplete-select-style',
                                    // input: 'input-select-style',
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" />
                                )}
                                noOptionsText="該当なし"
                            />
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">完了日付</div>
                        <div className="datepicker-wrapper">
                            <DatePicker
                                id="completed_at"
                                selected={formik.values.completed_at}
                                onChange={(date) => {
                                    formik.setFieldValue('completed_at', date);
                                }}
                                dateFormat="yyyy-MM-dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <label htmlFor="completed_at">
                                <img
                                    className="calendar-datepicker-icon"
                                    src={calendarIcon}
                                ></img>
                            </label>
                        </div>
                    </div>

                    <div className="action-section">
                        <button
                            type="button"
                            className="back-step-edit-button"
                            onClick={onClose}
                        >
                            キャンセル
                        </button>
                        <button
                            type="submit"
                            className="submit-step-edit-button"
                            disabled={submitLoading}
                        >
                            {submitLoading ? (
                                <CircularProgress color="inherit" size={18} />
                            ) : (
                                '登録'
                            )}
                        </button>
                    </div>
                </form>
            )}
        </CommonStyledSugorkuDialogWrapper>
    );
};

export default RequestQuotationModal;
