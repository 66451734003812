export default function keydownControls(e: any, jexcel: any) {
  if (jexcel.current) {
    if (jexcel.current.edition) {
      const column = Number(jexcel.current?.edition[2]);
      if (column === 1 || column === 2 || column === 6 || column === 7) {
        jexcel.current.edition[0].children[0].maxLength = 255;
      }

      if (column === 4 || column === 9) {
        jexcel.current.edition[0].children[0].oninput = function () {
          this.value = this.value.slice(0, 15).replace(/[^\d]/g, "");
        };
      }

      if (column === 3 || column === 8) {
        jexcel.current.edition[0].children[0].oninput = function () {
          let valueInput = this.value;
          if (`${this.value}`.charAt(0) === "-") {
            valueInput = `-${`${this.value}`
              .slice(1)
              .replace(/-/g, "")
              .replace(/[^\d.]/g, "")}`;
          } else {
            valueInput = valueInput.replace(/[^\d.]/g, "");
          }

          const index = valueInput.indexOf(".");
          if (index !== -1) {
            valueInput = valueInput.slice(0, index + 5);
          }

          this.value = valueInput.trim().slice(0, 20);
        };
      }

      if (e.which == 27) {
        // Escape
        if (jexcel.current.edition) {
          // Exit without saving
          jexcel.current.closeEditor(jexcel.current.edition[0], false);
        }
        e.preventDefault();
      } else if (e.which == 13) {
        //Enter
        if (
          jexcel.current.options.columns[jexcel.current.edition[2]].type ==
          "calendar"
        ) {
          jexcel.current.closeEditor(jexcel.current.edition[0], true);
        } else if (
          jexcel.current.options.columns[jexcel.current.edition[2]].type ==
            "dropdown" ||
          jexcel.current.options.columns[jexcel.current.edition[2]].type ==
            "autocomplete"
        ) {
          // Do nothing
        } else {
          // Alt enter -> do not close editor
          if (
            (jexcel.current.options.wordWrap == true ||
              jexcel.current.options.columns[jexcel.current.edition[2]]
                .wordWrap == true ||
              jexcel.current.options.data[jexcel.current.edition[3]][
                jexcel.current.edition[2]
              ].length > 200) &&
            e.altKey
          ) {
            // Add new line to the editor
            var editorTextarea = jexcel.current.edition[0].children[0];
            var editorValue = jexcel.current.edition[0].children[0].value;
            var editorIndexOf = editorTextarea.selectionStart;
            editorValue =
              editorValue.slice(0, editorIndexOf) +
              "\n" +
              editorValue.slice(editorIndexOf);
            editorTextarea.value = editorValue;
            editorTextarea.focus();
            editorTextarea.selectionStart = editorIndexOf + 1;
            editorTextarea.selectionEnd = editorIndexOf + 1;
          } else {
            jexcel.current.edition[0].children[0].blur();
          }
        }
      } else if (e.which == 9) {
        // Tab
        if (
          ["calendar", "html"].includes(
            jexcel.current.options.columns[jexcel.current.edition[2]].type
          )
        ) {
          jexcel.current.closeEditor(jexcel.current.edition[0], true);
        } else {
          jexcel.current.edition[0].children[0].blur();
        }
      } else if (e.shiftKey && e.which === 8) {
        jexcel.current.setValue(jexcel.current.highlighted, "");
      }
    }
  }
}
