export const departmentValidation = (values: any) => {
  let errors = {};

  if (!values.name) {
    errors = {
      ...errors,
      name: '部署名は必須です。'
    }
  }

  return errors;
}