import { FC, useEffect, useState } from 'react';
import SelectSearch, { SelectSearchOption } from 'react-select-search';
// import useSPQuoteForm, { ReceivedProps, Props } from './hook';
import { QuoteStatusEnum } from 'utils/enums';
import { useParams } from 'react-router-dom';
import './style.scss';
import useSPPaymentForm, { ReceivedProps, Props } from './hook';
import { SPQuoteFormStyles } from './styled';
import { PaymentElement, Elements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FormPayment from './FormPayment';

const inputStyle = {
    fontSize: '16px',
    lineHeight: '38px',
    border: '1px solid #000'
}

const SPPaymentFormLayout: FC<Props> = ({
    handleSubmit,
    control,
    register,
    errors,
    setValue,
    handleValidateWhitespace,
    isCard,
    setIsCard,
    errorDate,
    clientSecret,
}) => {
    const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret && clientSecret,
    };
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);



    const statusOptions = Object.values(QuoteStatusEnum).map((item) => ({
        value: item.value,
        name: item.title,
    }));
    return (
        <div>
            {clientSecret && <Elements stripe={stripePromise} options={{ locale: 'ja', clientSecret: clientSecret }}>
                <SPQuoteFormStyles>
                    <FormPayment errors={errors} setValue={setValue} handleValidateWhitespace={handleValidateWhitespace} isCard={isCard} setIsCard={setIsCard}
                    handleSubmit={handleSubmit} control={control} register={register} clientSecret={clientSecret}
                    errorDate={errorDate} />
                </SPQuoteFormStyles>
            </Elements>}
        </div>

    );
};

const SPPaymentForm: FC<ReceivedProps> = (props) => {
    return <SPPaymentFormLayout {...useSPPaymentForm(props)} />;
};
export default SPPaymentForm;
