import { FC } from 'react';
import { StylesTargetStatus } from './styled';
import { ReceivedProps, Props } from './hook';
import initStatusIcon from 'assets/icons/status/ic_init_fill.svg';
import initStatusEmptyIcon from 'assets/icons/status/ic_init_empty.svg';
import progressStatusIcon from 'assets/icons/status/ic_progress_fill.svg';
import progressStatusEmptyIcon from 'assets/icons/status/ic_progress_empty.svg';
import finishStatusIcon from 'assets/icons/status/ic_finish_fill.svg';
import finishStatusEmptyIcon from 'assets/icons/status/ic_finish_empty.svg';

import useTargetStatus from './hook';

const TargetStatusLayout: FC<Props> = ({ status, id, handleChange }) => {
    return (
        <StylesTargetStatus>
            <div className="d-flex status-wrapper">
                {status === 'initial' && (
                    <>
                        <img src={initStatusIcon} />
                        <img src={progressStatusEmptyIcon} onClick={() => {handleChange(id, 'in_progress')}} />
                        <img src={finishStatusEmptyIcon} onClick={() => {handleChange(id, 'completed')}} />
                    </>
                )}
                {status === 'in_progress' && (
                    <>
                        <img src={initStatusEmptyIcon} onClick={() => {handleChange(id, 'initial')}} />
                        <img src={progressStatusIcon} />
                        <img src={finishStatusEmptyIcon} onClick={() => {handleChange(id, 'completed')}} />
                    </>
                )}
                {status === 'completed' && (
                    <>
                        <img src={initStatusEmptyIcon} onClick={() => {handleChange(id, 'initial')}} />
                        <img src={progressStatusEmptyIcon} onClick={() => {handleChange(id, 'in_progress')}} />
                        <img src={finishStatusIcon} />
                    </>
                )}
            </div>
        </StylesTargetStatus>
    );
};
const TargetStatus: FC<ReceivedProps> = (props) => {
    return <TargetStatusLayout {...useTargetStatus(props)} />;
};
export default TargetStatus;
