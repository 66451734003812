import { FC } from 'react';

type IProps = {
    strokeColor?: string;
};

const FilterSearch: FC<IProps> = ({ strokeColor = '#CDD1D5' }) => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" stroke="black" stroke-opacity="0.1"/>
        <path d="M16 15.25V24.25" stroke="#1890FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M16 7.75V12.25" stroke="#1890FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.75 22.75V24.25" stroke="#1890FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M22.75 7.75V19.75" stroke="#1890FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M25 19.75H20.5" stroke="#1890FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.25 19.75V24.25" stroke="#1890FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.25 7.75V16.75" stroke="#1890FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 16.75H11.5" stroke="#1890FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.25 12.25H13.75" stroke="#1890FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        
    );
};

export default FilterSearch;
