import styled from 'styled-components';

export const StylesCreateVehicleInspection = styled.div`
    background-color: #fff;
    margin: 28px;
    padding: 32px 28px;
    border-radius: 8px;
    .create-vehicle-inspection-title {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 25px;
    }
    .label {
        color: #344054;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
    }
`;
