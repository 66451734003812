import { useEffect, useState } from "react";
import { useModal } from "react-hooks-use-modal";
import { useLocation, useParams } from "react-router-dom";
import invoiceApi from "services/invoiceApi";
import quoteApi from "services/quoteApi";
import quoteService from "services/quoteService";
import { alertSuccess } from "utils/helper/appHelper";

interface ListData {
    id: number;
    user_name: string;
    avatar_url: string;
    address: string;
    status: string;
    created_at: string;
    quote_type: string;
}

const fakeData = [
    {
        number: 1,
        content: '内装工事',
        unit: '式',
        quantity: '01',
        unit_price: 3020,
        amount_of_money: 3020,
        tax_rate: 10,
        amount_with_tax: 3020
    },
    {
        number: 2,
        content: 'トイレ工事',
        unit: '式',
        quantity: '01',
        unit_price: 3020,
        amount_of_money: 3020,
        tax_rate: 10,
        amount_with_tax: 3020
    },
    {
        number: 3,
        content: '電気工事',
        unit: '式',
        quantity: '01',
        unit_price: 3020,
        amount_of_money: 3020,
        tax_rate: 10,
        amount_with_tax: 3020
    },
    {
        number: 4,
        content: '水道工事',
        unit: '式',
        quantity: '01',
        unit_price: 3020,
        amount_of_money: 3020,
        tax_rate: 10,
        amount_with_tax: 3020
    }
]

const fakeObj = {
    companyName: 'コンアフェット株式会社',
    date: '2023/11/30',
    phone_number: 'XXXX-XX-XXXX',
    address: '〒220-0001 神奈川県 横浜市西区 北軽井沢20-1',
    customer: '山田太郎',
    address2: '〒220-0001 神奈川県 横浜市西区 北軽井沢30-1',
    quotes: [
        {
            title: '内装工事',
            quantity: '1',
            unit: '式',
            unit_price: 16000,
            tax_rate: 10,
            amount_money: 16000
        },
        {
            title: '電気工事',
            quantity: '1',
            unit: '¥',
            unit_price: 16000,
            tax_rate: 10,
            amount_money: 16000
        }
    ],
    subtotal: 180,
    discount: 96,
    total: 74
}

export type ReceivedProps = Record<never, never>;
const useSPInvoiceDetail = (props: ReceivedProps) => {
    const [quoteList, setQuoteList] = useState<ListData[]>([]);
    const [data, setData] = useState({})
    const [invoice, setInvoice]: any = useState()
    const location = useLocation()
    const [paymentDate, setPaymentDate] = useState('')
    let storage = localStorage.getItem('line:accessToken');
    const params = useParams();
    const { id } = params;

    const fetchInvoice = async () => {
        try {
            
            const res = await invoiceApi.getLineInvoiceDetail(storage, id)
            if (res) {
                setInvoice(res.data.invoice)
            }
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        fetchInvoice()
    }, [])
    return {
        ...props,
        invoice,
        data
    };
};
export type Props = ReturnType<typeof useSPInvoiceDetail>;

export default useSPInvoiceDetail;
