import { FC } from "react";
import { Breadcrumbs, Link, Typography } from "@mui/material";

interface Props {
  title: string;
  data: Data[];
}

interface Data {
  name: string;
  url: string;
}

const Breadcrumb: FC<Props> = ({ title, data }) => {
  return (
    <div className="bg-white w-full px-[24px] py-[19px] border-t-2 border-[#f0f0f0] border-solid">
      <div className="mb-[10px]">
        <Typography fontFamily="Roboto" fontWeight={500} fontSize={20}>
          {title}
        </Typography>
      </div>

      <Breadcrumbs aria-label="breadcrumb">
        {data.map((el, i) => (
          <Link
            underline="hover"
            color={i === data.length - 1 ? "#000" : "inherit"}
            href={el.url}
          >
            <Typography fontFamily="Roboto" fontWeight={400} fontSize={14}>
              {el.name}
            </Typography>
          </Link>
        ))}
      </Breadcrumbs>
    </div>
  );
};

export default Breadcrumb;
