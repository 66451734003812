import React, {useState, useEffect} from 'react';
import './progressBar.css'

declare let window: any;


const ProgressBar = (props) =>{
    const {
       width
    } = props;
  const [hide, setHide]=useState(false)
  
  useEffect(() => {

  });

  return (
    <div className='progress-container' style={{width}}>
        
    </div>
  );
}
export default ProgressBar ;