import { GridApi } from 'ag-grid-community';
import { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import projectsService from 'services/projectsService';
import chatService from 'services/chatService';

export type ReceivedProps = {};

export const ROW_HEIGHT = 50;
export const HEADER_HEIGHT = 40;

const useChat = (props: ReceivedProps) => {
    const { id } = useParams();
    const [gridApi, setGridApi] = useState<GridApi>();
    const [rowData, setRowData] = useState<any[]>([]);
    const [chatId, setChatId] = useState<any>(null);
    const getListChat = async () => {
        try {
            if (!id) return;
            const response = await projectsService.getListChatSP(Number(id), {
                page: 1,
                per_page: 0,
            });
            if (response !== null && response !== undefined) {
                setRowData(response.chats);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const fetchChats = useCallback(async () => {
        try {
            const { chats, meta } = await chatService.getChats({});

            setRowData(chats);
            // setTotal(meta.total_count);
        } catch (error) {
            //
        }
    }, []);

    const onDeleteChat = async (closeDelete: () => void) => {
        if (!chatId) return;
        try {
            await chatService.deleteChat(chatId);
            fetchChats();
            closeDelete();
        } catch (error) {
            //
            console.log(error);
        }
    };

    // useEffect(() => {
    //     getListChat();
    // }, [id]);

    useEffect(() => {
        fetchChats();
    }, []);
    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
        const element = document.querySelectorAll('.ag-text-field-input');

        element.forEach((item: any) => {
            item.placeholder = '検索';
        });
    }, [rowData, gridApi]);

    return {
        ...props,
        rowData,
        onDeleteChat,
        setChatId,
        id,
    };
};

export type Props = ReturnType<typeof useChat>;

export default useChat;
