import useMeetingMinus, { Props } from './hook';
import { StylesMeetingMinutes } from './styled';
import { Button, TextField, InputAdornment, Box } from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { ReceivedProps, DataType } from './type';

const MeetingMinutessLayout = ({
    formik,
    meetingMinusList,
    ModalDelete,
    closeDelete,
    handleOpenDeleleModal,
    handleDelete,
    navigate,
    ModalUpdate,
    handleOpenUpdateModal,
    openUpdate,
    closeUpdate,
    isOpenUpdate,
    updateFormik,
}: Props) => {
    const columns: ColumnsType<DataType> = [
        {
            title: 'タイトル',
            dataIndex: 'subject',
            key: 'subject',
            render: (text) => <span className="vehicle-name">{text}</span>,
            ellipsis: true,
        },
        {
            title: 'ファイル名',
            dataIndex: 'file_name',
            key: 'file_name',
            render: (text, record: any) => (
                <div>
                    {record?.meeting_files?.map((file, index) => (
                        <span className="vehicle-name">
                            {file.name}
                            {index < record?.meeting_files.length - 1 ? ',' : ''}
                        </span>
                    ))}
                </div>
            ),
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'action',
            width: 180,
            align: 'center',
            render: (_, record) => (
                <div className="flex items-center gap-[4px]">
                    <button
                        onClick={() => navigate(`edit/${record.id}`)}
                        className="search-user-button !w-[72px] d-flex flex-column height36"
                        type="button"
                    >
                        編集
                    </button>
                    <button
                        onClick={() => handleOpenDeleleModal(record)}
                        className="search-user-button !bg-[#FF5045] !w-[72px] d-flex flex-column height36"
                        type="button"
                    >
                        削除
                    </button>
                </div>
            ),
        },
    ];

    return (
        <StylesMeetingMinutes>
            <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={closeDelete}
                        >
                            キャンセル
                        </button>
                        <button
                            onClick={handleDelete}
                            className="modal-create-user-button2 d-flex flex-row"
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
            <ModalUpdate>
                <Box
                    sx={{
                        '.input-search': {
                            border: '1px solid #cdd1d5',
                            borderRadius: '8px',
                            marginBottom: '14.5px',
                            paddingLeft: '14px',
                        },
                        '.MuiTextField-root': {
                            border: '1px solid #cdd1d5',
                            width: '342px',
                            borderRadius: '8px',
                            marginRight: '12.5px',
                        },
                        '.MuiInputBase-input': {
                            height: '35px',
                            padding: '0px 0px 0px 12px !important',
                        },
                    }}
                >
                    <form onSubmit={updateFormik.handleSubmit}>
                        <div className="modal-create-user d-flex flex-column">
                            <div className="modal-create-user-title d-flex flex-row mb-[20px]">
                                本当にこれを更新しますか?
                            </div>
                            <div>
                                <label className="text-[14px]">
                                    名前 <span className="text-red">*</span>
                                </label>
                                <TextField
                                    inputProps={{ maxLength: 222 }}
                                    autoComplete="off"
                                    id="input-search"
                                    type="text"
                                    name="subject"
                                    value={updateFormik.values.subject}
                                    onChange={(e) => {
                                        updateFormik.setFieldValue(
                                            'subject',
                                            e.target.value,
                                        );
                                    }}
                                />
                            </div>
                            <div
                                className="modal-create-user-buttons d-flex flex-row"
                                style={{ marginTop: '30px' }}
                            >
                                <button
                                    className="modal-create-user-button1 d-flex flex-row"
                                    onClick={closeUpdate}
                                >
                                    キャンセル
                                </button>
                                <Button
                                    sx={{
                                        padding: '6px 18px',
                                        width: '166.5px',
                                        marginLeft: '4px',
                                        backgroundColor: '#215493',
                                        borderColor: '#215493',
                                        color: '#fff',
                                        height: '44.5px',
                                        borderRadius: '8px',
                                    }}
                                    variant="contained"
                                    disabled={!!updateFormik?.errors?.subject}
                                    type="submit"
                                    // className="modal-create-user-button2 d-flex flex-row"
                                >
                                    はい
                                </Button>
                            </div>
                        </div>
                    </form>
                </Box>
            </ModalUpdate>
            <div className="flex justify-between">
                <p className="vehicle-title">議事録</p>
                <Button
                    onClick={() => navigate('create')}
                    sx={{ mb: '20px', fontWeight: 'bold', padding: '6px 18px' }}
                    variant="outlined"
                >
                    <Add /> 新規作成
                </Button>
            </div>
            <div>
                <form onSubmit={formik.handleSubmit} className="search-box">
                    <div className="search-box">
                        <label className="text-[#344054] text-xs">
                            キーワード
                        </label>
                        <div className="flex items-center">
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search sx={{ color: '#000' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="off"
                                id="input-search"
                                type="text"
                                name="subject"
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'subject',
                                        e.target.value,
                                    );
                                }}
                            />
                            <button
                                className="search-user-button !w-[100px] d-flex flex-column height36"
                                type="submit"
                            >
                                検索
                            </button>
                        </div>
                    </div>
                    <div className="mt-[14.5px]">
                        <Table
                            rowKey="id"
                            columns={columns}
                            pagination={false}
                            dataSource={meetingMinusList}
                        />
                    </div>
                </form>
            </div>
        </StylesMeetingMinutes>
    );
};

const MeetingMinutes = (props: ReceivedProps) => {
    return <MeetingMinutessLayout {...useMeetingMinus(props)} />;
};

export default MeetingMinutes;
