import styled from 'styled-components';
import Slider from 'react-slick';

export const StyledSlider = styled(Slider)`
    .slick-prev {
        left: -4px;
    }
    .slick-next {
        right: -1px;
    }
    .slick-slide {
        text-align: center;
    }

    .slick-slide img {
        display: block;
        margin-left: auto;
        margin-right: auto;
        // If you want to vertically center as well in a fixed height container
        max-width: 300px; // adjust the containerHeight to your value
        object-fit: cover; // if you want the images to cover the area without changing aspect ratio
    }
    .slick-prev,
    .slick-next {
        z-index: 1;
        width: 30px;
        height: 30px;
        &::before {
            font-size: 30px;
        }
    }

    .slick-dots {
        li {
            margin: 0;
            &.slick-active {
                button::before {
                    color: #215493;
                    transform: scale(1.4);
                    opacity: 1;
                }
            }
            button::before {
                font-size: 12px;
            }
        }
    }

    .slick-next:before {
        color: #d3d3d3;
    }

    .slick-prev:before {
        color: #d3d3d3;
    }

    @media (max-width: 768px) {
        .slick-prev,
        .slick-next {
            color: #dcdcdc;
        }
    }
`;
