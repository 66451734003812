import styled from 'styled-components';

export const Main = styled.div`
    .button-tb {
        margin-left: 8px !important;

        &.btn-delete {
            width: 72px;
            background: #ff5045;
        }
    }

    .payment-wrapper {
        justify-content: center;
        align-items: center;
        justify-content: space-between;

        &.row-single {
            th {
                border-bottom: 0;
            }
        }

        tr {
            &:nth-child(1),
            &:nth-child(2) {
                width: 25%;
            }

            &:nth-child(2) {
                margin-left: 8px;
            }
        }
    }

    .actions {
        vertical-align: top;
        width: 300px;
        min-width: 300px;
    }
`;
