import { useState } from 'react';
import scheduleService from 'services/scheduleService';
import moment from 'moment';

export type ReceivedProps = Record<never, never>;
const useTarget = (props: ReceivedProps) => {
    const [data, setData] = useState<any>([]);
    const fetchSchedules = async (date) => {
        try {
            const { schedules } = await scheduleService.getTarget({
                included_date: date
            });
            setData(
                schedules
            );
        } catch (error) {}
    };

    return {
        ...props,
        data,
        fetchSchedules,
    };
};

export type Props = ReturnType<typeof useTarget>;

export default useTarget;
