import styled from 'styled-components';

const TabWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #dddddd;
    padding: 5px 0 0;
    margin: 0 0 15px;

    button {
        border: 0;
        padding: 0px 15px 7px 15px;
        background: none;
        color: rgba(28, 30, 33, 0.7);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        border-bottom: 2px solid transparent;
        transition: all 0.3s ease 0s;
        position: relative;
        top: 1px;
        &.active,
        &:hover {
            color: #195192;
            border-bottom-color: #195192;
        }
    }
`;

export default TabWrapper;
