import { FC } from 'react';

import SchedulerBox from './SchedulerBox';

import plus from '../../assets/icons/Plus.png';
import TabBox from './TabBox';
import useSchedules, { Props, ReceivedProps } from './hook';
import { SchedulesWrapper } from './style';
import FilterBox from './FilterBox';
import { useNavigate } from 'react-router-dom';

const SchedulesLayout: FC<Props> = ({
    openDeleteConfirmationDialog,
    openScheduleFormDialog,
    openPlanUpdateModal,
    activeTab,
    workerOptions,
    positionOptions,
    departmentOptions,
    searchParam,
    setSearchParam,
    setActiveTab,
    setOpenScheduleFormDialog,
    setOpenDeleteConfirmationDialog,
    setOpenPlanUpdateModal,
}) => {
    const navigate = useNavigate();

    return (
        <>
            <SchedulesWrapper className="container-schedules d-flex flex-column">
                <div className="schedules-content d-flex flex-column">
                    <div className="schedules-header d-flex flex-row">
                        <div className="page-title">カレンダー</div>
                        <button
                            className="button-create"
                            onClick={() => {
                                setOpenScheduleFormDialog(true);
                                navigate(`/sp/calendar`, {
                                    state: {
                                        editingId: null,
                                    },
                                });
                            }}
                        >
                            <img width={'16px'} height={'16px'} src={plus} />
                            <span>新規作成</span>
                        </button>
                    </div>

                    <TabBox activeTab={activeTab} setActiveTab={setActiveTab} />

                    {activeTab !== 0 && (
                        <FilterBox
                            workerOptions={workerOptions || []}
                            positionOptions={positionOptions || []}
                            departmentOptions={departmentOptions || []}
                            setSearchParam={setSearchParam}
                        />
                    )}

                    <SchedulerBox
                        activeTab={activeTab}
                        searchParam={searchParam}
                        workerOptions={workerOptions || []}
                        setOpenScheduleFormDialog={setOpenScheduleFormDialog}
                        setOpenDeleteConfirmationDialog={
                            setOpenDeleteConfirmationDialog
                        }
                        setOpenPlanUpdateModal={setOpenPlanUpdateModal}
                        openDeleteConfirmationDialog={
                            openDeleteConfirmationDialog
                        }
                        openScheduleFormDialog={openScheduleFormDialog}
                        openPlanUpdateModal={openPlanUpdateModal}
                    />
                </div>
            </SchedulesWrapper>
        </>
    );
};

const Schedules: FC<ReceivedProps> = (props) => (
    <SchedulesLayout {...useSchedules(props)} />
);

export default Schedules;
