import Api from './api/importDataApi';

const importFile = async (body) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .post(
                    ``,
                    body,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                            'Content-Type': 'multipart/form-data',
                        },
                    },
                )
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log(err);
            return Promise.reject();
        }
    } else {
        window.location.href = '/login';
    }
};

const checkImportFile = async (uuid) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .get(
                    `${uuid}`,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                        },
                    },
                )
                .then((response) => {
                    if (response.data) {
                        return response.data;
                    }
                });
        } catch (err) {
            console.log(err);
            return Promise.reject();
        }
    } else {
        window.location.href = '/login';
    }
};

const importData = {
    importFile,
    checkImportFile
};

export default importData;