import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setMessage } from '../message/message';

import UserService from '../../services/userService';

export const getUser = createAsyncThunk('users/profiles', async () => {
    const response = await UserService.getMe();
    return response;
});

const initialState = {
    showModalCreate: false,
    showModalDetail: false,
    showModalUpdate: false,
    showModalDelete: false,
    selectedReport: null,
    deleteSuccess: false,
    addSuccess: false,
    editSuccess: false,
    tempFilterReset: false,
    filter: {},
    reports: [],
    fetching: false,
    fetchingLog: false,
    workers: [],
    workingLogs: [],
    logs: [],
};

const workingReportSlice = createSlice({
    name: 'workingReport',
    initialState,
    reducers: {
        setShowModalCreate: (state, action) => {
            state.showModalCreate = action.payload;
        },
        setShowModalDetail: (state, action) => {
            state.showModalDetail = action.payload;
        },
        setShowModalUpdate: (state, action) => {
            state.showModalUpdate = action.payload;
        },
        setShowModalDelete: (state, action) => {
            state.showModalDelete = action.payload;
        },
        setSelectedReport: (state, action) => {
            state.selectedReport = action.payload;
        },
        setAddSuccess: (state, action) => {
            state.addSuccess = action.payload;
        },
        setEditSuccess: (state, action) => {
            state.editSuccess = action.payload;
        },
        setDeleteSuccess: (state, action) => {
            state.deleteSuccess = action.payload;
        },
        setTempFilterReset: (state, action) => {
          state.tempFilterReset = action.payload;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setReports: (state, action) => {
            state.reports = action.payload;
        },
        setFetching: (state, action) => {
            state.fetching = action.payload;
        },
        setFetchingLog: (state, action) => {
            state.fetchingLog = action.payload;
        },
        setWorkers: (state, action) => {
            state.workers = action.payload;
        },
        setLogs: (state, action) => {
            state.logs = action.payload;
        },
        setWorkingLogs: (state, action) => {
            state.workingLogs = action.payload;
        },
    },
});

export const {
    setShowModalCreate,
    setShowModalDetail,
    setShowModalUpdate,
    setShowModalDelete,
    setSelectedReport,
    setAddSuccess,
    setEditSuccess,
    setDeleteSuccess,
    setTempFilterReset,
    setFilter,
    setReports,
    setFetching,
    setWorkers,
    setLogs,
    setFetchingLog,
    setWorkingLogs,
} = workingReportSlice.actions;

export default workingReportSlice.reducer;
