import React from 'react';
import { useSelector } from 'react-redux';

import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Autocomplete } from '@mui/material';
// import { ALL } from 'utils/constants';

import FilterBoxStyle from './FilterBoxStyle';

const FilterBox = ({
    formik,
    positionOptions,
    workerOptions,
    departmentOptions,
    isOpenDepartment,
    setIsOpenDepartment,
    isOpenPosition,
    setIsOpenPosition,
    isOpenWorker,
    setIsOpenWorker,
}) => {
    // const {
    //   positionList = {
    //     data: [],
    //   },
    //   officesList = {
    //     data: [],
    //   },
    // } = useSelector(state => state.global || {});
    return (
        <FilterBoxStyle>
            <div className="filterBoxTop">
                <div className="wrapSelectField">
                    <span className="fieldLabel">部署</span>
                    <Autocomplete
                        multiple
                        onChange={(event, newValue) => {
                            formik.setFieldValue('department_id', newValue);
                        }}
                        className="max-w-[400px]"
                        noOptionsText="該当なし"
                        options={departmentOptions}
                        getOptionLabel={(option: any) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        open={isOpenDepartment}
                        onOpen={() => setIsOpenDepartment(true)}
                        onClose={() => setIsOpenDepartment(false)}
                        onFocus={() => setIsOpenDepartment(true)}
                    />
                </div>
                <div className="wrapSelectField !mt-2">
                    <span className="fieldLabel">役職</span>
                    <Autocomplete
                        onChange={(event, newValue) => {
                            formik.setFieldValue('position_id', newValue);
                        }}
                        multiple
                        className="max-w-[400px]"
                        noOptionsText="該当なし"
                        options={positionOptions}
                        getOptionLabel={(option: any) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        open={isOpenPosition}
                        onOpen={() => setIsOpenPosition(true)}
                        onClose={() => setIsOpenPosition(false)}
                        onFocus={() => setIsOpenPosition(true)}
                    />
                </div>
                <div className="wrapSelectField !mt-2 !mb-2">
                    <span className="fieldLabel">社員</span>
                    <Autocomplete
                        value={formik.values.worker_id}
                        onChange={(event, newValue) => {
                            formik.setFieldValue('worker_id', newValue);
                        }}
                        className="max-w-[400px]"
                        noOptionsText="該当なし"
                        options={workerOptions}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} />}
                        open={isOpenWorker}
                        onOpen={() => setIsOpenWorker(true)}
                        onClose={() => setIsOpenWorker(false)}
                        onFocus={() => setIsOpenWorker(true)}
                    />
                </div>
                <div className="wrapTextField">
                    <span className="fieldLabel">キーワード</span>
                    <TextField
                        fullWidth
                        hiddenLabel
                        name="free_word"
                        type="text"
                        variant="outlined"
                        margin="dense"
                        onChange={formik.handleChange}
                        value={formik.values.free_word}
                    />
                </div>

                <div className="btnGroup mt-2">
                    <button
                        type="submit"
                        onClick={formik.handleSubmit}
                        className="customBtn"
                        style={{ minWidth: 100 }}
                    >
                        検索
                    </button>
                </div>
            </div>
        </FilterBoxStyle>
    );
};

export default FilterBox;
