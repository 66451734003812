import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import DepartmentService from "../../services/departmentsService";

export const getListDepartments = createAsyncThunk(
  "departments",
  async () => {
    const response = await DepartmentService.getListDepartments();
    return response;
  }
);

const initialState = {
    departmentList: []
}

const departmentSlice = createSlice({
    name: "Departments",
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(getListDepartments.fulfilled, (state, action) => {
            state.departmentList = action.payload.departments
        })

        .addCase(getListDepartments.rejected,(state, action) => {
            state.departmentList = [];
        })
    },
    reducers: {},
});

const { reducer } = departmentSlice;
export default reducer;
