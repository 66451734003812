import Api from './api/manifestApi';
import pick from 'lodash/pick';

const createManifest = async (input) => {
  // console.log('create payload', input);
  return await Api()
  .post('', input, {})
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      });
    }
    
const updateManifest = async (id, input) => {
  // console.log('update payload', input);
  return await Api()
      .put(`${id}`, input, {})
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
}

const deleteManifest = async (id, project_id) => {
  return await Api()
      .delete(`${id}?project_id=${project_id}`)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
}

const getManifestList = async (input: any = {}) => {
  let data = pick(input, ['page', 'per_page', 'project_id']);
  const params = new URLSearchParams(data);

  try {
    return await Api()
        .get(`?${params.toString()}`)
        .then((response) => {
          if (response.data) {
            return response.data;
          }
        })
  } catch (error) {
    // empty
  }
}

const getManifestDetail = async (id: number, project_id: any) => {
  return await Api()
      .get(`${id}?project_id=${project_id}`)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
}

const createManifestLog = async (id: number, projectId: any, input: any) => {
  return await Api()
      .post(`${id}/manifest_logs?project_id=${projectId}`, input, {})
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
}

const getManifestLogs = async (id: number, projectId: any) => {
  return await Api()
      .get(`${id}/manifest_logs?project_id=${projectId}`)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
}

const manifestService = {
  createManifest,
  updateManifest,
  deleteManifest,
  getManifestDetail,
  getManifestList,
  createManifestLog,
  getManifestLogs
};

export default manifestService



