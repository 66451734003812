export const convertManifestLogsArrayToObject = (array) => {
  const manifestLogsObject = {};

  array.forEach((item) => {
    if (item && item.log_type) {
      manifestLogsObject[item.log_type] = {...item}
    }
  });

  return manifestLogsObject;
}

export const checkManifestLogProgress = (manifestLogObject) => {
  let step = 1;
  let has1A = false,
    has1B1 = false,
    has1B2 = false,
    has1C1 = false,
    has1C2 = false,
    has1D = false,
    has1E = false,
    has2A = false,
    has2B1 = false,
    has2B2 = false,
    has2C1 = false,
    has2C2 = false,
    has2D = false,
    has2E = false;

  const documentList = Object.keys(manifestLogObject);
  documentList.forEach((item) => {
    if (item === '1-A') {
      has1A = true;
    }
    if (item === '1-B1') {
      has1B1 = true;
    }
    if (item === '1-B2') {
      has1B2 = true;
    }
    if (item === '1-C1') {
      has1C1 = true;
    }
    if (item === '1-C2') {
      has1C2 = true;
    }
    if (item === '1-D') {
      has1D = true;
    }
    if (item === '1-E') {
      has1E = true;
    }
    if (item === '2-A') {
      has2A = true;
    }
    if (item === '2-B1') {
      has2B1 = true;
    }
    if (item === '2-B2') {
      has2B2 = true;
    }
    if (item === '2-C1') {
      has2C1 = true;
    }
    if (item === '2-C2') {
      has2C2 = true;
    }
    if (item === '2-D') {
      has2D = true;
    }
    if (item === '2-E') {
      has2E = true;
    }
  });

  if (
    has2C1 || (has2C2 && has2D && has2E && has1E)
  ) {
    step = 6;
    return step;
  }

  if (has2B1 || has2B2) {
    step = 5;
    return step;
  }

  if (
    (has1D && has1C2) || (has1C1 && has2A)
  ) {
    step = 4;
    return step;
  }

  if (has1B1 || has1B2) {
    step = 3
    return step;
  }

  if (has1A) {
    step = 2;
    return step;
  }

  return step;
}