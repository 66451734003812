// Lib
import { required } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createClientCompanyValidation = (t: TypeOfT) => ({
    name: () => ({
        required: required('company_name', t),
    }),
    parent_company_id: () => ({
        required: required('parent_company', t),
    }),
    email: () => ({
        required: required('email', t),
    }),
    fax: () => ({
        required: required('fax', t),
    }),
    zipcode: () => ({
        required: required('zipcode', t),
    }),
    address: () => ({
        required: required('address', t),
    }),
    date_of_establishment: () => ({
        required: required('date_of_establishment', t),
    }),
    capital: () => ({
        required: required('capital', t),
    }),
    number_of_employees: () => ({
        required: required('number_of_employees', t),
    }),
    phone: () => ({
        required: required('phone', t),
    }),
    business_content: () => ({
        required: required('business_content', t),
    }),
    // note: () => ({
    //     required: required('note', t),
    // }),
});

export default createClientCompanyValidation;
