import { FC } from 'react';
import { StylesJob } from './styled';
import { Button, Box, Grid } from '@mui/material';
import useJob, { ReceivedProps, Props } from './hook';
import { Add } from '@mui/icons-material';
import TrashIcon from 'assets/icons/delete_ic.svg';
import ViewIcon from 'assets/icons/view_ic.svg';

const JobLayout: FC<Props> = ({
    data,
    navigate,
    setDeleteData,
    handleDelete,
    ModalDelete,
    openDelete,
    closeDelete
}) => {

    return (
        <StylesJob>
            <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column !w-[80vw]"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={closeDelete}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            onClick={handleDelete}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
            <p className="job--title">契約外作業確認書</p>
            <Button
                onClick={() => navigate('create')}
                startIcon={<Add />}
                sx={{
                    color: '#215493',
                    fontWeight: '500',
                    lineHeight: '20px',
                }}
                variant="outlined"
            >
                新規作成
            </Button>
            <Box sx={{ marginTop: '26px' }}>
                <Box
                    sx={{
                        border: '1px solid #CDD1D5 ',
                        '.MuiGrid-container': {
                            borderBottom: '1px solid #F5F5F5',
                        },
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#C7DCF5',
                            padding: '14px 17px',
                            fontWeight: 'bold',
                        }}
                    >
                        追加作業タイトル
                    </Box>
                    {data?.map((job) => (
                        <Grid
                            key={job.id}
                            sx={{ padding: '14px 17px' }}
                            container
                        >
                            <Grid xs={9} item>
                                {job?.name}
                            </Grid>
                            <Grid
                                display="flex"
                                justifyContent="end"
                                alignItems="center"
                                xs={3}
                                item
                            >
                                <Box
                                    onClick={() =>
                                        navigate(
                                            `/sp/jobs/detail/${job.id}?project_id=${job?.project?.id}`,
                                        )
                                    }
                                    sx={{
                                        backgroundColor: '#215493',
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={ViewIcon} />
                                </Box>
                                <Box
                                    onClick={() => {
                                        setDeleteData(job);
                                        openDelete();
                                    }}
                                    sx={{
                                        backgroundColor: '#FF5045',
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        ml: '6px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={TrashIcon} />
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
                <Button
                    onClick={() => navigate(`/`)}
                    sx={{
                        color: '#215493',
                        mt: '42px',
                        width: '166px',
                    }}
                    variant="outlined"
                >
                    戻る
                </Button>
            </Box>
        </StylesJob>
    );
};

const Job: FC<ReceivedProps> = (props) => {
    return <JobLayout {...useJob(props)} />;
};

export default Job;
