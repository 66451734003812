import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import _ from 'lodash';

import userService from 'services/userService';
import projectsService from 'services/projectsService';
import workersService from 'services/wokersService';
import billService from 'services/billService';
import estimateItemService from 'services/estimateItemService';

import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import { convertObjectToDropdownOptions } from 'utils/helper/dropdownHelper';
import { sizeBoolean } from '@fluentui/react';

export function useLogicBill() {
    const { id = null } = useParams();

    const [status, setStatus] = useState<any>([]);
    const [info, setInfo] = useState<any>({});
    const [project, setProject] = useState<any>({});
    const [workers, setWorker] = useState<any>([]);
    const [loading, setLoading] = useState<any>(false);
    const [estimate, setEstimate] = useState<any>({});
    const [bills, setBills] = useState<any>({});
    const [detailEstimateItem, setDetailEstimateItem] = useState<any>({});

    useEffect(() => {
        getListConfiguration();
    }, []);

    const getListConfiguration = async () => {
        try {
            const response = await userService.getListConfiguration();
            if (response !== null && response !== undefined) {
                setStatus(
                    convertUserConfigToDropdownOptions(
                        response.configurations?.estimate?.statuses,
                    ),
                );
            }
        } catch (err) {
            console.log('err', err);
        }
    };
    const getProjectDetail = async (projectId: number) => {
        try {
            const response = await projectsService.getProject(projectId);
            if (response !== null && response !== undefined) {
                setProject(response.project);
            }
        } catch (err) {
            console.log('err', err);
        }
    };
    const getListWorkers = async () => {
        try {
            const response = await workersService.getListWorkers();
            if (response !== null && response !== undefined) {
                setWorker(convertObjectToDropdownOptions(response.workers));
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getDetailEstimate = async (estimateId: number) => {
        await billService
            .getDetailBill(estimateId, Number(id))
            .then((response) => {
                setEstimate(response.estimate);
            })
            .catch((error) => {});
    };

    const getDetailEstimateItem = async (input?: any) => {
        await estimateItemService
            .getDetailEstimateItem(input)
            .then((response) => {
                setDetailEstimateItem(_.get(response, 'estimate_item', {}));
            })
            .catch((error) => {
                setDetailEstimateItem({});
            });
    };

    const getProfileUser = async () => {
        await userService
            .getMe()
            .then((response) => {
                setInfo(_.get(response, 'user', {}));
            })
            .catch((error) => {
                setInfo([]);
                console.error(error);
            });
    };

    return {
        id,
        info,
        status,
        project,
        workers,
        loading,
        estimate,
        detailEstimateItem,

        setLoading,
        getProfileUser,
        getListWorkers,
        getProjectDetail,
        getDetailEstimate,
        getDetailEstimateItem,
    };
}
