import { FC } from 'react';
import { StylesTargetList } from './styled';
import useTargetlist, { ReceivedProps, Props } from './hook';
import TargetStatus from '../Status';

const TargetListLayout: FC<Props> = ({ data, handleChange }) => {

    return (
        <StylesTargetList>
            <div className="targetList mt-4">
                <table>
                    <thead>
                        <tr>
                            <td colSpan={2}>車両点検簿</td>
                        </tr>
                    </thead>
                    <tbody>
                    {data?.map((target) => (
                        <tr className='w-full'>
                            <td className='title-name max-w-[62vw] truncate ...'>
                                {target.title}
                            </td>
                            <td>
                                <TargetStatus status={target.status} id={target.id} handleChange={handleChange} />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </StylesTargetList>
    );
};
const TargetList: FC<ReceivedProps> = (props) => {
    return <TargetListLayout {...useTargetlist(props)} />;
};
export default TargetList;
