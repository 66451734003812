import styled from "styled-components";

const StyledDialogWrapper = styled.div`
  width: 900px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 20px 24px 24px 24px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .construction-recycling-law,
  .work-implement-notification,
  .engineering-table,
  .application,
  .application-removal-lifeline,
  .start-of-construction {
    margin-top: 16px;

    .step-section-title {
      font-weight: 700;
      margin-bottom: 8px;
    }
  }

  .construction-recycling-law,
  .work-implement-notification,
  .engineering-table,
  .start-of-construction {
    & > .dialog-field-row {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 0.5rem;
        height: 42px;

        .field-label {
          height: 100%;
          font-family: 'Zen Kaku Gothic Antique';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #1c1e21;
          width: 120px;
          background-color: #c7dcf5;
          border-bottom: 1px solid #f5f5f5;
          margin-right: 0.5rem
        }

        .value {
          height: 100%;
          // width: 100%;
          display: flex;
          align-items: center;
          padding-left: 5px;
          // border: 1px solid red;
        }

        .datepicker-wrapper {
          position: relative;

          input {
            justify-content: flex-start;
            align-items: flex-start;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 12px;
            gap: 8px;
            width: 250px;
            height: 38px;
            background: #FFFFFF;
            border: 1px solid #CDD1D5;
            border-radius: 8px;
          }

          .calendar-datepicker-icon {
            position: absolute;
            right: 15px;
            top: 10px;
          }
        }

        .autocomplete-wrapper {
          .autocomplete-select-style {
            width: 250px;
            height: 100%;
            padding: 0px;
    
            .MuiOutlinedInput-root {
              height: 100%;
              padding: 0px;
              padding-left: 10px;
    
              fieldset {
                border-radius: 8px;
              }
            }
          }
        }

        .textfield-style {
          width: 250px;
          height: 100%;
          input {
            height: 38px;
            padding-top: 0px;
            padding-bottom: 0px;
            padding-right: 0px;
            width: 210px;
            border-radius: 8px;
          }
        }
    }
  }

  .application,
  .application-removal-lifeline {
    .value {
      height: 100%;
      // width: 100%;
      display: flex;
      align-items: center;
      padding-left: 5px;
      // border: 1px solid red;
    }

    .autocomplete-wrapper {
      .autocomplete-select-style {
        width: 100%;
        height: 100%;
        padding: 0px;

        .MuiOutlinedInput-root {
          height: 100%;
          padding: 0px;
          padding-left: 10px;

          fieldset {
            border-radius: 8px;
          }
        }
      }
    }

    .datepicker-wrapper {
      position: relative;

      input {
        justify-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 12px;
        gap: 8px;
        width: 100%;
        height: 38px;
        background: #FFFFFF;
        border: 1px solid #CDD1D5;
        border-radius: 8px;
      }

      .calendar-datepicker-icon {
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }

    .textfield-style {
      width: 100%;
      height: 100%;
      input {
        height: 40px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        width: 210px;
        border-radius: 8px;
      }
    }
  }

  // .dialog-field-row {
  //   display: flex;
  //   width: 100%;
  //   margin-top: 0.5rem;

  //   .field-label {
  //     justify-content: center;
  //     align-items: center;
  //     height: 40px;
  //     font-family: 'Zen Kaku Gothic Antique';
  //     font-style: normal;
  //     font-weight: 500;
  //     font-size: 14px;
  //     line-height: 20px;
  //     display: flex;
  //     align-items: center;
  //     color: #1c1e21;
  //     min-width: 30%;
  //     background-color: #c7dcf5;
  //     border-bottom: 1px solid #f5f5f5;
  //     margin-right: 0.5rem;
  //   }

  //   .textfield-style {
  //     width: 100%;
  //     height: 100%;
  //     input {
  //       height: 36px;
  //       padding-top: 0px;
  //       padding-bottom: 0px;
  //       padding-right: 0px;
  //       width: 210px;
  //       border-radius: 8px;
  //     }
  //   }
  // }

  .action-section {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 16px;
    .submit-step-edit-button {
      background-color: #215493;
      color: white;
      padding: 8px 24px;
      border-radius: 4px;
    }
  }
`;

export default StyledDialogWrapper;
