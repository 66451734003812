export const validate = (values: any) => {
  let errors = {};

  if (!values.name) {
    errors = { ...errors, name: "は必須です。" };
  }
  // if (!values.summary) {
  //   errors = { ...errors, summary: "は必須です。" };
  // }
  // if (!values.is_parent) {
  if (!values.amount) {
    errors = { ...errors, amount: "は必須です。" };
  }
  // if (!values.unit) {
  //   errors = { ...errors, unit: "は必須です。" };
  // }
  if (!values.unit_price) {
    errors = { ...errors, unit_price: "は必須です。" };
  }
  // if (values.price) {
  //   errors = { ...errors, price: "は必須です。" };
  // }
  if (!values.is_parent) {
    if (!values.parent_estimate_item_id) {
      errors = { ...errors, parent_estimate_item_id: "は必須です。" };
    }
  }
  // }

  // if (!values.note) {
  //   errors = { ...errors, note: "は必須です。" };
  // }
  return errors;
};
