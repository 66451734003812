import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import moment from 'moment';

export type ReceivedProps = {
    currentDate: any;
    data: any[];
    setOpenScheduleFormDialog: Dispatch<SetStateAction<boolean>>;
};

const useDayList = (props: ReceivedProps) => {
    const [localList, setLocalList] = useState<any[]>([]);

    useEffect(() => {
        const today = new Date(props.currentDate);
        today.setHours(0, 0, 0, 0);

        const sameOrAfterTodayList = [...props.data].filter((item) =>
            moment(item.startDate).isSameOrAfter(moment(today)),
        );

        let maxDateOfSameOrAfterTodayList = moment(today);

        sameOrAfterTodayList.forEach((item) => {
            if (moment(item.startDate) > maxDateOfSameOrAfterTodayList) {
                maxDateOfSameOrAfterTodayList = moment(item.startDate);
            }
        });

        const dateTitleList: any[] = [];

        let dateTitleItem = moment(today)
            .subtract(1, 'days')
            .format('YYYY-MM-DD');

        for (
            let i = 0;
            dateTitleItem <
            moment(maxDateOfSameOrAfterTodayList).format('YYYY-MM-DD');
            i += 1
        ) {
            dateTitleList.push(
                moment(dateTitleItem, 'YYYY-MM-DD')
                    .add(1, 'days')
                    .format('YYYY-MM-DD'),
            );
            dateTitleItem = moment(dateTitleItem, 'YYYY-MM-DD')
                .add(1, 'days')
                .format('YYYY-MM-DD');
        }

        const newData: any[] = [];

        dateTitleList.forEach((item) => {
            const getItemListByStartDate = sameOrAfterTodayList.filter(
                (todayItem) =>
                    moment(todayItem.startDate).format('YYYY-MM-DD') === item,
            );

            if (getItemListByStartDate.length > 0) {
                getItemListByStartDate.forEach((itemList, itemListIndex) => {
                    if (itemListIndex === 0) {
                        newData.push({
                            ...itemList,
                            customTitle: item,
                        });
                    } else {
                        newData.push({
                            ...itemList,
                        });
                    }
                });
            } else {
                newData.push({
                    customTitle: item,
                });
            }
        });

        setLocalList(newData);
    }, [props.data, props.currentDate]);

    return {
        ...props,
        localList,
        setLocalList,
    };
};

export type Props = ReturnType<typeof useDayList>;

export default useDayList;
