import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment'
import _ from 'lodash'

import TextField from '@mui/material/TextField/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import closeIc from 'assets/icons/closeIc.svg';

import { useLogicComment } from './hooks'
import { Main, Avatar } from './styles'
import { useSearchParams } from 'react-router-dom';

const ContentComment = ({
  date,
  status,
  content,
  workerName,
  contentReply,
  onReplyContent,
  onUpdateComment,
}) => <React.Fragment>
    <div className='answer-quetsion'>
      <div className='content'>
        {
          contentReply && <React.Fragment>
            <div className='d-flex justify-between mb-2'>
              <span className='worker-name'>{contentReply?.worker.name}</span>
              <span className='time d-flex'>{moment(contentReply.created_at).format("YYYY/MM/DD HH:mm")}</span>
            </div>
            <div className='content-reply'>
              {contentReply.body}
            </div>
          </React.Fragment>

        }

        <React.Fragment>
          {/* time and worker name */}
          <div className={`d-flex justify-between mb-1 ${contentReply && 'pl-30'}`}>
            <span className='worker-name'>{workerName}</span>
            <span className='time d-flex'>{moment(date).format("YYYY/MM/DD HH:mm")}</span>
          </div>

          {/* content comment */}
          <p className={contentReply && 'pl-30'}>{content}</p>
          
          {/* button reply and status */}
          <div className={`group-bnt d-flex mt-2 ${contentReply && 'pl-30'}`}>
            {
              status && (
                <Checkbox
                  checked
                  color="success"
                  style={{ padding: 0 }}
                />
              )
            }

            <button
              className={`bnt ${status ? 'bg_dba12eba' : 'bg_1C8E43'}`}
              onClick={onUpdateComment}
            >
              {status ? '未解決' : '解決'}
            </button>
            <button
              className='bnt bg_215493'
              onClick={onReplyContent}
            >返信</button>
          </div>
        </React.Fragment>
      </div>
      {/* <span className='time d-flex mt-1'>{moment(date).format("YYYY/MM/DD.hh:mm")}</span> */}
    </div>
  </React.Fragment>

const CommentBox = ({
  infoUser,
  estimateId,
  estimateItemId,
  showDataEstimateItem
}) => {
  const {
    comment = [],

    getListComment,
    createComment,
    updateComment
  } = useLogicComment();
  const [content, setContent] = useState<any>('');
  const [replyContent, setReplyContent] = useState<any>({});
  const commentRef = useRef<any>(null)
  const inputRef = useRef<any>(null)
  const [searchParams] = useSearchParams()

  useEffect(() => {
    getListComment({
      estimate_id: estimateId,
      estimate_item_id: showDataEstimateItem ? estimateItemId : null
    })
  }, [showDataEstimateItem])
  useEffect(() => {
    commentRef.current.scrollTo(0, _.size(comment) * 150)
  }, [comment])


  const onCommentSubmit = async () => {
    await createComment({
      body: content,
      estimate_id: estimateId,
      estimate_item_id: showDataEstimateItem ? estimateItemId : null,
      worker_id: _.get(infoUser, "worker.id", null),
      reply_comment_id: replyContent?.id
    })

    // reset data reply and create
    setContent('');
    setReplyContent({})
    getListComment({
      estimate_id: estimateId,
      estimate_item_id: showDataEstimateItem ? estimateItemId : null
    })
  }
  const onUpdateComment = async (value) => {
    await updateComment({
      ...value,
      estimate_id: estimateId,
      estimate_item_id: showDataEstimateItem ? estimateItemId : null,
      worker_id: _.get(infoUser, "worker.id", null),
      status: value.status === 'not_solved' ? 1 : 0
    })

    // call api after update
    getListComment({
      estimate_id: estimateId,
      estimate_item_id: showDataEstimateItem ? estimateItemId : null
    })
  }
  const onReplyContent = (comment) => {
    setReplyContent(comment);
    inputRef.current.focus()
  }

  return (
    <Main>
      <div className='container-comment'>
        {/* <div className="title">
          見積編集
        </div> */}
        <div className='group-content' ref={commentRef}>
          {/* <div className='info-user-comment d-flex mb-3'>
                        <Avatar src={avatar} alt="avatar" />
                        <span className='ml-3'>山田太郎</span>
                    </div> */}
          {
            comment.map((comment, index) => (
              <ContentComment
                key={index}
                content={comment?.body}
                workerName={comment?.worker?.name}
                date={comment?.created_at}
                status={comment.status !== 'not_solved'}
                contentReply={comment?.contentReply}
                onReplyContent={() => onReplyContent(comment)}
                onUpdateComment={() => onUpdateComment(comment)}
              />
            ))
          }

        </div>
        <div className="answer-box ">
          {
            replyContent?.id ? <div className='d-flex content-reply'>
              <span>{replyContent.body}</span>
              <img
                src={closeIc}
                alt='close-reply'
                className='closeReply'
                onClick={() => setReplyContent({})}
              />
            </div> : <div className='not-reply'>コメント</div>
          }

          <TextField
            disabled={searchParams.get('type') === 'detail'}
            inputRef={inputRef}
            className={`input-answer ${replyContent?.id && 'pl-30'}`}
            hiddenLabel
            size="small"
            value={content}
            rows={2}
            onChange={event => setContent(event.target.value)}
            multiline
          />
          <button disabled={searchParams.get('type') === 'detail'} className='bnt bnt bg_215493 mt-3' onClick={onCommentSubmit}>送信</button>
        </div>
      </div>
    </Main>
  )
}



export default CommentBox