import { TypeOfT } from "utils/type/translations/Translation.type";
import { requiredOnly } from "../validator";

const updateWorkingLogValidation = (t: TypeOfT) => ({
  name: () => ({
    required: requiredOnly(t),
  }),
  worked_at: () => ({
    required: requiredOnly(t),
  }),
  plan_id: () => ({
    required: requiredOnly(t),
  }),
  worker: () => ({
    required: requiredOnly(t),
  }),
  note: () => ({
    required: requiredOnly(t),
  }),
});

export default updateWorkingLogValidation;