export type ReceivedProps = {
    data: any;
    handleChange: (id: number, status: string) => void;
};

const useTargetList = (props: ReceivedProps) => {
    return {
        ...props,
    };
};

export type Props = ReturnType<typeof useTargetList>;

export default useTargetList;
