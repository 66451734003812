import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WorkingTimeService from 'services/workingTimesService';
import { RootState } from 'store';
import { ObjectToFormData } from 'utils/helper/helpers';
import { alertError } from 'utils/helper/appHelper';
import { usePosition } from 'use-position';

const useTimeKeeping = (props: any) => {
    const [workingTime, setWorkingTime] = useState<any>();
    const watch = true;

    const { latitude, longitude } =
        usePosition(watch);
    const { userData }: any = useSelector((state: RootState) => state.users);
    const fetchWorkingTime = async () => {
        try {
            const { working_time_management }: any =
                await WorkingTimeService.getWorkingTimeToday({
                    submit_date: moment().zone('+9:00').format('YYYY-MM-DD'),
                    worker_id: userData?.worker?.id,
                });
            setWorkingTime(working_time_management);
        } catch (error) {
            const response = error?.response;
            if (response?.data?.error) {
                alertError(response?.data?.error);
            }
        }
    };

    const handleCheckin = async () => {
        if (!latitude) return;
        if (workingTime?.start_time) return;
        const formData = ObjectToFormData({
            latitude,
            longitude,
            worker_id: userData?.worker?.id,
            submit_date: moment().zone('+9:00').format('YYYY-MM-DD HH:mm:ss'),
        });
        try {
            await WorkingTimeService.checkin(formData);
            fetchWorkingTime();
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const handleCheckout = async () => {
        if (!latitude) return;
        if (workingTime?.end_time || !workingTime.start_time) return;
        const formData = ObjectToFormData({
            latitude,
            longitude,
            worker_id: userData?.worker?.id,
            submit_date: moment().zone('+9:00').format('YYYY-MM-DD HH:mm:ss'),
        });
        try {
            await WorkingTimeService.checkout(formData);
            fetchWorkingTime();
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    useEffect(() => {
        fetchWorkingTime();
    }, []);

    return {
        ...props,
        handleCheckin,
        handleCheckout,
        workingTime,
        loading: !latitude,
    };
};

export type Props = ReturnType<typeof useTimeKeeping>;

export default useTimeKeeping;
