import { useMemo, useState } from 'react';
import useChatBoxPrivateMessage, { Props } from './hook';
import { Box, Dialog } from '@mui/material';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { BiImageAdd, BiSend } from 'react-icons/bi';
import { TiCancel } from 'react-icons/ti';
import { Formik, Form, Field } from 'formik';
import CircularProgress from '@mui/material/CircularProgress';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
} from '@chatscope/chat-ui-kit-react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import moment from 'moment';

const ChatBoxPrivateMessageLayout = ({
    listMessage,
    loading,
    userData,
    activeMessage,
    handleFileChange,
    // handleReceived,
    removeImage,
    isLoading,
    setImagePreview,
    setOpen,
    imagePreview,
    open,
    downloadImage,
    onSubmit,
    fetchHasUnreadChat,
    isInHistories,
    setIsInHistories,
    fetchListMessage,
    typePreview,
    setTypeReivew,
    listErrorFile
}: Props) => {
    type TVideo = {
        signed_id: string;
        video_path: string;
    }
    type TImage = {
        signed_id: string;
        image_path: string;
    }
    
    interface ISender {
        id: number;
        name: string;
    }
    interface IMessage {
        body: string | undefined;
        id: number;
        images: TImage[] | null;
        is_owner: boolean | null;
        is_read: boolean | null;
        is_system: boolean | null;
        publish_at: string | undefined;
        sender: ISender;
        videos: TVideo[] | File[] | null;
    }
    const [limit, setLimit] = useState(0);
    const [loadingMore, setLoadingMore] = useState(false);
    const onYReachStart = () => {
        if (loadingMore === true) {
            return;
        }
        setLoadingMore(true);
        setTimeout(() => {
            setLimit(limit+5);
            setLoadingMore(false);
        }, 1500);
    };
    const listMessageItem = useMemo(() => {
        return listMessage?.slice(-limit)?.map((message: IMessage) => (
            <div key={message.id}>
                {!message.is_system ? (
                    <>
                        <Message
                            key={message?.id}
                            model={{
                                message: message.body,
                                sender: message?.sender?.name,
                                sentTime: message?.publish_at,
                                direction:
                                    message?.sender?.id === userData?.worker?.id
                                        ? 'outgoing'
                                        : 'incoming',
                                position: 'first',
                                type: 'custom',
                            }}
                        >
                            <Message.CustomContent>
                                <div className="message">
                                    <p className="mb-0">
                                        {message?.body || ''}
                                    </p>

                                    {message?.images &&
                                        Object.values(message?.images).map(
                                            (img: any, id) => (
                                                <img
                                                    src={img?.image_path || URL.createObjectURL(
                                                        img,
                                                    )}
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '324px',
                                                        cursor: 'pointer',
                                                    }}
                                                    key={id}
                                                    onClick={() => {
                                                        setOpen(true);
                                                        setImagePreview(
                                                            img?.image_path || URL.createObjectURL(img),
                                                        );
                                                        setTypeReivew('image');
                                                    }}
                                                />
                                            ),
                                        )}
                                    {message?.videos &&
                                        Object.values(message?.videos).map(
                                            (video, id) => (
                                                <video
                                                    controls
                                                    style={{
                                                        marginTop: '5px',
                                                        width: '324px',
                                                        cursor: 'pointer',
                                                    }}
                                                    key={id}
                                                    onClick={() => {
                                                        setOpen(true);
                                                        setImagePreview(
                                                            video?.video_path || URL.createObjectURL(video),
                                                        );
                                                        setTypeReivew('video');
                                                    }}
                                                >
                                                    <source
                                                        src={video?.video_path || URL.createObjectURL(video)}
                                                        type="video/mp4"
                                                    />
                                                </video>
                                            ),
                                        )}
                                </div>
                            </Message.CustomContent>

                        </Message>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent:
                                    message?.sender?.id === userData?.worker?.id
                                        ? 'end'
                                        : 'start',
                            }}
                            className="message-footer"
                        >
                            <p className="text-xs text-[#666666] mr-1">
                                {message?.sender?.name || ''}
                            </p>
                            <p className="text-xs text-[#666666]">
                                {message.publish_at &&
                                    moment(message.publish_at)
                                        .zone('+09:00')
                                        .format('YYYY/MM/DD HH:mm:ss')}
                            </p>
                        </div>
                    </>
                ) : (
                    <div className="flex justify-center mb-2">
                        <div className="message bg-[#f4f4f4] py-1 px-2 text-[#666666] rounded-md flex text-xs justify-center flex-col items-center">
                            <p className="mb-0">
                                {message.publish_at &&
                                    moment(message.publish_at)
                                        .zone('+09:00')
                                        .format('YYYY/MM/DD HH:mm:ss')}
                            </p>
                            <p className="mb-0">{message?.body || ''}</p>
                        </div>
                    </div>
                )}
            </div>
        ));
    }, [listMessage, limit]);
    return (
        <Box boxShadow="rgb(181 181 181) 0px 2px 10px 1px" borderRadius="8px">
            <Dialog
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box>
                    <div className="flex justify-end px-2 py-1">
                        <FileDownloadOutlinedIcon
                            className="cursor-pointer"
                            onClick={() => downloadImage()}
                        />
                    </div>
                    {
                        typePreview === "image" ? <img
                            src={imagePreview}
                            className="w-[600px] cursor-pointer"
                            alt="preview"
                        /> : (
                            <video
                                className="w-[600px] cursor-pointer"
                                controls
                            >
                                <source
                                    src={imagePreview}
                                    type="video/mp4"
                                    className="image-ads-edit"
                                />
                            </video>
                        )
                    }

                </Box>
            </Dialog>
            {/* <ActionCableConsumer
                channel={{
                    channel: 'ChatStatusChannel',
                    user_id: userData.id,
                }}
                onReceived={handleReceived}
            > */}
            <Box
                marginTop="15px"
                height="calc(80vh - 178px)"
                sx={{
                    '.cs-main-container': {
                        border: 'none;',
                        '.cs-message-list__scroll-wrapper': {
                            padding: '0px 16px !important',
                        },
                        borderTopRightRadius: '8px',
                        borderTopLeftRadius: '8px',
                    },
                }}
            >
                <MainContainer>
                    <ChatContainer id="chat-container">
                        <MessageList autoScrollToBottomOnMount={true} loadingMore={loadingMore} onYReachStart={onYReachStart}>
                            {loading ? (
                                <div className="flex items-center h-full justify-center">
                                    <CircularProgress />
                                </div>
                            ) : (
                                isInHistories && listMessageItem
                            )}
                        </MessageList>
                    </ChatContainer>
                </MainContainer>
            </Box>
            <Box
                sx={{
                    form: {
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        bgcolor: 'rgb(244,247,249)',
                        position: 'relative',
                        padding: '10px 20px 10px 20px',
                        borderBottomRightRadius: '8px',
                        borderBottomLeftRadius: '8px',
                    },
                    input: {
                        outline: 'none',
                        paddingLeft: '12px',
                    },
                    '.preview-img-upload': {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        position: 'absolute',
                        bottom: '88px',
                        left: '50px',
                        zIndex: '1000',
                        top: '-40px',
                        img: {
                            width: '40px',
                            height: '40px',
                            borderRadius: '6px',
                            marginRight: '5px',
                        },
                        video: {
                            width: '40px',
                            height: '40px',
                            borderRadius: '6px',
                            marginRight: '5px',
                        }
                    },
                    '.img-upl-ct': {
                        position: 'relative',
                    },
                    '.delete-image-upl': {
                        position: 'absolute',
                        top: '-7px',
                        right: 0,
                        color: 'red',
                        cursor: 'pointer',
                    },
                    '.btnSend[disabled]': {
                        opacity: 0.5,
                    },
                    '.btnSend:disabled': {
                        opacity: 0.5,
                    }
                }}
            >
                <Formik
                    initialValues={{
                        message: '',
                        file: [],
                    }}
                    onSubmit={(values, { resetForm }) => {
                        onSubmit(values);
                        resetForm();
                        setIsInHistories(true);
                    }}
                >
                    {({ values, handleSubmit, setFieldValue }) => (
                        <Form>
                            <label
                                className="upload-image-chat cursor-pointer"
                                htmlFor="fileInput"
                            >
                                <input
                                    onChange={(e) =>
                                        handleFileChange(
                                            e,
                                            values,
                                            setFieldValue,
                                        )
                                    }
                                    id="fileInput"
                                    name="file"
                                    type="File"
                                    multiple
                                    // ref={refInput}
                                    accept="image/*, video/*"
                                    style={{ display: 'none' }}
                                    onClick={(e) => (e.target as HTMLInputElement).value = ""}
                                ></input>
                                <BiImageAdd size={20} />
                            </label>
                            <Field
                                onKeyPress={(e) => {
                                    if (e.code === 'Enter') {
                                        e.preventDefault();
                                        if(listErrorFile?.length > 0) return;
                                        handleSubmit();
                                    }
                                }}
                                className="input-custom-chat w-[90%] h-[40px]"
                                type="text"
                                placeholder="メッセージを入力..."
                                autoFocus
                                name="message"
                            />
                            <button
                                disabled={isLoading || listErrorFile?.length > 0}
                                style={{
                                    bottom: '16px',
                                    zIndex: 100000,
                                    right: '16px',
                                }}
                                type="submit"
                                className='btnSend'
                            >
                                <BiSend size={25} />
                            </button>
                            <div className="preview-img-upload">
                                {values?.file?.length > 0 &&
                                    values?.file.map((item: File, id) => {
                                        return (
                                            <div className="img-upl-ct">
                                                {
                                                    item?.type?.includes('image/')
                                                        ?
                                                        <img
                                                            src={URL.createObjectURL(
                                                                item,
                                                            )}
                                                            key={id}
                                                        />
                                                        :
                                                        <video
                                                            className="img-container-edit"
                                                            controls
                                                        >
                                                            <source
                                                                src={URL.createObjectURL(item)}
                                                                type="video/mp4"
                                                                className="image-ads-edit"
                                                            />
                                                        </video>
                                                }
                                                <TiCancel
                                                    onClick={() =>
                                                        removeImage(
                                                            id,
                                                            values,
                                                            setFieldValue,
                                                        )
                                                    }
                                                    size={15}
                                                    className="delete-image-upl"
                                                />
                                            </div>
                                        );
                                    })}
                                {listErrorFile?.length > 0 && <p className='text-[#ff3333] mt-[1em]'>10MB以上がアップロードできません。</p>}
                            </div>
                        </Form>
                    )}
                </Formik>
            </Box>
            {/* </ActionCableConsumer> */}
        </Box>
    );
};

const ChatBoxPrivateMessage = (props: any) => {
    return <ChatBoxPrivateMessageLayout {...useChatBoxPrivateMessage(props)} />;
};

export default ChatBoxPrivateMessage;
