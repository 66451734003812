import { useEffect, useState } from 'react';
import inspectionService from 'services/inspectionRecordService';
import {
    ICompany,
    IInspectionCategory,
    IInspectionRecord,
    IInspectionRecordsMapMachineModel,
    IMachineModel,
    IMachineModelInspectItem,
    IProject,
    ReceivedProps,
} from './type';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { IPurchase } from 'pages/spPurchaseRequest/type';
import ProjectService from 'services/projectServiceSP';
import { IWorker } from 'models';
import workersService from 'services/wokersService';
import { uniqBy } from 'lodash';
import clientCompanyService from 'services/clientCompanyService';

export const validation = yup.object().shape({
    name: yup.string().required('必須項目です'),
});

const useInspectionRecord = (props: ReceivedProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<IPurchase[]>([]);
    const [machineModels, setMachineModels] = useState<IMachineModel[]>([]);
    const [inspectionRecords, setInspectionRecords] = useState<
        IInspectionRecord[] | []
    >([]);
    const [machineModelInspectItems, setMachineModelInspectItems] = useState<
        IMachineModelInspectItem[]
    >([]);
    const [projectsList, setProjectsList] = useState<IProject[]>([]);
    const [listDataRender, setListDataRender] = useState<IInspectionCategory[]>([]);
    const [valueProject, setValueProject] = useState<IProject | null>(null);
    const [valueDate, setValueDate] = useState('');
    const [valueMachineModel, setValueMachineModel] = useState<IMachineModel>();
    const [valueWorker, setValueWorker] = useState<IWorker>();
    const [companyList, setCompanyList] = useState<ICompany[]>([]);
    const [valueCompany, setValueCompany] = useState<ICompany | null>(null);
    const [workerList, setWorkerList] = useState<IWorker[]>([]);
    const [modelImg, setModelImg] = useState('');
    const getWorkers = async () => {
        try {
            const response = await workersService.getListWorkers();
            if (response) {
                setWorkerList(response.workers);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getListProjects = async () => {
        try {
            const res = await ProjectService.getProjectLists({
                page: 1,
                per_page: 99999,
                ...data,
            });
            if (!(res as { projects: IProject[] })?.projects) return;
            const projects = (res as { projects: IProject[] })?.projects;
            setProjectsList(projects);
        } catch (err) {}
    };

    const getListCompanies = async () => {
        try {
            const response = await clientCompanyService.getListCompanies();
            if (response !== null && response !== undefined) {
                setCompanyList(response.client_companies);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const fetchMachineModels = async (params = {}) => {
        try {
            const res = await inspectionService.getMachineModels({
                page: 1,
                per_page: 99999,
                ...params,
            });
            if (!(res as { machine_models: IMachineModel[] })?.machine_models)
                return;
            const machine_models = (res as { machine_models: IMachineModel[] })
                ?.machine_models;
            setMachineModels(machine_models);
        } catch (error) {
            console.log(error);
        }
    };

    const transformData = (
        inspectionRecords: IInspectionRecord[],
        machineModelInspectItems: IMachineModelInspectItem[],
    ) => {
        const listDataRender: IInspectionRecordsMapMachineModel[] = [];
        for (let j = 0; j < machineModelInspectItems.length; j++) {
            const itemFound = listDataRender.some(
                (record) =>
                    record.machine_model_inspect_item_id ===
                    machineModelInspectItems[j].id,
            );
            if (!itemFound) {
                listDataRender.push({
                    inspect_item: {
                        ...machineModelInspectItems[j].inspect_item,
                        machine_model_inspect_item_id:
                            machineModelInspectItems[j].id,
                    },
                });
            }
        }

        const groupedData = listDataRender.reduce((result, item) => {
            const {
                category,
                machine_model_inspect_item_id,
                id: index,
                item_name: title,
            } = item.inspect_item;
            if (!result[category]) {
                result[category] = { category, items: [], rowSpan: 0 };
            }
            result[category].items.push({
                ind: index,
                title,
                machine_model_inspect_item_id,
            });
            result[category].rowSpan++;
            return result;
        }, {});

        const transformedData = Object.values(groupedData).map(
            (category: any, index) => ({
                id: index + 1,
                category: category.category,
                items: uniqBy(category.items, "machine_model_inspect_item_id"),
                rowSpan: category.rowSpan,
            }),
        );

        let indCounter = 1;
        transformedData.forEach((category) => {
            category.items.forEach((item) => {
                item.ind = indCounter;
                indCounter++;
            });
        });

        setListDataRender(transformedData);
        return transformedData;
    };

    const fetchMachineModelInspectItem = async (id: number) => {
        try {
            const res = await inspectionService.getListMachineModelInspectItems(
                {
                    machine_model_id: id,
                },
            );
            if (
                !(
                    res as {
                        machine_model_inspect_item: IMachineModelInspectItem[];
                    }
                )?.machine_model_inspect_item
            )
                return;
            const machine_model_inspect_item = (
                res as {
                    machine_model_inspect_item: IMachineModelInspectItem[];
                }
            )?.machine_model_inspect_item;
            setMachineModelInspectItems(machine_model_inspect_item);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchInspectionRecords = async (params = {}) => {
        const date = new Date(valueDate);
        let data = Object.assign(
            {},
            { page: 1, per_page: 99999 },
            !valueDate
                ? null
                : {
                      inspection_month: date?.getMonth() + 1,
                      inspection_year: date?.getFullYear(),
                  },
            !valueProject ? null : { project_id: valueProject?.id },
            !valueCompany ? null : { company_id: valueCompany?.id },
            !valueMachineModel
                ? null
                : { machine_model_id: valueMachineModel?.id },
            !valueWorker ? null : { inspected_worker_id: valueWorker?.id },
        );
        try {
            const res = await inspectionService.getListInspectionRecords({
                ...data,
                ...params,
            });
            if (
                !(res as { inspection_records: IInspectionRecord[] })
                    ?.inspection_records
            )
                return;
            const inspection_records = (
                res as { inspection_records: IInspectionRecord[] }
            )?.inspection_records;
            setInspectionRecords(inspection_records);
        } catch (error) {
            setInspectionRecords([]);
            console.log(error);
        }
    };

    useEffect(() => {
        fetchMachineModels();
        getListProjects();
        getListCompanies();
        getWorkers();
    }, []);

    useEffect(() => {
        if (valueDate && valueProject && valueMachineModel)
            fetchMachineModelInspectItem(valueMachineModel.id)
            fetchInspectionRecords();
    }, [valueDate, valueProject, valueCompany, valueWorker, valueMachineModel]);

    useEffect(() => {
        if (valueMachineModel?.image_url)
            setModelImg(valueMachineModel.image_url)
    }, [valueMachineModel])

    useEffect(() => {
        const newTransformData = transformData(
            inspectionRecords,
            machineModelInspectItems,
        );
    }, [machineModelInspectItems]);

    return {
        ...props,
        machineModels,
        inspectionRecords,
        machineModelInspectItems,
        setMachineModelInspectItems,
        setInspectionRecords,
        listDataRender,
        setListDataRender,
        projectsList,
        valueProject,
        setValueProject,
        valueCompany,
        setValueCompany,
        valueDate,
        setValueDate,
        valueMachineModel,
        setValueMachineModel,
        // onSubmit,
        fetchMachineModelInspectItem,
        fetchInspectionRecords,
        companyList,
        setCompanyList,
        workerList,
        setWorkerList,
        setValueWorker,
        modelImg,
        setModelImg
    };
};
export type Props = ReturnType<typeof useInspectionRecord>;

export default useInspectionRecord;
