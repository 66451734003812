import { useState, useEffect, FocusEventHandler } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { validateFullWhitespace } from 'utils/validation/validatorHelper';
import querystring from 'query-string'
import invoiceApi from 'services/invoiceApi';
import { loadStripe } from '@stripe/stripe-js';

export type ReceivedProps = Record<never, never>;

const useSPPaymentForm = (props: ReceivedProps) => {
    const params = useParams();
    const { id }: any = params;
    const {
        control,
        handleSubmit,
        watch,
        register,
        setError,
        setValue,
        formState: { errors },
        resetField,
        clearErrors,
    } = useForm();
    const numberOfSelectedImages = watch('images');
    let storage = localStorage.getItem('line:accessToken');
    const [errorMessage, setErrorMessage] = useState(null);
    // const user = storage !== null ? JSON.parse(storage) : null;
    const [isAllowUpload, setAllowUpload] = useState(true);
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);
    const [isLoading, setIsLoading] = useState(false);
    const [clientSecret, setClientSecret] = useState('')
    const location = useLocation();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [isCard, setIsCard] = useState(true);
    const [errorDate, setErrorDate] = useState('')
    const queryObj = querystring.parse(location.search)
    const submitData = async (value: any, evt: any) => {
        evt.preventDefault();

        // if (!stripe || !elements) {
        //     return;
        // }
        // const cardElement = elements.getElement(CardNumberElement);
        // if (cardElement) {
        //     const { paymentIntent, error }: any = await stripe.confirmCardPayment(clientSecret, {
        //         payment_method: {
        //             card: cardElement,
        //             billing_details: {
        //                 name: evt.target.name.value
        //             }
        //         }
        //     });

        //     console.log("paymentIntent", paymentIntent)
        //     console.log("error", error);
        // }
        // alertSuccessPopup2({
        //     title: '決済完了',
        //     confirmButtonText: '閉じる'
        // });
        // setIsLoading(true);

        // navigate(`/sp/quote/history${queryObj.top === 'true' ? '?top=true' : ''}`);
        // handleResetFields();

    };

    const getSecret = async () => {
        try {
            const res = await invoiceApi.createPaymentIntent(storage, id)
            if (res) {
                // console.log('res', res.data.client_secret)
                setClientSecret(res.data.client_secret)
            }
        } catch (error) {
            console.log('error', error)
        }
    }

    useEffect(() => {
        getSecret()
    }, [])

    const handleValidateWhitespace: FocusEventHandler<HTMLInputElement> = (
        e,
    ) => {
        const onlyContainWhitespaces = validateFullWhitespace(e.target.value);
        if (e.target.value.includes('_')) {
            let textTmp = e.target.value
            textTmp = textTmp.replaceAll('_', '')
            setValue(e.target.name, textTmp);
        }
        if (onlyContainWhitespaces) {
            setValue(e.target.name, '');
        }
    };

    const handleResetFields = () => {
        setValue('card_number', '');
        setValue('holder_name', '');
        setValue('date_expire', '');
        setValue('security_code', '');
    };

    const navigateBackToDetail = () => {
        navigate(`/sp/quote/history${queryObj.top === 'true' ? '?top=true' : ''}`);
    };


    return {
        ...props,
        numberOfSelectedImages,
        handleSubmit,
        control,
        register,
        setError,
        errors,
        setValue,
        id,
        isLoading,
        setAllowUpload,
        title,
        clearErrors,
        navigateBackToDetail,
        handleValidateWhitespace,
        isCard,
        setIsCard,
        errorDate,
        clientSecret,
        stripePromise
    };
};

export type Props = ReturnType<typeof useSPPaymentForm>;

export default useSPPaymentForm;
