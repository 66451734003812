import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import { Table } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import plus from '../../../../assets/icons/Plus.png';
import { useModal } from 'react-hooks-use-modal';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import projectsService from 'services/projectsService';
import { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#000000',
        paddingTop: '19.5px',
        paddingBottom: '19px',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 400,
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontSize: '14px',
        lineHeight: '17px',
        fontWeight: 400,
        color: '#000000',
        backgroundColor: '#FFFFFF',
        padding: '6.5px 12px',
    },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ProjectDetailChats = ({ currentProject, chats, setRefresh }) => {
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: true,
        },
    );
    const navigate = useNavigate();
    const [chatId, setChatId] = useState<any>(null);

    const handleDeleteChat = async (closeDelete: () => void) => {
        if (!chatId) return;

        try {
            await projectsService.deleteChat(currentProject.id, chatId);
            setChatId(null);
            setRefresh((state) => !state);
            closeDelete();
        } catch (error) {
            //
        }
    };

    return (
        <div>
            <ModalDelete>
                本当に削除しますか?
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={() => {
                                setChatId(null);
                                closeDelete();
                            }}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            onClick={() => handleDeleteChat(closeDelete)}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'end',
                    mb: '20px',
                    mt: '12px',
                }}
            >
                <button
                    onClick={() =>
                        navigate(
                            `/projects/${currentProject?.id}/chats/create?project_id=${currentProject?.id}`,
                        )
                    }
                    className="border-main border-[1px] text-main px-[20px] py-[4px] rounded-[6px] flex items-center"
                >
                    <img src={plus} alt="plus" />
                    <span>新規作成</span>
                </button>
            </Box>
            <TableContainer
                table-layout={'auto'}
                className="border-[#cccccc] border-[1px] !rounded-b-[5px] !rounded-t-[0px] !shadow-none"
                component={Paper}
            >
                <Table sx={{ minWidth: 700 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                            >
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    チャットタイトル
                                </div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                            >
                                <div style={{ whiteSpace: 'nowrap' }}>
                                    参加者
                                </div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                            >
                                <div>作成日</div>
                            </StyledTableCell>
                            <StyledTableCell
                                className="text-overflow1"
                                align="center"
                            />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chats &&
                            chats.map((row, ind) => {
                                return (
                                    <StyledTableRow key={ind}>
                                        <StyledTableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                        >
                                            <div
                                                className="text-overflow1"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {row?.title || ''}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            component="th"
                                            scope="row"
                                        >
                                            <div
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {row?.who_can_join || ''}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            <div
                                                className="text-overflow1"
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {row?.created_at
                                                    ? moment(
                                                          row?.created_at,
                                                      ).format('YYYY/MM/DD')
                                                    : ''}
                                            </div>
                                        </StyledTableCell>
                                        <StyledTableCell
                                            align="center"
                                            style={{ width: '10%' }}
                                        >
                                            <div className="d-flex">
                                                <button
                                                    className="button-tb"
                                                    style={{
                                                        width: '72px',
                                                        margin: '5px',
                                                    }}
                                                    onClick={() =>
                                                        navigate(
                                                            `/projects/${currentProject?.id}/chats/${row?.id}`,
                                                        )
                                                    }
                                                >
                                                    詳細
                                                </button>
                                                <button
                                                    className="button-tb"
                                                    style={{
                                                        width: '72px',
                                                        margin: '5px',
                                                        background: '#FF5045',
                                                    }}
                                                    onClick={() => {
                                                        openDelete();
                                                        setChatId(row?.id);
                                                    }}
                                                >
                                                    削除
                                                </button>
                                            </div>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};
export default ProjectDetailChats;
