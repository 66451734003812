import { useEffect, useState } from "react";
import moment from "moment";
import { useModal } from "react-hooks-use-modal";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import _ from "lodash";
import ModalCreateNew from "../budgetAction/ModalCreateItem";
import FormEdit from "../budgetAction/BudgetDetail/index";
import ListDetail from "../budgetAction/BudgetDetail/ListDetail";
import { useLogicBudget } from "./hooks";
import styled from "styled-components";
import budgetActionService from "services/budgetActionService";
import budgetItemService from "services/budgetItemService";
import { Button } from "antd";
import { ExclamationOutlined } from "@ant-design/icons";

export const Main = styled.div`
  padding: 28px;
  border-radius: 12px;
`;
const BudgetAction = (props) => {
  const [
    ModalCreateBudgetItem,
    openCreateBudgetItem,
    closeCreateBudgetItem,
    isOpen,
  ] = useModal("root", {
    preventScroll: true,
  });
  const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
    "root",
    {
      preventScroll: true,
    }
  );
  const { budgetId = null } = useParams();
  const navigate = useNavigate();

  const {
    id,
    project = {},
    workers = [],
    statusOptions = [],
    loading = false,
    budget = {},
    detailBudgetItem = [],

    setLoading,
    getProfileUser,
    getListWorkers,
    getProjectDetail,
    getDetailBudget,
    getDetailBudgetItem,
  } = useLogicBudget();
  const [rowData, setRowData] = useState<any>({});
  const [deleteItemRow, setDeleteItemRow] = useState<any>({});
  const [dataBudgetItem, setDataBudgetItem] = useState<any>([]);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (id && budgetId) {
      getProjectDetail(Number(id));
      getListWorkers();
      getDetailBudget(Number(budgetId));
      getProfileUser();
    }
  }, [id]);

  const [dataList, setDataList] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);

  const getListItem = async ({
    page = 1,
    per_page = 30,
    budget_id = budgetId,
  }: any) => {
    const response = await budgetItemService.getListBudgetItem({
      page,
      per_page,
      budget_id,
    });

    if (response !== null && response !== undefined) {
      const data = response?.budget_items?.map((item) => {
        var newObj = {};
        Object.keys(item).forEach((key) => {
          newObj["key"] = item["id"];
          newObj[key] = item[key];
          // if (key === "child_budget_items" && item[key].length > 0)
          //   newObj["children"] = item[key];
          // else {
          //   newObj[key] = item[key];
          // }
        });
        return newObj;
      });
      setDataList(data);
      setTotalCount(response.meta.total_count);
    }
  };

  const submitForm = async (data) => {
    setLoading(true);

    return await budgetActionService
      .updateBudget(Number(budgetId), {
        project_id: id,
        status: data.status.value,
        assigned_worker_id: data.assigned_worker.value,
        approver_worker_id: data.approver_worker.value,
        approve_expired_at: moment(data.approve_expired_at).format(
          "YYYY-MM-DD"
        ),
      })
      .then((res) => {
        if (res?.budget?.id && !data.isGotoEdit) {
          if (!searchParams.get("from")) {
            return navigate(`/projects/detail/${id}/plan?tab=1`);
          }
          return navigate(-1);
        }

        if (res?.budget?.id && data.isGotoEdit) {
          return navigate(`/projects/estimation/${id}/edit/${res?.budget?.id}`);
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const submitFormCreateNewBudgetItem = async (data) => {
    setLoading(true);
    let formData = new FormData();
    formData.append("name", data.name ?? "");
    formData.append("summary", data.summary ?? "");
    formData.append("note", data.note ?? "");
    formData.append("is_parent", data.is_parent ?? "");
    formData.append("budget_id", String(budgetId) ?? "");
    formData.append("supplier", data.supplier ?? "");
    // formData.append("project_id", String(id));

    // if (!data.is_parent) {
    formData.append("amount", data.amount ?? "");
    formData.append("unit", data.unit ?? "");
    formData.append("unit_price", data.unit_price ?? "");
    formData.append("price", data.price ?? "");
    // }

    if (data?.parent_budget_item_id?.value) {
      formData.append(
        "parent_budget_item_id",
        String(data?.parent_budget_item_id?.value) ?? ""
      );
    }

    return await budgetItemService
      .createBudgetItem(formData)
      .then((res) => {
        if (res?.budget_item?.id) {
          closeCreateBudgetItem();
          getListItem({});
          getDetailBudget(Number(budgetId));
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };

  const getListBudgetChildItem = (child_budget_items_id: string) => {
    getDetailBudgetItem({
      project_id: String(id),
      budget_id: budgetId,
      id: child_budget_items_id,
    });
  };

  const handleDeleteBudgetItem = async () => {
    const response = await budgetItemService.deleteBudgetItem(
      deleteItemRow.id,
      budgetId
    );
    console.log(
      "🚀 ~ file: index.tsx:182 ~ handleDeleteBudgetItem ~ response:",
      response
    );

    // call func data
    getListItem({});
    closeDelete();
    getDetailBudget(Number(budgetId));
  };

  const [ModalError, openModalError, closeModal, isOpenModalError] = useModal(
    "root",
    {
      preventScroll: true,
    }
  );
  const handleImportBudget = async (event) => {
    let formData = new FormData();
    const fileData = event.target.files[0];
    if (fileData) {
      formData.append("budget_id", String(budgetId));
      formData.append("attachment", fileData);
      try {
        const response = await budgetItemService.importBudgetItem(formData);
        if (response) {
          getListItem({});
        } else {
          openModalError();
        }
      } catch (error) {
        openModalError();
      }
      getListItem({});
    }
  };

  const goBackDetailParentBudget = () => {
    // update array budget
    dataBudgetItem.pop();
    setDataBudgetItem(dataBudgetItem);

    // update object children budget item
    const row = _.size(dataBudgetItem)
      ? dataBudgetItem[_.size(dataBudgetItem) - 1]
      : {};
    setRowData(row);

    // call api detail budget
    if (row?.id) getListBudgetChildItem(row?.id);
    else getDetailBudget(Number(budgetId));
  };

  const onDeleteBudgetItem = (row) => {
    setDeleteItemRow(row);
    openDelete();
  };

  const onGetDataChildrenBudgetItem = (row) => {
    setRowData(row);
    setDataBudgetItem([...dataBudgetItem, { ...row }]);
    getListBudgetChildItem(row?.id);
  };

  const [actionBudgetItem, setActionBudgetItem] = useState<any>({
    dataItem: {},
    type: "create",
  });
  const submitFormUpdateNewBudgetItem = async (data) => {
    return await budgetItemService
      .updateBudgetItem(Number(actionBudgetItem?.dataItem?.id), {
        budget_id: budgetId,
        parent_budget_item_id: data.is_parent
          ? null
          : data?.parent_budget_item_id?.value,
        is_parent: data.is_parent ?? "",
        name: data.name ?? "",
        summary: data.summary ?? "",
        amount: data.amount ?? "",
        unit: data.unit ?? "",
        unit_price: data.unit_price ?? "",
        note: data.note ?? "",
        supplier: data.supplier ?? "",
      })
      .then((res) => {
        if (res?.budget_item?.id) {
          closeCreateBudgetItem();
          getListItem({});
          getDetailBudget(Number(budgetId));
        }
      })
      .catch((err) => {})
      .finally(() => setLoading(false));
  };
  return (
    <Main>
      <FormEdit
        id={id}
        loading={loading}
        project={project}
        status={statusOptions}
        workers={workers}
        budget={budget}
        submitForm={submitForm}
      />

      <div className="d-flex">
        <ListDetail
          importBudget={handleImportBudget}
          dataList={dataList}
          totalCount={totalCount}
          totalPrice={
            !!_.size(dataBudgetItem)
              ? detailBudgetItem.total_price
              : budget.total_price
          }
          onAddLineNew={openCreateBudgetItem}
          onBack={goBackDetailParentBudget}
          showDataBudgetItem={!!_.size(dataBudgetItem)}
          openDelete={onDeleteBudgetItem}
          onGetDetail={onGetDataChildrenBudgetItem}
          getListItem={getListItem}
          setActionBudgetItem={setActionBudgetItem}
        />
      </div>

      <ModalCreateBudgetItem>
        <ModalCreateNew
          item={rowData}
          onClose={closeCreateBudgetItem}
          submitForm={
            actionBudgetItem?.type == "create"
              ? submitFormCreateNewBudgetItem
              : submitFormUpdateNewBudgetItem
          }
          showDataBudgetItem={!!_.size(dataBudgetItem)}
          listBudgetItem={budget?.budget_items}
          actionBudgetItem={actionBudgetItem}
        />
      </ModalCreateBudgetItem>
      <ModalDelete>
        <div
          className="modal-create-user d-flex flex-column"
          style={{ height: "152px" }}
        >
          <div className="modal-create-user-title d-flex flex-row">
            本当に削除しますか?
          </div>
          <div
            className="modal-create-user-buttons d-flex flex-row"
            style={{ marginTop: "50px" }}
          >
            <button
              className="modal-create-user-button1 d-flex flex-row"
              onClick={closeDelete}
            >
              キャンセル
            </button>
            <button
              className="modal-create-user-button2 d-flex flex-row"
              style={{ background: "#FF5045" }}
              onClick={handleDeleteBudgetItem}
            >
              はい
            </button>
          </div>
        </div>
      </ModalDelete>
      <ModalError>
        <div
          className="modal-create-user !w-[450px] d-flex flex-column relative"
          style={{ height: "152px" }}
        >
          <Button
            className="!bg-[#215493] !text-[white] !border-0 !absolute right-[20px] top-[20px]"
            onClick={closeModal}
          >
            X
          </Button>
          <div className="flex !justify-between">
            <div className="modal-create-user-title !h-full">
              <p className="!mb-0">
                <ExclamationOutlined className="icon-error-mss" />
                データのインポートに失敗しました。
              </p>
              ファイルを確認してください
            </div>
          </div>
        </div>
      </ModalError>
    </Main>
  );
};

export default BudgetAction;
