import { ISugoroku, ISugorokuList } from "models"

export const convertSugorokuArrayToObject = (array: ISugoroku[] = []) => {
  const sugorokuObject: ISugorokuList = {};
  
  array.forEach((item) => {
    if (item && item.sugoroku_index) {
      sugorokuObject[item.sugoroku_index] = {...item}
    }
  });

  return sugorokuObject;
}

export const convertSugorokuEnumObjectToArray = (enums) => {
  if (!enums) return;
  if (typeof enums !== "object") return;

  let array: any = [];

  for (const key in enums) {
    array.push(enums[key]);
  }

  return array;
};