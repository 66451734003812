import fetch from './api/config';

const API = {
    INTERNAL_OJTS: () => `/internal_ojts`,
    INTERNAL_OJT_DETAIL: (id: number) => `internal_ojts/${id}`,
    INTERNAL_OJT_ADD_FILES: (id: number) => `internal_ojts/${id}/add_files`,
    INTERNAL_OJT_DELETE_FILES: (id: number) =>
        `internal_ojts/${id}/delete_file`,
    INTERNAL_OJTS_SP: () => `sp/internal_ojts`,
};

export default class InternalOJTService {
    static getInternalOJTLists = (params: any) =>
        fetch.get(API.INTERNAL_OJTS(), params);

    static createInternalOJT = (params: any) =>
        fetch.post(API.INTERNAL_OJTS(), params);

    static updateInternalOJT = (id: any, params: any) =>
        fetch.put(API.INTERNAL_OJT_DETAIL(id), params);

    static deleteInternalOJT = (id: any) =>
        fetch.delete(API.INTERNAL_OJT_DETAIL(id), {});

    static getInternalOJTDetail = (id: any) =>
        fetch.get(API.INTERNAL_OJT_DETAIL(id), {});

    static deleteInternalOJTFile = (id: any, params) =>
        fetch.delete(API.INTERNAL_OJT_DELETE_FILES(id), params);

    static addInternalOJTFile = (id: any, params: any) =>
        fetch.put(API.INTERNAL_OJT_ADD_FILES(id), params);

    static getInternalOJTListsSP = (params: {page: number; per_page: number}) =>
        fetch.get(API.INTERNAL_OJTS_SP(), params);
}
