export const validate = (values: any) => {
  let errors = {};

  if (!values.assigned_worker?.value) {
    errors = {
      ...errors,
      assigned_worker: "は必須です。",
    };
  }
  if (!values.approver_worker?.value) {
    errors = {
      ...errors,
      approver_worker: "は必須です。",
    };
  }
  // if (!values.approve_expired_at) {
  //     errors = {
  //         ...errors,
  //         approve_expired_at: 'は必須です。'
  //     }
  // }
  return errors;
};
