import styled from 'styled-components';

export const StylesTodoList = styled.div`
    .todoList {
        padding: 11px 13px;
        border-bottom: 1px solid #D0D2D3;
        display: flex;
        align-items: center;
    }
    .ant-checkbox.foo span.ant-checkbox-inner {
        background-color: hotpink;
        border-radius: 50%;
      }
    .todo-title {
        color: #225594;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;s
    }
    .todo-time {
        color: #404041;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .todo-description {
        color: #6D6E70;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .rounded-checkbox {
        width:18px;
        height: 18px;
        border-radius: 50%;
        vertical-align: middle;
        border: 1px solid #D0D2D3;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        cursor: pointer;
      }
      .rounded-checkbox:checked {
        appearance: auto;
        clip-path: circle(50% at 50% 50%);
        accent-color: #215493 !important;
      }
`;
