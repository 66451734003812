import fetch from './api/config';

const API = {
    READ_MESSAGES: (id: number) => `/sp/messages/${id}/read`,
};

export default class MessageServiceSP {
    static reaAllMessages = (id: number, params: any) =>
        fetch.put(API.READ_MESSAGES(id), params);
}
