import fetch from './api/config';

const API = {
    ORDER_ITEM: () => `/order_items`,
    ORDER_ITEMS_ID: (id) => `/order_items/${id}`,
    DELETE_ORDER_ITEM: (id) => `/order_items/${id}`,
};
export default class IndustriesWatesService {
    static getListOrderItem = (params: any) =>
        fetch.get(API.ORDER_ITEM(), params);

    static createOrderItem = (params: any) =>
        fetch.post(API.ORDER_ITEM(), params);

    static deleteOrderItem = (id: number, params: any) =>
        fetch.delete(API.DELETE_ORDER_ITEM(id), params);

    static updateOrderItem = (id: number, params: any) =>
        fetch.put(API.ORDER_ITEMS_ID(id), params);

    static getOrderItemDetail = (id: number, params: any) =>
        fetch.get(API.ORDER_ITEMS_ID(id), params);
}
