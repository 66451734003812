import fetch from './api/config';

const API = {
    GET_LIST_PURCHASE_REQUESTS: () => `sp/purchase_requests`,
    DELETE_PURCHASR_REQUEST: (id: number) => `/sp/purchase_requests/${id}`,
    GET_PURCHASE_REQUEST_DETAIL: (id: number | string) => `sp/purchase_requests/${id}`,
    UPDATE_PURCHASR_REQUEST_APPROVED_DENIED: (id: number) => `/sp/purchase_requests/${id}/approve_deny`
};

export default class PurchaseRequestService {
    static getListPurchaseRequests = (params: { page: number; per_page: number }) =>
        fetch.get(API.GET_LIST_PURCHASE_REQUESTS(), params);

    static deletePurchaseRequest = (id: number) =>
        fetch.delete(API.DELETE_PURCHASR_REQUEST(id), {});

    static createPurchaseReuqest = (params: any) =>
        fetch.post(API.GET_LIST_PURCHASE_REQUESTS(), params);

    static getPRDetail = (id: number | string) =>
        fetch.get(API.GET_PURCHASE_REQUEST_DETAIL(id), {});

    static updatePRApproveDeny = (id: number, params: {status: string}) =>
        fetch.put(API.UPDATE_PURCHASR_REQUEST_APPROVED_DENIED(id), params);

}
