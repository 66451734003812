import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { ReceivedProps, DataType } from './type';
import { useNavigate } from 'react-router-dom';
import VehicleInspectionService from 'services/vehicleInspectionService';
import * as yup from 'yup';
import InternalOJTService from 'services/internalOJTService';
import MeetingMinutesService from 'services/meetingMinutesService';

export const validation = yup.object().shape({
    subject: yup.string().required('必須項目です'),
});

const useMeetingMinutes = (props: ReceivedProps) => {
    const [deleteData, setDeleteData] = useState<DataType>({
        id: 1,
        name: '',
        created_at: '',
        file_url: '',
        updated_at: '',
    });
    const [meetingMinusList, setMeetingMinusList] = useState<DataType[]>([]);
    const [refreshList, setRefreshList] = useState<boolean>(false);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            subject: '',
        },
        onSubmit: (values) => fetchListMeetingMinutes(values),
    });

    const updateFormik = useFormik({
        enableReinitialize: true,
        initialValues: {
            subject: '',
        },
        validationSchema: validation,
        onSubmit: (values) => handleUpdate(values),
    });

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: true,
        },
    );

    const [ModalUpdate, openUpdate, closeUpdate, isOpenUpdate] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: true,
        },
    );
    const handleUpdate = async (values) => {
        const formData = new FormData();
        formData.append('subject', `${values.subject}`);
        try {
            await MeetingMinutesService.updateMeetingMinutes(
                deleteData.id,
                formData,
            );
            setRefreshList((preState) => !preState);
            closeUpdate();
        } catch (error) {}
    };

    const fetchListMeetingMinutes = async (params = {}) => {
        try {
            const { meeting_minutes }: any =
                await MeetingMinutesService.getMeetingMinutes({
                    page: 1,
                    per_page: 0,
                    ...params,
                });
            setMeetingMinusList(meeting_minutes);
        } catch (error) {
            //
        }
    };

    const handleOpenDeleleModal = (data) => {
        setDeleteData(data);
        openDelete();
    };

    const handleOpenUpdateModal = (data) => {
        setDeleteData(data);
        updateFormik.setFieldValue('subject', data?.subject);
        openUpdate();
    };

    const handleDelete = async () => {
        try {
            await MeetingMinutesService.deleteMeetingMinutes(deleteData.id);
            closeDelete();
            setRefreshList((preState) => !preState);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchListMeetingMinutes();
    }, [refreshList]);

    return {
        ...props,
        formik,
        ModalDelete,
        isOpenDelete,
        meetingMinusList,
        openDelete,
        handleOpenUpdateModal,
        closeDelete,
        handleOpenDeleleModal,
        navigate,
        handleDelete,
        ModalUpdate,
        openUpdate,
        closeUpdate,
        isOpenUpdate,
        updateFormik,
    };
};
export type Props = ReturnType<typeof useMeetingMinutes>;

export default useMeetingMinutes;
