import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import PositionService from "../../services/positionService";

export const getListPositions = createAsyncThunk(
  "positions",
  async () => {
    const response = await PositionService.getListPositions();
    return response;
  }
);

const initialState = {
    positionsList: []
}

const positionSlice = createSlice({
    name: "positions",
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(getListPositions.fulfilled, (state, action) => {
            state.positionsList = action.payload.positions
        })

        .addCase(getListPositions.rejected,(state, action) => {
            state.positionsList = [];
        })
    },
    reducers: {},
});

const { reducer } = positionSlice;
export default reducer;
