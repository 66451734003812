import Api from "./api/budgetApi";
import pick from "lodash/pick";
// import planLists from '../shared/mocks/planLists.json';
import moment from "moment";

const createBudget = async (payload) => {
  return await Api()
    .post("", payload, {})
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const updateBudget = async (budgetId: number, payload: any) => {
  return await Api()
    .put(`${budgetId}`, payload, {})
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const deleteBudget = async (budgetId: number, project_id: number) => {
  return await Api()
    .delete(`${budgetId}?project_id=${project_id}`)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const getDetailBudget = async (budgetId: number, project_id: number) => {
  return await Api()
    .get(`${budgetId}?project_id=${project_id}`)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const getListBudgetProject = async (project_id: number, input?: any) => {
  // return planLists;
  let data = pick(input, ["page", "per_page"]);
  if (project_id) {
    data.project_id = project_id;
  }
  const params = new URLSearchParams(data);
  return await Api()
    .get(`?${params.toString()}`)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const budgetActionService = {
  createBudget,
  updateBudget,
  deleteBudget,
  getDetailBudget,
  getListBudgetProject,
};

export default budgetActionService;
