import { useState, useEffect } from 'react';
import scheduleService from 'services/scheduleService';
import moment from 'moment';
import { RootState } from 'store';
import { useSelector } from 'react-redux';

export type ReceivedProps = Record<never, never>;
const useTodo = (props: ReceivedProps) => {
    const [open, setOpen] = useState(false);
    const [currentTodo, setCurrentTodo] = useState<any>();
    const [data, setData] = useState<any>([]);
    const { userData }: any = useSelector((state: RootState) => state.users);
    const fetchSchedules = async () => {
        try {
            const { schedules } = await scheduleService.getTodolist({
                page: 1,
                per_page: 9999,
                worker_id: userData?.worker?.id,
            });
            setData(
                schedules?.filter((schedule) => schedule.is_show_on_todo_list),
            );
        } catch (error) {}
    };
    useEffect(() => {
        fetchSchedules();
    }, []);

    const checkIsThreeDays = (schedule) => {
        const startDate = moment(schedule.start_date); // Replace with your startAt date
        const endDate = moment(schedule.end_date); // Replace with your finishAt date
        // Get the current date
        const currentDate = moment();

        // Define the three-day period
        const startOfThreeDays = currentDate.clone();
        const endOfThreeDays = currentDate.clone().add(2, 'days');

        if (
            startDate.isSameOrBefore(endOfThreeDays, 'day') &&
            endDate.isSameOrAfter(startOfThreeDays, 'day')
        ) {
            return true;
        } else {
            return false;
        }
    };
    function checkIsInThisWeek(date) {
        const startDtate = moment(date.start_date);
        const endDate = moment(date.end_date);
        const firstDateOfWeek = moment().startOf('isoWeek');
        const endDateOfWeek = moment().endOf('isoWeek');
        if (
            startDtate.isSameOrBefore(endDateOfWeek, 'day') &&
            endDate.isSameOrAfter(firstDateOfWeek, 'day')
        ) {
            return true;
        } else {
            return false;
        }
    }
    const handleOpen = (todo) => {
        setCurrentTodo(todo);
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
        setCurrentTodo(null);
    };
    return {
        ...props,
        open,
        currentTodo,
        data,
        checkIsThreeDays,
        checkIsInThisWeek,
        handleOpen,
        handleClose,
    };
};

export type Props = ReturnType<typeof useTodo>;

export default useTodo;
