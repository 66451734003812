import { Dialog } from '@mui/material';
import { useFormik } from 'formik';
import StyledForm from '../formStyle';
import departmentsService from 'services/departmentsService';
import { alertError } from 'utils/helper/appHelper';
import { departmentValidation } from '../validation';

const CreateDepartmentDialog = ({ open, setOpen, onFinish }) => {
    const formik = useFormik({
        initialValues: {
            name: '',
        },
        validate: departmentValidation,
        onSubmit: (values) => {
            submitForm(values);
        },
    });

    const submitForm = async (values) => {
        try {
            const data = await departmentsService.createDepartments(values);
            if (data) {
                onFinish();
            }
        } catch (err) {
            const response = err?.response;
            alertError(response.data?.error);
            setOpen(false);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
            <StyledForm onSubmit={formik.handleSubmit}>
                <h2 className="formTitle">部署 新規作成</h2>
                <div className="fieldRow">
                    <p>部署名</p>
                    <input
                        autoComplete="off"
                        type="text"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                    ></input>
                    {formik.touched.name && formik.errors.name && (
                        <span className="error">{formik.errors.name}</span>
                    )}
                </div>
                <div className="buttonGroup">
                    <button
                        className="cancelBtn"
                        type="button"
                        onClick={handleClose}
                    >
                        キャンセル
                    </button>
                    <button className="registerBtn" type="submit">
                        登録
                    </button>
                </div>
            </StyledForm>
        </Dialog>
    );
};

export default CreateDepartmentDialog;
