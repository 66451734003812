import styled from 'styled-components';

const StyledWorkingLogForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 500px;
    background: #ffffff;
    border-radius: 12px;
    padding: 28px;
    justify-content: center;
    align-items: center;

    .formTitle {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-family: 'Zen Kaku Gothic Antique';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #1c1e21;
    }

    .formRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .formFieldRow {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        p {
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
            font-family: 'Zen Kaku Gothic Antique';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
            margin: 0;
            margin-bottom: 4px;
            margin-top: 12px;
        }

        .autocomplete-select-style {
            width: 100%;
            height: 100%;
            margin-right: 16px;
            padding: 0px;

            .MuiOutlinedInput-root {
                height: 100%;
                padding: 0px;
                padding-left: 10px;

                fieldset {
                    border-radius: 8px;
                }
            }
        }

        .textfield {
            justify-content: flex-start;
            align-items: flex-start;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 12px;
            gap: 8px;
            width: 100%;
            height: 36px;
            background: #ffffff;
            border: 1px solid #cdd1d5;
            border-radius: 8px;
        }

        .textFieldArray {
            justify-content: flex-start;
            align-items: flex-start;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 12px;
            gap: 8px;
            width: 160px;
            height: 44px;
            background: #ffffff;
            border: 1px solid #cdd1d5;
            border-radius: 8px;
        }

        .textarea {
            justify-content: flex-start;
            align-items: flex-start;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 12px;
            gap: 8px;
            width: 100%;
            height: 84px;
            background: #ffffff;
            border: 1px solid #cdd1d5;
            border-radius: 8px;
            resize: vertical;
        }

        .datepicker-wrapper {
            width: 100%;
            position: relative;

            input {
                justify-content: flex-start;
                align-items: flex-start;
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                padding: 10px 12px;
                gap: 8px;
                width: 100%;
                height: 100%;
                background: #ffffff;
                border: 1px solid #cdd1d5;
                border-radius: 8px;
            }

            .calendar-datepicker-icon {
                position: absolute;
                right: 15px;
                top: 10px;
            }
        }

        .imagesContainer {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .imageWrapper {
                width: 122px;
                height: 100px;
                position: relative;

                img {
                    width: 100%;
                    height: 100%;
                }

                .deleteIcon {
                    position: absolute;
                    width: 24px;
                    height: 24px;
                    top: 0px;
                    right: 0px;
                    cursor: pointer;
                }
            }
        }

        .file-uploader-wrapper {
            font-family: 'Zen Kaku Gothic Antique';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 26px;
            cursor: pointer;

            .addFileBtn {
                text-align: center;
                padding: 8px 0px;
                width: 100%;
                background: #215493;
                border-radius: 8px;
                color: #ffffff;
            }
        }
    }

    .cancelBtn,
    .registerBtn {
        width: 190px;
        height: 44px;
        border-radius: 8px;
    }

    .cancelBtn {
        border: 1px solid #215493;
        color: #215493;
    }

    .registerBtn {
        background: #215493;
        color: #ffffff;
    }

    .btnAddpayment {
        margin-top: 16px;
        margin-left: auto;
    }

    .btnRemovepayment {
        margin-left: 16px;
    }

    .MuiAutocomplete-root  {
        border: 1px solid #cdd1d5;
        border-radius: 8px;
    }

    .formButtonGroup {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        gap: 16px;

        .cancelBtn,
        .registerBtn {
            width: 190px;
            height: 44px;
            border-radius: 8px;
        }

        .cancelBtn {
            border: 1px solid #215493;
            color: #215493;
        }

        .registerBtn {
            background: #215493;
            color: #ffffff;
        }
    }
`;

export default StyledWorkingLogForm;

