import { Dispatch, FC, SetStateAction } from 'react';
import { Appointments } from '@devexpress/dx-react-scheduler-material-ui';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#1c1e21',
        boxShadow: theme.shadows[1],
        fontSize: 10,
    },
}));

type IProps = {
    restProps: any;
    currentViewName: string;
    setOpenScheduleFormDialog: Dispatch<SetStateAction<boolean>>;
    setOpenPlanUpdateModal: Dispatch<SetStateAction<boolean>>;
    setCurrentViewName: Dispatch<SetStateAction<string>>;
    setCurrentDate: Dispatch<SetStateAction<any>>;
};

const AppointmentItem: FC<IProps> = ({
    restProps,
    currentViewName,
    setOpenScheduleFormDialog,
    setOpenPlanUpdateModal,
    setCurrentViewName,
    setCurrentDate,
}) => {
    const { children } = restProps;
    const navigate = useNavigate();

    if (currentViewName === 'Month') {
        return (
            <Appointments.Appointment
                {...restProps}
                onClick={() => {
                    if (restProps.data.project_id) {
                        // navigate(
                        //     `/projects/detail/${restProps.data.project_id}/plan`,
                        //     {
                        //         state: { id: restProps.data.project_id },
                        //     },
                        // );
                        setOpenPlanUpdateModal(true);
                        navigate(`/sp/calendar`, {
                          state: {
                            plan_id: restProps.data.id,
                            project_id: restProps.data.project_id,
                            currentPlan: restProps.data,
                          },
                        })
                    } else {
                        if (restProps.data.showWeekView) {
                            setCurrentViewName('Week');
                            setCurrentDate(restProps.data.startDate);
                        } else {
                            navigate(`/sp/calendar`, {
                                state: {
                                    editingId: restProps.data.id,
                                },
                            });
                            setOpenScheduleFormDialog(true);
                        }
                    }
                }}
                style={(() => {
                    if (restProps.data.showWeekView) {
                        return {
                            background: '#195192',
                        };
                    }

                    return {
                        background: restProps.data.color || '#195192',
                    };
                })()}
            >
                {children}
            </Appointments.Appointment>
        );
    }

    return (
        <LightTooltip
            title={
                <button
                    type="button"
                    onClick={() => {
                        if (restProps.data.project_id) {
                            navigate(
                                `/projects/detail/${restProps.data.project_id}/plan`,
                                {
                                    state: { id: restProps.data.project_id },
                                },
                            );
                        } else {
                            navigate(`/sp/calendar`, {
                                state: {
                                    editingId: restProps.data.id,
                                },
                            });
                            setOpenScheduleFormDialog(true);
                        }
                    }}
                >
                    {restProps.data.title}
                </button>
            }
            arrow
            placement="top-end"
            followCursor
        >
            <div className="wrapTooltip">
                <Appointments.Appointment
                    {...restProps}
                    onClick={() => {
                        if (restProps.data.project_id) {
                            navigate(
                                `/projects/detail/${restProps.data.project_id}/plan`,
                                {
                                    state: { id: restProps.data.project_id },
                                },
                            );
                        } else {
                            navigate(`/sp/calendar`, {
                                state: {
                                    editingId: restProps.data.id,
                                },
                            });
                            setOpenScheduleFormDialog(true);
                        }
                    }}
                    style={{
                        background: restProps.data.color || '#195192',
                    }}
                >
                    {children}
                </Appointments.Appointment>
            </div>
        </LightTooltip>
    );
};

export default AppointmentItem;
