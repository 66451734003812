import { useState, useEffect } from 'react';
import { Controller, useForm, FieldValues } from 'react-hook-form';
import { Grid, Autocomplete, TextField } from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import createWorkingLogValidation from 'utils/validation/registers/workingLogs/createWorkingLogValidation';
import { getFilePreview } from 'utils/helper/file';
import calendarIcon from 'assets/icons/calendar.png';
import deleteIcon from 'assets/icons/deleteIcon.svg';
import magnifyingGlass from 'assets/icons/MagnifyingGlass.svg';
import StyledCreateWorkingLog from './style';
import Dropdown from 'react-dropdown';
import workersService from 'services/wokersService';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import PlanService from 'services/planServiceSP';
import WorkingLogService from 'services/workingLogServiceSP';
import { getRole } from 'utils/helper/userConfigHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const Input = styled('input')({
    display: 'none !important',
});

type FormProps<T extends FieldValues = FieldValues> = {
    defaultValues: T;
};

const CreateWorkingLogSP = () => {
    const navigate = useNavigate();
    const [plans, setPlans] = useState<any[]>([]);
    const [workers, setWorkers] = useState<any[]>([]);
    const { t } = useTranslation();
    const [fileUpload, setFileUpload] = useState<any[]>([]);
    const { id, detail } = useParams();
    const [searchParams] = useSearchParams();
    const isDetail = searchParams.get('detail');
    const workingLogId = searchParams.get('workingLogId');
    const [workingLog, setWorkingLog] = useState<any>(null);
    const [hasUploadedImagesDeleted, setHasUploadedImagesDeleted] =
        useState<boolean>(false);
    const role = getRole();
    const { userData }: any = useSelector((state: RootState) => state.users);
    const {
        register,
        control,
        handleSubmit,
        setValue,
        setError,
        formState: { errors },
        getValues,
        reset,
    } = useForm<FormProps['defaultValues']>({
        defaultValues: {
            worker: {
                value: userData?.id,
                label: userData?.worker?.name,
            },
            worked_at: new Date(),
        },
    });

    const getWorkingLogsDetails = async () => {
        try {
            const response: any = await WorkingLogService.getWorkingLogDetail(
                Number(workingLogId),
                { project_id: id },
            );
            setWorkingLog(response?.working_log);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRemoveUploadedImage = (signed_id) => {
        setFileUpload((prev) => {
            const newFilesArray = [...prev];
            let target = newFilesArray.find(
                (item) => item.signed_id === signed_id,
            );
            const targetIndex = newFilesArray.findIndex(
                (item) => item.signed_id === signed_id,
            );
            target = { ...target, type: 'destroy' };
            newFilesArray[targetIndex] = target;
            return newFilesArray;
        });

        if (!hasUploadedImagesDeleted) {
            setHasUploadedImagesDeleted(true);
        }
    };

    const handleUpdateDeletedImage = () => {
        const promisesArray: Promise<unknown>[] = [];
        fileUpload.forEach((item) => {
            if (item.type == 'destroy') {
                promisesArray.push(
                    new Promise(async (resolve, reject) => {
                        await WorkingLogService.deleteImage(
                            Number(workingLogId),
                            { project_id: id, signed_id: item.signed_id },
                        )
                            .then(() => resolve(true))
                            .catch((err) => reject(err));
                    }),
                );
            }
        });
        return Promise.all(promisesArray);
    };

    const handleUpdateNewImage = async () => {
        const files = fileUpload.filter((item) => item?.type == 'upload');
        if (files.length <= 0) return;
        const formData: any = new FormData();
        formData.append('project_id', id);
        formData.append('id', workingLogId);
        files.forEach((item) => {
            if (item.type == 'upload') {
                formData.append('working_log_images[]', item?.file);
            }
        });

        try {
            const response = await WorkingLogService.uploadImage(
                Number(workingLogId),
                formData,
            );
            if (response) {
                return true;
            }
        } catch (error) {
            // empty
        }
    };

    const handleUploadFile = async (files) => {
        let current = fileUpload ? [...fileUpload] : [];
        if (files.length > 0) {
            if (current) {
                current.push({ file: files[0], type: 'upload' });
            } else {
                current = [{ file: files[0], type: 'upload' }];
            }
            setFileUpload(current);
        }
    };

    const removeImage = (idx) => {
        let current = [...fileUpload];
        current = current.filter((item, index) => index !== idx);
        setFileUpload(current);
    };

    const getListWorkers = async () => {
        try {
            const responseWorker = await workersService.getListWorkers();
            if (responseWorker !== null && responseWorker !== undefined) {
                setWorkers(responseWorker.workers);
            }
        } catch (error) {
            // empty
        }
    };

    const getPlansLists = async () => {
        try {
            const response: any = await PlanService.getListPlans({
                page: 1,
                per_page: 0,
                project_id: id,
            });
            setValue('plan_id', {
                value: response?.plans?.[0].id,
                label: response?.plans?.[0].name,
            });
            setPlans(
                response?.plans.map((plan) => ({
                    label: plan.name,
                    value: plan.id,
                })) || [],
            );
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getListWorkers();
        getPlansLists();
    }, []);

    useEffect(() => {
        if (!!workingLogId && plans.length > 0 && workers.length > 0) {
            getWorkingLogsDetails();
        }
    }, [workingLogId, plans, workers]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (workingLog) {
            reset({
                name: workingLog?.name,
                note: workingLog?.note,
                plan_id: {
                    label: workingLog?.plan?.name,
                    value: workingLog?.plan?.id,
                },
                worked_at: new Date(workingLog?.worked_at),
                worker: {
                    label: workingLog?.worker?.name,
                    value: workingLog?.worker?.id,
                },
            });
            setFileUpload(workingLog?.working_log_images?.[0]?.images || []);
        }
    }, [workingLog]);

    const onCreate = async (data) => {
        try {
            const formData = new FormData();

            formData.append('project_id', id as any);
            formData.append(
                'worker_id',
                data?.worker?.value || (userData?.worker as any).id,
            );
            formData.append('name', data?.name);
            formData.append('note', data?.note);
            formData.append('worked_at', data?.worked_at);
            formData.append('plan_id', data?.plan_id?.value);

            if (!!workingLogId) {
                if (hasUploadedImagesDeleted) {
                    await handleUpdateDeletedImage();
                }
                await handleUpdateNewImage();

                await WorkingLogService.updateWorkingLog(
                    Number(workingLogId),
                    formData,
                );
            } else {
                fileUpload?.forEach((file) => {
                    formData.append('working_log_images[]', file.file);
                });
                await WorkingLogService.createWorkingLog(formData);
            }
            navigate(`/sp/working_logs/${id}`, { replace: true });
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <StyledCreateWorkingLog>
            <form style={{ width: '100%' }} onSubmit={handleSubmit(onCreate)}>
                <div className="formTitle">日報</div>
                <div className="formFieldRow">
                    <p>工程</p>
                    <Controller
                        name="plan_id"
                        control={control}
                        render={({ field }) => (
                            <>
                                <Dropdown
                                    disabled={!!isDetail || !plans.length}
                                    {...register(
                                        'plan_id',
                                        createWorkingLogValidation(t).plan_id(),
                                    )}
                                    {...field}
                                    className=" dropdown-create-user1 height36 mt-[16px]"
                                    controlClassName="dropdown-control-user height36"
                                    options={plans}
                                    placeholder=""
                                />
                            </>
                        )}
                    />
                    {errors.plan_id && (
                        <span className="error">
                            {errors.plan_id.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="formFieldRow">
                    <p>作業名</p>
                    <input
                        autoComplete="off"
                        type="name"
                        disabled={!!isDetail}
                        {...register(
                            'name',
                            createWorkingLogValidation(t).name(),
                        )}
                        className="textfield"
                    />
                    {errors.name && (
                        <span className="error">
                            {errors.name.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="formFieldRow">
                    <p>作業日</p>
                    <div className="datepicker-wrapper">
                        <Controller
                            name="worked_at"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    disabled={!!isDetail}
                                    selected={field.value}
                                    {...register(
                                        'worked_at',
                                        createWorkingLogValidation(
                                            t,
                                        ).worked_at(),
                                    )}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    autoComplete="off"
                                    locale="ja"
                                    id="worked_at"
                                />
                            )}
                        />
                        <label htmlFor="worked_at">
                            <img
                                className="calendar-datepicker-icon"
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                    {errors.worked_at && (
                        <span className="error">
                            {errors.worked_at?.message?.toString()}
                        </span>
                    )}
                </div>
                {role === 'admin' && (
                    <div className="formFieldRow">
                        <p>担当</p>
                        <div className="d-flex" style={{ width: '100%' }}>
                            <div
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                }}
                            >
                                <img
                                    src={magnifyingGlass}
                                    alt="magnifying glass icon"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '10px',
                                        transform: 'translate(0%, -50%)',
                                    }}
                                />
                                <Controller
                                    name="worker"
                                    control={control}
                                    render={({ field }) => (
                                        <>
                                            <Dropdown
                                                disabled={!!isDetail}
                                                {...register(
                                                    'worker',
                                                    createWorkingLogValidation(
                                                        t,
                                                    ).worker(),
                                                )}
                                                {...field}
                                                className="dropdown-create-user1 height36 mt-[16px]"
                                                controlClassName="dropdown-control-user height36"
                                                options={workers.map(
                                                    (item) => ({
                                                        value: item.id,
                                                        label: item.name,
                                                    }),
                                                )}
                                                placeholder=""
                                            />
                                        </>
                                    )}
                                />
                            </div>
                        </div>
                        {errors.worker && (
                            <span className="error">
                                {errors.worker.message?.toString()}
                            </span>
                        )}
                    </div>
                )}
                <div className="formFieldRow">
                    <p>備考</p>
                    <textarea
                        disabled={!!isDetail}
                        autoComplete="off"
                        className="textarea"
                        {...register(
                            'note',
                            createWorkingLogValidation(t).note(),
                        )}
                    />
                    {errors.note && (
                        <span className="error">
                            {errors.note.message?.toString()}
                        </span>
                    )}
                </div>
                <div className="formFieldRow">
                    <p>画像</p>
                    <Grid container>
                        <Grid item xs={9}>
                            <div className="imagesContainer">
                                {fileUpload &&
                                    fileUpload
                                        .filter(
                                            (item) => item?.type != 'destroy',
                                        )
                                        .map((item, idx) => {
                                            return (
                                                <div
                                                    className="imageWrapper"
                                                    key={`image-preview-${idx}`}
                                                >
                                                    <img
                                                        src={
                                                            item?.image_path
                                                                ? item.image_path
                                                                : getFilePreview(
                                                                      item?.file,
                                                                  )
                                                        }
                                                    />
                                                    {!isDetail && (
                                                        <img
                                                            className="deleteIcon"
                                                            src={deleteIcon}
                                                            onClick={() =>
                                                                workingLogId
                                                                    ? handleRemoveUploadedImage(
                                                                          item.signed_id,
                                                                      )
                                                                    : removeImage(
                                                                          idx,
                                                                      )
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            );
                                        })}
                            </div>
                        </Grid>
                        <Grid item xs={3}>
                            <label
                                htmlFor="contained-button-file"
                                className="file-uploader-wrapper"
                            >
                                <Input
                                    accept="image/*"
                                    id="contained-button-file"
                                    multiple={false}
                                    type="file"
                                    onChange={(e) =>
                                        handleUploadFile(e.target.files)
                                    }
                                />
                                <div className="addFileBtn">追加</div>
                            </label>
                        </Grid>
                    </Grid>
                </div>

                <div className="formButtonGroup">
                    <button
                        type="button"
                        className="cancelBtn"
                        onClick={() => {
                            setFileUpload([]);
                            navigate(`/sp/projects_list`);
                        }}
                    >
                        戻る
                    </button>
                    <button
                        disabled={!!isDetail}
                        className="registerBtn"
                        type="submit"
                    >
                        登録
                    </button>
                </div>
            </form>
        </StyledCreateWorkingLog>
    );
};

export default CreateWorkingLogSP;
