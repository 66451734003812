import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box, Button, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { useEffect, useState } from 'react';
import TopPageService from 'services/topPageServiceSP';
import ProgressBar from './components/Progressbar';
import moment from 'moment';
import NewsModal from './components/newsModal';
import Modal from 'react-modal';
import NewsService from 'services/newsService';
import purchaseRequestService from 'services/purchaseRequestService';
import { isAdmin } from 'utils/helper/workerHelper';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import './dashboard.css';

export const NewsItem = ({ newItem }) => {
    const [isShowMore, setIsShowMore] = useState(false);
    return (
        <Box marginBottom="12px">
            <Typography
                lineHeight="20px"
                color="#1C1E21"
                marginBottom="16px"
                fontWeight={500}
            >
                {moment(newItem?.publish_at).format('YYYY年M月D日')}
            </Typography>
            <Typography
                fontSize={14}
                fontWeight={700}
                lineHeight="24px"
                color="#448CDF"
                marginBottom="8px"
            >
                {newItem?.title}
            </Typography>
            <Typography
                sx={
                    !isShowMore
                        ? {
                              textOverflow: 'ellipsis',
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                          }
                        : {}
                }
                fontSize={14}
                fontWeight={400}
                lineHeight="24px"
                color="#rgba(0, 0, 0, 0.7)"
            >
                {newItem?.content}
            </Typography>
            {newItem?.content.length > 50 && (
                <Typography
                    onClick={() => setIsShowMore(!isShowMore)}
                    sx={{ textDecoration: 'underline' }}
                    color="#195192"
                    fontSize={14}
                    fontWeight={400}
                    lineHeight="24px"
                >
                    もっと見る
                </Typography>
            )}
        </Box>
    );
};

export const CardWrapper = ({ children, title, classCustom = '' }) => {
    return (
        <Box marginBottom="20px">
            <Typography className='card-title' lineHeight="30px" color="#1C1E21" fontWeight={700}>
                {title}
            </Typography>
            <Box
                className={`card-container ${classCustom}`}
                marginTop="16px"
                border="1px solid #CDD1D5"
                borderRadius="12px"
                padding="16px"
            >
                {children}
            </Box>
        </Box>
    );
};
interface IPurchaseRequest {
    content: string;
    custom_data: {status: string, maker_worker_name: string},
    id: number;
    publish_at: string;
    resource_id: number;
    resource_type: string;
    title: string;
}
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        backgroundColor: '#FFFFFF',
        position: 'fixed',
    },
};
const DashboardSP = () => {
    const [news, setNews] = useState<any>([]);
    const [progress, setProgress] = useState<any>(null);
    const [request, setRequest] = useState<any>(null);
    const [isShowMoreNews, setIsShowMoreNews] = useState(false);
    const [purchaseRequests, setPurchaseRequests] = useState<IPurchaseRequest[]>([]);
    const [openPrModal, setOpenPrModal] = useState(false);
    const [prDetail, setPrDetail] = useState<IPurchaseRequest>();

    const { userData } = useSelector((state: RootState) => state.users);
    const isAllow = isAdmin(userData);

    const getTopPageProfiles = async () => {
        try {
            const response: any = await TopPageService.getTopPageNews();
            setNews(response?.top_page);
        } catch (error) {}
    };

    const getTopPageProgress = async () => {
        try {
            const response: any = await TopPageService.getTopPageProgress();
            const listProgress = [
                {
                    value: response?.negotiating_project * 100 + '%',
                    title: '見込み案件',
                    amount: response?.negotiating_project,
                },
                {
                    value: response?.inprogress_project * 100 + '%',
                    title: '実施中案件数',
                    amount: response?.inprogress_project,
                },
                {
                    value: response?.complete_project * 100 + '%',
                    title: '完了案件',
                    amount: response?.complete_project,
                },
            ];
            setProgress(listProgress);
        } catch (error) {}
    };

    const getTopPageRequest = async () => {
        try {
            const response: any = await TopPageService.getTopPageRequest();
            setRequest(response?.top_page);
        } catch (error) {}
    };

    const getListNews = async () => {
        try {
            const response = await NewsService.getListNewsSP({
                page: 1,
                per_page: 0,
            });
            if (response !== null && response !== undefined) {
                const listPrNews = response?.news?.filter((item: { resource_type: string; }) => item?.resource_type === "PurchaseRequest");
                setPurchaseRequests(listPrNews);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const updatePrApproveDeny = async (payload: string) => {
        if(!prDetail?.resource_id) return;
        const params = {
            status: payload,
          }
        try {
            await purchaseRequestService.updatePRApproveDeny(prDetail?.resource_id, params);
            getListNews();
        } catch (error) {
            console.log(error);
        }
    }


    const convertDateToJapan = (dateTime) => {
        let newDateTime = dateTime.replace(/ /g, 'T');
        const date = new Date(newDateTime);
        var lang = 'en';
        let year = date.toLocaleString(lang, { year: 'numeric' });
        let month = date.getMonth() + 1;
        let day = date.toLocaleString(lang, { day: 'numeric' });
        const textDate = `${year}年${month}月${day}日`;
        return textDate;
    };
    const handleAgreeSendApiFirst = () => {
        updatePrApproveDeny("approved");
        setOpenPrModal(false);
    }
    const handleRefuseSendApiFirst = () => {
        updatePrApproveDeny("denied");
        setOpenPrModal(false);
    }
 
    useEffect(() => {
        getTopPageProfiles();
        getTopPageProgress();
        getTopPageRequest();
        getListNews();
    }, []);

    return (
        <Box
            sx={{
                '.MuiTypography-root, button, th, td': {
                    fontFamily: 'Zen Kaku Gothic Antique',
                },
            }}
            padding="25px 16px 16px 16px"
        >
            <Box marginBottom="24px">
                <Typography color="#215493" fontWeight={700}>
                    一生懸命。
                </Typography>
                <Typography color="#215493" fontWeight={700}>
                    ベターな事はするな。ベストで行け。
                </Typography>
            </Box>
            <CardWrapper classCustom={purchaseRequests?.length > 3 ? 'dashboard-news-content1' : '!h-fit'} title="お知らせ">
                {news?.map((newItem, index) =>
                    isShowMoreNews ? (
                        <NewsItem key={newItem?.id} newItem={newItem} />
                    ) : (
                        index < 2 && (
                            <NewsItem key={newItem?.id} newItem={newItem} />
                        )
                    ),
                )}
                <div className='mt-[18px]'>
                    {
                        purchaseRequests?.length > 0 && purchaseRequests?.map((item, index) => (
                            <div className="flex items-start justify-between" key={index}>
                                <div className="mr-2">
                                    <p className="text-[14px] mb-0 text-[#1c1e21] leading-[24px] font-[500]">
                                        {convertDateToJapan(
                                            item.publish_at,
                                        )}
                                    </p>
                                </div>
                                <div className="news-content-title">
                                    <p className="text-[#406fe5] text-[16px] truncate w-full leading-[24px] mb-0">
                                        {item?.content}
                                    </p>
                                    <div className="news-inf-content-ctn d-flex flex-row">
                                        <p
                                            style={{
                                                width: 'calc(100% - 85px)',
                                                color: 'rgba(0, 0, 0, 0.7)',
                                            }}
                                            className="text-[14px] truncate leading-[24px] mb-0"
                                        >
                                            {item?.title}
                                        </p>
                                        <div
                                            className="text-[#195192] text-[14px] font-[500] cursor-pointer underline mb-0"
                                            onClick={() => {
                                                setOpenPrModal(
                                                    true,
                                                );
                                                setPrDetail(
                                                    item,
                                                );
                                            }}
                                        >
                                            もっと見る
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                <Box display="flex" justifyContent="center" className='mt-[10px]'>
                    <Button
                        onClick={() => setIsShowMoreNews(!isShowMoreNews)}
                        sx={{
                            border: '1px solid rgba(33, 84, 147, 0.3)',
                            filter: 'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))',
                            borderRadius: '8px',
                            color: '#195192',
                            padding: '4px 23px',
                            fontWeight: '500',
                        }}
                        variant="outlined"
                    >
                        もっと見る
                    </Button>
                </Box>
            </CardWrapper>
            <CardWrapper title="今月進捗">
                <Box
                    sx={{
                        '.highcharts-yaxis-labels, .highcharts-title, .highcharts-axis-title, .highcharts-legend-item':
                            {
                                display: 'none',
                            },
                        '.progress-chart-item': {
                            paddingLeft: '0px !important',
                        },
                        '.title-progress': {
                            width: '115px',
                        },
                    }}
                >
                    {progress?.map((item) => (
                        <Box
                            sx={{
                                marginBottom: '8px',
                                '&:last-child': {
                                    marginBottom: '0px',
                                },
                            }}
                            className="progress-chart-item d-flex flex-row"
                        >
                            <Typography className="title-progress">
                                {item.title}
                            </Typography>
                            <Box className="custom-progress d-flex flex-row">
                                <ProgressBar width={item.value}></ProgressBar>
                                <label>{item?.amount || 0}</label>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </CardWrapper>
            <Modal
                contentLabel="pr modal"
                isOpen={openPrModal}
                onRequestClose={() => setOpenPrModal(false)}
                style={customStyles}
            >
                <NewsModal
                    type="pr"
                    close={() => setOpenPrModal(false)}
                    news={prDetail}
                    convertDateToJapan={convertDateToJapan}
                    handleAgreeSendApiFirst={handleAgreeSendApiFirst}
                    handleRefuseSendApiFirst={handleRefuseSendApiFirst}
                    classNameCustom='w-full'
                    isAllowApproveDeny={isAllow}
                ></NewsModal>
            </Modal>
            {/* will open later this section */}
            {/* <CardWrapper title="アラート">
                <Box
                    display="flex"
                    paddingBottom="16px"
                    justifyContent="space-between"
                    alignItems="center"
                    borderBottom="1px solid #F5F5F5"
                    sx={{ fill: '#74777E' }}
                >
                    <Typography
                        fontSize="14px"
                        fontWeight="500"
                        color="#F85825"
                    >
                        🚨◯◯案件　△△工程2日遅れ
                    </Typography>
                    <NavigateNextIcon />
                </Box>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    paddingTop="16px"
                    sx={{ fill: '#74777E' }}
                >
                    <Typography
                        fontSize="14px"
                        fontWeight="500"
                        color="#F85825"
                    >
                        ⚠️◯◯案件　クレーム1件
                    </Typography>
                    <NavigateNextIcon />
                </Box>
            </CardWrapper> */}
            {/* <Box>
                <Typography
                    sx={{ marginBottom: '16px' }}
                    lineHeight="30px"
                    color="#1C1E21"
                    fontWeight={700}
                >
                    依頼・承認待ち
                </Typography>
                <TableContainer table-layout={'auto'} component={Paper}>
                    <Table>
                        <TableHead sx={{ background: '#C7DCF5' }}>
                            <TableRow
                                sx={{
                                    th: {
                                        padding: '18px',
                                        minWidth: '138.5px',
                                        textAlign: 'center',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                    },
                                }}
                            >
                                <TableCell>依頼者</TableCell>
                                <TableCell>登録日</TableCell>
                                <TableCell>期限</TableCell>
                                <TableCell>案件</TableCell>
                                <TableCell>内容</TableCell>
                                <TableCell>操作</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody
                            sx={{
                                td: {
                                    textAlign: 'center',
                                    fontWeight: 400,
                                    fontSize: '16px',
                                },
                            }}
                        >
                            {request?.map((row, index) => (
                                <TableRow key={row?.id}>
                                    <TableCell>
                                        {row?.by_worker?.name || ''}
                                    </TableCell>
                                    <TableCell>
                                        {moment(row?.created_at).format(
                                            'YYYY年M月D日',
                                        )}
                                    </TableCell>
                                    <TableCell>{row?.due_date}</TableCell>
                                    <TableCell>{row?.project?.name}</TableCell>
                                    <TableCell>{row?.content_type}</TableCell>
                                    <TableCell
                                        className="text-overflow"
                                        align="center"
                                    >
                                        <Button
                                            variant="contained"
                                            className="button-tb"
                                            sx={{ width: '72px', backgroundColor: '#215493' }}
                                        >
                                            確認
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box> */}
        </Box>
    );
};
export default DashboardSP;
