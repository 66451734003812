import { useState, useEffect, useRef, useMemo } from 'react';
import moment from 'moment';
import './style.css'
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Button, Grid } from '@mui/material';
import { buildChartData } from 'utils/helper/chartHelper';
import Dropdown from 'react-dropdown';
import { rangeDropdownOptions } from 'utils/helper/dropdownHelper';
import ChartButton from 'components/Button/ChartButton';

interface ChartNavProps {
    currentDatetime?: string | any
    changeRange?: (date: string | any) => void
}

const ChartNav = (props: ChartNavProps) => {
    const { currentDatetime, changeRange } = props
    const listYears = useMemo(() => rangeDropdownOptions(), [])
    const listMonths = useMemo(() => rangeDropdownOptions(1, 12, 1, true), [])

    const { register, control, handleSubmit, watch, reset, setError, setValue, getValues, formState: { errors } } = useForm({
        defaultValues: useMemo(() => {
            const year = currentDatetime.format('YYYY')
            const month = currentDatetime.format('MM')
            return {
                year: {
                    value: year, label: year
                },
                month: {
                    value: month, label: month
                }
            };
        }, [currentDatetime])
    });

    useEffect(() => {
        const subscription = watch((value, { name, type }) => {
            handleChangeYearMonth(value?.year?.value, value?.month?.value)
        });
        return () => subscription.unsubscribe();
    }, [watch]);

    const handleChangeYearMonth = (year, month) => {
        const newCount = moment(`${year}-${month}-${currentDatetime.format("DD")}`).format("YYYY-MM-DD");

        (changeRange || Function)(newCount)
    }

    const handleChangeRange = (count) => {
        const newCount = currentDatetime.add(count, 'weeks').format("YYYY-MM-DD");

        (changeRange || Function)(newCount)
    }

    return (
        <Grid item xs={12} className="chart-control-wrapper !justify-end">
            <ChartButton variant="text" startIcon={<KeyboardDoubleArrowLeftIcon />} className="chart-control-button-pre" onClick={() => handleChangeRange(-4)}>
                1ヶ月前
            </ChartButton>
            <ChartButton variant="text" startIcon={<KeyboardArrowLeftIcon />} className="chart-control-button-pre" onClick={() => handleChangeRange(-2)}>
                2週間前
            </ChartButton>
            <Controller
                name="year"
                control={control}
                render={({ field }) =>
                    <Dropdown
                        className='height36'
                        controlClassName='height36 items-center flex'
                        options={listYears}
                        {...register('year')}
                        {...field}
                        placeholder=""
                    />}
            />
            <span className="chart-text-year">年</span>
            <Controller
                name="month"
                control={control}
                render={({ field }) =>
                    <Dropdown
                        className='height36'
                        controlClassName='height36 items-center flex'
                        options={listMonths}
                        {...register('month')}
                        {...field}
                        placeholder=""
                    />}
            />
            <span className="chart-text-month">月</span>
            <ChartButton variant="text" endIcon={<KeyboardArrowRightIcon />} className="chart-control-button-next" onClick={() => handleChangeRange(2)}>
                2週間後
            </ChartButton>
            <ChartButton variant="text" endIcon={<KeyboardDoubleArrowRightIcon />} className="chart-control-button-next" onClick={() => handleChangeRange(4)}>
                1ヶ月後
            </ChartButton>
        </Grid>
    );
};

export default ChartNav;
