export const convertDateToJapan = (dateTime) => {
    let newDateTime = dateTime.replace(/ /g,"T")
    // console.log(newDateTime)
    const date = new Date(newDateTime)
    var lang = 'en';
    let year = date.toLocaleString(lang, {year:'numeric'});
    let month = date.getMonth() + 1;
    let day = date.toLocaleString(lang, {day:'numeric'});
    const textDate = `${year}年${month}月${day}日`
    return textDate
}