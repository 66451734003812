import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import PrivateMessageService from 'services/privateMessageService';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { uniqueId } from "lodash";

const useChatBoxPrivateMessage = (props: any) => {
    const {
        activeMessage,
        fetchChats,
        setActiveMessage,
        fetchHasUnreadChat,
        listMessage,
        setListMessage,
        setHistories,
    } = props;
    const { userData }: any = useSelector((state: RootState) => state.users);
    const [open, setOpen] = useState(false);
    const [imagePreview, setImagePreview] = useState();
    const [typePreview, setTypeReivew] = useState('image');
    const [isLoading, setIsLoading] = useState(false);
    const [listErrorFile, setListErrorFile] = useState<File[]>([]);
    const downloadImage = () => {
        typePreview === "image" ? saveAs(imagePreview, 'image.jpeg') : saveAs(imagePreview, 'video.mp4')
    };

    const onSubmit = async (response: any) => {
        setIsLoading(true);
        const { message, file } = response;

        if (!userData.worker) return;
        if (!message && file.length <= 0) return;
        if (listErrorFile?.length > 0) return;
        
        setListMessage((preState) => [
            ...preState,
            {
                body: message,
                images: file?.filter((f: { type: string | string[]; }) => f.type.includes('image/')),
                id: uniqueId(),
                publish_at: moment().zone('+9:00'),
                is_owner: true,
                sender: {
                    id: userData?.worker?.id || activeMessage?.workerId,
                    name: userData?.worker?.name,
                },
                videos: file?.filter((f: { type: string | string[]; }) => f.type.includes('video/')),
            },
        ]);
        setHistories((preState) => {
            const newArray = [...preState];
            const index = newArray.findIndex(
                (item) => item.id === activeMessage.id,
            );
            if (index !== -1) {
                newArray[index] = {
                    ...activeMessage,
                    last_message: message,
                    last_message_published_at: moment().zone('+9:00'),
                };
            }
            return newArray;
        });
        let formData = new FormData();
        formData.append(
            'chat_worker_id',
            activeMessage?.workers?.find(
                (worker) => worker.id !== userData.worker.id,
            )?.id || activeMessage?.workerId,
        );
        if (message) {
            formData.append('body', message ? message : '');
        }
        if (file.length > 0) {
            for (let x = 0; x < file.length; x++) {
                if(file[x].type.includes('video/')) {
                    formData.append('videos[]', file[x]!)
                } else {
                    formData.append('images[]', file[x]!);
                }   
            }
        }
        try {
            const { chat }: any = await PrivateMessageService.createDirectChats(
                formData,
            );

            if (!activeMessage?.id) {
                // fetchMessages(chat);
                fetchChats();
                setActiveMessage(chat);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            //
        } finally {
        }
    };

    const navigate = useNavigate();

    const removeImage = (idx, values, setFieldValue) => {
        let current = [...values.file];
        current = current.filter((item, index) => index !== idx);
        setFieldValue('file', current);
        let listError = current?.filter(f => f?.size > 10 * 1024 * 1024);
        setListErrorFile(listError);
    };

    const handleFileChange = async (e: any, values, setFieldValue) => {
        if (e.target.files.length) {
            const files: File[] = Array.from(e.target.files);
            let current: any = [...values.file];
            if (files.length > 0) {
                if (current.length > 0) {
                    current = current.concat(files);
                } else {
                    current = files;
                }
                setFieldValue('file', current);
            }
            let filesExceeds10MB = files.filter((f: { size: number; }) => f?.size > 10 * 1024 * 1024)
            setListErrorFile(filesExceeds10MB);
        }
    };

    return {
        ...props,
        navigate,
        removeImage,
        setImagePreview,
        setOpen,
        downloadImage,
        handleFileChange,
        listMessage,
        userData,
        activeMessage,
        open,
        onSubmit,
        isLoading,
        imagePreview,
        typePreview,
        setTypeReivew,
        listErrorFile
    };
};
export type Props = ReturnType<typeof useChatBoxPrivateMessage>;

export default useChatBoxPrivateMessage;
