import styled from 'styled-components';

export const StylesPR = styled.div`
    padding: 12px 16px;
    .job--title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 24px;
        line-height: normal;
    }
`;
