import { requiredOnly } from '../validator';

import { TypeOfT } from 'utils/type/translations/Translation.type';

const createBillValidation = (t: TypeOfT) => ({
    author_worker_id: () => ({
      required: requiredOnly(t),
    }),
    client_company_id: () => ({
      required: requiredOnly(t),
    }),
    submitted_at: () => ({
      required: requiredOnly(t),
    }),
    payment_deadline: () => ({
      required: requiredOnly(t),
    }),
    amount: () => ({
      required: requiredOnly(t),
    }),
    payment_amount: () => ({
      required: requiredOnly(t),
    }),
    payment_paid_at: () => ({
      required: requiredOnly(t),
    }),
});

export default createBillValidation