import styled from 'styled-components';

export const StylesCreateStockNotes = styled.div`
    margin: 28px;
    background-color: #fff;
    border-radius: 12px;
    padding: 32px 28px;
    .title {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 30px;
    }
    label {
        color: #344054;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
    }
    input, textarea {
        border: 1px solid #CDD1D5;
        height: 35px;
        border-radius: 4px;
        padding-left: 12px;
        outline: none;
    }
    textarea {
        min-height: 83px;
    }
`;
