import Api from './api/estimationApi';
import pick from 'lodash/pick';
// import planLists from '../shared/mocks/planLists.json';
import moment from 'moment';

const createEstimate = async (payload) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateEstimate = async (estimateId: number, payload: any) => {

    return await Api()
        .put(`${estimateId}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteEstimate = async (estimateId: number, project_id: number) => {
    return await Api()
        .delete(`${estimateId}?project_id=${project_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getDetailEstimate = async (estimateId: number, project_id: number) => {
    return await Api()
        .get(`${estimateId}?project_id=${project_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getListEstimation = async (project_id: number, input?: any) => {
    // return planLists;
    let data = pick(input, ['page', 'per_page']);
    if (project_id) {
      data.project_id = project_id;
    }
    const params = new URLSearchParams(data);
    return await Api()
        .get(`?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const estimateService = {
    createEstimate,
    updateEstimate,
    deleteEstimate,
    getDetailEstimate,
    getListEstimation,
};

export default estimateService;
