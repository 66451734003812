import fetch from './api/config';

const API = {
    INSPECTION_RECORDs: () => `/inspection_records`,
    SP_INSPECTION_RECORDs: () => `sp/inspection_records`,
    MACHINE_MODELS: () => `/machine_models`,
    MACHINE_MODEL_INSPECT_ITEM: () => `/machine_model_inspect_item`
};

interface IInspectionRecord {
    machine_model_inspect_item_id: number;
    inspection_status: string | undefined;
}
interface IBodyInspectionRecords {
    inspection_date: string;
    project_id: number | undefined;
    company_id: number | undefined;
    inspection_records: IInspectionRecord[]
}

interface IParamsInspectionRecordsSP {
    page: number;
    per_page: number;
    inspection_month?: number;
    inspection_year?: number;
    project_id?: number;
    inspection_day?: number;
}

interface IInspectionRecordUpdate {
    id: number;
    inspection_status: string;
}
interface IParamsInspectionRecordsUpdate {
    inspection_records: IInspectionRecordUpdate[]
}
export default class InspectionRecordService {
    static getListInspectionRecords = (params: { page: number; per_page: number }) =>
        fetch.get(API.INSPECTION_RECORDs(), params);
    static getMachineModels = (params: { page: number; per_page: number }) =>
        fetch.get(API.MACHINE_MODELS(), params);
    static getListInspectionRecordsSP = (params: IParamsInspectionRecordsSP) =>
        fetch.get(API.SP_INSPECTION_RECORDs(), params);
    static getListMachineModelInspectItems = (params: { machine_model_id: number }) =>
        fetch.get(API.MACHINE_MODEL_INSPECT_ITEM(), params);
    static createInspectionRecords = (params: IBodyInspectionRecords) =>
        fetch.post(API.SP_INSPECTION_RECORDs(), params);
    static updateInspectionRecords = (params: IParamsInspectionRecordsUpdate) =>
        fetch.put(API.SP_INSPECTION_RECORDs(), params);

}
