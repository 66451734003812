import { FC } from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ConfirmPopup from 'components/ComfirmPopup';

import ChatBox from '../ChatBox';
import { Main } from './style';
import { formatMoney } from 'utils/helper/helpers';
import useSuggestion, { Props } from './hook';

const SuggestionControlLayout: FC<Props> = ({
    isCreate,
    workerOptions,
    formik,
    data,
    ModalDelete,
    closeDelete,
    isOpenDelete,
    isOpenDropdown,
    setIsOpenDropdown
}) => {
    const navigate = useNavigate();

    return (
        <Main>
            <ModalDelete>
                <ConfirmPopup
                    closeDelete={closeDelete}
                    text="登録しました。"
                />
            </ModalDelete>
            <div className="suggestion-detail-content">
                <form onSubmit={formik.handleSubmit}>
                    <div className="form-suggestion-detail">
                        <div className="input-suggestion d-flex">
                            <p className="d-flex">宛先</p>
                            <div className="d-flex content-right">
                                <Autocomplete
                                    open={isOpenDropdown}
                                    onOpen={() => setIsOpenDropdown(true)}
                                    onClose={()=> setIsOpenDropdown(false)}
                                    onFocus={() => setIsOpenDropdown(true)}
                                    value={formik.values.to_worker_id}
                                    onChange={(event, newValue) => {
                                        formik.setFieldValue(
                                            'to_worker_id',
                                            newValue,
                                        );
                                    }}
                                    classes={{
                                        root: 'search-select-style',
                                        input: 'search-select-style',
                                    }}
                                    noOptionsText="該当なし"
                                    options={workerOptions || []}
                                    getOptionLabel={(option: any) =>
                                        option.name
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                                {formik.touched.to_worker_id &&
                                    formik.errors.to_worker_id && (
                                        <span className="error">
                                            {formik.errors.to_worker_id}
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className="input-suggestion d-flex">
                            <p className="d-flex">タイトル</p>
                            <div className="d-flex content-right">
                                <input
                                    autoComplete="off"
                                    type="text"
                                    value={String(formik.values.title)}
                                    className="input-text height36"
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                    onChange={(e: any) =>
                                        formik.setFieldValue(
                                            'title',
                                            e.target.value,
                                        )
                                    }
                                />
                                {formik.touched.title &&
                                    formik.errors.title && (
                                        <span className="error">
                                            {formik.errors.title}
                                        </span>
                                    )}
                            </div>
                        </div>

                        <div className="input-suggestion d-flex">
                            <p className="d-flex">貸与金額</p>
                            <div className="d-flex content-right">
                                <span className="character">￥</span>
                                <input
                                    autoComplete="off"
                                    type="text"
                                    className="input-number height36"
                                    value={formatMoney(
                                        String(formik.values.amount || ''),
                                    )}
                                    onKeyDown={(e: any) => {
                                        formik.setFieldValue(
                                            'amount',
                                            e.target.value.replace(
                                                /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                '$1,',
                                            ),
                                        );
                                    }}
                                    onKeyPress={(e) => {
                                        e.key === 'Enter' && e.preventDefault();
                                    }}
                                    onChange={(e: any) => {
                                        formik.setFieldValue(
                                            'amount',
                                            e.target.value?.replace(/\D+/g, ''),
                                        );
                                    }}
                                />
                                {formik.touched.amount &&
                                    formik.errors.amount && (
                                        <span className="error">
                                            {formik.errors.amount}
                                        </span>
                                    )}
                            </div>
                        </div>

                        {!isCreate && (
                            <div className="input-suggestion d-flex">
                                <p className="d-flex">返済金額</p>
                                <div className="d-flex content-right">
                                    <span className="character">￥</span>
                                    <input
                                        autoComplete="off"
                                        type="text"
                                        value={formatMoney(
                                            String(
                                                formik.values
                                                    .repayment_amount || '',
                                            ),
                                        )}
                                        onKeyPress={(e) => {
                                            e.key === 'Enter' &&
                                                e.preventDefault();
                                        }}
                                        onKeyDown={(e: any) => {
                                            formik.setFieldValue(
                                                'repayment_amount',
                                                e.target.value.replace(
                                                    /(\d)(?=(\d\d\d)+(?!\d))/g,
                                                    '$1,',
                                                ),
                                            );
                                        }}
                                        name="repayment_amount"
                                        className="input-number height36"
                                        onChange={(e: any) => {
                                            formik.setFieldValue(
                                                'repayment_amount',
                                                e.target.value?.replace(
                                                    /\D+/g,
                                                    '',
                                                ),
                                            );
                                        }}
                                    />
                                    {formik.touched.repayment_amount &&
                                        formik.errors.repayment_amount && (
                                            <span className="error">
                                                {formik.errors.repayment_amount}
                                            </span>
                                        )}
                                </div>
                            </div>
                        )}
                    </div>

                    {!isCreate && <ChatBox suggestion={data} />}

                    <div className="group-buttons d-flex flex-row">
                        <Button
                            className="bnt bnt-cancel d-flex flex-row"
                            onClick={() => navigate('/suggestions')}
                            type="button"
                        >
                            戻る
                        </Button>
                        <Button
                            className="bnt bnt-submit d-flex flex-row"
                            type="submit"
                        >
                            登録
                        </Button>
                    </div>
                </form>
            </div>
        </Main>
    );
};

const SuggestionControl: FC = () => (
    <SuggestionControlLayout {...useSuggestion()} />
);

export default SuggestionControl;
