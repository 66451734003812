import { useEffect } from "react";
import moment from "moment";
import { useFormik } from "formik";
import Dropdown from "react-dropdown";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { Link, useSearchParams, useNavigate } from "react-router-dom";

import { Button } from "@mui/material";

import calendarIcon from "assets/icons/calendar.png";

import { validate } from "./validation";
import { Main } from "./style";

const FormEdit = ({
  id,
  loading,
  project,
  status,
  estimate,
  workers,
  submitForm,
}) => {
  const { control } = useForm();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: 0,
      name: "",
      status: {
        value: "",
        label: "",
      },
      status_name: "",
      assigned_worker: {
        value: 0,
        label: "",
      },
      approver_worker: {
        value: 0,
        label: "",
      },
      approve_expired_at: "",
      isGotoEdit: false,
    },
    validate,
    onSubmit: (values) => {
      submitForm(values);
    },
  });

  useEffect(() => {
    if (estimate?.id) {
      const value = { ...estimate };

      if (value.approver_worker) {
        value.approver_worker = {
          ...value.approver_worker,
          label: value?.approver_worker?.name,
          value: value?.approver_worker?.id,
        };
      }
      if (value.assigned_worker) {
        value.assigned_worker = {
          ...value.assigned_worker,
          label: value?.assigned_worker?.name,
          value: value?.assigned_worker?.id,
        };
      }
      if (value.status) {
        const result = status.find((resl) => resl.value === value.status);
        // value.status_name = result?.label;
        value.status = {
          value: result?.value,
          label: result?.label,
        };
      }
      if (value.approve_expired_at) {
        value.approve_expired_at = new Date(
          moment(value.approve_expired_at, "YYYY-MM-DD").toString()
        );
      }

      formik.setValues({
        ...value,
      });
    }
  }, [estimate]);

  return (
    <Main>
      <form
        onSubmit={formik.handleSubmit}
        className="form-edit-estimation d-flex flex-column"
      >
        <div className="group-buttons d-flex flex-row justify-between mb-7">
          <div className="form-edit-estimation-title">
            {searchParams.get("type") ? "見積詳細" : "見積編集"}
          </div>
          <div className="group-buttons d-flex flex-row mt-0 justify-end">
            <Button
              className="bnt bnt-cancel d-flex flex-row"
              onClick={() => navigate(-1)}
            >
              戻る
            </Button>
            {searchParams.get("type") != "detail" && (
              <Button
                className="bnt bnt-submit d-flex flex-row"
                type="submit"
                disabled={loading || searchParams.get("type") == "detail"}
              >
                登録
              </Button>
            )}
          </div>
        </div>

        <div className="form-create-estimation-form d-flex flex-column">
          <div className="input-add-estimation d-flex">
            <p className="d-flex">案件名</p>
            <div className="d-flex content-right">{project?.name}</div>
          </div>
          <div className="input-add-estimation d-flex">
            <p className="d-flex">見積担当</p>
            <div className="d-flex content-right">
              <Controller
                control={control}
                name="assigned_worker"
                render={() => (
                  <Dropdown
                    disabled={loading || searchParams.get("type") == "detail"}
                    className="modal-dropdown-create-estimation height36"
                    controlClassName="dropdown-control-plan height36"
                    options={workers}
                    value={String(formik?.values?.assigned_worker?.label)}
                    onChange={(value: any) =>
                      formik.setFieldValue("assigned_worker", { ...value })
                    }
                    placeholder=""
                  />
                )}
              />
              {formik.touched.assigned_worker &&
                formik.errors.assigned_worker && (
                  <span className="error ml-3">
                    {String(formik.errors.assigned_worker)}
                  </span>
                )}
            </div>
          </div>
          <div className="input-add-estimation d-flex">
            <p className="d-flex">状態</p>
            <div className="d-flex content-right">
              {/* {formik.values?.status_name} */}
              <Controller
                control={control}
                name="status"
                render={() => (
                  <Dropdown
                    disabled={loading || searchParams.get("type") == "detail"}
                    className="modal-dropdown-create-estimation height36"
                    controlClassName="dropdown-control-plan height36"
                    options={status}
                    value={formik.values.status}
                    onChange={(value: any) =>
                      formik.setFieldValue("status", {
                        ...value,
                      })
                    }
                    placeholder=""
                  />
                )}
              />
            </div>
          </div>
          <div className="input-add-estimation d-flex">
            <p className="d-flex">承認担当</p>
            <div className="d-flex content-right">
              <Controller
                control={control}
                name="approver_worker"
                render={() => (
                  <Dropdown
                    disabled={loading || searchParams.get("type") == "detail"}
                    className="modal-dropdown-create-estimation height36"
                    controlClassName="dropdown-control-plan height36"
                    options={workers}
                    value={String(formik?.values?.approver_worker?.label)}
                    onChange={(value: any) =>
                      formik.setFieldValue("approver_worker", { ...value })
                    }
                    placeholder=""
                  />
                )}
              />
              {formik.touched.approver_worker &&
                formik.errors.approver_worker && (
                  <span className="error ml-3">
                    {String(formik.errors.approver_worker)}
                  </span>
                )}
            </div>
          </div>
          <div className="input-add-estimation d-flex">
            <p className="d-flex">承認期限</p>
            <div className="content-right d-flex">
              <div className="datepicker-wrap">
                <div className="target-datepicker-wrap d-flex flex-row">
                  <div className="input-date" style={{ position: "relative" }}>
                    <Controller
                      control={control}
                      name="approve_expired_at"
                      render={() => (
                        <DatePicker
                          selectsStart
                          dateFormat="yyyy-MM-dd"
                          autoComplete="off"
                          locale="ja"
                          selected={formik.values.approve_expired_at}
                          startDate={formik.values.approve_expired_at}
                          onChange={(date) => {
                            formik.setFieldValue("approve_expired_at", date);
                          }}
                          disabled={
                            loading || searchParams.get("type") == "detail"
                          }
                        />
                      )}
                    />
                    <img
                      className="input-date-img"
                      width={"16px"}
                      height={"16px"}
                      src={calendarIcon}
                    ></img>
                  </div>
                </div>
              </div>
              {formik.touched.approve_expired_at &&
                formik.errors.approve_expired_at && (
                  <span className="error ml-3">
                    {String(formik.errors.approve_expired_at)}
                  </span>
                )}
            </div>
          </div>
        </div>
      </form>
    </Main>
  );
};

export default FormEdit;
