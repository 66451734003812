import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReceivedProps } from './type';
import StockNotesService from 'services/stockNotesService';
import { IStockNote } from '../type';

const useSPStockNotes = (props: ReceivedProps) => {
    const [stockNotesList, setStockNotesList] = useState<IStockNote[]>([]);
    const [currentData, setCurrenData] = useState<IStockNote>();
    const [pdfData, setPdfData] = useState<string | null>(null);

    const navigate = useNavigate();
    const handleShare = async (values: IStockNote) => {
        const response = await fetch(values?.file_url);
        const data = await response.blob();
        const metadata = {
            type: 'application/pdf',
        };
        const file = new File([data], values.name, metadata);
        const files = [file];
        if (navigator.share) await navigator.share({ files });
    };

    const fetchListStockNotes = async (params = {}) => {
        try {
            const res =
                await StockNotesService.getListStockNotes({
                    page: 1,
                    per_page: 0,
                    ...params
                });
                if(!(res as {stock_notes: IStockNote[]})?.stock_notes) return;
                const stock_notes = (res as {stock_notes: IStockNote[]})?.stock_notes;
                setStockNotesList(stock_notes);
        } catch (error) {
            console.log(error);
        }
    };

    const handleViewPdf = async (values: IStockNote) => {
        try {
            setCurrenData(values);
            const response = await fetch(values?.file_url);
            const data = await response.blob();
            const file = window.URL.createObjectURL(data);
            setPdfData(file);
        } catch (error) {}
    };

    useEffect(() => {
        fetchListStockNotes();
    }, []);
    return {
        ...props,
        stockNotesList,
        pdfData,
        currentData,
        navigate,
        handleShare,
        handleViewPdf,
        setPdfData,
    };
};
export type Props = ReturnType<typeof useSPStockNotes>;

export default useSPStockNotes;
