import { FC } from 'react';
import { StylesNews } from './styled';
import { Button, Box, Grid } from '@mui/material';
import useNews, { ReceivedProps, Props } from './hook';
import ViewIcon from 'assets/icons/view_ic.svg';
import moment from 'moment';

const NewsLayout: FC<Props> = ({
    data,
    navigate,
    setSelectedNew,
    ModalNew,
    openNew,
    closeNew,
    selectedNew,
}) => {
    return (
        <StylesNews>
            <ModalNew>
                <Box
                    sx={{
                        backgroundColor: '#fff',
                        margin: '0px 16px',
                        borderRadius: '4px',
                        border: '1px solid rgb(204, 204, 204)',
                        padding: '16px',
                        maxHeight: '80vh',
                        overflow: 'auto',
                        overscrollBehavior: 'contain',
                    }}
                >
                    <p className="text-[14px] font-[500]">
                        {moment(selectedNew?.publish_at)?.format(
                            'YYYY年M月D日',
                        )}
                    </p>
                    <p className="text-[24px] text-[#448CDF] leading-[32px] font-bold">
                        {selectedNew?.title}
                    </p>
                    <p className="text-[14px] leading-[24px] text-[rgba(0,0,0,.7)]">
                        {selectedNew?.content}
                    </p>
                    <button
                        onClick={() => closeNew()}
                        className="modal-cancel-news d-flex flex-row"
                    >
                        キャンセル
                    </button>
                </Box>
            </ModalNew>
            <p className="job--title">お知らせ</p>
            <Box sx={{ marginTop: '26px' }}>
                <Box
                    sx={{
                        border: '1px solid #CDD1D5 ',
                        '.MuiGrid-container': {
                            borderBottom: '1px solid #F5F5F5',
                        },
                    }}
                >
                    <Grid
                        container
                        sx={{
                            backgroundColor: '#C7DCF5',
                            padding: '14px 17px',
                            fontWeight: 'bold',
                        }}
                    >
                        <Grid item xs={3}>
                            タイトル
                        </Grid>
                        <Grid padding="0px 10px" item xs={8}>
                            本文
                        </Grid>
                    </Grid>
                    {data?.map((job) => (
                        <Grid
                            key={job.id}
                            sx={{ padding: '14px 17px' }}
                            container
                        >
                            <Grid className="truncate" xs={3} item>
                                {job?.title}
                            </Grid>
                            <Grid
                                padding="0px 10px"
                                className="truncate"
                                xs={8}
                                item
                            >
                                {job?.content}
                            </Grid>
                            <Grid
                                display="flex"
                                justifyContent="end"
                                alignItems="center"
                                xs={1}
                                item
                            >
                                <Box
                                    onClick={() => {
                                        setSelectedNew(job);
                                        openNew();
                                    }}
                                    sx={{
                                        backgroundColor: '#215493',
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={ViewIcon} />
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
                <Button
                    onClick={() => navigate(`/`)}
                    sx={{
                        color: '#215493',
                        mt: '42px',
                        width: '166px',
                    }}
                    variant="outlined"
                >
                    戻る
                </Button>
            </Box>
        </StylesNews>
    );
};

const News: FC<ReceivedProps> = (props) => {
    return <NewsLayout {...useNews(props)} />;
};

export default News;
