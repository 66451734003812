import { FC } from 'react';
import moment from 'moment';

import useDayList, { Props, ReceivedProps } from './hook';
import { useNavigate } from 'react-router-dom';

const renderDateToUI = (date) => {
    if (!date) {
        return '';
    }

    return `${moment(date).format('YYYY')}年${moment(date).format(
        'MM',
    )}月${moment(date).format('D')}日`;
};

const DayListLayout: FC<Props> = ({ localList, setOpenScheduleFormDialog }) => {
    const navigate = useNavigate();

    return (
        <div className="dayListView">
            {localList.map((item) => (
                <div key={item.id}>
                    {item.customTitle && (
                        <h3 className="viewTitle">
                            {renderDateToUI(item.customTitle)}
                        </h3>
                    )}

                    {item.title && (
                        <button
                            type="button"
                            className="viewItem"
                            onClick={() => {
                                navigate(`/sp/calendar`, {
                                    state: {
                                        editingId: item.id,
                                    },
                                });
                                setOpenScheduleFormDialog(true);
                            }}
                        >
                            <span className="itemLabel">
                                {moment(item.startDate).format('HH:mm')} -{' '}
                                {moment(item.endDate).format('HH:mm')}
                            </span>
                            <span className="itemTitle">{item.title}</span>
                        </button>
                    )}
                </div>
            ))}
        </div>
    );
};

const DayList: FC<ReceivedProps> = (props) => (
    <DayListLayout {...useDayList(props)} />
);

export default DayList;
