import { FC } from "react";

type IProps = {
  strokeColor?: string;
};

const ListBullets: FC<IProps> = ({ strokeColor = '#215493' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.5 6H20.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.5 12H20.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.5 18H20.5" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M4.375 7.125C4.99632 7.125 5.5 6.62132 5.5 6C5.5 5.37868 4.99632 4.875 4.375 4.875C3.75368 4.875 3.25 5.37868 3.25 6C3.25 6.62132 3.75368 7.125 4.375 7.125Z" fill={strokeColor} />
      <path d="M4.375 13.125C4.99632 13.125 5.5 12.6213 5.5 12C5.5 11.3787 4.99632 10.875 4.375 10.875C3.75368 10.875 3.25 11.3787 3.25 12C3.25 12.6213 3.75368 13.125 4.375 13.125Z" fill={strokeColor} />
      <path d="M4.375 19.125C4.99632 19.125 5.5 18.6213 5.5 18C5.5 17.3787 4.99632 16.875 4.375 16.875C3.75368 16.875 3.25 17.3787 3.25 18C3.25 18.6213 3.75368 19.125 4.375 19.125Z" fill={strokeColor} />
    </svg>
  )
}

export default ListBullets