import { StylesCreateVehicleInspection } from './styled';
import { ReceivedProps } from '../type';
import { Button } from '@mui/material';
import useCreateVehicleInspection, { Props } from './hook';
import uploadSimple from 'assets/icons/UploadSimple.svg';

const CreateVehicleInspectionLayout = ({
    fileUpload,
    handleFileUpload,
    navigate,
    handleCreateVehicleInspection,
    errorMsg,
}: Props) => {
    return (
        <StylesCreateVehicleInspection>
            <p className="create-vehicle-inspection-title">
                車両点検簿新規登録
            </p>
            <p className='label'>ファイル</p>
            <Button
                component="label"
                className="uploadImageFileBtn"
                style={{
                    backgroundColor: '#215493',
                    color: '#FFFFFF',
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '24px',
                    padding: '10px 18px',
                    height: '36px',
                    borderRadius: '6px',
                }}
            >
                <img
                    src={uploadSimple}
                    alt="upload icon"
                    style={{ marginRight: '7px' }}
                />
                <span>ファイルアップロード</span>
                <input
                    type="file"
                    hidden
                    style={{
                        display: 'none',
                    }}
                    accept="application/pdf"
                    onChange={(e) => handleFileUpload(e.target.files)}
                />
            </Button>
            <p className="pt-2 mb-0">{fileUpload?.name}</p>
            {errorMsg && <p className="pt-2 mb-0 text-red">{errorMsg}</p>}
            <div className="mt-10">
                <Button
                    onClick={() => navigate('/vehicle-inspection')}
                    sx={{
                        padding: '6px 18px',
                        height: '44.5px',
                        width: '166.5px',
                        borderColor: 'rgba(33, 84, 147, 0.30)',
                        color: '#215493',
                        borderRadius: '8px',
                        marginRight: '4px',
                    }}
                    variant="outlined"
                >
                    戻る
                </Button>
                <Button
                    onClick={handleCreateVehicleInspection}
                    sx={{
                        padding: '6px 18px',
                        width: '166.5px',
                        marginLeft: '4px',
                        backgroundColor: '#215493',
                        borderColor: '#215493',
                        color: '#fff',
                        height: '44.5px',
                        borderRadius: '8px',
                    }}
                    variant="contained"
                >
                    登録
                </Button>
            </div>
        </StylesCreateVehicleInspection>
    );
};

const CreateVehicleInspection = (props: ReceivedProps) => {
    return (
        <CreateVehicleInspectionLayout {...useCreateVehicleInspection(props)} />
    );
};

export default CreateVehicleInspection;
