export type ReceivedProps = {
    status: string;
    id: number;
    handleChange: (id: number, status: string) => void;
};

const useTargetStatus = (props: ReceivedProps) => {
    return {
        ...props,
    };
};

export type Props = ReturnType<typeof useTargetStatus>;

export default useTargetStatus;
