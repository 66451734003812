import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { ReceivedProps, DataType } from './type';
import { useNavigate } from 'react-router-dom';
import VehicleInspectionService from 'services/vehicleInspectionService';
import * as yup from 'yup';
import InternalOJTService from 'services/internalOJTService';

export const validation = yup.object().shape({
    name: yup.string().required('必須項目です'),
});

const useInternalOJT = (props: ReceivedProps) => {
    const [deleteData, setDeleteData] = useState<DataType>({
        id: 1,
        name: '',
        created_at: '',
        file_url: '',
        updated_at: '',
    });
    const [internalOJTLists, setInternalOJTLists] = useState<any[]>([]);
    const [refreshList, setRefreshList] = useState<boolean>(false);

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: '',
        },
        onSubmit: (values) => fetchListInternalOJT(values),
    });

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: true,
        },
    );

    const fetchListInternalOJT = async (params: any = {}) => {
        try {
            const { internal_ojts }: any =
                await InternalOJTService.getInternalOJTLists({
                    page: 1,
                    per_page: 0,
                    keyword: params?.name || '',
                });
            setInternalOJTLists(internal_ojts);
        } catch (error) {
            //
        }
    };

    const handleOpenDeleleModal = (data) => {
        setDeleteData(data);
        openDelete();
    };

    const handleDelete = async () => {
        try {
            await InternalOJTService.deleteInternalOJT(deleteData.id);
            closeDelete();
            setRefreshList((preState) => !preState);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchListInternalOJT();
    }, [refreshList]);

    return {
        ...props,
        formik,
        ModalDelete,
        isOpenDelete,
        internalOJTLists,
        openDelete,
        closeDelete,
        handleOpenDeleleModal,
        navigate,
        handleDelete,
    };
};
export type Props = ReturnType<typeof useInternalOJT>;

export default useInternalOJT;
