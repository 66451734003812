import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    TextField,
    CardMedia,
} from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { useForm } from 'react-hook-form';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import { Popover } from 'antd';
import { IPageQueries } from 'models';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import { StyledUserListTable } from './styles';
import { ReceivedProps } from './type';
import useInspectionRecord, { Props } from './hook';
import { StylesStockNotes } from './styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: '#1C1E21',
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
        border: '1px solid #00000026',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '4px 17.9px',
        borderRight: '1px solid #00000026',
    },
}));

const InspectionRecordsLayout = ({
    machineModels,
    // inspectionRecords,
    // setInspectionRecords,
    // machineModelInspectItems,
    // setMachineModelInspectItems,
    projectsList,
    valueProject,
    setValueProject,
    valueDate,
    setValueDate,
    // valueMachineModel,
    companyList,
    machineModelInspectItems,
    setCompanyList,
    // onSubmit,
    fetchMachineModelInspectItem,
    fetchInspectionRecords,
    workerList,
    setValueCompany,
    setValueWorker,
    setValueMachineModel,
    inspectionRecords,
    listDataRender,
    modelImg,
    setModelImg
}: Props) => {
    const ref = useRef<HTMLTableCellElement>(null);
    const ref2 = useRef<HTMLTableCellElement>(null);
    const [width, setWidth] = useState(0);
    const [widthSecondCell, setWidthSecondCell] = useState(0);
    const [searchedState, setSearchedState] = useState({});
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });
    const today = new Date();
    const [month, setMonth] = useState(today.getMonth() + 1);
    const [year, setYear] = useState(today.getFullYear());
    const {
        register,
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const numDaysOfMonth = new Date(year, month, 0).getDate();
    const listDays: number[] = [];
    for (let i = 1; i <= numDaysOfMonth; i++) {
        listDays.push(i);
    }

    const inspectionDateToRecordMap = useMemo(() => {
        const map = {};
        inspectionRecords.forEach((record) => {
            const recordDate = new Date(record.inspection_date).toDateString();
            if (!map[`${recordDate}-${record.machine_model_inspect_item_id}`]) {
                map[`${recordDate}-${record.machine_model_inspect_item_id}`] =
                    record;
            }
        });

        return map;
    }, [inspectionRecords]);

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (!date) { setValueDate('') ;return; }
        setValueDate(date.toString());
        setMonth(date?.month());
        setYear(date?.year());
    };

    const onSearch = (data) => {
        setSearchedState({ ...data });
        if (pageQueries.page !== 1) {
            setPageQueries((prevState) => ({ ...prevState, page: 1 }));
        }
        //getListProjects({
        //  page: 1,
        //  per_page: pageQueries.per_page,
        //  data: data,
        //});
    };
    useLayoutEffect(() => {
        if (!ref.current) return;
        if (!ref2.current) return;
        if (listDataRender?.length > 0) {
            setWidth(ref.current.offsetWidth);
            setWidthSecondCell(ref2.current.offsetWidth);
        }
    }, [listDataRender]);
    return (
        <StylesStockNotes>
            <p className="vehicle-title">車両系建設機械始業前点検表</p>
            <StyledUserListTable>
                <form
                    onSubmit={handleSubmit(onSearch)}
                    className="bg-[#ffffff]"
                >
                    <div
                        className="search-workers d-flex flex-column"
                        style={{ height: '200px' }}
                    >
                        <div className="w-full d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>現場名</p>
                                    <Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={projectsList || []}
                                        popupIcon={<ExpandMoreOutlinedIcon />}
                                        getOptionLabel={(option) => option.name}
                                        value={valueProject}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setValueProject(newValue);
                                            }
                                        }}
                                        classes={{
                                            root: 'project-list-search-select-style',
                                            input: 'project-list-search-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                className="autocomplete-custom-textfield"
                                            />
                                        )}
                                        noOptionsText="該当なし"
                                        clearIcon={
                                            <CloseIcon
                                                className='!text-[14px]'
                                                onClick={() => {
                                                    setValueProject(null);
                                                }}
                                            >
                                            </CloseIcon>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>点検月</p>
                                    <DatePicker
                                        placeholder=""
                                        className="w-full !rounded-[8px] !py-[5px] !px-[12px]"
                                        onChange={onChange}
                                        picker="month"
                                        locale={locale}

                                        suffixIcon={<ExpandMoreOutlinedIcon />}
                                        clearIcon={
                                            <CloseIcon
                                                className='!text-[14px] !ml-[-40px]'
                                                onClick={() => {
                                                    setValueDate('');
                                                }}
                                            >
                                            </CloseIcon>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: 0 }}>機種名</p>
                                    <Autocomplete
                                        disablePortal
                                        popupIcon={<ExpandMoreOutlinedIcon />}
                                        id="combo-box-demo"
                                        // defaultValue={null}
                                        options={machineModels || []}
                                        getOptionLabel={(option) =>
                                            option?.machine_name || ''
                                        }
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                if (valueDate && valueProject)
                                                    fetchMachineModelInspectItem(
                                                        newValue.id,
                                                    );
                                                setValueMachineModel(newValue);
                                            }
                                        }}
                                        classes={{
                                            root: 'project-list-search-select-style',
                                            input: 'project-list-search-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                className="autocomplete-custom-textfield"
                                            />
                                        )}
                                        noOptionsText="該当なし"
                                        clearIcon={
                                            <CloseIcon
                                                className='!text-[14px]'
                                                onClick={() => {
                                                    setValueMachineModel(
                                                        undefined,
                                                    );
                                                    setModelImg('')
                                                }}
                                            >

                                            </CloseIcon>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full d-flex flex-row">
                            <div className="search-workers-row-dropdown d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: '8px' }}>整理番号</p>
                                    <TextField
                                        autoComplete="off"
                                        id="input-search"
                                        type="text"
                                        name="name"
                                        fullWidth
                                        //onChange={(e) => {
                                        //  formik.setFieldValue(
                                        //    'name',
                                        //    e.target.value,
                                        //  );
                                        //}}
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: '8px' }}>会社名</p>
                                    <Autocomplete
                                        disablePortal
                                        popupIcon={<ExpandMoreOutlinedIcon />}
                                        id="combo-box-demo"
                                        options={companyList || []}
                                        getOptionLabel={(option: any) =>
                                            option.company?.name || ''
                                        }
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setValueCompany(newValue);
                                            }
                                        }}
                                        classes={{
                                            root: 'project-list-search-select-style',
                                            input: 'project-list-search-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                className="autocomplete-custom-textfield"
                                            />
                                        )}
                                        noOptionsText="該当なし"
                                        clearIcon={
                                            <CloseIcon
                                                className='!text-[14px]'
                                                onClick={() => {
                                                    setValueCompany(null);
                                                }}
                                            >
                                            </CloseIcon>
                                        }
                                    />
                                </div>
                            </div>
                            <div className="search-workers-row-dropdown margin-left d-flex flex-column">
                                <div className="input-add-user d-flex flex-column">
                                    <p style={{ marginTop: '8px' }}>使用者</p>
                                    <Autocomplete
                                        disablePortal
                                        popupIcon={<ExpandMoreOutlinedIcon />}
                                        id="combo-box-demo"
                                        options={workerList || []}
                                        getOptionLabel={(option: any) =>
                                            option.name || ''
                                        }
                                        onChange={(event, newValue: any) => {
                                            if (newValue) {
                                                setValueWorker(newValue);
                                            }
                                        }}
                                        classes={{
                                            root: 'project-list-search-select-style',
                                            input: 'project-list-search-select-style',
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label=""
                                                className="autocomplete-custom-textfield"
                                            />
                                        )}
                                        noOptionsText="該当なし"
                                        clearIcon={
                                            <CloseIcon
                                                className='!text-[14px]'
                                                onClick={() => {
                                                    setValueWorker(undefined);
                                                }}
                                            >
                                            </CloseIcon>
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                {modelImg ? (
                    <div style={{ marginBottom: '16px', marginTop: '-60px', maxWidth: "148px", maxHeight: "169px"  }}>
                        <CardMedia
                            component="img"
                            alt="Model image"
                            height="140"
                            image={modelImg}
                        />
                    </div>
                ) : null}
                <TableContainer component={Paper}>
                    <Table
                        sx={{ minWidth: 700 }}
                        style={{
                            whiteSpace: 'nowrap',
                            borderCollapse: 'separate',
                            borderSpacing: 0,
                        }}
                        aria-label="customized table"
                    >
                        <TableHead>
                            <TableRow>
                                <StyledTableCell
                                    colSpan={3}
                                    className="text-overflow classSticky"
                                    align="center"
                                    style={{
                                        paddingRight: '2px',
                                        boxShadow: '2px 0px 7px 0px #0000001A',
                                    }}
                                >
                                    {' '}
                                    点検項目
                                </StyledTableCell>
                                {listDays?.map((day) => (
                                    <StyledTableCell
                                        style={{ borderLeft: 'none' }}
                                        className={`text-overflow block min-w-[41.02px] !px-[0px] max-w-[41.02px]`}
                                        align="center"
                                    >
                                        {day}
                                    </StyledTableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listDataRender?.map((vehicle, indexVehicle) => (
                                <>
                                    <TableRow>
                                        <StyledTableCell
                                            className="classSticky"
                                            ref={ref}
                                            rowSpan={vehicle.rowSpan}
                                        >
                                            {vehicle?.category}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            ref={ref2}
                                            className="classSticky"
                                            style={{ left: `${width}px` }}
                                        >
                                            {vehicle.items[0].ind}
                                        </StyledTableCell>
                                        <StyledTableCell
                                            className="classSticky"
                                            style={{
                                                left: `${
                                                    width + widthSecondCell
                                                }px`,
                                                boxShadow:
                                                    '2px 0px 7px 0px #0000001A',
                                            }}
                                        >
                                            {vehicle.items[0].title}
                                        </StyledTableCell>
                                        {listDays?.map((day) => {
                                            const date = new Date(
                                                year,
                                                month,
                                                day,
                                            ).toDateString(); // Convert to a simple date string
                                            const matchingRecord =
                                                inspectionDateToRecordMap[
                                                    `${date}-${vehicle.items[0].machine_model_inspect_item_id}`
                                                ];

                                            return (
                                                <Popover
                                                    overlayClassName="!pt-[0px]"
                                                    placement="bottom"
                                                    title={
                                                        matchingRecord
                                                            ?.inspected_worker
                                                            ?.name || ''
                                                    }
                                                    content={
                                                        matchingRecord ? new Date(matchingRecord.updated_at).toLocaleString('en-US', { timeZone: 'Asia/Tokyo' }): null
                                                    }
                                                    trigger="hover"
                                                >
                                                    <StyledTableCell
                                                        className="text-overflow cursor-pointer block max-w-[41.02px] !px-[0px]"
                                                        align="center"
                                                    >
                                                        {matchingRecord?.inspection_status ===
                                                        'good' ? (
                                                            <CheckIcon className="w-[20px] h-[20px] cursor-pointer" />
                                                        ) : matchingRecord?.inspection_status ===
                                                          'maintenance_required' ? (
                                                            <CloseIcon className="w-[20px] h-[20px] cursor-pointer" />
                                                        ) : matchingRecord?.inspection_status ===
                                                          'maintenance' ? (
                                                            <PanoramaFishEyeIcon className="w-[20px] h-[20px] cursor-pointer" />
                                                        ) : undefined}
                                                    </StyledTableCell>
                                                </Popover>
                                            );
                                        })}
                                    </TableRow>
                                    {vehicle?.items?.map((item, indexItem) => {
                                        if (indexItem) {
                                            return (
                                                <TableRow>
                                                    <StyledTableCell
                                                        className="classSticky"
                                                        style={{
                                                            left: `${width}px`,
                                                        }}
                                                    >
                                                        {item.ind}
                                                    </StyledTableCell>
                                                    <StyledTableCell
                                                        className="classSticky"
                                                        style={{
                                                            left: `${
                                                                width +
                                                                widthSecondCell
                                                            }px`,
                                                            boxShadow:
                                                                '2px 0px 7px 0px #0000001A',
                                                        }}
                                                    >
                                                        {item.title}
                                                    </StyledTableCell>
                                                    {listDays?.map((day) => {
                                                        const date = new Date(
                                                            year,
                                                            month,
                                                            day,
                                                        ).toDateString(); // Convert to a simple date string
                                                        const matchingRecord =
                                                            inspectionDateToRecordMap[
                                                                `${date}-${item.machine_model_inspect_item_id}`
                                                            ];

                                                        return (
                                                            <Popover
                                                                overlayClassName="!pt-[0px] !pb-[0px]"
                                                                placement="bottom"
                                                                title={
                                                                    matchingRecord
                                                                        ?.inspected_worker
                                                                        ?.name ||
                                                                    ''
                                                                }
                                                                content={
                                                                  matchingRecord ? new Date(matchingRecord.updated_at).toLocaleString('en-US', { timeZone: 'Asia/Tokyo' }): null
                                                                }
                                                                trigger="hover"
                                                            >
                                                                <StyledTableCell
                                                                    className="text-overflow cursor-pointer block max-w-[41.02px] !px-[0px]"
                                                                    align="center"
                                                                >
                                                                    {matchingRecord?.inspection_status ===
                                                                    'good' ? (
                                                                        <CheckIcon className="w-[20px] h-[20px] cursor-pointer" />
                                                                    ) : matchingRecord?.inspection_status ===
                                                                      'maintenance_required' ? (
                                                                        <CloseIcon className="w-[20px] h-[20px] cursor-pointer" />
                                                                    ) : matchingRecord?.inspection_status ===
                                                                      'maintenance' ? (
                                                                        <PanoramaFishEyeIcon className="w-[20px] h-[20px] cursor-pointer" />
                                                                    ) : undefined}
                                                                </StyledTableCell>
                                                            </Popover>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        }
                                    })}
                                </>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledUserListTable>
        </StylesStockNotes>
    );
};

const InspectionRecords = (props: ReceivedProps) => {
    return <InspectionRecordsLayout {...useInspectionRecord(props)} />;
};

export default InspectionRecords;
