export const useLogicChart = () => {
  const time_work_month = {
    '1': 177,
    '2': 177,
    '3': 160,
    '4': 177,
    '5': 171,
    '6': 177,
    '7': 171,
    '8': 177,
    '9': 177,
    '10': 171,
    '11': 177,
    '12': 171,
  };
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 0,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        display: false,
        legend: {
          position: 'left' as const,
        },
      },
      zoom: {
        pan: {
          enabled: true,
          mode: 'x',
        },
        zoom: {
          pinch: {
            enabled: true, // Enable pinch zooming
          },
          wheel: {
            enabled: true, // Enable wheel zooming
          },
          mode: 'x',
        },
      },
    },
    scales: {
      y: {
        // position: 'top',
        grid: {
          display: false,
        },
        ticks: {
          // beginAtZero: true,
          color: '#1C1E21',
          font: {
            size: 14,
          },
        },
      },
      x: {
        position: 'top',
        // position: 'left',
        grid: {
          borderDash: [2, 1],
          color: 'rgba(28, 30, 33, 0.1)',
        },
        ticks: {
          //   min: 0,
          // max: 100,
          //   stepSize: 10,
          color: '#1C1E21',
          font: {
            size: 14,
          },
        },
      },
    },
  };

  const getColorForchart = (time_range, data = []) => {
    const colors = data.map(value => {
      switch (time_range) {
        case 'weekly': {
          if (value < 30) {
            return '#C1D5EB';
          }
          if (value >= 30 && value < 40) {
            return '#CADEB7';
          }
          if (value >= 40 && value < 50) {
            return '#FAE5A2';
          }
          if (value >= 50 && value < 60) {
            return '#E08143';
          }
          if (value >= 60 && value < 70) {
            return '#E93223';
          }
          return '#B12419';
        }
        case 'monthly': {
          if (value >= 0 && value < 35) {
            return '#C1D5EB';
          }
          if (value >= 35 && value < 50) {
            return '#CADEB7';
          }
          if (value >= 50 && value < 70) {
            return '#FAE5A2';
          }
          if (value >= 70 && value < 80) {
            return '#E08143';
          }
          if (value >= 80 && value < 100) {
            return '#E93223';
          }
          return '#B12419';
        }
        default:
          break;
      }
    });

    return colors;
  };

  const getDataChartForMonthOrWeek = (time_range, working_times, month) => {
    if (time_range === 'weekly') return working_times;

    const monthDefault = time_work_month[month];
    const overtime = working_times - monthDefault;
    return overtime > 0 ? overtime : 0;
  };

  return {
    options,
    getColorForchart,
    getDataChartForMonthOrWeek,
  };
};
