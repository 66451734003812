import Api from './api/sugorokuApi';
import ApiV2 from './api/sugorokuV2Api';

const getListOfSugoroku = async (project_id: number) => {
  return await Api()
      .get(`?project_id=${project_id}`)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        return error;
      })
}

const getListOfWasteTypeSugoroku = async (project_id: number) => {
  return await ApiV2()
      .get(`?project_id=${project_id}`)
      .then((response) => {
        if (response.data) {
          return response.data;
        }
      })
      .catch((error) => {
        return error;
      })
}

const getSugorokuEventDetail = async (id?: number, project_id?: number) => {
  return await Api()
      .get(`${id}?project_id=${project_id}`)
      .then((response) => {
        if (response.data) {
          return response.data
        }
      })
      .catch((error) => {
        return error;
      })
}

const getWasteTypeSugorokuEventDetail = async (id?: number, project_id?: number) => {
  return await ApiV2()
      .get(`${id}?project_id=${project_id}`)
      .then((response) => {
        if (response.data) {
          return response.data
        }
      })
      .catch((error) => {
        return error;
      })
}

const createSugorokuEvent = async (data) => {
  return await Api()
    .post('', data)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      return error;
    })
}

const updateSugorokuEvent = async (id, data) => {
  return await Api()
    .put(`${id}`, data)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    })
    .catch((error) => {
      return error;
    })
}

const sugorokuService = {
  getListOfSugoroku,
  getSugorokuEventDetail,
  createSugorokuEvent,
  updateSugorokuEvent,
  getListOfWasteTypeSugoroku,
  getWasteTypeSugorokuEventDetail,
}

export default sugorokuService;