import { FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { TextField, InputAdornment } from '@mui/material';
import { Search } from '@mui/icons-material'
import SuggestionList from './SuggestionList';
import plus from '../../assets/icons/Plus.png';
import { Main } from './style';
import { formatMoney } from 'utils/helper/helpers';

const Suggestions: FC = () => {
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [queryFilter, setQueryFilter] = useState<any>({});
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            free_word: null,
        },
        onSubmit: (values: any) => {
            setQueryFilter(values);
        },
    });

    return (
        <Main>
            <div className="suggestions-content d-flex flex-column">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row searchRow">
                        <div className="amount">
                            <p className="mb-0">前借総額：</p>{' '}
                            <span>
                                {'¥' + formatMoney(String(totalAmount))}
                            </span>
                        </div>

                        <Link
                            className="button-create"
                            to={`/suggestions/create`}
                        >
                            <img width={'16px'} height={'16px'} src={plus} />
                            新規作成
                        </Link>
                    </div>
                    <div className="flex justify-start mb-6">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="search-box !w-auto"
                        >
                            <div className="search-box flex items-center">
                            <TextField
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search sx={{ color: '#000' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="off"
                                id="input-search"
                                type="text"
                                name="free_word"
                                onChange={(e) => {
                                    formik.setFieldValue(
                                        'free_word',
                                        e.target.value,
                                    );
                                }}
                            />
                                <button
                                    className="search-user-button d-flex flex-column height36"
                                    type="submit"
                                >
                                    検索
                                </button>
                            </div>
                        </form>
                    </div>
                    <SuggestionList
                        queryFilter={queryFilter}
                        setTotalAmount={setTotalAmount}
                    />
                </div>
            </div>
        </Main>
    );
};

export default Suggestions;
