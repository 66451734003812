import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { useModal } from 'react-hooks-use-modal';
import WorkingTimeService from 'services/workingTimesService';
import { alertError } from 'utils/helper/appHelper';
import departmentsService from 'services/departmentsService';
import PositionsService from 'services/positionService';
import workersService from 'services/wokersService';

const useWorkingTimesManagements = (props: any) => {
    const [workingTimeLists, setWorkingTimeLists] = useState<any>([]);
    const [departmentOptions, setDepartmentOptions] = useState<any>([]);
    const [deleteData, setDeleteData] = useState<any>({});
    const [positionOptions, setPositionOptions] = useState<any>([]);
    const [workerOptions, setWorkerOptions] = useState<any>([]);
    const [pageQueries, setPageQueries] = useState<any>({
        page: 1,
        per_page: 10,
    });
    const [isOpenDepartment, setIsOpentDepartment] = useState(false);
    const [isOpenPosition, setIsOpenPosition] = useState(false);
    const [isOpenWorker, setIsOpenWorker] = useState(false);

    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            department_id: [],
            position_id: [],
            free_word: '',
        },
        onSubmit: (values) => fetchWorkingTimes(values),
    });

    const fetchWorkingTimes = async (params: any = {}) => {
        try {
            const { working_time_managements }: any =
                await WorkingTimeService.getWorkingTime({
                    ...pageQueries,
                    attribute_department_ids:
                        params?.department_id?.map(
                            (department) => department.value,
                        ) || [],
                    attribute_position_ids:
                        params?.position_id?.map(
                            (department) => department.value,
                        ) || [],
                    worker_id: params?.worker_id || '',
                    free_word: params?.free_word || '',
                });
            setWorkingTimeLists(working_time_managements);
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchDepartments = async () => {
        try {
            const { departments } =
                await departmentsService.getListDepartments();
            setDepartmentOptions(
                departments?.map((department) => ({
                    name: department?.name,
                    value: department?.id,
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchPositions = async () => {
        try {
            const { positions } = await PositionsService.getListPositions();
            setPositionOptions(
                positions.map((item) => ({
                    value: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const fetchWorkerOptions = async () => {
        try {
            const { workers } = await workersService.getListWorkers();
            setWorkerOptions(
                workers.map((item) => ({
                    value: item.id,
                    name: item.name,
                })),
            );
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: true,
        },
    );

    const handleOpenDelete = (data) => {
        setDeleteData(data);
        openDelete();
    };

    const handleDelete = async () => {
        try {
            await WorkingTimeService.deleteWorkingTimes(deleteData.id, {
                worker_id: deleteData?.worker?.id,
            });
            closeDelete();
            fetchWorkingTimes();
        } catch (error) {
            const response = error?.response;
            alertError(response.data?.error);
        }
    };

    useEffect(() => {
        fetchWorkingTimes();
    }, [pageQueries]);

    useEffect(() => {
        fetchDepartments();
        fetchPositions();
        fetchWorkerOptions();
    }, []);

    return {
        ...props,
        formik,
        navigate,
        workingTimeLists,
        ModalDelete,
        openDelete,
        closeDelete,
        isOpenDelete,
        departmentOptions,
        workerOptions,
        positionOptions,
        setPageQueries,
        pageQueries,
        fetchWorkingTimes,
        handleOpenDelete,
        handleDelete,
        isOpenDepartment,
        setIsOpentDepartment,
        isOpenPosition,
        setIsOpenPosition,
        isOpenWorker,
        setIsOpenWorker,
    };
};

export type Props = ReturnType<typeof useWorkingTimesManagements>;

export default useWorkingTimesManagements;
