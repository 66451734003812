import styled from 'styled-components';

export const StylesOJTSP = styled.div`
    padding: 16.5px 12px;
    .ant-table-thead {
        .ant-table-cell {
            background-color: #c7dcf5;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &::before {
                display: none;
            }
        }
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
    }
    .ant-table-container table > thead > tr:last-child th:last-child {
        border-top-right-radius: 0px !important;
        border-bottom-right-radius: 0px !important;
    }
    .ant-table-container table > tbody > tr:last-child {
        td {
            border: none;
        }
    }
    .ant-table-wrapper {
        border: 1px solid #CDD1D5;
    }
    .ant-table-thead {
        background-color: #c7dcf5;
    }
    .custom-column {
        padding: 16px 6.6px;
    }
    .vehicle-name {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .vehicle-inspection-title {
        車両点検簿
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .icon-rounded {
        background-color: #215493;
        // padding: 8px 5px;
        border-radius: 50%;
    }
`;
