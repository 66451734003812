export const validate = (values) => {
    const errors: any = {};
    const start_date = new Date(values?.start_date)
    const end_date = new Date(values?.end_date)
    if (!values.worker_id) {
        errors.worker_id = '必須です。';
    }

    if (!values.title) {
        errors.title = '必須です。';
    }

    if (!values.start_date) {
        errors.start_date = '必須です。';
    }

    if (!values.end_date) {
        errors.end_date = '必須です。';
    }
    if(start_date.getTime() > end_date.getTime()) {
        errors.end_date = '終了時刻は開始時刻以降より入力してください';
    }
    return errors;
};
