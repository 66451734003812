import { StylesWorkingTimesManagements } from './styled';
import useWorkingTimesManagements, { Props } from './hook';
import {
    Button,
    TextField,
    Autocomplete,
    Grid,
    Box,
    InputAdornment,
} from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import type { ColumnsType } from 'antd/es/table';
import { Table, Pagination } from 'antd';
import CustomPagination from 'components/CustomPagination';
import vector from '../../assets/icons/Vector.png';
import moment from 'moment';

const WorkingTimesManagementLayout = ({
    navigate,
    formik,
    workingTimeLists,
    ModalDelete,
    openDelete,
    closeDelete,
    isOpenDelete,
    positionOptions,
    departmentOptions,
    workerOptions,
    setPageQueries,
    pageQueries,
    handleOpenDelete,
    handleDelete,
    isOpenDepartment,
    setIsOpentDepartment,
    isOpenPosition,
    setIsOpenPosition,
    isOpenWorker,
    setIsOpenWorker
}: Props) => {
    const columns: ColumnsType<any> = [
        {
            title: '名前',
            dataIndex: 'worker_name',
            key: 'worker_name',
            render: (_, record) => (
                <span className="vehicle-name">{record?.worker?.name}</span>
            ),
            ellipsis: true,
        },
        {
            title: '日付',
            dataIndex: 'submit_date',
            key: 'submit_date',
            render: (text) => <span className="vehicle-name">{text}</span>,
            ellipsis: true,
        },
        {
            title: '出勤時間',
            dataIndex: 'start_time',
            key: 'start_time',
            render: (text) =>
                text ? (
                    <span className="vehicle-name">
                        {moment(text)?.format('HH:mm')}
                    </span>
                ) : (
                    ''
                ),
            ellipsis: true,
        },
        {
            title: '位置',
            dataIndex: 'checkin_location',
            key: 'checkin_location',
            render: (text) => <span className="vehicle-name">{text}</span>,
            ellipsis: true,
        },
        {
            title: '退勤時間',
            dataIndex: 'end_time',
            key: 'end_time',
            render: (text) =>
                text ? (
                    <span className="vehicle-name">
                        {moment(text)?.format('HH:mm')}
                    </span>
                ) : (
                    ''
                ),
            ellipsis: true,
        },
        {
            title: '位置',
            dataIndex: 'checkout_location',
            key: 'checkin_location',
            render: (text) => <span className="vehicle-name">{text}</span>,
            ellipsis: true,
        },
        {
            title: '操作',
            key: 'action',
            width: 260,
            align: 'center',
            render: (_, record) => (
                <div className="flex items-center gap-[4px]">
                    <button
                        onClick={() =>
                            navigate(
                                `detail/${record?.id}?worker_id=${record?.worker?.id}`,
                            )
                        }
                        className="search-user-button !w-[72px] d-flex flex-column height36"
                        type="button"
                    >
                        詳細
                    </button>
                    <button
                        onClick={() =>
                            navigate(
                                `edit/${record?.id}?worker_id=${record?.worker?.id}`,
                            )
                        }
                        className="search-user-button !w-[72px] d-flex flex-column height36"
                        type="button"
                    >
                        編集
                    </button>
                    <button
                        onClick={() => handleOpenDelete(record)}
                        className="search-user-button !bg-[#FF5045] !w-[72px] d-flex flex-column height36"
                        type="button"
                    >
                        削除
                    </button>
                </div>
            ),
        },
    ];
    return (
        <StylesWorkingTimesManagements>
            <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={closeDelete}
                        >
                            キャンセル
                        </button>
                        <button
                            onClick={handleDelete}
                            className="modal-create-user-button2 d-flex flex-row"
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
            <div className="flex items-center justify-between">
                <p className="working_times--title mb-0">勤怠管理</p>
                <Button
                    onClick={() => navigate('create')}
                    sx={{ mb: '20px', fontWeight: 'bold', padding: '6px 18px' }}
                    variant="outlined"
                >
                    <Add /> 新規作成
                </Button>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <Grid spacing={4} container className="filterBoxTop">
                    <Grid item xs={2.5} className="filterBoxCol">
                        <span className="fieldLabel">部署</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                open={isOpenDepartment}
                                onOpen={() => setIsOpentDepartment(true)}
                                onClose={() => setIsOpentDepartment(false)}
                                onFocus={() => setIsOpentDepartment(true)}
                                multiple
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'department_id',
                                        newValue,
                                    );
                                }}
                                noOptionsText="該当なし"
                                options={departmentOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2.5} className="filterBoxCol">
                        <span className="fieldLabel">役職</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                open={isOpenPosition}
                                onOpen={() => setIsOpenPosition(true)}
                                onClose={() => setIsOpenPosition(false)}
                                onFocus={() => setIsOpenPosition(true)}
                                noOptionsText="該当なし"
                                multiple
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'position_id',
                                        newValue,
                                    );
                                }}
                                options={positionOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2.5} className="filterBoxCol">
                        <span className="fieldLabel">社員</span>
                        <div className="wrapSelectField">
                            <Autocomplete
                                open={isOpenWorker}
                                onOpen={() => setIsOpenWorker(true)}
                                onClose={() => setIsOpenWorker(false)}
                                onFocus={() => setIsOpenWorker(true)}
                                noOptionsText="該当なし"
                                onChange={(event, newValue: any) => {
                                    formik.setFieldValue(
                                        'worker_id',
                                        newValue?.value || '',
                                    );
                                }}
                                options={workerOptions}
                                getOptionLabel={(option: any) => option.name}
                                renderInput={(params) => (
                                    <TextField {...params} />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={2.5}>
                        <span className="fieldLabel">キーワード</span>
                        <Box
                            sx={{
                                width: '100%',
                                '.MuiTextField-root': {
                                    width: '100%',
                                    borderRadius: '8px',
                                    marginRight: '12.5px',
                                    backgroundColor: '#f3f3f3'
                                },
                                '.MuiInputBase-input': {
                                    padding: '0px !important',
                                },
                            }}
                            className="wrapTextField"
                        >
                            <TextField
                                fullWidth
                                hiddenLabel
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <Search sx={{ color: '#000', fontSize: '20px' }} />
                                        </InputAdornment>
                                    ),
                                }}
                                name="free_word"
                                type="text"

                                variant="outlined"
                                onChange={formik.handleChange}
                                value={formik.values.free_word}
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={2}>
                        <span className="fieldLabel h-[18px]"></span>
                        <button
                            type="submit"
                            className="bg-main px-[30px] py-[4px] rounded-[6px] text-white"
                        >
                            検索
                        </button>
                    </Grid>
                </Grid>
            </form>
            <div className="mt-[14.5px]">
                <Table
                    rowKey="id"
                    columns={columns}
                    pagination={false}
                    dataSource={workingTimeLists}
                />
                <div className="flex justify-center">
                    <CustomPagination
                        totalCount={workingTimeLists?.length || 0}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) => console.log(page)}
                    />
                </div>
            </div>
        </StylesWorkingTimesManagements>
    );
};
const WorkingTimesManagement = (props: any) => {
    return (
        <WorkingTimesManagementLayout {...useWorkingTimesManagements(props)} />
    );
};

export default WorkingTimesManagement;
