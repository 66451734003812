import { useNavigate } from 'react-router-dom';
import { ReceivedProps} from '../type';
import { useState } from 'react';
import StockNotesService from 'services/stockNotesService';


const useCreateInternalOJT = (props: ReceivedProps) => {
    const [fileUpload, setFileUpload] = useState<File>();
    const [errorMsg, setErrorMsg] = useState<string>('');
    const navigate = useNavigate();
   
    const handleFileUpload = (values: FileList | null) => {
        if(!values) return;
        const fileSizeKiloBytes = values?.[0]?.size / 1024;
        if (fileSizeKiloBytes > 6144) {
            setErrorMsg('6MB以上がアップロードできません。');
            return;
        }
        setErrorMsg('');
        setFileUpload(values[0]);
    };

    const handleCreateStockNotes = async () => {
        if(!fileUpload) return;
        const formData = new FormData();
        formData.append('file', fileUpload);
        try {
            await StockNotesService.createStockNotes(formData);
            navigate('/stock_notes');
        } catch (error) {}
    };

    //const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    //    if(!e.target.files) return;
    //    formik.setFieldValue('file', e.target.files[0]);
    //};

    return {
        ...props,
        navigate,
        handleFileUpload,
        fileUpload,
        setFileUpload,
        handleCreateStockNotes,
        errorMsg
    };
};
export type Props = ReturnType<typeof useCreateInternalOJT>;

export default useCreateInternalOJT;
