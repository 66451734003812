import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import DatePicker from 'react-datepicker';
import pick from 'lodash/pick';
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import calendarIcon from 'assets/icons/calendar.png';
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from 'utils/constants';
import DialogHeader from '../../common/DialogHeader';
import CommonStyledSugorkuDialogWrapper from '../../common/commonSugorokuDialogStyle';
import { SugorokuIndexEnum } from 'utils/enums';
import { useFormik, FormikProps } from 'formik';
import sugorokuService from 'services/sugorokuService';
import { formatDateTime } from 'utils/helper/dateHelper';
import { IPreConstructReviewPayload } from 'models';

interface IProps {
    step: number;
    onClose?: any;
    data_id?: number;
    workerOptions: any[];
    onFinish: () => void;
}

interface IPreConstructReviewFormikValues {
    person_in_charge_worker?: any;
    completed_at?: Date | null;
}

const PreConstructReviewModal = (props: IProps) => {
    const { step, onClose, workerOptions, data_id, onFinish } = props;
    const [mode, setMode] = useState(VIEW_SUGOROKU_MODE);
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<any>(true);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const hasData = !!data_id;
    const { id } = useParams();

    const formik: FormikProps<IPreConstructReviewFormikValues> =
        useFormik<IPreConstructReviewFormikValues>({
            initialValues: {
                person_in_charge_worker: null,
                completed_at: null,
            },
            onSubmit: (values: IPreConstructReviewFormikValues) => {
                setSubmitLoading(true);
                let data: IPreConstructReviewPayload = pick(values);
                if (!id) {
                    setSubmitLoading(false);
                    return;
                }

                data.project_id = +id;
                data.sugoroku_index =
                    SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.value;

                if (values.person_in_charge_worker) {
                    data.person_in_charge_worker_id =
                        +values.person_in_charge_worker.value;
                } else {
                    data.person_in_charge_worker_id = null;
                }

                if (values.completed_at) {
                    data.completed_at = formatDateTime(
                        values.completed_at,
                        'yyyy-MM-DD',
                    );
                } else {
                    data.completed_at = null;
                }

                if (hasData) {
                    updateSugoroku(data);
                } else {
                    createSugoroku(data);
                }
            },
        });

    const fetchData = async () => {
        try {
            setFetchLoading(true);
            const data = await sugorokuService.getSugorokuEventDetail(
                data_id,
                Number(id),
            );
            if (data && data.sugoroku_event) {
                const sugorokuData = data.sugoroku_event;

                formik.setValues({
                    person_in_charge_worker:
                        sugorokuData.person_in_charge_worker
                            ? {
                                  value: String(
                                      sugorokuData.person_in_charge_worker.id,
                                  ),
                                  label: sugorokuData.person_in_charge_worker
                                      .name,
                                  name: sugorokuData.person_in_charge_worker
                                      .name,
                              }
                            : null,
                    completed_at: sugorokuData.completed_at
                        ? new Date(sugorokuData.completed_at)
                        : null,
                });
                setIsEmpty(false);
            } else {
                setIsEmpty(true);
            }
        } catch (error) {
            // error
        } finally {
            setFetchLoading(false);
        }
    };

    const createSugoroku = async (data: IPreConstructReviewPayload) => {
        try {
            const responseData = await sugorokuService.createSugorokuEvent(
                data,
            );
            if (responseData) {
                setSubmitLoading(false);
                onFinish();
            }
        } catch (error) {
            setSubmitLoading(false);
            // empty
        }
    };

    const updateSugoroku = async (data: IPreConstructReviewPayload) => {
        try {
            const responseData = await sugorokuService.updateSugorokuEvent(
                data_id,
                data,
            );
            if (responseData) {
                setSubmitLoading(false);
                onFinish();
            }
        } catch (error) {
            setSubmitLoading(false);
            // epmty
        }
    };

    useEffect(() => {
        if (data_id && id) {
            fetchData();
        }
    }, []);

    return (
        <CommonStyledSugorkuDialogWrapper>
            <DialogHeader
                step={step}
                title={SugorokuIndexEnum.PRE_CONSTRUCTION_REVIEW_MEETING.title}
                onClose={onClose}
                mode={mode}
                setMode={setMode}
                completed={!!formik.values.completed_at}
            />

            {fetchLoading && (
                <div className="d-flex justify-center">
                    <CircularProgress />
                </div>
            )}

            {mode === VIEW_SUGOROKU_MODE && !fetchLoading && !isEmpty && (
                <>
                    <div className="dialog-field-row">
                        <div className="field-label">担当者</div>
                        <div className="value">
                            {formik.values.person_in_charge_worker?.label || ''}
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">完了日付</div>
                        <div className="value">
                            {formatDateTime(
                                formik.values.completed_at,
                                'yyyy-MM-DD',
                            )}
                        </div>
                    </div>
                </>
            )}

            {mode === EDIT_SUGOROKU_MODE && !fetchLoading && (
                <form onSubmit={formik.handleSubmit}>
                    <div className="dialog-field-row">
                        <div className="field-label">担当者</div>
                        <div className="autocomplete-wrapper">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={workerOptions.map((item) => ({
                                    label: item.label,
                                    value: String(item.value),
                                    name: item.name,
                                }))}
                                value={formik.values.person_in_charge_worker}
                                onChange={(event, newValue) => {
                                    formik.setFieldValue(
                                        'person_in_charge_worker',
                                        newValue,
                                    );
                                }}
                                isOptionEqualToValue={(option, value) =>
                                    option.value === value.value
                                }
                                classes={{
                                    root: 'autocomplete-select-style',
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="" />
                                )}
                                noOptionsText="該当なし"
                            />
                        </div>
                    </div>

                    <div className="dialog-field-row">
                        <div className="field-label">完了日付</div>
                        <div className="datepicker-wrapper">
                            <DatePicker
                                id="completed_at"
                                selected={formik.values.completed_at}
                                onChange={(date) => {
                                    formik.setFieldValue('completed_at', date);
                                }}
                                dateFormat="yyyy-MM-dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <label htmlFor="completed_at">
                                <img
                                    className="calendar-datepicker-icon"
                                    src={calendarIcon}
                                ></img>
                            </label>
                        </div>
                    </div>
                    <div className="action-section">
                        <button
                            type="button"
                            className="back-step-edit-button"
                            onClick={onClose}
                        >
                            キャンセル
                        </button>
                        <button
                            type="submit"
                            className="submit-step-edit-button"
                            disabled={submitLoading}
                        >
                            {submitLoading ? (
                                <CircularProgress color="inherit" size={18} />
                            ) : (
                                '登録'
                            )}
                        </button>
                    </div>
                </form>
            )}
        </CommonStyledSugorkuDialogWrapper>
    );
};

export default PreConstructReviewModal;
