export const handleFilter = (items) => {
    return (searchValue) => {
      if (searchValue.length === 0) {
        return items;
      }
      const updatedItems = items.filter((item) => {
        if(item.name !== undefined && item.name.includes(searchValue)){
            return item
        }
      });
      return updatedItems;
    };
};