import { FC } from 'react';

type IProps = {
    strokeColor?: string;
};

const Calendar: FC<IProps> = ({ strokeColor = '#CDD1D5' }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            fill="none"
            viewBox="0 0 24 24"
        >
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 3.75h-15a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h15a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75zM16.5 2.25v3M7.5 2.25v3M3.75 8.25h16.5"
            ></path>
            <path
                stroke={strokeColor}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.625 12h2.625l-1.5 1.875a1.5 1.5 0 11-1.06 2.56M13.5 13.125L15 12v4.875"
            ></path>
        </svg>
    );
};

export default Calendar;
