/*
 * function convert workers to dropdown options
 *
 */
export const convertWorkersToDropdownOptions = (
    array: Array<any> = [],
): Array<{
    value: string;
    label: string;
    name: string;
}> => {
    return array.map((item) => ({
        value: item.id,
        label: item.name,
        name: item.name,
    }));
};

export const isAdmin= (userData: {role: string}) => {
    if(!userData) return;
    if(userData?.role === "admin") return true;
}
