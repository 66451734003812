/*
 * function convert clientCompanies to dropdown options
 *
 */
export const convertCooperativeCompaniesToDropdownOptions = (
    array: Array<any> = [],
): Array<{
    value: string;
    label: string;
}> => {
    return array.map((item) => ({
        value: item.id,
        label: item.company?.name,
    }));
};
