import Api from './api/positionApi';
import pick from 'lodash/pick';

const getListPositions = async(input: any = {}) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    let data = pick(input, ['page', 'per_page']);
    const params = new URLSearchParams(data);
    if (user.access_token !== undefined && user.access_token !== null ){
        try {
            return await Api().get(`?${params.toString()}`, { headers: {Authorization: `Bearer ${user.access_token}`} }).then(response => {
                if(response.data) {
                    return response.data;
                }
            })
        } catch (err) {
            window.location.href = '/login';
            return null
        }
    } else {
        window.location.href = '/login';
    }
}

const createPositions = async(body) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null)){
        return await Api().post('', body, { headers: {Authorization: `Bearer ${user.access_token}`} }).then(response => {
            if(response.data) {
                return response.data;
            }
        })
    } else {
        window.location.href = '/login';
    }
}
  
const updatePositions = async(body, id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null)){
        return await Api().put(`${id}`, body, { headers: {Authorization: `Bearer ${user.access_token}`}}).then(response => {
            if(response.data) {
                return response.data;
            }
        })
    } else {
        window.location.href = '/login';
    }
}

const reorderPositions = async(body) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    console.log(body)
    if ((user.access_token !== undefined && user.access_token !== null)){
        try {
            return await Api().put('reorder', body, { headers: {Authorization: `Bearer ${user.access_token}`} }).then(response => {
                if(response.data) {
                    return response.data;
                }
            })
        } catch (err) {
            console.log(err)
            return null
        }
    } else {
        window.location.href = '/login';
    }
}

const deletePositions = async(id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    // const params = { 
    //     id: id
    // };
    if ((user.access_token !== undefined && user.access_token !== null)){
        return await Api().delete(`${id}`, { headers: {Authorization: `Bearer ${user.access_token}`}}).then(response => {
            if(response.data) {
                return response.data;
            }
        })
    } else {
        window.location.href = '/login';
    }
}

const PositionsService = {
    getListPositions,
    createPositions,
    updatePositions,
    reorderPositions,
    deletePositions
};


export default PositionsService;