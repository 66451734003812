import fetch from './api/config';

const API = {
    GET_LIST_PLANS: () => `/sp/plans`,
};

export default class PlanService {
    static getListPlans = (params: any) =>
        fetch.get(API.GET_LIST_PLANS(), params);
}
