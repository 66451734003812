import styled from 'styled-components';

export const StylesTargetStatus = styled.div`
    .status-wrapper {
      gap: 8px;
      justify-content: end;
    }
    .status-wrapper img {
      min-width: 19px;
    }
`;
