import React from 'react';
import './WorkingReport.css';
import { RootState, useAppDispatch } from '../../../../store/index';
import { useSelector } from 'react-redux';
import {
    setDeleteSuccess,
    setSelectedReport,
    setShowModalDelete,
} from 'store/workingReport/workingReport';
import reportsService from 'services/reportsService';
import { useParams } from 'react-router-dom';

const WorkingReportDelete = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { selectedReport = {} as any } = useSelector(
        (state: RootState) => state.workingReport,
    );

    const onClose = () => {
        dispatch(setSelectedReport({}));
        dispatch(setShowModalDelete(false));
    };

    const onDelete = async () => {
        try {
            const response = await reportsService.deleteReport(
                selectedReport?.id,
                {
                    project_id: id ? id : selectedReport?.project_id,
                },
            );
            if (response) {
                dispatch(setDeleteSuccess(true));
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(setShowModalDelete(false));
        }
    };

    const footer = (
        <div className="grid gap-x-[16px] justify-end grid-cols-2 mt-[24px] w-full">
            <button
                className="border-main border-[1px] text-main px-[18px] py-[10px] rounded-[6px]"
                onClick={onClose}
            >
                キャンセル
            </button>
            <button
                className="bg-main px-[18px] py-[10px] rounded-[6px] text-white"
                onClick={onDelete}
            >
                はい
            </button>
        </div>
    );

    return (
        <div className="w-[400px] bg-[#ffffff] flex flex-col rounded-[12px] justify-center items-center p-[28px] max-h-[90vh]">
            <div className="modal-create-user-title d-flex flex-row">
                本当に削除しますか?
            </div>
            {footer}
        </div>
    );
};

export default WorkingReportDelete;
