import * as yup from 'yup';

export const validation = yup.object().shape({
    subject: yup.string().required('必須項目です'),
    meeting_file: yup
        .array()
        .min(1, '必須項目です')
        .required('必須項目です')
        .test('fileSize', '6MB以上がアップロードできません。', (value: any) => {
            const test = value?.find((x) => x.size > 6144 * 1024);
            return !test;
        }),
    meeting_image: yup
        .array()
        .min(1, '必須項目です')
        .required('必須項目です')
        .test('fileSize', '6MB以上がアップロードできません。', (value: any) => {
            const test = value?.find((x) => x.size > 6144 * 1024);
            return !test;
        }),
});
