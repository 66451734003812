import { FC } from "react";

type IProps = {
  strokeColor?: string;
};

const SquaresFour: FC<IProps> = ({ strokeColor = '#215493' }) => {
  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.25 4.5H5.25V10.5H11.25V4.5Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.25 4.5H14.25V10.5H20.25V4.5Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.25 13.5H5.25V19.5H11.25V13.5Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M20.25 13.5H14.25V19.5H20.25V13.5Z" stroke={strokeColor} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default SquaresFour