import Api from './api/workingReportApi';
import pick from 'lodash/pick';
// import projectList from '../shared/mocks/projectLists.json';
import moment from 'moment';

const printPdf = async (data: any = {}) => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null) {
        try {
            return await Api()
                .post(
                    'print_pdf', data,
                    {
                        headers: {
                            Authorization: `Bearer ${user.access_token}`,
                        },
                        responseType: 'blob'
                    },
                )
                .then((response) => {
                    if (response) {
                        return response;
                    }
                });
        } catch (err) {
            // window.location.href = '/login';
            return null;
        }
    } else {
        // window.location.href = '/login';
    }
};

const workingReportsService = {
    printPdf
};

export default workingReportsService;
