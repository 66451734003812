export const validate = (values: any) => {
    let errors = {};

    if (!values.to_worker_id) {
        errors = {
            ...errors,
            to_worker_id: '必須です。',
        };
    }
    if (!values.title) {
        errors = {
            ...errors,
            title: '必須です。',
        };
    }
    if (!values.amount) {
        errors = {
            ...errors,
            amount: '必須です。',
        };
    }
    return errors;
};
