import styled from 'styled-components'



export const Avatar = styled.img`
    width: 32px;
    height: 32px;
    border-radius: 100%;
`
export const Main = styled.div`
    background: #fff;
    padding: 28px;
    padding-left: 0;
    border-bottom-right-radius: 12px;


    .bnt{
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 6px;
        width: 60px;
        height: 25px;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        margin-right:0;
    }
    .bg_1C8E43{
        background: #1C8E43;
    }
    .bg_215493{
        background: #215493;
    }

    .container-comment{
        border: 1px solid #CDD1D5;
        border-radius: 8px;
        width: 254px;
        min-height: 458px;

        .title{
            font-style: normal;
            font-weight: 700;
            font-size: 18px;
            line-height: 30px;
            color: #1C1E21;
            padding: 16px;
            height:62px;
            border-bottom: 1px solid #CDD1D5;
            margin:0;
        }

        .group-content{
            border-bottom: 1px solid #CDD1D5;
            padding: 16px;
            height: 276px;
            text-align: end;
            overflow: hidden;
            overflow-y: auto;

            .info-user-comment{
                align-items: center;

                span{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .answer-quetsion{
                margin-bottom: 16px;

                .content{
                    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
                    border-radius: 0px 8px 8px 8px;
                    background: #F7F8F8;
                    padding:14px;
                    // padding-top: 7px;
    
                    p{
                        font-family: 'Zen Kaku Gothic Antique';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;
                        color: #101828;
                    }
    
                    .group-bnt{
                        justify-content: flex-end;
                        gap: 5px;
                    }
                }

                .time{
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 16px
                    color: rgba(16, 24, 40, 0.8);
                    text-align: right;
                    // width:100%;
                    justify-content: flex-end;
                    min-width: 95px;
                }

                .worker-name{
                    font-size: 9px;
                    line-height: 16px
                }
            }
            .checked{
                margin-right:0;
            }
        }

        .answer-box{
            padding: 16px;
            text-align: end;

            

            .input-answer{
                width:100%;
            }
        }

        .bg_dba12eba{
            background:#dba12eba;
        }

        .content-reply{
            gap: 8px;
            font-size: 14px;
            border-bottom: 1px solid #CDD1D5;
            margin-bottom: 10px;
            padding-bottom: 10px;
            padding-top: 0;
            justify-content: space-between;
            
            .closeReply{
                height: 15px;
                cursor: pointer;
                margin-top: 4px;
            }
        }

        .not-reply{
            width: 100%;
            text-align: left;
        }

        .pl-30{
            padding-left:20px;
            gap: 5px;
        }
    }
    
    
`