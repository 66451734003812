import { StylesCreateJob, Input } from './styled';
import useCreateJob, { ReceivedProps, Props } from './hook';
import { Controller } from 'react-hook-form';
import Dropdown from 'react-dropdown';
import { Grid, Stack, Button } from '@mui/material';
import { FC, useMemo } from 'react';
import { Add, Close } from '@mui/icons-material';
import { getFilePreview } from 'utils/helper/file';
import SignatureCanvas from 'react-signature-canvas';
import { Checkbox } from 'antd';

const CreatJobLayout: FC<Props> = ({
    fileUpload,
    removeImage,
    getRootProps,
    navigate,
    signatureRef,
    getInputProps,
    formik,
    signatureBase64,
    setSignatureChange,
    id,
    projectOptions,
}) => {
    const files: any = fileUpload.map((file: any, index) => (
        <Grid position="relative" item xs={6} key={file.path}>
            <img
                className="dropzone--image__preview"
                src={
                    typeof file?.image_path === 'string'
                        ? file?.image_path
                        : getFilePreview(file)
                }
                alt="image_preview_job"
            />
            {!id && (
                <Close
                    onClick={() => removeImage(index)}
                    sx={{ position: 'absolute', top: '20px', right: '4px' }}
                />
            )}
        </Grid>
    ));
    return (
        <StylesCreateJob>
            <p className="job--title">{id ? formik?.values?.name : '新規登録'}</p>
            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                <div className="formFieldRow">
                    <p className="mb-2">案件</p>
                    <Dropdown
                        disabled={!!id}
                        className=" dropdown-create-user1 height36"
                        controlClassName="dropdown-control-user height36"
                        options={projectOptions}
                        value={formik.values.project_id}
                        placeholder=""
                        onChange={(values) => console.log(values)}
                    />
                </div>
                <div className="formFieldRow mt-[12px]">
                    <p className="mb-2">追加作業タイトル</p>
                    <input
                        disabled={!!id}
                        autoComplete="off"
                        type="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        className="textfield"
                    />
                    {/* {formik.errors.name && (
                        <span className="error">{formik.errors.name}</span>
                    )} */}
                </div>

                <div className="formFieldRow mt-[12px]">
                    <p className="mb-2">内容</p>
                    <textarea
                        disabled={!!id}
                        autoComplete="off"
                        className="textarea"
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        name="note"
                    />
                    {/* {formik.errors.note && (
                        <span className="error">{formik.errors.note}</span>
                    )} */}
                </div>
                <Grid sx={{ mt: '8px' }} container spacing={2}>
                    {files}
                    {!id && (
                        <Grid item xs={6}>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <Add
                                    sx={{
                                        color: '#808184',
                                        width: '29px',
                                        height: '29px',
                                    }}
                                />
                                <p className="dropzone--text">
                                    写真をアップロード
                                </p>
                            </div>
                        </Grid>
                    )}
                </Grid>
                <div>
                    <Stack marginTop="19px" direction="row" alignItems="center">
                        <Checkbox
                            disabled={!!id}
                            name="is_agree_additional"
                            className="custom-checkbox"
                            checked={formik.values.is_agree_additional}
                            onChange={formik.handleChange}
                        />
                        <div className="text-[#000] text-[16px]">
                            以上の内容で追加作業を依頼しました
                        </div>
                    </Stack>
                </div>
                <div className="mt-[16px]">
                    <p className="text-[#666] text-base mb-[8.5px]">サイン</p>
                    <div className="signature">
                        {signatureBase64 ? (
                            <img
                                src={signatureBase64}
                                alt="電子署名"
                                className="w-full h-[180px] object-contain"
                            />
                        ) : (
                            <SignatureCanvas
                                onEnd={() =>
                                    setSignatureChange((preState) => !preState)
                                }
                                ref={signatureRef}
                                penColor="#225594"
                                canvasProps={{
                                    className: 'sigCanvas',
                                }}
                            />
                        )}

                        {!id && (
                            <div
                                onClick={() => {
                                    signatureRef?.current?.clear();
                                    setSignatureChange((preState) => !preState);
                                }}
                                className="undo-image"
                            >
                                <img src="/img/undo.svg" alt="undo" />
                            </div>
                        )}
                    </div>
                </div>
                <Grid marginTop="25px" container>
                    {id ? (
                        <Grid item display="flex" justifyContent="center" xs={12}>
                            <Button
                                type="button"
                                onClick={() => navigate('/sp/jobs')}
                                sx={{
                                    color: '#215493',
                                    width: '166px',
                                }}
                                variant="outlined"
                            >
                                戻る
                            </Button>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <Button
                                    type="button"
                                    onClick={() => navigate('/sp/jobs')}
                                    sx={{
                                        color: '#215493',
                                        width: '166px',
                                    }}
                                    variant="outlined"
                                >
                                    戻る
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={
                                        !formik.isValid ||
                                        !fileUpload?.length ||
                                        signatureRef?.current?.isEmpty()
                                    }
                                    type="submit"
                                    sx={{
                                        color: '#fff',
                                        width: '166px',
                                        background: '#225594',
                                    }}
                                    variant="contained"
                                >
                                    承認
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </form>
        </StylesCreateJob>
    );
};

const CreateJob: FC<ReceivedProps> = (props) => {
    return <CreatJobLayout {...useCreateJob(props)} />;
};

export default CreateJob;
