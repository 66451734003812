import moment from 'moment';
import { formatDateTime } from 'utils/helper/dateHelper';

export const columns = () => {
    return [
        {
            headerName: '作業日',
            field: 'createdAt',
            maxWidth: 260,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => (
                <div>
                    {`${formatDateTime(
                        e?.data?.from_at,
                        'YYYY/MM/DD',
                    )} - ${formatDateTime(e?.data?.to_at, 'YYYY/MM/DD')}`}
                </div>
            ),
        },
        {
            headerName: '発行日',
            field: 'published_at',
            maxWidth: 150,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => (
                <div>{formatDateTime(e?.data?.published_at, 'YYYY/MM/DD')}</div>
            ),
        },
        {
            headerName: '送信日',
            field: 'submitted_at',
            maxWidth: 150,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => (
                <div>{formatDateTime(e?.data?.submitted_at, 'YYYY/MM/DD')}</div>
            ),
        },
        {
            headerName: '作業内容',
            field: 'submitted_at',
            width: 350,
            unSortIcon: true,
            filter: 'agTextColumnFilter',
            cellRenderer: (e: any) => (
                <div>
                    {e?.data?.working_logs?.reduce(
                        (accu, log, idx, workingLogsArray) => {
                            if (idx === workingLogsArray.length - 1) {
                                accu = accu + log?.name;
                            } else {
                                accu = accu + log?.name + ', ';
                            }
                            return accu;
                        },
                        '',
                    )}
                </div>
            ),
        },
    ];
};
