import { styled } from '@mui/material/styles';
import TextField, { TextFieldProps } from '@mui/material/TextField'

export const StyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    // height: '36px',
    // width: '210px',
    '& .MuiOutlinedInput-input': {
      paddingLeft: '8px',
    },
    '&:hover fieldset': {
      border: '1px solid rgba(0, 0, 0, 0.23)'
    },
    '&.Mui-focused fieldset': {
      border: '1px solid rgba(0, 0, 0, 0.23)'
    },
  },
}))