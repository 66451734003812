export const DefaultButton = ({ text, onClick }) => {
    return (
        <button
            style={{ marginRight: '10px', width: 'auto' }}
            className="button-tb"
            onClick={onClick}
        >
            {text}
        </button>
    );
};

export const DeleteButton = ({ onClick }) => {
    return (
        <button
            style={{
                backgroundColor: '#FF5045',
            }}
            className="button-tb btn-delete"
            onClick={onClick}
        >
            削除
        </button>
    );
};