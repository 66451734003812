import { Dialog } from '@mui/material';
import userService from 'services/userService';
import { alertError } from 'utils/helper/appHelper';
import StyledDeleteUserWrapper from './style';

const DeleteUser = ({ selectedUser, open, setOpen, onFinish }) => {
    const handleClose = () => {
        setOpen(false);
    };

    const handleDeleteUser = async () => {
        try {
            const response = await userService.deleteUser(selectedUser.id);
            if (response) {
                onFinish();
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || '';
            setOpen(false);
            alertError(errorMessage);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
            <StyledDeleteUserWrapper>
                <div className="delete-popup-title">本当に削除しますか?</div>
                <div className="delete-popup-button-group">
                    <button className="return-button" onClick={handleClose}>
                        キャンセル
                    </button>
                    <button
                        className="register-button"
                        onClick={handleDeleteUser}
                    >
                        はい
                    </button>
                </div>
            </StyledDeleteUserWrapper>
        </Dialog>
    );
};

export default DeleteUser;
