import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import messageService from 'services/messageService';
import { orderBy, uniqueId } from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import PrivateMessageService from 'services/privateMessageService';
import moment from 'moment';

const useChatBoxPrivateMessage = (props: any) => {
    // const [listMessage, setListMessage] = useState<any>([]);
    const [unreadFlag, setUnreadFlag] = useState<any>();

    const {
        activeMessage,
        setActiveMessage,
        fetchChats,
        listMessage,
        setListMessage,
        setHistories,
    } = props;
    const { userData }: any = useSelector((state: RootState) => state.users);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [listErrorFile, setListErrorFile] = useState<File[]>([]);

    const onSubmit = async (response: any) => {
        setIsLoading(true);
        const { message, file } = response;
        if (!userData.worker) return;
        if (!message && file.length <= 0) {
            setIsLoading(false);
            return;
        }
        setListMessage((preState) => [
            ...preState,
            {
                body: message,
                id: uniqueId(),
                images: file?.filter((f: { type: string | string[]; }) => f.type.includes('image/')),
                publish_at: moment().zone('+9:00'),
                is_owner: true,
                sender: {
                    id: userData?.worker?.id || activeMessage?.workerId,
                    name: userData?.worker?.name,
                },
                videos: file?.filter((f: { type: string | string[]; }) => f.type.includes('video/')),
            },
        ]);
        setHistories((preState) => {
            const newArray = [...preState];
            const index = newArray.findIndex(
                (item) => item.id === activeMessage.id,
            );
            if (index !== -1) {
                newArray[index] = {
                    ...activeMessage,
                    last_message: message,
                    last_message_published_at: moment().zone('+9:00'),
                };
            }
            return newArray;
        });
        let formData = new FormData();
        formData.append(
            'chat_worker_id',
            activeMessage?.workers?.find(
                (worker) => worker.id !== userData.worker.id,
            )?.id || activeMessage?.workerId,
        );
        if (message) {
            formData.append('body', message ? message : '');
        }
        if (file.length > 0) {
            for (let x = 0; x < file.length; x++) {
                if(file[x].type.includes('video/')) {
                    formData.append('videos[]', file[x]!)
                } else {
                    formData.append('images[]', file[x]!);
                }   
            }
        }
        try {
            const { chat }: any = await PrivateMessageService.createDirectChats(
                formData,
            );
            if (!activeMessage?.id) {
                // alert('abc')
                // fetchMessages(chat);
                fetchChats();
                setActiveMessage(chat);
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            //
        } finally {
        }
    };

    const getLanguage = (lang) => {
        let result = 'ja';
        if (lang.startsWith('ko')) {
            result = 'ko';
        } else if (lang.startsWith('km') || lang.startsWith('kh')) {
            result = 'km';
        } else if (lang.startsWith('en')) {
            result = 'en';
        } else if (lang.startsWith('vi') || lang.startsWith('vn')) {
            result = 'vi';
        } else if (lang.startsWith('zh')) {
            result = 'zh';
        } else if (
            lang.startsWith('si') ||
            lang.startsWith('ta') ||
            lang.startsWith('lk')
        ) {
            result = 'si';
        } else if (lang.startsWith('ja')) {
            result = 'ja';
        }
        return result;
    };

    // const handleReceived = async (response) => {
    //     try {
    //         const { data } = response;
    //         const { message } = await messageService.getMessagesById(data.id);
    //         setListMessage((preState) => [...preState, message]);
    //     } catch (error) {}
    // };

    const navigate = useNavigate();

    // const fetchMessages = async (params) => {
    //     const { direct_chats }: any = await PrivateMessageService.getDirectMessages(
    //         activeMessage?.id || params?.id,
    //         {
    //             page: 1,
    //             per_page: 99999,
    //         },
    //     );
    //     setListMessage(orderBy(direct_chats, 'id', 'asc'));
    // };

    const removeImage = (idx, values, setFieldValue) => {
        let current = [...values.file];
        current = current.filter((item, index) => index !== idx);
        setFieldValue('file', current);
        let listError = current?.filter(f => f?.size > 10 * 1024 * 1024);
        setListErrorFile(listError);
    };

    const handleFileChange = async (e: any, values, setFieldValue) => {
        if (e.target.files.length) {
            const files: File[] = Array.from(e.target.files);
            let current: any = [...values.file];
            if (files.length > 0) {
                if (current.length > 0) {
                    current = current.concat(files);
                } else {
                    current = files;
                }
                setFieldValue('file', current);
            }
            let filesExceeds10MB = files.filter((f: { size: number; }) => f?.size > 10 * 1024 * 1024)
            setListErrorFile(filesExceeds10MB);
        }
    };

    // useEffect(() => {
    //     fetchMessages({});
    // }, [activeMessage]);

    return {
        ...props,
        navigate,
        listMessage,
        userData,
        // handleReceived,
        handleFileChange,
        removeImage,
        unreadFlag,
        getLanguage,
        isLoading,
        activeMessage,
        onSubmit,
        listErrorFile
    };
};
export type Props = ReturnType<typeof useChatBoxPrivateMessage>;

export default useChatBoxPrivateMessage;
