import fetch from './api/config';

const API = {
    GET_LIST_WORKING_LOG: () => `/sp/working_logs`,
    DELETE_WORKING_LOG: (id: number) => `/sp/working_logs/${id}`,
    UPLOAD_WORKING_LOG_IMAGE: (id) => `/sp/working_logs/${id}/add_images`,
    DELETE_WORKING_LOG_IMAGE: (id) => `/sp/working_logs/${id}/delete_image`,
    WORKING_LOG_DETAIL: (id) => `/sp/working_logs/${id}`,
};

export default class WorkingLogService {
    static getListWorkingLogs = (params: any) =>
        fetch.get(API.GET_LIST_WORKING_LOG(), params);

    static deleteWorkingLog = (id: number, project_id: number) =>
        fetch.delete(API.DELETE_WORKING_LOG(id), { project_id: project_id });

    static uploadImage = (id: number, params: any) =>
        fetch.put(API.UPLOAD_WORKING_LOG_IMAGE(id), params);

    static deleteImage = (id: number, params: any) =>
        fetch.delete(API.DELETE_WORKING_LOG_IMAGE(id), params);

    static createWorkingLog = (params: any) =>
        fetch.post(API.GET_LIST_WORKING_LOG(), params);

    static getWorkingLogDetail = (id: number, params: any) =>
        fetch.get(API.WORKING_LOG_DETAIL(id), params);

    static updateWorkingLog = (id: number, params: any) =>
        fetch.put(API.WORKING_LOG_DETAIL(id), params);
}
