import { required } from "../validator";

import { TypeOfT } from "utils/type/translations/Translation.type";

const createNewsValidation = (t: TypeOfT) => ({
  title: () => ({
    required: required('title', t)
  }),
  content: () => ({
    required: required('content', t)
  }),
  publish_at: () => ({
    required: required('publish_at', t)
  }),
});

export default createNewsValidation