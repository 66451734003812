import moment from 'moment';

/*
 * @param yyyy/MM/DDTHH:mm:ss.sssZ
 * || yyyy/MM/DDTHH:mm:ss+00:00
 * @param format Date
 * @param prefix String
 * return DD/MM/YYYY HH:mm
 */
export const formatDateTime = (
    dateTime,
    format = 'DD/MM/YYYY HH:mm',
    prefix = '',
) => {
    try {
        if (!dateTime) return '';
        if (moment(dateTime).isValid())
            return prefix + moment(dateTime).format(format);
    } catch (error) {
        console.log('Catch formatDateTime', error);
    }
};

export const getDateTimeUtc = (dateTime) => {
    // input format: 2023/03/30 07:30
    // output format: 2023-03-30T00:30:00Z

    const currentDate = moment(dateTime).utc().format();
    return currentDate;
};
