import React, { useState } from 'react';
import _ from 'lodash'

import commentService from 'services/commentService';

export function useLogicComment() {

    const [comment, setComment] = useState<any>([]);



    const getListComment = async (params: any) => {
        try {
            const response = await commentService.getListComment(params)
            if (response !== null && response !== undefined) {
                let arr = [...(response?.comments || [])];
                const replyComments = arr.filter(comment => _.size(comment.reply_comments))

                replyComments.forEach(comment => {
                    comment.reply_comments.forEach(data => {
                        arr = arr.map(result => {
                            if (result.id === data.id) {
                                result.contentReply = comment
                            }
                            return result
                        })
                    })
                })

                setComment(arr.sort((a, b) => a.id - b.id))
            }
        } catch (err) {
            setComment([])
            console.log('err', err)
        }
    }

    const createComment = async (payload: any) => {
        const formData = new FormData();
        formData.append("body", payload.body)
        formData.append("estimate_id", payload.estimate_id)
        formData.append("worker_id", payload.worker_id)
        if (payload.estimate_item_id) formData.append("estimate_item_id", payload.estimate_item_id)
        if (payload.reply_comment_id) formData.append("reply_comment_id", payload.reply_comment_id)

        try {
            const response = await commentService.createComment(formData)
            if (response !== null && response !== undefined) {
                // setComment(response?.comments || [])
            }
        } catch (err) {
            setComment([])
            console.log('err', err)
        }
    }

    const updateComment = async (payload: any) => {
        const formData = new FormData();
        formData.append("estimate_id", payload.estimate_id)
        formData.append("worker_id", payload.worker_id)
        formData.append("status", payload.status)
        if (payload.estimate_item_id) formData.append("estimate_item_id", payload.estimate_item_id)


        try {
            const response = await commentService.updateComment(payload.id, formData)
            if (response !== null && response !== undefined) {
                // setComment(response?.comments || [])
            }
        } catch (err) {
            setComment([])
            console.log('err', err)
        }
    }




    return {
        comment,

        getListComment,
        createComment,
        updateComment
    }
}