import { padStart } from 'lodash';
// import { ROUND_DOWN_TIME_DEPOSIT_AMOUNT } from '../../config/constants/business';
import { timesByDecimal, divideByDecimal } from './calculationHelper';

/*
 * function roundDown
 *
 * Number
 *   Return number rounded down by divide
 *   ex) roundDown(5500,1000) => 5000
 */
export const roundDown = (baseNumber: number, divide: number): number => {
    // eslint-disable-next-line radix
    if (baseNumber >= 100) {
      return baseNumber - 100;
    }
    
    return timesByDecimal(
        Math.floor(divideByDecimal(baseNumber, divide)),
        divide,
    );
};

/*
 * function roundDownTimeDepositAmount
 *
 * return
 *   string (str) after fullWidth characters are removed
 *
 */
// export const roundDownTimeDepositAmount = (number: number): number => {
//   return roundDown(number, ROUND_DOWN_TIME_DEPOSIT_AMOUNT);
// };

/*
 * function zeroPadding
 *
 * return
 *   string : string after zeroPadding
 *
 */
export const zeroPadding = (number: number, digit: number): string => {
    return padStart(String(number), digit, '0');
};

/*
 * function commaSeparatedFormat
 *
 * return
 *   string : number separated by common per 3 digits
 *
 */
export const commaSeparatedFormat = (number: number, fixed: number): string => {
    return number.toLocaleString('en-US', { minimumFractionDigits: fixed });
};

/*
 * function isDecimalNumberInput
 *
 * True
 *   If {str} is "Decimal Number Input Format"
 *
 *   [example]
 *     true  : 1 1.0 0.1     (General rule)
 *     true  : 1. 100. 2.    (Special rule)
 *     false : 1.1.1 .1
 *
 */
export const isDecimalNumberInput = (str: string): boolean => {
    const expression = '^[0-9]+(?!(\\..*\\..*))(\\.[0-9]+|(\\.$)|$)';
    return new RegExp(expression).test(str);
};

/*
 * function commaFormatToNumber
 *
 * return
 *   number : commaFormat to number
 *
 */
export const commaFormatToNumber = (numString: string) =>
    Number(numString.replace(/,/g, ''));

export const divideByTen = (a: number) => a / 10;
