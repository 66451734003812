import styled from "styled-components";

export const ContactStyles = styled.div`
  padding-top: 30px;
  .innerWrap {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      margin-bottom: 15px;
    }
  }
`;