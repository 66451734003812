import fetch from './api/config';

const API = {
    GET_PROJECT: () => `/sp/projects`,
    GET_PROJECT_DETAIL: (id: number) => `/sp/projects/${id}`,
    CHECK_SUGOROKU_PERMISSIONS: (id: number) => `sp/projects/${id}/permissions`,
};

export default class ProjectService {
    static getProjectLists = (params: any) =>
        fetch.get(API.GET_PROJECT(), params);

    static getProjectDetail = (id: number) =>
        fetch.get(API.GET_PROJECT_DETAIL(id), {});

    static checkPermissions = (id: number) =>
        fetch.get(API.CHECK_SUGOROKU_PERMISSIONS(id), {});
}
