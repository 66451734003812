import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export type ReceivedProps = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  fetchSchedules: () => Promise<void>;
};

export interface CustomizedState {
  plan_id: number;
  project_id: number;
  currentPlan: any;
}

const useProjectPlanDialog = (props: ReceivedProps) => {
  const location = useLocation();
  const state = location.state as CustomizedState;
  const plan_id = state.plan_id;
  const project_id = state.project_id;
  const currentPlan = state.currentPlan;

  const [projectPlanChange, setProjectPlanChange] = useState(false);
  

  return {
    ...props,
    plan_id,
    project_id,
    currentPlan,
    projectPlanChange,
    setProjectPlanChange,
  }
}

export type Props = ReturnType<typeof useProjectPlanDialog>;

export default useProjectPlanDialog;