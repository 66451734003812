import { useState, useEffect } from 'react';
import './WorkingReport.css';
import plus from '../../../../assets/icons/Plus.png';
import { RootState, useAppDispatch } from '../../../../store/index';
import { useSelector } from 'react-redux';
import {
    setAddSuccess,
    setEditSuccess,
    setFetchingLog,
    setLogs,
    setShowModalCreate,
    setShowModalDetail,
    setWorkers,
    setShowModalUpdate,
} from 'store/workingReport/workingReport';
import reportsService from 'services/reportsService';
import workingReportsService from 'services/workingReportsService';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import workingLogService from 'services/workingLogService';
import { formatDateTime } from 'utils/helper/dateHelper';
import calendarIcon from '../../../../assets/icons/calendar.png';
import DatePicker from 'react-datepicker';
import { Autocomplete, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import wokersService from 'services/wokersService';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import WorkingReportLog from './WorkingReportLog';
import { useModal } from 'react-hooks-use-modal';
import createWorkingReportValidation from 'utils/validation/registers/workingReports/createWorkingReportValidation';
import classNames from 'classnames';
import _ from 'lodash';
import projectsService from 'services/projectsService';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '8px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const checkBoxStyles = {
    checkmark: {
        background: '#3DDD74',
        color: 'white',
    },
    checkbox: {
        background: '#3DDD74',
        borderColor: '#3DDD74',
        width: '15px',
        margin: '5px 15px 0 0',
        height: '15px',
    },
};

const WorkingReportCreate = ({ currentProject }) => {
    const { t } = useTranslation();
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        watch,
        formState: { errors },
    } = useForm();
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const {
        showModalCreate = false,
        showModalDetail = false,
        showModalUpdate = false,
        selectedReport = {} as any,
        workers = [],
        logs = [] as any[],
    } = useSelector((state: RootState) => state.workingReport);
    const [selectedRow, setSelectedRow] = useState<any>();

    const [ModalCreate, openCreate, closeCreate] = useModal('root', {
        preventScroll: false,
        //closeOnOverlayClick: false,
    });
    const [ModalUpdate, openUpdate, closeUpdate] = useModal('root', {
        preventScroll: false,
        //closeOnOverlayClick: false,
    });
    const [projectOption, setProjectOptions] = useState<any>([]);
    const [disable, setDisable] = useState(false);

    const onDelete = (item: any) => {
        dispatch(setLogs(logs.filter((record) => record !== item)));
    };
    const onClose = () => {
        reset();
        dispatch(setLogs([]));
        if (showModalDetail) {
            dispatch(setShowModalDetail(false));
        } else if (showModalCreate) {
            dispatch(setShowModalCreate(false));
        } else if (showModalUpdate) {
            dispatch(setShowModalUpdate(false));
        }
    };

    const fetchProjects = async () => {
        try {
            const { projects } = await projectsService.getListProjects({
                page: 1,
                per_page: 9999,
            });
            setProjectOptions(
                projects.map((item) => ({
                    label: item.name,
                    value: item.id,
                })),
            );
        } catch (error) {
            //
        }
    };

    const handleCreate = async (body) => {
        try {
            const response = await reportsService.createReport(body);
            if (response) {
                reset();
                dispatch(setShowModalCreate(false));
                dispatch(setAddSuccess(true));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleEdit = async (body) => {
        try {
            const response = await reportsService.updateReport(
                body,
                selectedReport?.id,
            );
            if (response) {
                reset();
                dispatch(setShowModalUpdate(false));
                dispatch(setEditSuccess(true));
            }
        } catch (e) {
            console.log(e);
        }
    };

    const onSubmit = (value) => {
        const body = {
            manager_worker_id: value?.manager_worker_id?.value,
            published_at: moment(value?.published_at).format('YYYY-MM-DD'),
            submitted_at: null,
            from_at: moment(value?.from_at).format('YYYY-MM-DD'),
            to_at: moment(value?.to_at).format('YYYY-MM-DD'),
            project_id: id ? id : value?.project_id?.value,
            working_log_ids: logs?.map((log) => log?.id) || [],
            note: value?.note || '',
            issue_note: value?.issue_note || '',
            improve_note: value?.improve_note || '',
        };
        if (showModalCreate) {
            handleCreate(body);
        } else if (showModalUpdate) {
            handleEdit(body);
        }
    };

    const onSendMail = () => {
        const value = getValues();
        const body = {
            manager_worker_id: value?.manager_worker_id?.value,
            published_at: moment(value?.published_at).format('YYYY-MM-DD'),
            submitted_at: moment().format('YYYY-MM-DD'),
            from_at: moment(value?.from_at).format('YYYY-MM-DD'),
            to_at: moment(value?.to_at).format('YYYY-MM-DD'),
            project_id: id ? id : value?.project_id?.value,
            working_log_ids: logs?.map((log) => log?.id) || [],
            note: value?.note || '',
            issue_note: value?.issue_note || '',
            improve_note: value?.improve_note || '',
        };
        if (showModalCreate) {
            handleCreate(body);
        } else if (showModalUpdate) {
            handleEdit(body);
        }
    };

    const onPrintPdf = async (event) => {
        setDisable(true);
        const {
            manager_worker_id,
            submitted_at,
            published_at,
            from_at,
            to_at,
            note,
            issue_note,
            improve_note,
            project_id,
        } = getValues();
        const body = {
            manager_worker_id: manager_worker_id.value,
            published_at: moment(published_at).format('YYYY-MM-DD'),
            submitted_at: moment(submitted_at).format('YYYY-MM-DD'),
            from_at: moment(from_at).format('YYYY-MM-DD'),
            to_at: moment(to_at).format('YYYY-MM-DD'),
            project_id: id ? id : project_id?.value,
            working_log_ids: logs?.map((log) => log?.id) || [],
            note: note || '',
            issue_note: issue_note || '',
            improve_note: improve_note || '',
            working_report_id: showModalCreate ? '' : selectedReport?.id,
        };
        try {
            const response = await workingReportsService.printPdf({
                ...body,
            });
            if (response) {
                setDisable(false);
                const newBlob = new Blob([response.data], {
                    type: 'application/pdf',
                });
                const file = window.URL.createObjectURL(newBlob);
                window.open(file, '_blank');
            }
        } catch (e) {
            setDisable(false);
            console.log(e);
        }
    };

    const footer = (
        <div className="flex justify-end mt-[16px]">
            <div
                className={classNames(
                    'grid gap-x-[16px] justify-end grid-cols-4 w-full xl:w-3/4',
                    // showModalCreate ? 'grid-cols-3 w-1/2' : 'grid-cols-4 w-3/4',
                )}
            >
                <button
                    type="button"
                    className="border-main border-[1px] text-main px-[20px] py-[10px] rounded-[6px]"
                    style={{ color: '#215493' }}
                    onClick={onClose}
                >
                    キャンセル
                </button>
                {/* {showModalUpdate && ( */}
                <button
                    disabled={disable}
                    type="button"
                    onClick={onPrintPdf}
                    className="bg-main px-[20px] py-[10px] rounded-[6px] text-white"
                >
                    PDF確認
                </button>
                {/* )} */}
                <button
                    type="submit"
                    className="bg-main px-[20px] py-[10px] rounded-[6px] text-white"
                    disabled={showModalDetail}
                >
                    登録
                </button>
                <button
                    type="button"
                    onClick={onSendMail}
                    className="bg-main px-[20px] py-[10px] rounded-[6px] text-white"
                >
                    登録して送信
                </button>
            </div>
        </div>
    );

    const covertOptions = (workers) => {
        return workers?.map((worker) => {
            return {
                label: worker?.name,
                value: worker?.id,
            };
        });
    };

    const getListWorkingLog = async (page = 1, data = {}) => {
        try {
            dispatch(setFetchingLog(true));
            const response = await workingLogService.getListWorkingLogs({
                page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                dispatch(setLogs(response.working_logs));
            }
        } catch (err) {
            console.log('err', err);
        } finally {
            dispatch(setFetchingLog(false));
        }
    };

    const getLogs = (data) => {
        getListWorkingLog(1, {
            project_id: id ? id : data?.project_id?.value,
            start_at: data?.start_at,
            end_at: data?.end_at,
            // worker_id: data?.worker_id,
        });
    };

    const refreshLogs = () => {
        const { from_at, to_at, manager_worker_id, project_id } = getValues();
        const start_at = from_at;
        const end_at = to_at;
        const worker_id = manager_worker_id?.value;
        selectedReport?.manager_worker?.id;
        getLogs({ start_at, end_at, worker_id, project_id });
    };

    const setWeek = () => {
        setValue(
            'from_at',
            moment()
                .subtract(1, 'week')
                .startOf('week')
                .add(1, 'day')
                .toDate() || null,
        );
        setValue(
            'to_at',
            moment()
                .subtract(1, 'week')
                .startOf('week')
                .add(5, 'day')
                .toDate() || null,
        );
    };

    const getListWorkers = async () => {
        try {
            const response = await wokersService.getListWorkers({
                project_id:
                    selectedReport?.project?.id ||
                    getValues('project_id')?.value,
            });
            const worker: any = response?.workers?.find(
                (worker: any) =>
                    worker?.id === selectedReport?.manager_worker?.id,
            );
            setValue('manager_worker_id', {
                label: worker?.name,
                value: worker?.id,
            });
            if (response !== null && response !== undefined) {
                dispatch(setWorkers(response?.workers || []));
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    useEffect(() => {
        if (!currentProject?.id) {
            getListWorkers();
        }
    }, [watch('project_id')?.value]);
    useEffect(() => {
        if (showModalCreate) {
            if (currentProject.manager_worker) {
                setValue('manager_worker_id', {
                    label: currentProject.manager_worker?.name || '',
                    value: currentProject.manager_worker?.id || '',
                });
            } else {
                setValue('manager_worker_id', null);
            }
        }
        if (!currentProject?.id) {
            fetchProjects();
        }
    }, [showModalCreate]);

    useEffect(() => {
        if (showModalUpdate || showModalDetail) {
            setValue(
                'from_at',
                moment(selectedReport?.from_at).toDate() || null,
            );
            setValue('to_at', moment(selectedReport?.to_at).toDate() || null);
            setValue(
                'published_at',
                moment(selectedReport?.published_at).toDate() || null,
            );
            setValue(
                'submitted_at',
                moment(selectedReport?.submitted_at).toDate() || null,
            );
            setValue(
                'project_id',
                {
                    label: selectedReport?.project?.name,
                    value: selectedReport?.project?.id,
                } || null,
            );
            setValue('note', selectedReport?.note);
            setValue('issue_note', selectedReport?.issue_note);
            setValue('improve_note', selectedReport?.improve_note);
            const worker: any = workers?.find(
                (worker: any) =>
                    worker?.id === selectedReport?.manager_worker?.id,
            );
            if (worker) {
                setValue('manager_worker_id', {
                    label: worker?.name,
                    value: worker?.id,
                });
            }
            dispatch(setLogs(selectedReport?.working_logs));
        }
    }, [showModalUpdate, showModalDetail]);

    return (
        <>
            <div className=" bg-[#ffffff] flex flex-col rounded-[12px] justify-center items-center p-[14px] mx-auto">
                <div className="modal-create-user-title d-flex flex-row">
                    {showModalCreate && '作業報告書作成(週報)'}
                    {showModalDetail && '作業報告書(週報)詳細'}
                    {showModalUpdate && '作業報告書(週報)編集'}
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-col overflow-auto max-h-[80vh] p-[14px]">
                        <p className="text-[14px] mb-[4px] font-medium">
                            作業所名
                        </p>
                        <p className="text-[16px] mb-[24px]">
                            {showModalCreate
                                ? currentProject?.name
                                : selectedReport?.project?.name}
                        </p>
                        <div className="w-full grid grid-cols-2 gap-x-[28px]">
                            <div className="flex flex-col">
                                <Controller
                                    name="manager_worker_id"
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex-1 custom-dropdown">
                                            <p className="text-[14px] mb-[4px] font-medium">
                                                管理者名
                                            </p>
                                            <Autocomplete
                                                disablePortal
                                                options={covertOptions(workers)}
                                                {...register(
                                                    'manager_worker_id',
                                                    createWorkingReportValidation(
                                                        t,
                                                    ).manager_worker_id(),
                                                )}
                                                {...field}
                                                value={
                                                    field.value
                                                        ? {
                                                              label: field.value
                                                                  ?.label,
                                                              value: field.value
                                                                  ?.value,
                                                          }
                                                        : null
                                                }
                                                onChange={(event, newValue) => {
                                                    if (newValue) {
                                                        setValue(
                                                            'manager_worker_id',
                                                            newValue,
                                                        );
                                                    } else {
                                                        setValue(
                                                            'manager_worker_id',
                                                            null,
                                                        );
                                                    }
                                                }}
                                                classes={{
                                                    root: 'input-select-style',
                                                    input: 'input-select-style',
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label=""
                                                    />
                                                )}
                                                disabled={showModalDetail}
                                                noOptionsText="該当なし"
                                            />
                                        </div>
                                    )}
                                />
                                {errors.manager_worker_id && (
                                    <span className="error">
                                        {errors.manager_worker_id.message?.toString()}
                                    </span>
                                )}
                            </div>
                            <div className="flex flex-col">
                                <Controller
                                    rules={{ required: true }}
                                    name="published_at"
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex-1 possition-relative">
                                            <p className="text-[14px] mb-[4px] font-medium">
                                                発行日
                                            </p>
                                            <DatePicker
                                                {...field}
                                                {...register(
                                                    'published_at',
                                                    createWorkingReportValidation(
                                                        t,
                                                    ).published_at(),
                                                )}
                                                id="published_at"
                                                className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                                selected={getValues(
                                                    'published_at',
                                                )}
                                                onChange={(date) => {
                                                    setValue(
                                                        'published_at',
                                                        date,
                                                    );
                                                }}
                                                dateFormat="yyyy/MM/dd"
                                                autoComplete="off"
                                                locale="ja"
                                                disabled={showModalDetail}
                                            />
                                            <label htmlFor="published_at">
                                                <img
                                                    className="absolute-bottom"
                                                    width={'16px'}
                                                    height={'16px'}
                                                    src={calendarIcon}
                                                />
                                            </label>
                                        </div>
                                    )}
                                />
                                {errors.published_at && (
                                    <span className="error">
                                        {errors.published_at.message?.toString()}
                                    </span>
                                )}
                            </div>
                            <div className="mt-[12px]">
                                <p className="text-[14px] mb-[4px]">作業日</p>
                                <div className="flex items-baseline justify-between gap-x-[8px] mt-[8px]">
                                    <div className="flex flex-col">
                                        <Controller
                                            rules={{ required: true }}
                                            name="from_at"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <div className="flex-1 relative">
                                                        <DatePicker
                                                            {...field}
                                                            {...register(
                                                                'from_at',
                                                                createWorkingReportValidation(
                                                                    t,
                                                                ).from_at(),
                                                            )}
                                                            id="from_at"
                                                            className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px] mt-[8px]"
                                                            selected={getValues(
                                                                'from_at',
                                                            )}
                                                            onChange={(
                                                                date,
                                                            ) => {
                                                                setValue(
                                                                    'from_at',
                                                                    date,
                                                                );
                                                            }}
                                                            dateFormat="yyyy/MM/dd"
                                                            autoComplete="off"
                                                            locale="ja"
                                                            disabled={
                                                                showModalDetail
                                                            }
                                                        />
                                                        <label htmlFor="from_at">
                                                            <img
                                                                className="absolute-bottom"
                                                                width={'16px'}
                                                                height={'16px'}
                                                                src={
                                                                    calendarIcon
                                                                }
                                                            />
                                                        </label>
                                                    </div>
                                                </>
                                            )}
                                        />
                                        {errors.from_at && (
                                            <span className="error">
                                                {errors.from_at.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                    <span className="flex-0 pb-[6px]">~</span>
                                    <div className="flex flex-col">
                                        <Controller
                                            name="to_at"
                                            rules={{ required: true }}
                                            control={control}
                                            render={({ field }) => (
                                                <div className="flex-1 possition-relative">
                                                    <label htmlFor="to_at"></label>
                                                    <DatePicker
                                                        {...field}
                                                        {...register(
                                                            'to_at',
                                                            createWorkingReportValidation(
                                                                t,
                                                            ).to_at(),
                                                        )}
                                                        className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px] mt-[8px]"
                                                        selected={getValues(
                                                            'to_at',
                                                        )}
                                                        id="to_at"
                                                        onChange={(date) => {
                                                            setValue(
                                                                'to_at',
                                                                date,
                                                            );
                                                        }}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                        disabled={
                                                            showModalDetail
                                                        }
                                                    />
                                                    <label htmlFor="to_at">
                                                        <img
                                                            className="absolute-bottom"
                                                            width={'16px'}
                                                            height={'16px'}
                                                            src={calendarIcon}
                                                        />
                                                    </label>
                                                </div>
                                            )}
                                        />
                                        {errors.to_at && (
                                            <span className="error">
                                                {errors.to_at.message?.toString()}
                                            </span>
                                        )}
                                    </div>
                                    <button
                                        className="bg-main px-[20px] py-[7px] rounded-[6px] text-white whitespace-nowrap"
                                        type="button"
                                        onClick={setWeek}
                                        disabled={showModalDetail}
                                    >
                                        先週
                                    </button>
                                </div>
                            </div>
                            {!currentProject?.id && (
                                <div className="flex mt-[12px] flex-col">
                                    <Controller
                                        name="project_id"
                                        rules={{ required: true }}
                                        control={control}
                                        render={({ field }) => (
                                            <div className="flex-1 custom-dropdown">
                                                <p className="text-[14px] mb-[4px] font-medium">
                                                    案件名
                                                </p>
                                                <Autocomplete
                                                    disabled={
                                                        showModalDetail ||
                                                        showModalUpdate
                                                    }
                                                    disablePortal
                                                    options={projectOption}
                                                    {...register(
                                                        'project_id',
                                                        // createWorkingReportValidation(
                                                        //     t,
                                                        // ).project_id(),
                                                    )}
                                                    {...field}
                                                    value={
                                                        field.value
                                                            ? {
                                                                  label: field
                                                                      .value
                                                                      ?.label,
                                                                  value: field
                                                                      .value
                                                                      ?.value,
                                                              }
                                                            : null
                                                    }
                                                    onChange={(
                                                        event,
                                                        newValue,
                                                    ) => {
                                                        if (newValue) {
                                                            setValue(
                                                                'project_id',
                                                                newValue,
                                                            );
                                                        } else {
                                                            setValue(
                                                                'project_id',
                                                                null,
                                                            );
                                                        }
                                                    }}
                                                    classes={{
                                                        root: 'input-select-style mt-[16px]',
                                                        input: 'input-select-style',
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label=""
                                                        />
                                                    )}
                                                    noOptionsText="該当なし"
                                                />
                                            </div>
                                        )}
                                    />
                                    {errors.project_id && (
                                        <span className="error">
                                            {errors.project_id.message?.toString()}
                                        </span>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="flex justify-between mt-[16px]">
                            <div className="flex items-center gap-x-[22px]">
                                <span className="font-bold text-[16px]">
                                    作業内容
                                </span>
                                <button
                                    disabled={showModalDetail}
                                    type="button"
                                    className="bg-main px-[20px] py-[7px] rounded-[6px] text-white"
                                    onClick={refreshLogs}
                                >
                                    読込
                                </button>
                            </div>
                            <button
                                disabled={showModalDetail}
                                type="button"
                                className="border-main border-[1px] text-main px-[20px] py-[4px] rounded-[6px] flex items-center"
                                onClick={() => openCreate()}
                            >
                                <img src={plus} alt="plus" />
                                <span className="button-plus-wrp">追加</span>
                            </button>
                        </div>
                        <div className="table-workers d-flex flex-column mt-[16px]">
                            <div className="table-content d-flex flex-column">
                                <TableContainer
                                    component={Paper}
                                    table-layout={'auto'}
                                >
                                    <Table aria-label="customized ">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    作業日
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    工程
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    作業名
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    操作
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {logs !== null &&
                                                logs.map((record, ind) => (
                                                    <StyledTableRow key={ind}>
                                                        <StyledTableCell
                                                            className="text-overflow "
                                                            align="center"
                                                        >
                                                            {formatDateTime(
                                                                record?.worked_at,
                                                                'YYYY/MM/DD',
                                                            )}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            className="text-overflow"
                                                            align="center"
                                                        >
                                                            {record?.plan?.name}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            className="text-overflow"
                                                            align="center"
                                                        >
                                                            {record?.name}
                                                        </StyledTableCell>
                                                        <StyledTableCell
                                                            className="text-overflow"
                                                            align="center"
                                                        >
                                                            <div className="flex justify-center gap-x-[16px]">
                                                                <button
                                                                    disabled={
                                                                        showModalDetail
                                                                    }
                                                                    type="button"
                                                                    className="bg-main px-[20px] py-[4px] rounded-[6px] text-white"
                                                                    onClick={() => {
                                                                        setSelectedRow(
                                                                            record,
                                                                        );
                                                                        openCreate();
                                                                    }}
                                                                >
                                                                    詳細
                                                                </button>
                                                                <button
                                                                    disabled={
                                                                        showModalDetail
                                                                    }
                                                                    type="button"
                                                                    className="bg-main px-[20px] py-[4px] rounded-[6px] text-white"
                                                                    onClick={() => {
                                                                        setSelectedRow(
                                                                            record,
                                                                        );
                                                                        openCreate();
                                                                    }}
                                                                >
                                                                    編集
                                                                </button>
                                                                <button
                                                                    disabled={
                                                                        showModalDetail
                                                                    }
                                                                    type="button"
                                                                    className="bg-red px-[20px] py-[4px] rounded-[6px] text-white"
                                                                    onClick={() =>
                                                                        onDelete(
                                                                            record,
                                                                        )
                                                                    }
                                                                >
                                                                    削除
                                                                </button>
                                                            </div>
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                ))}
                                            {/* {logs?.length === 0 && (
                                                <StyledTableRow>
                                                    <StyledTableCell
                                                        className="text-overflow"
                                                        align="center"
                                                        colSpan={4}
                                                    >
                                                        データなし
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )} */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                        <Controller
                            name="note"
                            control={control}
                            render={({ field }) => (
                                <div className="w-full flex flex-col mt-[16px]">
                                    <p className="text-[14px] font-medium">
                                        備考
                                    </p>
                                    <textarea
                                        disabled={showModalDetail}
                                        {...register('note')}
                                        {...field}
                                        className="rounded-[8px] border-[1px] border-[#CDD1D5] mt-[12px] p-[16px]"
                                        rows={3}
                                    ></textarea>
                                </div>
                            )}
                        />

                        <Controller
                            name="issue_note"
                            control={control}
                            render={({ field }) => (
                                <div className="w-full flex flex-col mt-[16px]">
                                    <p className="text-[14px] font-medium">
                                        是正箇所
                                    </p>
                                    <textarea
                                        disabled={showModalDetail}
                                        {...register('issue_note')}
                                        {...field}
                                        className="rounded-[8px] border-[1px] border-[#CDD1D5] mt-[12px] p-[16px]"
                                        rows={3}
                                    ></textarea>
                                </div>
                            )}
                        />

                        <Controller
                            name="improve_note"
                            control={control}
                            render={({ field }) => (
                                <div className="w-full flex flex-col mt-[16px]">
                                    <p className="text-[14px] font-medium">
                                        改善方法
                                    </p>
                                    <textarea
                                        disabled={showModalDetail}
                                        {...register('improve_note')}
                                        {...field}
                                        className="rounded-[8px] border-[1px] border-[#CDD1D5] mt-[12px] p-[16px]"
                                        rows={3}
                                    ></textarea>
                                </div>
                            )}
                        />
                    </div>
                    {footer}
                </form>
            </div>
            <ModalCreate>
                <WorkingReportLog
                    id={selectedRow?.id}
                    project_id={id ? id : getValues('project_id')?.value}
                    onClose={() => {
                        closeCreate();
                    }}
                    onAdd={() => {
                        closeCreate();
                    }}
                />
            </ModalCreate>
            <ModalUpdate>
                <WorkingReportLog
                    id={selectedRow?.id}
                    project_id={id ? id : getValues('project_id')?.value}
                    onClose={() => {
                        closeCreate();
                    }}
                    onAdd={() => {
                        closeCreate();
                    }}
                />
            </ModalUpdate>
        </>
    );
};

export default WorkingReportCreate;
