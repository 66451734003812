import styled from 'styled-components';

export const StylesCreateMeetingMinutes = styled.div`
    margin: 28px;
    background-color: #fff;
    border-radius: 12px;
    padding: 32px 28px;
    .Mui-disabled {
        background-color: rgba(0, 0, 0, 0.26) !important;
        color: #fff !important;
    }
    .title {
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 30px;
    }
    .sigCanvas {
        background-color: #f3f3f3;
        border: 1px solid #d0d2d3;
        border-radius: 4px;
        position: relative;
    }
    .undo-image {
        background-color: #c7dcf5;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #466eb6;
        position: relative;
        cursor: pointer;
        position: absolute;
        top: 41px;
        right: 11px;
    }
    .custom-checkbox {
        margin: 0 12px 0 0;
        .ant-checkbox-inner {
            background-color: #3ddd74;
            border: 1px solid #3ddd74;
            outline: none;
        }
        .ant-checkbox-checked::after {
            border: none;
        }
    }
    label {
        color: #344054;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin-bottom: 8px;
    }
    input,
    textarea {
        border: 1px solid #cdd1d5;
        height: 35px;
        border-radius: 4px;
        padding-left: 12px;
        outline: none;
    }
    textarea {
        min-height: 83px;
    }
`;
