import { useEffect, useState } from 'react';
import Close from '@mui/icons-material/Close';
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    TextField,
    Typography,
    Paper,
} from '@mui/material';
import { useFormik } from 'formik';
import { useParams } from 'react-router-dom';
import IndustriesWatesService from 'services/industriesWatesService';
import userService from 'services/userService';
import { convertUserConfigToDropdownOptions } from 'utils/helper/userConfigHelper';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import CooperativeCompanyService from 'services/cooperativeCompaniesService';
import { convertClientCompaniesToDropdownOptions } from 'utils/helper/clientCompanyHelper';
interface ICreateOrderPopup {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    initValues: any;
    getListOrderItems: any;
    setInitValues: (initValues: any) => void;
    wasteType: any;
}
const CreateOrderPopup = ({
    isOpen,
    setIsOpen,
    initValues,
    getListOrderItems,
    setInitValues,
    wasteType
}: ICreateOrderPopup) => {
    const { id } = useParams();
    // const [wasteType, setWasteType] = useState<any>([]);
    const [companyOption, setCompanyOption] = useState<any>([]);

    const getListCompanies = async () => {
        try {
            const response =
                await CooperativeCompanyService.getListCooperativeCompanies();
            setCompanyOption(
                convertClientCompaniesToDropdownOptions(
                    response.cooperative_companies,
                ),
            );
        } catch (err) {
            console.log('err', err);
        }
    };

    // const getListConfiguration = async () => {
    //     try {
    //         const response = await userService.getListConfiguration();
    //         setWasteType(
    //             convertUserConfigToDropdownOptions(
    //                 response.configurations.order_item.waste_type,
    //             ),
    //         );
    //     } catch (err) {
    //         console.log('err', err);
    //     }
    // };

    const handleCreateOrderItem = async (values) => {
        try {
            if (initValues?.id) {
                await IndustriesWatesService?.updateOrderItem(initValues.id, {
                    ...values,
                    project_id: id,
                });
            }
            else {
                await IndustriesWatesService.createOrderItem({
                    ...values,
                    project_id: id,
                });
            }
            getListOrderItems()
            setIsOpen(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleClose = () => {
        setIsOpen(false);
        setInitValues(null)
    };

    useEffect(() => {
        // getListConfiguration();
        getListCompanies();
    }, []);

    const formik = useFormik({
        initialValues: initValues
            ? initValues
            : {
                  name: '',
                  amount: '',
                  collect_amount_per_car: '',
                  collect_receipt_fee: '',
                  disposal_amount_per_unit: '',
                  disposal_receipt_fee: '',
                  num_of_car: '',
                  collect_company_id: '',
                  disposal_company_id: '',
                  waste_type: '',
                  disposal_unit: '',
                  unit: '',
              },
        onSubmit: (values) => handleCreateOrderItem(values),
    });

    // console.log(formik.values);

    return (
        <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={isOpen}
            maxWidth="xs"
            fullWidth={true}
            sx={{
                '.MuiPaper-root': {
                    borderRadius: '12px',
                },
            }}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '28px 28px 24px 28px',
                    position: 'relative',
                }}
            >
                <Box />
                <Typography fontSize="18px" fontWeight="700">
                    委託内容新規作成
                </Typography>
                <Box sx={{ position: 'absolute', right: '16px' }}>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
            </DialogTitle>
            <DialogContent
                sx={{
                    padding: '0px 28px 28px 28px',
                    '.MuiFormControl-root ': {
                        width: '100%',
                    },
                    '.MuiInputBase-root': {
                        border: '1px solid #CDD1D5',
                        height: '36px',
                        backgroundColor: '#fff',
                    },
                    fieldset: {
                        border: 'none',
                    },
                    '.MuiTypography-root': {
                        color: '#344054',
                        fontSize: '14px',
                        fontWeight: '700',
                        mb: '4px',
                    },
                }}
            >
                <Paper
                    sx={{
                        '.Dropdown-control': {
                            borderRadius: '4px !important',
                            height: '36px !important',
                            mb: '12px',
                        },
                        boxShadow: 'none',
                    }}
                    component="form"
                    onSubmit={formik.handleSubmit}
                >
                    <Box>
                        <Typography>委託内容</Typography>
                        <TextField
                            variant="outlined"
                            name="name"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                        />
                    </Box>
                    <Box sx={{ mt: '12px' }}>
                        <Typography>廃材種類</Typography>
                        <Dropdown
                            className="dropdown-create-user1 height36"
                            controlClassName="dropdown-control-user"
                            options={wasteType}
                            value={wasteType?.find(
                                (item) =>
                                    item.value === formik.values.waste_type,
                            )}
                            onChange={(waste_type) => {
                                formik.setFieldValue(
                                    'waste_type',
                                    waste_type.value,
                                );
                            }}
                            placeholder=""
                        />
                    </Box>
                    <Box sx={{ mt: '12px' }}>
                        <Typography>数量</Typography>
                        <TextField
                            type="number"
                            variant="outlined"
                            name="amount"
                            onChange={formik.handleChange}
                            value={formik.values.amount}
                        />
                    </Box>
                    <Box sx={{ mt: '12px' }}>
                        <Typography>单位</Typography>
                        <TextField
                            variant="outlined"
                            name="unit"
                            onChange={formik.handleChange}
                            value={formik.values.unit}
                        />
                    </Box>
                    <Box sx={{ mt: '12px' }}>
                        <Typography>契約金額</Typography>
                        <Box
                            bgcolor="#F2F2F2"
                            borderRadius="6px"
                            padding="10px"
                        >
                            <Typography>収集</Typography>
                            <Grid
                                borderBottom="1px solid #CDD1D5"
                                marginTop="10px"
                                paddingTop="0px"
                                paddingBottom="8px"
                                container
                            >
                                <Grid paddingRight="4px" item xs={6}>
                                    <Box>
                                        <Typography>台数単価</Typography>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            name="collect_amount_per_car"
                                            onChange={formik.handleChange}
                                            value={
                                                formik.values
                                                    .collect_amount_per_car
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid paddingLeft="4px" item xs={6}>
                                    <Box>
                                        <Typography>台数</Typography>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            name="num_of_car"
                                            onChange={formik.handleChange}
                                            value={formik.values.num_of_car}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            <Typography sx={{ mt: '8px' }}>処分</Typography>
                            <Grid
                                marginTop="10px"
                                paddingTop="0px"
                                paddingBottom="8px"
                                container
                            >
                                <Grid paddingRight="4px" item xs={6}>
                                    <Box>
                                        <Typography>単価</Typography>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            name="disposal_amount_per_unit"
                                            onChange={formik.handleChange}
                                            value={
                                                formik.values
                                                    .disposal_amount_per_unit
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid paddingLeft="4px" item xs={6}>
                                    <Box>
                                        <Typography>単位</Typography>
                                        <TextField
                                            // type="number"
                                            variant="outlined"
                                            name="disposal_unit"
                                            onChange={formik.handleChange}
                                            value={formik.values.disposal_unit}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Box sx={{ mt: '12px' }}>
                        <Typography>収集運営</Typography>
                        <Dropdown
                            className="dropdown-create-user1 height36"
                            controlClassName="dropdown-control-user"
                            options={companyOption}
                            value={companyOption?.find(
                                (company) =>
                                    company.value ==
                                    formik.values.collect_company_id,
                            )}
                            onChange={(company) => {
                                formik.setFieldValue(
                                    'collect_company_id',
                                    company.value,
                                );
                            }}
                            placeholder=""
                        />
                    </Box>
                    <Box sx={{ mt: '12px' }}>
                        <Typography>処分先</Typography>
                        <Dropdown
                            className="dropdown-create-user1 height36"
                            controlClassName="dropdown-control-user"
                            options={companyOption}
                            value={companyOption?.find(
                                (company) =>
                                    company.value ==
                                    formik.values.disposal_company_id,
                            )}
                            onChange={(company) => {
                                formik.setFieldValue(
                                    'disposal_company_id',
                                    company.value,
                                );
                            }}
                            placeholder=""
                        />
                    </Box>
                    <Box sx={{ mt: '12px' }}>
                        <Typography>印紙額</Typography>
                        <Box
                            bgcolor="#F2F2F2"
                            borderRadius="6px"
                            padding="10px"
                        >
                            <Grid marginTop="10px" paddingTop="0px" container>
                                <Grid paddingRight="4px" item xs={6}>
                                    <Box>
                                        <Typography>収集</Typography>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            name="collect_receipt_fee"
                                            onChange={formik.handleChange}
                                            value={
                                                formik.values
                                                    .collect_receipt_fee
                                            }
                                        />
                                    </Box>
                                </Grid>
                                <Grid paddingLeft="4px" item xs={6}>
                                    <Box>
                                        <Typography>処分</Typography>
                                        <TextField
                                            type="number"
                                            variant="outlined"
                                            name="disposal_receipt_fee"
                                            onChange={formik.handleChange}
                                            value={
                                                formik.values
                                                    .disposal_receipt_fee
                                            }
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                    <Grid
                        container
                        sx={{
                            mt: '28px',
                            button: {
                                width: '100%',
                                fontFamily: 'Zen Kaku Gothic Antique',
                                padding: '10px 67.5px',
                                fontSize: '16px',
                            },
                        }}
                    >
                        <Grid sx={{ paddingRight: '4px' }} item xs={6}>
                            <Button
                                sx={{ color: '#215493' }}
                                variant="outlined"
                                onClick={handleClose}
                            >
                                戻る
                            </Button>
                        </Grid>
                        <Grid sx={{ paddingLeft: '4px' }} item xs={6}>
                            <Button
                                type="submit"
                                sx={{
                                    backgroundColor: '#215493',
                                    color: '#fff',
                                }}
                            >
                                登録
                            </Button>
                        </Grid>
                    </Grid>
                </Paper>
            </DialogContent>
        </Dialog>
    );
};
export default CreateOrderPopup;
