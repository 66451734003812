import { ReceivedProps } from '../type';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import VehicleInspectionService from 'services/vehicleInspectionService';

const useCreateVehicleInspection = (props: ReceivedProps) => {
    const [fileUpload, setFileUpload] = useState<any>();
    const [errorMsg, setErrorMsg] = useState<string>('');

    const navigate = useNavigate();

    const handleFileUpload = (values) => {
        const fileSizeKiloBytes = values?.[0]?.size / 1024;
        if (fileSizeKiloBytes > 6144) {
            setErrorMsg('6MB以上がアップロードできません。');
            return;
        }
        setFileUpload(values[0]);
    };
    const handleCreateVehicleInspection = async () => {
        const formData = new FormData();
        formData.append('file', fileUpload);
        try {
            await VehicleInspectionService.createVehicleInspection(formData);
            navigate('/vehicle-inspection');
        } catch (error) {}
    };
    return {
        ...props,
        fileUpload,
        setFileUpload,
        navigate,
        handleCreateVehicleInspection,
        handleFileUpload,
        errorMsg,
    };
};
export type Props = ReturnType<typeof useCreateVehicleInspection>;

export default useCreateVehicleInspection;
