import { useState, useEffect, ChangeEvent } from 'react';
import { useParams } from 'react-router-dom';
import {
    TextField,
    Radio,
    RadioGroup,
    FormControlLabel,
    CircularProgress,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import pick from 'lodash/pick';
import calendarIcon from 'assets/icons/calendar.png';
import DialogHeader from '../../common/DialogHeader';
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from 'utils/constants';
import CommonStyledSugorkuDialogWrapper from '../../common/commonSugorokuDialogStyle';
import {
    SugorokuIndexEnum,
    ConsignmentContractTypeEnum,
    ManifestTypeEnum,
} from 'utils/enums';
import sugorokuService from 'services/sugorokuService';
import { formatDateTime } from 'utils/helper/dateHelper';
import { useFormik, FormikProps } from 'formik';
import { IWasteRemovalPlanPayload } from 'models';

interface IProps {
    step: number;
    onClose?: any;
    data_id?: number;
    onFinish: () => void;
}

interface IWasteRemovalPlanFormikValues {
    consignment_contract_type?: number | null;
    manifest_type?: number | null;
    completed_at?: Date | null;
}

const WasteRemovalPlanModal = (props: IProps) => {
    const { step, onClose, data_id, onFinish } = props;
    const [mode, setMode] = useState(VIEW_SUGOROKU_MODE);
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<any>(true);
    const [submitLoading, setSubmitLoading] = useState<boolean>(false);
    const hasData = !!data_id;
    const { id } = useParams();

    const formik: FormikProps<IWasteRemovalPlanFormikValues> =
        useFormik<IWasteRemovalPlanFormikValues>({
            initialValues: {
                consignment_contract_type: null,
                manifest_type: null,
                completed_at: null,
            },
            onSubmit: (values: IWasteRemovalPlanFormikValues) => {
                setSubmitLoading(true);
                let data: IWasteRemovalPlanPayload = pick(values);
                if (!id) {
                    setSubmitLoading(false);
                    return;
                }

                data.project_id = +id;
                data.sugoroku_index =
                    SugorokuIndexEnum.WASTE_REMOVAL_PLAN.value;

                if (
                    values.consignment_contract_type !== null &&
                    values.consignment_contract_type !== undefined
                ) {
                    data.consignment_contract_type =
                        +values.consignment_contract_type;
                } else {
                    data.consignment_contract_type = null;
                }

                if (
                    values.manifest_type !== null &&
                    values.manifest_type !== undefined
                ) {
                    data.manifest_type = +values.manifest_type;
                } else {
                    data.manifest_type = null;
                }

                if (values.completed_at) {
                    data.completed_at = formatDateTime(
                        values.completed_at,
                        'yyyy-MM-DD',
                    );
                } else {
                    data.completed_at = null;
                }

                if (hasData) {
                    updateSugoroku(data);
                } else {
                    createSugoroku(data);
                }
            },
        });

    const fetchData = async () => {
        try {
            setFetchLoading(true);
            const data = await sugorokuService.getSugorokuEventDetail(
                data_id,
                Number(id),
            );
            if (data && data.sugoroku_event) {
                const sugorokuData = data.sugoroku_event;

                formik.setValues({
                    consignment_contract_type:
                        sugorokuData.consignment_contract_type !== null
                            ? ConsignmentContractTypeEnum[
                                  sugorokuData.consignment_contract_type
                              ].value
                            : null,
                    manifest_type:
                        sugorokuData.manifest_type !== null
                            ? ManifestTypeEnum[sugorokuData.manifest_type].value
                            : null,
                    completed_at: sugorokuData.completed_at
                        ? new Date(sugorokuData.completed_at)
                        : null,
                });
                setIsEmpty(false);
            } else {
                setIsEmpty(true);
            }
        } catch (error) {
            // error
        } finally {
            setFetchLoading(false);
        }
    };

    const createSugoroku = async (data: IWasteRemovalPlanPayload) => {
        try {
            const responseData = await sugorokuService.createSugorokuEvent(
                data,
            );
            if (responseData) {
                setSubmitLoading(false);
                onFinish();
            }
        } catch (error) {
            setSubmitLoading(false);
            // empty
        }
    };

    const updateSugoroku = async (data: IWasteRemovalPlanPayload) => {
        try {
            const responseData = await sugorokuService.updateSugorokuEvent(
                data_id,
                data,
            );
            if (responseData) {
                setSubmitLoading(false);
                onFinish();
            }
        } catch (error) {
            setSubmitLoading(false);
            // epmty
        }
    };

    useEffect(() => {
        if (data_id && id) {
            fetchData();
        }
    }, []);

    const onChangeConsignmentType = (e) => {
        if (e.target.value === formik.values.consignment_contract_type) {
            formik.setFieldValue('consignment_contract_type', null);
        } else {
            formik.setFieldValue('consignment_contract_type', e.target.value);
        }
    };

    const onChangeManifestType = (e) => {
        if (e.target.value === formik.values.manifest_type) {
            formik.setFieldValue('manifest_type', null);
        } else {
            formik.setFieldValue('manifest_type', e.target.value);
        }
    };

    return (
        <CommonStyledSugorkuDialogWrapper>
            <DialogHeader
                step={step}
                title={SugorokuIndexEnum.WASTE_REMOVAL_PLAN.title}
                onClose={onClose}
                mode={mode}
                setMode={setMode}
                completed={!!formik.values.completed_at}
            />

            {fetchLoading && (
                <div className="d-flex justify-center">
                    <CircularProgress />
                </div>
            )}

            {mode === VIEW_SUGOROKU_MODE && !fetchLoading && !isEmpty && (
                <>
                    <div className="dialog-field-row">
                        <div className="field-label">委託契約書</div>
                        <div className="value">
                            {formik.values.consignment_contract_type ===
                                ConsignmentContractTypeEnum[
                                    'consignment_contract_paper'
                                ].value && data_id
                                ? '紙'
                                : formik.values.consignment_contract_type ===
                                      ConsignmentContractTypeEnum[
                                          'consignment_contract_file'
                                      ].value && data_id
                                ? '電子'
                                : !formik.values.consignment_contract_type &&
                                  !data_id
                                ? ''
                                : ''}
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">マニフェスト</div>
                        <div className="value">
                            {formik.values.manifest_type ===
                                ManifestTypeEnum['manifest_paper'].value &&
                            data_id
                                ? '紙'
                                : formik.values.manifest_type ===
                                      ManifestTypeEnum['manifest_file'].value &&
                                  data_id
                                ? '電子'
                                : !formik.values.manifest_type && !data_id
                                ? ''
                                : ''}
                        </div>
                    </div>
                    <div className="dialog-field-row">
                        <div className="field-label">完了日付</div>
                        <div className="value">
                            {formatDateTime(
                                formik.values.completed_at,
                                'yyyy-MM-DD',
                            )}
                        </div>
                    </div>
                </>
            )}

            {mode === EDIT_SUGOROKU_MODE && !fetchLoading && (
                <form onSubmit={formik.handleSubmit}>
                    <div className="dialog-field-row">
                        <div className="field-label">委託契約書</div>
                        <div className="radio-group-wrapper">
                            <RadioGroup
                                aria-label="委託契約書"
                                value={formik.values.consignment_contract_type}
                                className="radio-group"
                            >
                                <FormControlLabel
                                    value={0}
                                    control={
                                        <Radio
                                            onClick={onChangeConsignmentType}
                                        />
                                    }
                                    label="紙"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={
                                        <Radio
                                            onClick={onChangeConsignmentType}
                                        />
                                    }
                                    label="電子"
                                />
                            </RadioGroup>
                        </div>
                    </div>

                    <div className="dialog-field-row">
                        <div className="field-label">マニフェスト</div>
                        <div className="radio-group-wrapper">
                            <RadioGroup
                                aria-label="委託契約書"
                                value={formik.values.manifest_type}
                                className="radio-group"
                            >
                                <FormControlLabel
                                    value={0}
                                    control={
                                        <Radio onClick={onChangeManifestType} />
                                    }
                                    label="紙"
                                />
                                <FormControlLabel
                                    value={1}
                                    control={
                                        <Radio onClick={onChangeManifestType} />
                                    }
                                    label="電子"
                                />
                            </RadioGroup>
                        </div>
                    </div>

                    <div className="dialog-field-row">
                        <div className="field-label">完了日付</div>
                        <div className="datepicker-wrapper">
                            <DatePicker
                                id="completed_at"
                                selected={formik.values.completed_at}
                                onChange={(date) => {
                                    formik.setFieldValue('completed_at', date);
                                }}
                                dateFormat="yyyy-MM-dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <label htmlFor="completed_at">
                                <img
                                    className="calendar-datepicker-icon"
                                    src={calendarIcon}
                                ></img>
                            </label>
                        </div>
                    </div>
                    <div className="action-section">
                        <button
                            type="button"
                            className="back-step-edit-button"
                            onClick={onClose}
                        >
                            キャンセル
                        </button>
                        <button
                            type="submit"
                            className="submit-step-edit-button"
                            disabled={submitLoading}
                        >
                            {submitLoading ? (
                                <CircularProgress color="inherit" size={18} />
                            ) : (
                                '登録'
                            )}
                        </button>
                    </div>
                </form>
            )}
        </CommonStyledSugorkuDialogWrapper>
    );
};

export default WasteRemovalPlanModal;
