import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message/message";

import UserService from "../../services/userService";
import { IWorker } from "models";

export const getUser = createAsyncThunk(
  "users/profiles",
  async () => {
    const response = await UserService.getMe();
    return response;
  }
);
interface IInitialState {
    userData: {
        id: number | null,
        email: string,
        worker: IWorker | null,
        role: string,
    }
}
const initialState: IInitialState = {
    userData: {
        id: null,
        email: "",
        worker: null,
        role: "",
    }
}

const authSlice = createSlice({
    name: "users",
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(getUser.fulfilled, (state, action) => {
            state.userData = action.payload.user
        })

        .addCase(getUser.rejected,(state, action) => {
            state.userData = {
                id: null,
                email: "",
                worker: null,
                role: "",
            };
        })
    },
    reducers: {},
});

const { reducer } = authSlice;
export default reducer;
