import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GridSizeChangedEvent, GridApi } from 'ag-grid-community';
import { ColDef } from 'ag-grid-community';
import { isEmpty } from 'lodash';
import projectsService from 'services/projectsService';
import { IPageQueries } from 'models';
import { columns } from './utility';
import chatService from 'services/chatService';
import { useParams } from 'react-router-dom';

export type ReceivedProps = {};

export const ROW_HEIGHT = 50;
export const HEADER_HEIGHT = 40;

const useChat = (props: ReceivedProps) => {

    const { id } = useParams();
    const [gridApi, setGridApi] = useState<GridApi>();
    const [rowData, setRowData] = useState<any[]>([]);
    const [chatId, setChatId] = useState<number | null>(null);
    const getListChat = async () => {
        try {
            if (!id) return;
            const response = await projectsService.getListChatSP(Number(id), {
                page: 1,
                per_page: 0,
            });
            if (response !== null && response !== undefined) {
                setRowData(response.chats);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const onDeleteChat = async (closeDelete: () => void) => {
        if (!chatId) return;
        try {
            await projectsService.deleteChatSP(id, chatId);
            getListChat();
            closeDelete();
        } catch (error) {
            //
            console.log(error)
        }
    };

    useEffect(() => {
        getListChat();
    }, [id]);

    useEffect(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
        const element = document.querySelectorAll('.ag-text-field-input');

        element.forEach((item: any) => {
            item.placeholder = '検索';
        });
    }, [rowData, gridApi]);

    return {
        ...props,
        rowData,
        onDeleteChat,
        setChatId,
        id,
    };
};

export type Props = ReturnType<typeof useChat>;

export default useChat;
