import fetch from './api/config';

const API = {
    GET_LINE_FRIEND: () => `/line/list_friends`,
};

export default class LineFriendService {
    static getLineFriends = () =>
        fetch.get(API.GET_LINE_FRIEND(), {});
}
