import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReceivedProps, DataType } from './type';
import VehicleInspectionService from 'services/vehicleInspectionService';

const useSPVehicleInspection = (props: ReceivedProps) => {
    const [vehicleInspectionLists, setVehicleInspectionLists] = useState<
        DataType[]
    >([]);
    const [currentData, setCurrenData] = useState<any>();
    const [pdfData, setPdfData] = useState<any>();

    const navigate = useNavigate();
    const handleShare = async (values) => {
        const response = await fetch(values?.file_url);
        const data = await response.blob();
        const metadata = {
            type: 'application/pdf',
        };
        const file = new File([data], values.name, metadata);
        const files = [file];
        if (navigator.share) await navigator.share({ files });
    };

    const fetchListVerhicleInspection = async (params = {}) => {
        try {
            const { checklists }: any =
                await VehicleInspectionService.getListVehicleInspectionSP({
                    page: 1,
                    per_page: 0,
                    ...params,
                });
            setVehicleInspectionLists(checklists);
        } catch (error) {
            //
        }
    };

    const handleViewPdf = async (values) => {
        try {
            setCurrenData(values);
            const response = await fetch(values?.file_url);
            const data = await response.blob();
            const file = window.URL.createObjectURL(data);
            setPdfData(file);
        } catch (error) {}
    };

    useEffect(() => {
        fetchListVerhicleInspection();
    }, []);
    return {
        ...props,
        vehicleInspectionLists,
        pdfData,
        currentData,
        navigate,
        handleShare,
        handleViewPdf,
        setPdfData,
    };
};
export type Props = ReturnType<typeof useSPVehicleInspection>;

export default useSPVehicleInspection;
