import { useEffect, useState } from "react";
import _ from "lodash";

import { Main } from "./styleListDetail";
import { useSearchParams } from "react-router-dom";
import { Button, Table } from "antd";
import { PrintIcon } from "../Icon/PrintIcon";
import { AddIcon } from "../Icon/AddIcon";
import { DeleteIcon } from "../Icon/DeleteIcon";
import { EditIcon } from "../Icon/EditIcon";
import CustomPagination from "components/CustomPagination";
import { DownCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { formatMoney } from "utils/helper/helpers";
import { ExpandedIcon } from "../Icon/ExpandedIcon";
import { OnExpandIcon } from "../Icon/OnExpandIcon";

const ListDetail = ({
  // item,
  dataList,
  onAddLineNew,
  totalCount,
  openDelete,
  onGetDetail,
  onBack,
  showDataBudgetItem,
  totalPrice,
  getListItem,
  setActionBudgetItem,
  importBudget,
}) => {
  const [searchParams] = useSearchParams();
  const [pageQueries, setPageQueries] = useState({
    page: 1,
    per_page: 30,
  });

  useEffect(() => {
    getListItem({
      page: pageQueries.page,
      per_page: pageQueries.per_page,
    });
  }, [pageQueries.page]);
  const columns = [
    {
      title: "名称",
      dataIndex: "name",
      width: "200px",
      key: "name",
    },
    {
      title: "単位",
      dataIndex: "unit",
      width: "200px",
      key: "unit",
    },
    {
      title: "単価",
      dataIndex: "unit_price",
      width: "200px",
      key: "unit_price",
    },
    {
      title: "数量",
      dataIndex: "amount",
      width: "200px",
      key: "amount",
    },
    {
      title: "金額",
      dataIndex: "price",
      width: "200px",
      key: "price",
    },
    {
      title: "備考",
      dataIndex: "note",
      width: "200px",
      key: "note",
    },
    {
      title: "仕入先",
      dataIndex: "supplier",
      width: "200px",
      key: "supplier",
    },
    {
      title: "操作",
      key: "action",
      width: "300px",
      render: (row) => (
        <div className="flex justify-center">
          <Button
            className="!border-0 !p-0 !bg-[#e4effd]"
            onClick={() => openDelete(row)}
          >
            <DeleteIcon />
          </Button>
          <Button
            className="!border-0 !p-0 !bg-[#e4effd]"
            onClick={() => (
              onAddLineNew(),
              setActionBudgetItem({ dataItem: row, type: "edit" })
            )}
          >
            <EditIcon />
          </Button>
        </div>
      ),
    },
  ];
  const columnsChildren = [
    {
      title: "名称",
      key: "name",
      width: "200px",
      render: (text, record, index) => (
        <div>{index + 1 + ". " + record?.name}</div>
      ),
    },
    {
      title: "単位",
      dataIndex: "unit",
      width: "200px",
      key: "unit",
    },
    {
      title: "単価",
      dataIndex: "unit_price",
      width: "200px",
      key: "unit_price",
    },
    {
      title: "数量",
      dataIndex: "amount",
      width: "200px",
      key: "amount",
    },
    {
      title: "金額",
      dataIndex: "price",
      width: "200px",
      key: "price",
    },
    {
      title: "備考",
      dataIndex: "note",
      width: "200px",
      key: "note",
    },
    {
      title: "仕入先",
      dataIndex: "supplier",
      width: "200px",
      key: "supplier",
    },
    {
      title: "操作",
      key: "action",
      width: "300px",
      render: (row) => (
        <div className="flex justify-center">
          <Button
            className="!border-0 !p-0 !bg-transparent"
            onClick={() => openDelete(row)}
          >
            <DeleteIcon />
          </Button>
          <Button
            className="!border-0 !p-0 !bg-transparent"
            onClick={() => (
              onAddLineNew(),
              setActionBudgetItem({ dataItem: row, type: "edit" })
            )}
          >
            <EditIcon />
          </Button>
        </div>
      ),
    },
  ];
  return (
    <Main>
      <div>
        <div className="flex justify-between mb-[16px]">
          <div>予実管理</div>
          <div className="flex gap-[20px]">
            {searchParams.get("type") != "detail" && (
              <>
                <Button
                  className="button-list-item !text-[#215493] relative"
                  icon={<PrintIcon />}
                >
                  <input
                    onChange={(e) => importBudget(e)}
                    type="file"
                    accept=".xls, .xlsx"
                    className="absolute right-0 h-full w-full opacity-0 z-50"
                  />
                  インポート
                </Button>
                {/* <input type='file'/> */}
                <Button
                  onClick={() => (
                    onAddLineNew(),
                    setActionBudgetItem({ dataItem: {}, type: "create" })
                  )}
                  icon={<AddIcon />}
                  className="button-list-item !bg-[#215493] !text-[#FFF]"
                >
                  新規作成
                </Button>
              </>
            )}
          </div>
        </div>
        <Table
          className="table-parent"
          columns={columns}
          dataSource={dataList}
          pagination={false}
          expandable={{
            rowExpandable: (record) =>
              record?.is_parent && record?.child_budget_items?.length > 0,
            expandedRowRender: (record) => (
              <Table
                columns={columnsChildren}
                dataSource={record?.child_budget_items}
                pagination={false}
                className="table-children"
              />
            ),
            expandIcon: ({ expanded, onExpand, record }) =>
              record?.is_parent && record?.child_budget_items?.length > 0 ? (
                expanded ? (
                  <Button
                    onClick={(e) => onExpand(record, e)}
                    className="!bg-[#E4EFFD] !border-0 !p-0 !shadow-none"
                  >
                    <ExpandedIcon />
                  </Button>
                ) : (
                  <Button
                    onClick={(e) => onExpand(record, e)}
                    className="!bg-[#E4EFFD] !border-0 !p-0 !shadow-none"
                  >
                    <OnExpandIcon />
                  </Button>
                )
              ) : null,
          }}
        />
      </div>
      <div className="w-fit my-0 mx-auto">
        {dataList && dataList.length > 0 && (
          <CustomPagination
            totalCount={totalCount}
            pageQueries={pageQueries}
            setPageQueries={setPageQueries}
            fetchList={(page) =>
              getListItem({
                page,
                per_page: pageQueries.per_page,
              })
            }
          />
        )}
      </div>
      <p className="total_price">
        合計金額 : ¥{formatMoney(String(totalPrice || 0))}
      </p>
    </Main>
  );
};

export default ListDetail;
