import { FC } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { BiBox } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import calendarIcon from '../../assets/icons/calendar.png';
import Dropdown from 'react-dropdown';
import CustomPagination from 'components/CustomPagination';
import useChat, {
    HEADER_HEIGHT,
    Props,
    ReceivedProps,
    ROW_HEIGHT,
} from './hook';
import { Styles, TableWrapper } from './styled';
import WorkingReportCreate from 'pages/project/projectPlan/workingReport/WorkingReportCreate';
import WorkingReportDelete from 'pages/project/projectPlan/workingReport/WorkingReportDelete';
import { Button, Box } from '@mui/material';
import { Add } from '@mui/icons-material';
import { Space, Table, Tag } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { formatDateTime } from 'utils/helper/dateHelper';

interface DataType {
    key: string;
    name: string;
    age: number;
    address: string;
    tags: string[];
}

const ChatListLayout: FC<Props> = ({
    gridRef,
    rowData,
    columnDefs,
    containerStyle,
    gridStyle,
    defaultColDef,
    workingLogs,
    pageQueries,
    total,
    onDeleteChat,
    setChatId,
    setPageQueries,
    isFullWidthRow,
    onVirtualColumnsChanged,
    onGridReady,
    formik,
    covertOptions,
    Modal,
    openCreate,
    open,
    openEdit,
    close,
    openShow,
    isOpen,
    ModalDelete,
    openDeleteModal,
    openDelete,
    closeDeleteModal,
    isOpenDelete,
}) => {
    const navigate = useNavigate();

    const columns: ColumnsType<any> = [
        {
            title: '作業日',
            dataIndex: 'createdAt',
            width: 260,
            align: 'center',
            key: 'createdAt',
            render: (_, record) => (
                <div>
                    {`${formatDateTime(
                        record?.from_at,
                        'YYYY/MM/DD',
                    )} - ${formatDateTime(record?.to_at, 'YYYY/MM/DD')}`}
                </div>
            ),
        },
        {
            title: '発行日',
            dataIndex: 'published_at',
            align: 'center',
            key: 'published_at',
            width: 150,
            render: (_, record) => (
                <div>{formatDateTime(record?.published_at, 'YYYY/MM/DD')}</div>
            ),
        },
        {
            title: '送信日',
            dataIndex: 'submitted_at',
            align: 'center',
            key: 'submitted_at',
            width: 150,
            render: (_, record) => (
                <div>{formatDateTime(record?.submitted_at, 'YYYY/MM/DD')}</div>
            ),
        },
        {
            title: '作業内容',
            dataIndex: 'submitted_at',
            align: 'center',
            width: '350px',
            key: 'submitted_at',
            render: (_, record) => (
                <div className="w-[350px] truncate">
                    {record?.working_logs?.reduce(
                        (accu, log, idx, workingLogsArray) => {
                            if (idx === workingLogsArray.length - 1) {
                                accu = accu + log?.name;
                            } else {
                                accu = accu + log?.name + ', ';
                            }
                            return accu;
                        },
                        '',
                    )}
                </div>
            ),
        },
        {
            title: '操作',
            key: 'action',
            width: 300,
            align: 'center',
            render: (_, record) => (
                <div className="group-btn-actions gap-x-[16px] flex justify-center">
                    <button
                        onClick={() => openShow(record)}
                        className="button-tb"
                    >
                        詳細
                    </button>
                    <button
                        onClick={() => openEdit(record)}
                        className="button-tb btn-delete"
                    >
                        編集
                    </button>
                    <button
                        onClick={() => openDelete(record)}
                        className="button-tb !bg-red btn-delete"
                    >
                        削除
                    </button>
                </div>
            ),
        },
    ];
    return (
        <Styles>
            <div className="container-weeklyreport">
                <Box display="flex" justifyContent="flex-end">
                    <Button
                        onClick={openCreate}
                        sx={{
                            mb: '20px',
                            fontWeight: 'bold',
                            padding: '6px 18px',
                        }}
                        variant="outlined"
                    >
                        <Add /> 新規作成
                    </Button>
                </Box>
                <form className="mb-4" onSubmit={formik.handleSubmit}>
                    <div className="w-full grid grid-cols-3 gap-x-[28px]">
                        <div className="flex items-end w-full justify-between gap-x-[12px]">
                            <div className="flex-1 relative">
                                <label htmlFor="from_at">作業日</label>
                                <DatePicker
                                    id="from_at"
                                    className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                    name="from_at"
                                    selected={formik.values.from_at}
                                    onChange={(val) => {
                                        formik.setFieldValue('from_at', val);
                                    }}
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                                <label htmlFor="from_at">
                                    <img
                                        className="absolute right-[9px] bottom-[10px]"
                                        width={'16px'}
                                        height={'16px'}
                                        src={calendarIcon}
                                    />
                                </label>
                            </div>
                            <span className="flex-0 pb-[8px]">~</span>
                            <div className="flex-1 relative">
                                <label htmlFor="from_at"></label>
                                <DatePicker
                                    id="to_at"
                                    className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                    dateFormat="yyyy/MM/dd"
                                    name="to_at"
                                    autoComplete="off"
                                    locale="ja"
                                    selected={formik.values.to_at}
                                    onChange={(val) => {
                                        formik.setFieldValue('to_at', val);
                                    }}
                                />
                                <label htmlFor="to_at">
                                    <img
                                        className="absolute right-[9px] bottom-[10px]"
                                        width={'16px'}
                                        height={'16px'}
                                        src={calendarIcon}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="flex items-end w-full justify-between gap-x-[12px]">
                            <div className="flex-1 relative">
                                <label htmlFor="from_at">発行日</label>
                                <DatePicker
                                    className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                    id="start_published_at"
                                    name="start_published_at"
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                    selected={formik.values.start_published_at}
                                    onChange={(val) => {
                                        formik.setFieldValue(
                                            'start_published_at',
                                            val,
                                        );
                                    }}
                                />
                                <label htmlFor="start_published_at">
                                    <img
                                        className="absolute right-[9px] bottom-[10px]"
                                        width={'16px'}
                                        height={'16px'}
                                        src={calendarIcon}
                                    />
                                </label>
                            </div>
                            <span className="flex-0 pb-[8px]">~</span>
                            <div className="flex-1 relative">
                                <label htmlFor="from_at"></label>
                                <DatePicker
                                    id="end_published_at"
                                    className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                    name="end_published_at"
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                    selected={formik.values.end_published_at}
                                    onChange={(val) => {
                                        formik.setFieldValue(
                                            'end_published_at',
                                            val,
                                        );
                                    }}
                                />
                                <label htmlFor="end_published_at">
                                    <img
                                        className="absolute right-[9px] bottom-[10px]"
                                        width={'16px'}
                                        height={'16px'}
                                        src={calendarIcon}
                                    />
                                </label>
                            </div>
                        </div>
                        <div className="flex items-end w-full justify-between gap-x-[12px]">
                            <div className="flex-1 relative">
                                <label htmlFor="from_at">送信日</label>
                                <DatePicker
                                    id="start_submitted_at"
                                    className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                    name="start_submitted_at"
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                    selected={formik.values.start_submitted_at}
                                    onChange={(val) => {
                                        formik.setFieldValue(
                                            'start_submitted_at',
                                            val,
                                        );
                                    }}
                                />
                                <label htmlFor="start_submitted_at">
                                    <img
                                        className="absolute right-[9px] bottom-[10px]"
                                        width={'16px'}
                                        height={'16px'}
                                        src={calendarIcon}
                                    />
                                </label>
                            </div>
                            <span className="flex-0 pb-[8px]">~</span>
                            <div className="flex-1 relative">
                                <label htmlFor="from_at"></label>
                                <DatePicker
                                    className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                    name="end_submitted_at"
                                    id="end_submitted_at"
                                    dateFormat="yyyy/MM/dd"
                                    autoComplete="off"
                                    locale="ja"
                                    selected={formik.values.end_submitted_at}
                                    onChange={(val) => {
                                        formik.setFieldValue(
                                            'end_submitted_at',
                                            val,
                                        );
                                    }}
                                />
                                <label htmlFor="end_submitted_at">
                                    <img
                                        className="absolute right-[9px] bottom-[10px]"
                                        width={'16px'}
                                        height={'16px'}
                                        src={calendarIcon}
                                    />
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="w-full grid grid-cols-3 gap-x-[28px] items-end mt-[12px]">
                        <div className="flex-1">
                            <label htmlFor="from_at">作業内容</label>
                            <Dropdown
                                className="dropdown-create-user1 height36"
                                controlClassName="dropdown-control-user height36"
                                options={covertOptions(workingLogs)}
                                placeholder=""
                                value={formik.values.working_log || null}
                                onChange={(e) =>
                                    formik.setFieldValue('working_log', e)
                                }
                            />
                        </div>
                        <div></div>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                className="bg-main px-[50px] py-[7px] rounded-[6px] text-white"
                            >
                                検索
                            </button>
                        </div>
                    </div>
                </form>
                <div className="table-wrapper">
                    {/* <TableWrapper>
                        <Table
                            scroll={{
                                x: 1200,
                            }}
                            columns={columns}
                            dataSource={rowData}
                            pagination={false}
                        />
                    </TableWrapper> */}
                </div>
                {total > pageQueries.per_page && (
                    <CustomPagination
                        totalCount={total}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={() => {}}
                    />
                )}
                {/* <Modal>
                    <WorkingReportCreate currentProject={{}} />
                </Modal>
                <ModalDelete>
                    <WorkingReportDelete />
                </ModalDelete> */}
            </div>
        </Styles>
    );
};

const ChatList: FC<ReceivedProps> = (props) => (
    <ChatListLayout {...useChat(props)} />
);

export default ChatList;
