import styled from "styled-components";

const StyledStepDialogHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  
  font-size: 18px;
  text-align: center;
  color: #1c1e21;
  margin-bottom: 20px;

  .step-number-icon {
    display: flex;
    width: 34px;
    height: 34px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    &.complete-theme {
      background-color: #215493;
      color: #ffffff;
    }
    &.incomplete-theme {
      background-color: #e3e5e8;
      color: #215493;
    }
  }

  p,
  .step-edit-button {
    font-family: 'Zen Kaku Gothic Antique';
    font-style: normal;
    font-weight: 700;
  }

  .step-edit-button {
    background-color: #215493;
    color: white;
    padding: 6px 15px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 200;
    margin-right: 5px;
  }
`;

export default StyledStepDialogHeader;