import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";

import "react-step-progress/dist/index.css";
import { useModal } from "react-hooks-use-modal";
import { useNavigate } from "react-router-dom";

import estimationService from "../../../../../services/estimationService";
import plus from "../../../../../assets/icons/Plus.png";
import { useLogicBudget } from "../hooks";
import ListBox from "./ListBox";
import { Main } from "./style";
import CustomPagination from "components/CustomPagination";
import { IPageQueries } from "models";
import budgetActionService from "services/budgetActionService";

const BudgetList = (props) => {
  const navigate = useNavigate();

  const { statusOptions, id } = useLogicBudget();
  const [budgets, setBudgets] = useState<any[]>([]);
  const [deleteRow, setDeleteRow] = useState<any>({});
  const [searchObject, setSearchObject] = useState<any>({
    plan_id: "",
    worker_id: "",
    start_at: null,
    end_at: null,
    free_word: "",
    project_id: id,
  });
  const [totalCount, setTotalCount] = useState<number>(0);
  const [pageQueries, setPageQueries] = useState<IPageQueries>({
    page: 1,
    per_page: 20,
  });

  const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
    "root",
    {
      preventScroll: true,
    }
  );

  const getBudgetForProject = async ({ page = 1, per_page = 0 }) => {
    try {
      const responseBudget = await budgetActionService.getListBudgetProject(
        Number(id),
        {
          page,
          per_page,
        }
      );
      if (responseBudget !== null && responseBudget !== undefined) {
        setTotalCount(responseBudget.meta.total_count);
        setBudgets(responseBudget.budgets);
      }
    } catch (err) {}
  };

  useEffect(() => {
    getBudgetForProject({
      page: pageQueries.page,
      per_page: pageQueries.per_page,
    });
  }, []);

  const handleDeleteBudget = async () => {
    const response = await budgetActionService.deleteBudget(
      deleteRow.id,
      deleteRow?.project?.id
    );
    if (response !== null && response !== undefined) {
      if (pageQueries.page !== 1) {
        setPageQueries((prevState) => ({ ...prevState, page: 1 }));
      }
      getBudgetForProject({ page: 1, per_page: pageQueries.per_page });
      closeDelete();
    }
  };

  return (
    <Main>
      <div className="d-flex flex-column">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row searchRow">
            <div className="actionArea">
              <Link
                className="button-create"
                to={`/projects/budget/${id}/create`}
              >
                <img width={"16px"} height={"16px"} src={plus}></img>
                新規作成
              </Link>
            </div>
          </div>
          <ListBox
            data={budgets}
            id={id}
            dataStatus={statusOptions}
            openDelete={(row) => {
              setDeleteRow(row);
              openDelete();
            }}
          />
        </div>
        {budgets && budgets.length > 0 && (
          <CustomPagination
            totalCount={totalCount}
            pageQueries={pageQueries}
            setPageQueries={setPageQueries}
            fetchList={(page) =>
              getBudgetForProject({ page, per_page: pageQueries.per_page })
            }
          />
        )}
        <ModalDelete>
          <div
            className="modal-create-user d-flex flex-column"
            style={{ height: "152px" }}
          >
            <div className="modal-create-user-title d-flex flex-row">
              本当に削除しますか?
            </div>
            <div
              className="modal-create-user-buttons d-flex flex-row"
              style={{ marginTop: "50px" }}
            >
              <button
                className="modal-create-user-button1 d-flex flex-row"
                onClick={closeDelete}
              >
                キャンセル
              </button>
              <button
                className="modal-create-user-button2 d-flex flex-row"
                onClick={handleDeleteBudget}
              >
                はい
              </button>
            </div>
          </div>
        </ModalDelete>
      </div>
    </Main>
  );
};

export default BudgetList;
