// import Api from './api/reportApi';
// import pick from 'lodash/pick';
// // import projectList from '../shared/mocks/projectLists.json';
// import moment from 'moment';

// const getListReports = async (input: any = {}) => {
//     console.log(input, 'input');

//     // return projectList; // TODO remove debug
//     let data = pick(input, ['page', 'per_page']);
//     if (input.project_id) {
//         data.project_id = input.project_id;
//     }
//     if (input.working_log) {
//         data.working_log_id = input.working_log.value;
//     }
//     if (input?.from_at) {
//         data.from_at = moment(input.from_at)
//             // .startOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }
//     if (input?.to_at) {
//         data.to_at = moment(input.to_at)
//             // .endOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }
//     if (input?.start_published_at) {
//         data.start_published_at = moment(input.start_published_at)
//             // .endOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }
//     if (input?.end_published_at) {
//         data.end_published_at = moment(input.end_published_at)
//             // .endOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }
//     if (input?.start_submitted_at) {
//         data.start_submitted_at = moment(input.start_submitted_at)
//             // .endOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }
//     if (input?.end_submitted_at) {
//         data.end_submitted_at = moment(input.end_submitted_at)
//             // .endOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }
//     const params = new URLSearchParams(data);
//     try {
//         return await Api()
//             .get(`?${params.toString()}`)
//             .then((response) => {
//                 if (response.data) {
//                     return response.data;
//                 }
//             });
//     } catch (err) {
//         window.location.href = '/login';
//         return null;
//     }
// };

// const getReport = async (id: Number, input: any = {}) => {
//     let data = pick(input);
//     if (input.project_id) {
//         data.project_id = input.project_id;
//     }
//     const params = new URLSearchParams(data);
//     return await Api()
//         .get(`sp/${id}?${params.toString()}`)
//         .then((response) => {
//             if (response.data) {
//                 return response.data;
//             }
//         });
// };

// const createReport = async (input) => {
//     let data = pick(input, [
//         'project_id',
//         'manager_worker_id',
//         // 'published_at',
//         // 'from_at',
//         // 'to_at',
//         'note',
//         'issue_note',
//         'improve_note',
//         // 'published_at',
//         // 'submitted_at',
//         'working_log_ids',
//     ]);
//     if (input?.from_at) {
//         data.from_at = moment(input.from_at)
//             // .startOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }
//     if (input?.to_at) {
//         data.to_at = moment(input.to_at)
//             // .endOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }
//     if (input?.published_at) {
//         data.published_at = moment(input.published_at)
//             // .endOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }
//     if (input?.submitted_at) {
//         data.submitted_at = moment(input.submitted_at)
//             // .endOf('day')
//             // .utc()
//             .format('YYYY-MM-DD');
//     }

//     return await Api()
//         .post('', data, {})
//         .then((response) => {
//             if (response.data) {
//                 return response.data;
//             }
//         });
// };

// const updateReport = async (body, id) => {
//     let storage = localStorage.getItem('user');
//     const user = storage !== null ? JSON.parse(storage) : null;
//     if (user.access_token !== undefined && user.access_token !== null) {
//         try {
//             return await Api()
//                 .put(`${id}`, body, {
//                     headers: { Authorization: `Bearer ${user.access_token}` },
//                 })
//                 .then((response) => {
//                     if (response.data) {
//                         return response.data;
//                     }
//                 });
//         } catch (err) {
//             console.log(err);
//             return Promise.reject();
//         }
//     } else {
//         window.location.href = '/login';
//     }
// };

// const deleteReport = async (id: Number, input: any = {}) => {
//     let data = pick(input);
//     if (input.project_id) {
//         data.project_id = input.project_id;
//     }
//     const params = new URLSearchParams(data);
//     return await Api()
//         .delete(`${id}?${params.toString()}`)
//         .then((response) => {
//             if (response.data) {
//                 return response.data;
//             }
//         });
// };

// const reportsServiceSP = {
//     getListReports,
//     createReport,
//     updateReport,
//     deleteReport,
//     getReport,
// };

// export default reportsServiceSP;

import fetch from './api/config';

const API = {
    GET_LIST_WORKING_LOG: () => `/sp/working_reports`,
    PRINT_WORKING_LOG: () => `/sp/working_reports/print_pdf`,
    DELETE_WORKING_LOG: (id: number) => `/sp/working_reports/${id}`,
    WORKING_LOG_DETAIL: (id) => `/sp/working_reports/${id}`,
};

export default class ReportServiceSP {
    static getListReports = (params: any) =>
        fetch.get(API.GET_LIST_WORKING_LOG(), params);

    static deleteReport = (id: number, params) =>
        fetch.delete(API.DELETE_WORKING_LOG(id), params);

    static createReport = (params: any) =>
        fetch.post(API.GET_LIST_WORKING_LOG(), params);

    static getReportDetail = (id: number, params: any) =>
        fetch.get(API.WORKING_LOG_DETAIL(id), params);

    static updateReport = (id: number, params: any) =>
        fetch.put(API.WORKING_LOG_DETAIL(id), params);

    static printPDFReport = (params: any) =>
        fetch.post(API.PRINT_WORKING_LOG(), params);
}
