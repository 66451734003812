import styled from 'styled-components';

const StyledSettingContainer = styled.div`
    width: 100%;
    background-color: #E3E6E8;
    padding: 28px;

    .pageContentWrapper {
      // min-height: calc(100% - 56px);
      width: 100%;
      background: #FFFFFF;
      border-radius: 12px;
      padding: 28px;

      .settingImport {
        display: flex;
        width: 100%;
        align-items: center;
        gap: 50px;

        .importTitle {
          font-family: 'Zen Kaku Gothic Antique';
          font-weight: 700;
          font-size: 20px;
          color: #1C1E21;
        }

        .importBtn {
          font-family: 'Zen Kaku Gothic Antique';
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          color: #215493;
          width: 130px;
          height: 36px;
          border: 1px solid #215493;
          border-radius: 6px;

          &:hover {
            background-color: #C7DCF5;
          }
        }
      }
    }
`;

export default StyledSettingContainer;