import styled from 'styled-components';

export const StylesTargetList = styled.div`
    table {
      min-width: 100%;
    }
    table thead {
      background: #C7DCF5;
      border: 1px solid #CDD1D5;
    }
    table tbody {
      border: 1px solid #CDD1D5;
    }
  
    table tr td {
      padding: 12px 10px;
      border-bottom: 1px solid #F5F5F5;
    }
  
    .title-name {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #000;
    }
`;
