/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Navigate, useLocation, NavigateProps } from 'react-router-dom';

const LoginVerify = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const accessToken = params.get('access_token');
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        if (accessToken) {
            fetch(`${process.env.REACT_APP_SERVER_API_LINE}/auth/verify-current-user`, {
                method: 'GET',
                headers: {
                    Authorization: accessToken,
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => response.json())
                .then(({ current_user }) => {
                    if (current_user) {
                        localStorage.setItem('line:accessToken', accessToken);
                        setCurrentUser(current_user);
                    } else {
                        localStorage.removeItem('line:accessToken')
                    }
                });
        }
    }, [accessToken]);

    const nextUrl = localStorage.getItem('next');
    if (currentUser)
        return (
            <Navigate
                to={{
                    pathname: nextUrl ? nextUrl : '/line',
                }}
                state={{ accessToken: accessToken }}
            />
        );

    return (
        <div className="d-flex p-5 align-items-center justify-content-center">
            <Spinner animation="border" />
        </div>
    );
};

export default LoginVerify;
