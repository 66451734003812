import styled from 'styled-components';

export const Main = styled.div`
    background-color: #e3e6e8;
    padding: 28px;
    width: 100%;
    font-family: 'Zen Kaku Gothic Antique';
    .input-search {
        border: 1px solid #cdd1d5;
        border-radius: 8px;
        margin-bottom: 14.5px;
        padding-left: 14px;
    }
    .MuiTextField-root {
        border: 1px solid #cdd1d5;
        width: 342px;
        border-radius: 8px;
        margin-right: 12.5px;
    }
    .MuiInputBase-input {
        height: 35px;
        padding: 0px !important;
    }
    .suggestions-content {
        background: #fff;
        border-radius: 12px;
        min-height: calc(100% - 56px);
        padding: 28px;
        width: 100%;

        .amount {
            font-family: 'Zen Kaku Gothic Antique';
            font-style: normal;
            font-weight: 500;
            font-size: 22px;
            line-height: 26px;
            color: #1c1e21;
            width: 50%;
            display: flex;
            align-items: center;

            span {
                font-weight: 900;
                font-size: 24px;
                color: #215493;
            }
        }

        .input-search {
            background: #fff;
            border: 1px solid #cdd1d5;
            border-radius: 8px;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            gap: 8px;
            padding: 10px 12px;
            width: 100%;
        }

        .search-box {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 16px;
            align-items: center;
        }

        .actionArea {
            width: 50%;
        }

        .searchRow {
            margin-bottom: 24px;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            align-items: center;
        }
    }

    .button-tb {
        margin-left: 8px !important;

        &.btn-delete {
            width: 72px;
            background: #ff5045;
        }
    }

    .actions {
        vertical-align: top;
        width: 300px;
        min-width: 300px;
    }
`;
