import Api from './api/commentApi';
import pick from 'lodash/pick';

const createComment = async (payload) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateComment = async (id: number, payload: any) => {

    return await Api()
        .put(`${id}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteEstimateItem = async (id: number, estimate_id: number) => {
    return await Api()
        .delete(`${id}?estimate_id=${estimate_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getDetailEstimateItem = async (id: number, estimate_id: number) => {
    return await Api()
        .get(`${id}?estimate_id=${estimate_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getListComment = async (input?: any) => {
    let data = pick(input, ['page', 'per_page', 'estimate_id']);
    if (input.estimate_item_id) data.estimate_item_id = input.estimate_item_id;

    const params = new URLSearchParams(data);

    return await Api()
        .get(`?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const commentService = {
    createComment,
    updateComment,
    deleteEstimateItem,
    getDetailEstimateItem,
    getListComment,
};

export default commentService;
