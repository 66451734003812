import { StylesTimeKeeping } from './styled';
import useTimeKeeping, { Props } from './hook';
import moment from 'moment';

const TimeKeepingLayout = ({
    handleCheckin,
    handleCheckout,
    workingTime,
    loading,
}: Props) => {
    return (
        <StylesTimeKeeping>
            <p className="time--title">出退勤</p>
            <div className="flex mt-4 justify-between gap-[12px]">
                <div
                    onClick={() => handleCheckin()}
                    className={`timekeeping ${
                        loading ? 'bg-[#f3f3f3]' : 'bg-[#b8f5ed]'
                    }`}
                >
                    出勤
                </div>
                <div
                    onClick={() => handleCheckout()}
                    className={`timekeeping  ${
                        loading ? 'bg-[#f3f3f3]' : 'bg-[#ffd8d9]'
                    }`}
                >
                    退勤
                </div>
            </div>
            <div className="flex mt-8 items-center">
                <p>出勤時間:</p>
                <p className="ml-1 font-[500]">
                    {workingTime?.start_time
                        ? moment(workingTime?.start_time).format(
                              'hh:mm YYYY/MM/DD',
                          )
                        : ''}
                </p>
            </div>
            <div className="flex mt-4 items-center">
                <p>退勤時間:</p>
                <p className="ml-1 font-[500]">
                    {workingTime?.end_time
                        ? moment(workingTime?.end_time).format(
                              'hh:mm YYYY/MM/DD',
                          )
                        : ''}
                </p>
            </div>
        </StylesTimeKeeping>
    );
};

const TimeKeeping = (props: any) => {
    return <TimeKeepingLayout {...useTimeKeeping(props)} />;
};

export default TimeKeeping;
