import styled from 'styled-components';

const ChartBoxStyle = styled.div`
  width: calc(100% - 300px);
  overflow-x: auto;
  // height: 100vh;
  padding: 24px 20px;
  @media screen and (max-width: 1201px) {
    width: calc(100% - 200px);
    padding: 24px 15px;
  }
  .viewTypeBox {
    margin: 0 0 12px;
    button {
      background: none;
      border: 0;
      border-radius: 30px;
      color: #88898a;
      font-size: 16px;
      line-height: 26px;
      margin: 0 12px 0 0;
      padding: 2px 11px;
      transition: all 0.3s ease 0s;
      &.active,
      &:hover {
        color: #195192;
        background: rgba(109, 148, 217, 0.1);
      }
    }
  }

  .selectionBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 0 0 5px;
    .wrapSelectField {
      width: 85px;
      margin: 0;

      .MuiSelect-nativeInput:placeholder-shown {
        font-size: 12px;
        color: #1c1e21;
        font-weight: 500;
      }

      .MuiSelect-select {
        font-size: 12px;
        color: #1c1e21;
      }
    }

    .wrapSelectField {
      .MuiOutlinedInput-root.MuiInputBase-root {
        background: none;
        border: 1px solid rgba(28, 30, 33, 0.2);
        height: 28px;
        &:after {
          right: 9px;
          top: 9px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }

  .boxLeft {
    width: 50%;
    padding: 0 15px 0 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .wrapSelectField {
      margin: 0 10px 0 0;
    }
  }

  .boxRight {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .chartTab {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #e3e7eb;
    button {
      color: #88898a;
      font-size: 14px;
      line-height: 24px;
      font-weight: 500;
      transition: all 0.3s ease 0s;
      background: none;
      border: 0;
      padding: 2px 0 12px;
      margin: 0 20px 0 0;
      border-bottom: 2px solid transparent;
      &:hover,
      &.active {
        color: #195192;
        border-bottom-color: #195192;
      }
    }
  }

  .chartBox {
    margin: 20px 0 0;
  }
`;

export default ChartBoxStyle;
