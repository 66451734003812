import styled from 'styled-components';

export const StylesInspectionSP= styled.div`
    padding: 12px 16px;
    .job--title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 24px;
        line-height: normal;
    }
    .classSticky {
        position: sticky;
        left: 0;
        background: white;
        border-right: 2px solid black;
        z-index: 1;
    }
    .btnSubmit:disabled,
    .btnSubmit[disabled]{
        opacity: 0.6;
    }
    .ant-picker-input > input {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.87);
    }
    .ant-picker-suffix {
        color: rgba(0, 0, 0, 0.5);
    }
`;
