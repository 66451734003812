import styled from 'styled-components';

export const StylesCreateJob = styled.div`
    padding: 12px 16px;
    .job--title {
        color: #000;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 24px;
        line-height: normal;
    }
    .undo-image {
        background-color: #c7dcf5;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        border: 1px solid #466eb6;
        position: relative;
        position: absolute;
        top: 11px;
        right: 11px;
    }
    .custom-checkbox {
        margin: 0 12px 0 0;
        .ant-checkbox-inner {
            background-color: #3ddd74;
            border: 1px solid #3ddd74;
            outline: none;
        }
        .ant-checkbox-checked::after {
            border: none;
        }
    }

    .signature {
        border: 1px solid #d0d2d3;
        border-radius: 4px;
        position: relative;
        background-color: #f3f3f3;
    }
    .dropzone {
        border: 1px dashed #d0d2d3;
        background-color: #f3f3f3;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 144px;
        &--text {
            color: #808184;
            margin-bottom: 0px;
            font-size: 16px;
            font-style: normal;
            margin-top: 16px;
            font-weight: 400;
            line-height: normal;
        }
        &--image__preview {
            height: 144px;
            width: 100%;
            border-radius: 4px;
        }
    }
    .dropdown-control-user {
        border: none !important;
        border-radius: 4px !important;
        background-color: #f3f3f3;
    }
    input {
        background-color: #f3f3f3;
        height: 30px;
        width: 100%;
        border-radius: 4px;
        padding-left: 10px;
        outline: none;
    }
    .sigCanvas {
        width: 100% !important;
        height: 180px !important;
    }
    textarea {
        padding-left: 10px;
        background-color: #f3f3f3;
        width: 100%;
        border-radius: 4px;
        outline: none;
        min-height: 60px;
    }
    .imageWrapper {
        img {
            width: 172px;
            height: 144px;
        }
    }
`;

export const Input = styled('input')({
    display: 'none !important',
});
