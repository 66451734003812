import fetch from './api/config';

const API = {
    STOCK_NOTES: () => `/stock_notes`,
    STOCK_NOTES_DETAIL: (id: number | string) => `stock_notes/${id}`,
};

export default class StockNotesService {
    static getListStockNotes = (params: {page: number; per_page: number}) =>
        fetch.get(API.STOCK_NOTES(), params);

    static createStockNotes = (params: FormData) =>
        fetch.post(API.STOCK_NOTES(), params);

    static updateStockNotes = (id: number | string, params: FormData) =>
        fetch.put(API.STOCK_NOTES_DETAIL(id), params);

    static deleteStockNotes = (id: string | number) =>
        fetch.delete(API.STOCK_NOTES_DETAIL(id), {});

    static getStockNotesDetail = (id: number | string) =>
        fetch.get(API.STOCK_NOTES_DETAIL(id), {});

}
