import React, {useState, useEffect} from 'react';
import './newsModal.css'

declare let window: any;

const NewsModal = (props) => {
  const {
    close,
    news,
    convertDateToJapan,
    type,
    handleAgreeSendApiFirst,
    handleRefuseSendApiFirst,
    classNameCustom,
    isAllowApproveDeny
  } = props;
  return (
    <div className={`modal-news-detail d-flex flex-column ${classNameCustom}`}>
        <div className='modal-news-detail-title d-flex flex-row'>{convertDateToJapan(news?.publish_at)}</div>
        <div className='modal-news-title d-flex flex-row'>{type === "pr" ? news?.custom_data?.name : news?.title}</div>
        <div className='modal-news-content d-flex flex-column'>
        {type === "pr" ? news?.title : news?.content}
      </div>
      {
        type === "pr" && isAllowApproveDeny ? <div className='flex gap-x-[12px] items-center mt-[20px]'>
          <button
            className='btn-agree btn-schedule'
            onClick={handleAgreeSendApiFirst}
          >
            承認
          </button>
          <button
            className='btn-refuse btn-schedule'
            onClick={handleRefuseSendApiFirst }
          >
            却下
          </button>
        </div> : <div className='modal-create-user-buttons d-flex flex-row'>
          <button className='modal-cancel-news d-flex flex-row' onClick={close}>キャンセル</button>
        </div>
      }

    </div>
  );
}
export default NewsModal ;
