import Api from './api/billApi';
import pick from 'lodash/pick';
// import planLists from '../shared/mocks/planLists.json';
import moment from 'moment';

const createBill = async (payload) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateBill = async (billId: number, payload: any) => {
    return await Api()
        .put(`${billId}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteBill = async (billId: number, project_id: number) => {
    return await Api()
        .delete(`${billId}?project_id=${project_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getDetailBill = async (billId: number, project_id: number) => {
    return await Api()
        .get(`${billId}?project_id=${project_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const getListBill = async (input?: any) => {
    // return planLists;
    let data = pick(input, ['page', 'per_page']);
    if (input.project_id) {
        data.project_id = input.project_id;
    }
    const params = new URLSearchParams(input);
    return await Api()
        .get(`?${params.toString()}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deletePayment = async (
    billId: number,
    project_id: number,
    payment_id: number,
) => {
    return await Api()
        .delete(`${billId}/payments/${payment_id}?project_id=${project_id}`)
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const billService = {
    createBill,
    updateBill,
    deleteBill,
    deletePayment,
    getDetailBill,
    getListBill,
};

export default billService;
