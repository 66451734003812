// Lib
import { max, min, required } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createPlanValidation = (t: TypeOfT) => ({
    name: () => ({
        required: required('plan_name', t),
    }),
    start_at: () => ({
        required: required('plan_start_at', t),
    }),
    end_at: () => ({
        required: required('plan_end_at', t),
    }),
    start_at_actual: () => ({
        required: required('plan_start_at_actual', t),
    }),
    end_at_actual: () => ({
        required: required('plan_end_at_actual', t),
    }),
    assigned_worker_id: () => ({
        required: required('plan_assigned_worker_id', t),
    }),
    status: () => ({
        required: required('plan_status', t),
    }),
    progress_percentage: () => ({
        required: required('plan_progress_percentage', t),
        validate: {
            min: min('plan_progress_percentage', 0, 0, t),
            max: max('plan_progress_percentage', 100, 0, t),
        },
    }),
});

export default createPlanValidation;
