import Highcharts from 'highcharts/highcharts-gantt';
import moment from 'moment';

/*
 * convert list plans to chart options
 *
 */
export const buildChartData = (plans: Array<any>, fixedMaxDate?: any): any => {
    const data: any = [],
        dataLabels: any = [
            {
                align: 'center',
            },
        ];
    var maxDate = moment(fixedMaxDate || plans[0]?.end_at || null).add(7, 'hours');
    plans.map((plan, i) => {
        const end = plan.end_at ? moment(plan.end_at, 'YYYY-MM-DD').add(7, 'hours') : null;
        if (!fixedMaxDate && end && maxDate.isBefore(end)) maxDate = end;
        data.push({
            start: moment(plan.start_at).startOf('day').add(6, 'hours').valueOf(),
            end: end ? moment(end).endOf('day')?.add(6, 'hours').valueOf() : null,
            id: plan.id,
            color: '#E3E5E8',
            completed: {
                amount: Number(
                    (Math.round(Number(plan.progress_percentage) || 0) / 100)   
                ),
                fill: '#3DDD74',
            },
            name: plan.name,
            y: i,
        });
    });
    const newMaxDate = maxDate.clone();
    const max = maxDate.valueOf();
    const min = maxDate.subtract(1, 'months').valueOf();

    return {
        data: {
            chart: {
                width: null,
                height: null,
            },
            xAxis: [
                {
                    labels: {
                        format: '{value:%a}',
                    },
                    grid: {
                        enabled: false,
                    },
                    min,
                    max,
                    tickInterval: 1000 * 60 * 60 * 24, // Day
                },
                {
                    labels: {
                        format: '{value:%d日}',
                    },
                    grid: {
                        enabled: false,
                    },
                    min,
                    max,
                    tickInterval: 1000 * 60 * 60 * 24, // Day
                },
                {
                    labels: {
                        format: '<b>{value:%Y年%m月}</b>',
                        align: 'left',
                    },
                    min,
                    max,
                    tickLength: 0,
                    tickInterval: 1000 * 60 * 60 * 24 * 30, // Month
                },
            ],
            tooltip: {
                enabled: false,
            },

            yAxis: {
                uniqueNames: false,
                type: 'category',
                grid: {
                    enabled: true,
                    borderColor: 'rgba(0,0,0,0.3)',
                    borderWidth: 0,
                    columns: [
                        {
                            title: {
                                text: '<b style="color:black;">工程</b>',
                            },
                            labels: {
                                borderColor: 'rgba(0,0,0,0.3)',
                                borderWidth: 1,
                                format: '{point.name}',
                            },
                        },
                        {
                            title: {
                                text: '',
                            },
                            offset: 10,
                            labels: {
                                useHTML: true,
                                formatter: function (e) {
                                    return `
                                    <button
                                        class="button-tb"
                                        id="${'button-detail-' + e.point.id}"
                                        style="width:72px;margin:5px"
                                    >
                                        進捗
                                    </button>
                                    <button
                                        class="button-tb"
                                        id="${'button-edit-' + e.point.id}"
                                        style="width:72px;margin:5px"
                                    >
                                        編集
                                    </button>
                                    <button
                                        class="button-tb"
                                        id="${'button-delete-' + e.point.id}"
                                        style="width:72px;margin:5px;background-color:#FF5045"
                                    >
                                        削除
                                    </button>
                                `;
                                },
                            },
                        },
                    ],
                },
            },

            // scrollbar: {
            //     enabled: true,
            // },

            rangeSelector: {
                enabled: true,
                // selected: 0,
                allButtonsEnabled: true,
                floating: true,
                y: -9999,
                x: -9999,
            },

            accessibility: {
                point: {
                    descriptionFormatter: function (
                        point: Highcharts.Point | any,
                    ) {
                        var completedValue = point?.completed
                                ? point.completed.amount || point?.completed
                                : null,
                            completed = completedValue
                                ? ' Task ' +
                                  Math.round(completedValue * 1000) / 10 +
                                  '% completed.'
                                : '';
                        return Highcharts.format(
                            '{point.yCategory}.{completed} Start {point.x:%Y-%m-%d}, end {point.x2:%Y-%m-%d}.',
                            { point, completed },
                        );
                    },
                },
                series: {
                    descriptionFormatter: function (series) {
                        return series.name;
                    },
                },
            },

            lang: {
                accessibility: {
                    axis: {
                        xAxisDescriptionPlural:
                            'The chart has a two-part X axis showing time in both week numbers and days.',
                        yAxisDescriptionPlural:
                            'The chart has one Y axis showing task categories.',
                    },
                },
            },

            series: [
                {
                    data,
                    dataLabels: {
                        align: 'left',
                        x: 0,
                    },
                },
            ],
        },
        newMaxDate,
    };
};
