import Api from "./api/estimateItemApi";
import pick from "lodash/pick";

const createChildEstimateItem = async (payload) => {
  return await Api()
    .post("/child_estimate_items", payload, {})
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const createEstimateItem = async (payload) => {
  return await Api()
    .post("", payload, {})
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const updateEstimateItem = async (estimateItemId: number, payload: any) => {
  return await Api()
    .put(`${estimateItemId}`, payload, {})
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const deleteEstimateItem = async (id?: any, estimate_id?: any) => {
  return await Api()
    .delete(`/${id}`, {
      data: { estimate_id: estimate_id },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const getDetailEstimateItem = async (input?: any) => {
  let data = pick(input, ["project_id", "estimate_id"]);
  const params = new URLSearchParams(data);

  return await Api()
    .get(`/${input?.id}?${params.toString()}`)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const getListEstimateItem = async (input?: any) => {
  let data = pick(input, ["page", "per_page", "estimate_id"]);

  const params = new URLSearchParams(data);

  return await Api()
    .get(`?${params.toString()}`)
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const importEstItem = async (payload) => {
  return await Api()
    .post("/import", payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response) => {
      if (response.data) {
        return response.data;
      }
    });
};

const estimateService = {
  createEstimateItem,
  createChildEstimateItem,
  updateEstimateItem,
  deleteEstimateItem,
  getDetailEstimateItem,
  getListEstimateItem,
  importEstItem,
};

export default estimateService;
