import styled from 'styled-components';

export const StylesPrivateMessageSP = styled.div`
    .message-icon {
        position: fixed;
        right: 24px;
        bottom: 24px;
        z-index: 100;
        width: 48px;
        cursor: pointer;
    }
    .private-message {
        background-color: #fff;
        border-radius: 8px;
        padding: 24px;
    }
`;
