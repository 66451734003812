import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import moment from 'moment';
import omit from 'lodash/omit';
import { useSelector } from 'react-redux';

import { RootState } from './../../../store/index';
import scheduleService from 'services/scheduleService';
import { getDateTimeUtc } from 'utils/helper/dateHelper';

export type ReceivedProps = {
    setOpenScheduleFormDialog: Dispatch<SetStateAction<boolean>>;
    setOpenDeleteConfirmationDialog: Dispatch<SetStateAction<boolean>>;
    setOpenPlanUpdateModal: Dispatch<SetStateAction<boolean>>;
    searchParam: any;
    openDeleteConfirmationDialog: boolean;
    openScheduleFormDialog: boolean;
    openPlanUpdateModal: boolean;
    workerOptions: {
        id: any;
        name: string;
    }[];
    activeTab: number;
};

export const SWITCHER_LIST = [
    {
        id: 'Day',
        name: '日',
    },
    {
        id: 'Week',
        name: '週',
    },
    {
        id: 'Month',
        name: '月',
    },
];

export interface CustomizedState {
    editingId: number;
}

const useSchedulerBox = (props: ReceivedProps) => {
    const { userData } = useSelector((state: RootState) => state.users);

    const [showDayList, setShowDayList] = useState<boolean>(false);
    const [currentViewName, setCurrentViewName] = useState<string>('Month');
    const [currentDate, setCurrentDate] = useState<any>();
    const [localScheduleDate, setLocalScheduleData] = useState<any[]>();
    const [currentItemId, setCurrentItemId] = useState<any>(null);
    const [scheduleList, setScheduleList] = useState<any>({
        data: [],
    });
    const [loading, setLoading] = useState<boolean>(false);
    const [isShowData, setIsShowData] = useState((localStorage.getItem("showData") === "false" ? false : true));

    const commitChanges = () => { };

    const fetchSchedules = useCallback(async () => {
        // if (!userData.worker) return;

        const queryFilter = {
            ...props.searchParam,
            worker_id:
                props.activeTab === 0
                    ? (userData as any).worker?.id
                    : props.searchParam.worker_id,
        };

        Object.keys(queryFilter).forEach((key) => {
            if ([undefined, null, ''].includes(queryFilter[key])) {
                delete queryFilter[key];
            }
        });

        try {
            setLoading(true);
            const { schedules, meta } = await scheduleService.getSchedules({
                page: 1,
                per_page: 9999,
                ...queryFilter,
            });

            const plansData = meta.plans.map((item) => ({
                ...item,
                color: '#009F08',
                startDate: item.start_at,
                endDate: moment(item.end_at).add(1, 'day').format('YYYY-MM-DD'),
                allDay: true,
                projectName:
                    meta.projects.find((obj) => obj.id === item.project_id)
                        ?.name || '',
            }));

            const schedulesData = schedules.map((item) => ({
                ...omit(item, ['start_date', 'end_date']),
                startDate: getDateTimeUtc(item.start_date),
                endDate: getDateTimeUtc(item.end_date),
                allDay: item.is_all_day,
            }));

            const newArr = [...schedulesData, ...plansData];

            setScheduleList({
                data: newArr.sort(
                    (d1, d2) =>
                        new Date(d1.startDate).getTime() -
                        new Date(d2.startDate).getTime(),
                ),
            });
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }, [userData, props.searchParam, props.activeTab]);

    const deleteSchedule = async () => {
        try {
            await scheduleService.deleteSchedule(currentItemId);
            props.setOpenDeleteConfirmationDialog(false);
            props.setOpenScheduleFormDialog(false);
            fetchSchedules();
        } catch (error) {
            //
        }
    };

    useEffect(() => {
        if (currentViewName === 'Month') {
            let counting = 0;
            let prevItemDate: any = null;

            let schedulerDataForMonth: any = [];

            scheduleList.data.forEach((item) => {
                const currentItemDate = moment(item.startDate).format(
                    'YYYY-MM-DD',
                );

                if (currentItemDate === prevItemDate && counting < 2) {
                    counting += 1;
                    schedulerDataForMonth = [
                        ...schedulerDataForMonth,
                        {
                            ...item,
                            title: item.project_id || item.allDay
                                ? `${moment(item.startDate).format(
                                    'DD',
                                )}日 ~ ${moment(item.endDate).format(
                                    'DD',
                                )}日 ${item.projectName ? `${item.projectName} ~ ${item.name}` : `${item.title}`}`
                                : `${moment(item.startDate).format('HH:mm')} ${item.title
                                }`,
                        },
                    ];
                } else if (currentItemDate === prevItemDate && counting === 2) {
                    counting += 1;

                    const getCurrentDateLength = scheduleList.data.filter(
                        (childItem) =>
                            moment(childItem.startDate).format('YYYY-MM-DD') ===
                            currentItemDate,
                    );
                    schedulerDataForMonth = [
                        ...schedulerDataForMonth,
                        {
                            ...item,
                            title: `他の${getCurrentDateLength.length - 3}件`,
                            showWeekView: true,
                        },
                    ];
                } else if (currentItemDate !== prevItemDate) {
                    counting = 0;
                    prevItemDate = moment(item.startDate).format('YYYY-MM-DD');
                    schedulerDataForMonth = [
                        ...schedulerDataForMonth,
                        {
                            ...item,
                            title: item.project_id || item.allDay
                                ? `${moment(item.startDate).format(
                                    'DD',
                                )}日 ~ ${moment(item.endDate).format(
                                    'DD',
                                )}日 ${item.projectName ? `${item.projectName} ~ ${item.name}` : `${item.title}`}`
                                : `${moment(item.startDate).format('HH:mm')} ${item.title
                                }`,
                        },
                    ];
                }
            });
            setLocalScheduleData(schedulerDataForMonth);
        } else {
            setLocalScheduleData(scheduleList.data);
        }
    }, [scheduleList.data, currentViewName]);

    useEffect(() => {
        // fetchSchedules();
    }, [fetchSchedules]);

    return {
        ...props,
        loading,
        showDayList,
        currentViewName,
        currentDate,
        localScheduleDate,
        scheduleList,
        deleteSchedule,
        setCurrentItemId,
        fetchSchedules,
        commitChanges,
        setLocalScheduleData,
        setCurrentDate,
        setShowDayList,
        setCurrentViewName,
        isShowData,
        setIsShowData,
    };
};

export type Props = ReturnType<typeof useSchedulerBox>;

export default useSchedulerBox;
