import { FC } from 'react';
import { Controller } from 'react-hook-form';
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
    TableCell,
    Paper,
    TextField
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";
import { styled } from '@mui/material/styles';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import CloseIcon from '@mui/icons-material/Close';
import type { DatePickerProps } from 'antd';
import { DatePicker } from 'antd';
import Radio from '@mui/material/Radio';
import locale from 'antd/es/date-picker/locale/ja_JP';
import 'moment/locale/ja';
import useInspectionRecord, { ReceivedProps, Props } from './hook';
import { IInspectionRecordsMapMachineModel, IMachineModel, IMachineModelInspectItem } from './type';
import { StylesInspectionSP } from './styled';
import moment from 'moment';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: "#1C1E21",
        paddingTop: '8px',
        paddingBottom: '8px',
        fontWeight: 600,
        border: "1px solid #00000026"
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
        color: "#1C1E21",
        padding: '4px 4px',
        border: "1px solid #00000026",

    },
}));


const PRLayout: FC<Props> = ({
    machineModels,
    inspectionRecords,
    setInspectionRecords,
    machineModelInspectItems,
    setMachineModelInspectItems,
    projectsList,
    valueProject,
    setValueProject,
    valueDate,
    setValueDate,
    valueMachineModel,
    setValueMachineModel,
    onSubmit,
    fetchMachineModelInspectItem,
    fetchInspectionRecords,
    listDataRender,
    isShowButton,
    setIsShowButton
}) => {

    const { userData } = useSelector((state: RootState) => state.users);
    const numDaysOfMonth = new Date(2023, 10, 0).getDate();
    const listDays: number[] = [];
    for (let i = 1; i <= numDaysOfMonth; i++) {
        listDays.push(i);
    }

    const options = ['good', 'maintenance_required', 'maintenance'];

    const onChange: DatePickerProps['onChange'] = (date, dateString) => {
        if (!date) return;
        setValueDate(dateString)
    };
    const onChangeSelect = (ob, e: React.ChangeEvent<HTMLInputElement>) => {
        setIsShowButton(true);
        const arrChecked = inspectionRecords?.map(item => {
            if (item.id === ob.id) {
                return { ...item, inspection_status: e.target.value }
            } else {
                return { ...item}
            }
        });
        setInspectionRecords(arrChecked)

        const arr = machineModelInspectItems.map(item => {
            if (item.id === ob.id) {
                return { ...item, inspection_status: e.target.value }
            } else {
                return { ...item};
            }
        })
        setMachineModelInspectItems(arr)
    };

    const onResetOption = (ob, e) => {
        setIsShowButton(true);
        const arrChecked = inspectionRecords?.map(item => {
            if (item.id === ob.id && (item.inspection_status !== null || item.inspection_status !== "")) {
                return { ...item, inspection_status: ""}
            } else {
                return { ...item, inspection_status: item.inspection_status}
            }
        });
        setInspectionRecords(arrChecked)

        const arr = machineModelInspectItems.map(item => {
            if (item.id === ob.id && (e.target.value !== null || e.target.value !== "")) {
                return { ...item, inspection_status: "" }
            } else {
                return { ...item};
            }
        })
        setMachineModelInspectItems(arr)
    };

    return (
        <StylesInspectionSP>
            <form className='mb-[24px]'>
                <div className="input-add-user d-flex flex-column mb-[12px]">
                    <p style={{ marginTop: 0 }}>現場名</p>
                    <Autocomplete
                        disablePortal
                        options={projectsList || []}
                        popupIcon={<ExpandMoreOutlinedIcon />}
                        getOptionLabel={(option) =>
                            option.name
                        }
                        value={valueProject}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setValueProject(newValue);
                            }
                        }}
                        classes={{
                            root: 'project-list-search-select-style',
                            input: 'project-list-search-select-style',
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                className="autocomplete-custom-textfield"
                            />
                        )}
                        noOptionsText="該当なし"
                        clearIcon={null}
                    />
                </div>
                <div className="input-add-user d-flex flex-column mb-[12px]">
                    <p style={{ marginTop: 0 }}>点検年月日</p>
                    <DatePicker
                        placeholder=''
                        className='w-full !rounded-[8px] !py-[5px] !px-[12px]'
                        onChange={onChange}
                        locale={locale}
                        value={valueDate !== "" ? moment(valueDate) : null}
                        suffixIcon={<ExpandMoreOutlinedIcon />}
                        allowClear={false}
                    />

                </div>
                <div className="input-add-user d-flex flex-column">
                    <p style={{ marginTop: 0 }}>機種名</p>
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        popupIcon={<ExpandMoreOutlinedIcon />}
                        options={machineModels || []}
                        getOptionLabel={(option) =>
                            option.machine_name
                        }
                        value={valueMachineModel}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setValueMachineModel(newValue)
                            }
                        }}
                        classes={{
                            root: 'project-list-search-select-style',
                            input: 'project-list-search-select-style',
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                className="autocomplete-custom-textfield"
                            />
                        )}
                        noOptionsText="該当なし"
                        clearIcon={null}
                    />
                </div>
            </form>
            <TableContainer component={Paper}>
                <Table
                    //sx={{ minWidth: 700 }}
                    style={{ borderSpacing: 0, fontSize: "14px" }}
                    aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell className="text-overflow min-w-[151px]" align="center"></StyledTableCell>
                            <StyledTableCell style={{ padding: "6px 13px" }} className='text-overflow' align="center">良✓</StyledTableCell>
                            <StyledTableCell style={{ padding: "6px 6px" }} className='text-overflow' align="center">整備要 <CloseIcon className='!text-[14px]' /></StyledTableCell>
                            <StyledTableCell style={{ padding: "6px 3px" }} className='text-overflow' align="center">整備済 <PanoramaFishEyeIcon className='!text-[12px]' /></StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            listDataRender?.length > 0 ? listDataRender?.map((item, indexVehicle) => (
                                <TableRow>
                                    <StyledTableCell>
                                        {item?.inspect_item?.item_name}
                                    </StyledTableCell>
                                    {options?.map((option, ind) => (
                                        <StyledTableCell className='text-overflow' align="center">
                                            <Radio
                                                key={ind}
                                                name="radioss"
                                                value={option}
                                                checked={item.inspection_status === option}
                                                onChange={(e) => onChangeSelect(item, e)}
                                                disabled={userData?.worker?.id !== item?.inspected_worker?.id && item?.inspected_worker !== null}
                                                onClick={(e) => onResetOption(item, e)}
                                            />
                                        </StyledTableCell>
                                    ))}

                                </TableRow>
                            )) : machineModelInspectItems?.length > 0 && machineModelInspectItems?.map((item, index) => (
                                <TableRow>
                                    <StyledTableCell>
                                        {item?.inspect_item?.item_name}
                                    </StyledTableCell>
                                    {options?.map((option, ind) => (
                                        <StyledTableCell className='text-overflow' align="center">
                                            <Radio
                                                name="radioss"
                                                value={option}
                                                checked={item.inspection_status === option}
                                                onChange={(e) => onChangeSelect(item, e)}
                                                onClick={(e) => onResetOption(item, e)}
                                            />
                                        </StyledTableCell>
                                    ))}

                                </TableRow>
                            ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <div className='flex justify-center my-[12px]'>
                {(listDataRender?.length > 0 || machineModelInspectItems?.length > 0) && isShowButton && <button disabled={listDataRender?.length <= 0 && machineModelInspectItems?.filter(it => it.inspection_status)?.length <= 0} className='bg-[#225594] text-[#ffffff] py-[6px] px-[16px] rounded-[8px] w-[110px] btnSubmit' onClick={onSubmit}>承認</button>}
            </div>
        </StylesInspectionSP>
    );
};

const InspectionRecordSP: FC<ReceivedProps> = (props) => {
    return <PRLayout {...useInspectionRecord(props)} />;
};

export default InspectionRecordSP;
