import Api from './api/news';
import ApiSP from './api/newsSP';
import pick from 'lodash/pick';

const getListNews = async(params: any = {page: 1, per_page: 0, free_word: ''}) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null ){
        try {
            if(user.access_token !== undefined && user.access_token !== null) {
                return await Api().get('', { headers: {Authorization: `Bearer ${user.access_token}`}, params }).then(response => {
                    if(response.data) {
                        return response.data;
                    }
                })
            }
        } catch (err) {
            window.location.href = '/login';
            return null
        }
    } else {
        window.location.href = '/login';
    }
}


const getListNewsSP = async(params: any = {page: 1, per_page: 0, free_word: ''}) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null ){
        try {
            if(user.access_token !== undefined && user.access_token !== null) {
                return await ApiSP().get('', { headers: {Authorization: `Bearer ${user.access_token}`}, params }).then(response => {
                    if(response.data) {
                        return response.data;
                    }
                })
            }
        } catch (err) {
            window.location.href = '/login';
            return null
        }
    } else {
        window.location.href = '/login';
    }
}

const getNews = async(id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = { 
        id: id
    };
    if ((user.access_token !== undefined && user.access_token !== null)){
        try {
            return await Api().get(`${id}`, { headers: {Authorization: `Bearer ${user.access_token}`}}).then(response => {
                if(response.data) {
                    return response.data;
                }
            })
        } catch (err) {
            window.location.href = '/login';
            return null
        }
    } else {
        window.location.href = '/login';
    }
}


const createNews = async (payload) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;

    if ((user.access_token !== undefined && user.access_token !== null)){
        return await Api().post('', payload, { headers: {Authorization: `Bearer ${user.access_token}`} }).then(response => {
            if(response.data) {
                return response.data;
            }
        })
    } else {
        window.location.href = '/login';
    }
}

const updateNews = async (payload, id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;

    if ((user.access_token !== undefined && user.access_token !== null)){
        return await Api().put(`${id}`, payload, { headers: {Authorization: `Bearer ${user.access_token}`}}).then(response => {
            if(response.data) {
                return response.data;
            }
        })
    } else {
        window.location.href = '/login';
    }
}

const deleteNews = async(id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = { 
        id: id
    };
    if ((user.access_token !== undefined && user.access_token !== null)){
        try {
            return await Api().delete(`${id}`, { headers: {Authorization: `Bearer ${user.access_token}`}}).then(response => {
                if(response.data) {
                    return response.data;
                }
            })
        } catch (err) {
            // window.location.href = '/login';
            return err
        }
    } else {
        window.location.href = '/login';
    }
}

const NewsService = {
    getListNews,
    createNews,
    updateNews,
    deleteNews,
    getNews,
    getListNewsSP
};

export default NewsService;