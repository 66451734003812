import { Button } from '@mui/material';
import { FC, useState, useEffect } from 'react';
import StyledSettingContainer from './style';
import importData from 'services/importDataService';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from "@mui/material";

const Setting: FC = () => {
  const [isCheckComplete, setCheckComplete] = useState<any>(false);
  const [uuid, setUuid] = useState<any>("");

  const handleImportFile = async (files: FileList | null) => {
    if (!files?.length) return;
    const uuid = uuidv4();
    setUuid(uuid)
    // setUploadedFiles((prev) => ([...prev, files[0]]));
    let formData = new FormData();
    formData.append('attachment', files[0]);
    formData.append('uuid', uuid);

    try {
      const response = await importData.importFile(formData);
      if (response) {
        setCheckComplete(true);
      }
    } catch (error) {
      // empty
    }
  };

  useEffect(() => {
    let myInterval: any;
    if (isCheckComplete) myInterval = setInterval(async() => {
      try {
        const response = await importData.checkImportFile(uuid);
        if (response) {
          if(response?.file_import?.status === "done") {
            setCheckComplete(false);
            clearInterval(myInterval); 
          }
        }
      } catch (error) {
        // empty
      }
    }, 1000);
}, [isCheckComplete, uuid]);

  return (
    <StyledSettingContainer>
      <div className="pageContentWrapper">
        <div className="settingImport">
          <h2 className="importTitle">マスターデータインポート</h2>
          <Button component="label" className="importBtn" disabled={isCheckComplete}>
            {
              isCheckComplete ? <CircularProgress color="inherit" size={25} /> : <span>インポート</span>
            }
            <input
              type="file"
              hidden
              accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
              onChange={(e) => handleImportFile(e.target.files)}
            />
          </Button>
        </div>
      </div>
    </StyledSettingContainer>
  );
};

export default Setting;
