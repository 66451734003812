import ListBullets from "assets/menuIcons/listBullets"
import SquaresFour from "assets/menuIcons/SquaresFour"
import StyledToggleWrapper from "./style"

// '#215493'
// '#ffffff'

const ViewToggle = (props) => {
  const { cardViewMode, setCardViewMode } = props;

  return (
    <StyledToggleWrapper>
      <div 
        className={`toggleBtn list-mode ${!cardViewMode ? 'active' : ''}`}
        onClick={() => setCardViewMode(false)}
      >
        <ListBullets strokeColor={!cardViewMode ? "#ffffff" : "#215493"} />
      </div>
      <div 
        className={`toggleBtn card-mode ${cardViewMode ? 'active' : ''}`}
        onClick={() => setCardViewMode(true)}
      >
        <SquaresFour strokeColor={cardViewMode ? "#ffffff" : "#215493"} />
      </div>
    </StyledToggleWrapper>
  )
}

export default ViewToggle