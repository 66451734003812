import { Box, Button, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import WorkingLogService from 'services/workingLogServiceSP';
import ConfirmPopup from 'components/ComfirmPopup';
import { useModal } from 'react-hooks-use-modal';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/index';
import { getRole } from 'utils/helper/userConfigHelper';

const WorkingLogSP = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [workingLog, setWorkingLog] = useState([]);
    const [deleteID, setDeleteID] = useState(null);
    const { userData }: any = useSelector((state: RootState) => state.users);
    const role = getRole();

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: true,
        },
    );
    const getListWorkingLogs = async () => {
        try {
            const response: any = await WorkingLogService.getListWorkingLogs(
                role === 'admin'
                    ? {
                          page: 1,
                          per_page: 0,
                          project_id: id,
                      }
                    : {
                          page: 1,
                          per_page: 0,
                          project_id: id,
                          worker_id:
                              (userData?.worker && userData?.worker?.id) ||
                              null,
                      },
            );
            setWorkingLog(response?.working_logs);
        } catch (error) {
            console.log(error);
        }
    };

    const deleteWorkingLog = async () => {
        try {
            await WorkingLogService.deleteWorkingLog(
                Number(deleteID),
                Number(id),
            );
            getListWorkingLogs();
            closeDelete();
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getListWorkingLogs();
    }, []);

    return (
        <Box
            height="100%"
            sx={{
                '.MuiTypography-root': {
                    fontFamily: 'Zen Kaku Gothic Antique',
                },
            }}
            padding="16px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
        >
            <ModalDelete>
                <ConfirmPopup
                    closeDelete={() => {
                        closeDelete();
                        setDeleteID(null);
                    }}
                    handleSubmit={deleteWorkingLog}
                ></ConfirmPopup>
            </ModalDelete>
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
                width="100%"
            >
                <Box width="100%">
                    <Typography
                        fontWeight="700"
                        fontSize="18px"
                        lineHeight="26px"
                        color="#1C1E21"
                        align="center"
                    >
                        作業記録一覧
                    </Typography>
                    <Box width="100%">
                        {workingLog?.map((item: any) => (
                            <Box
                                key={item.id}
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{
                                    paddingY: '16px',
                                    borderBottom:
                                        '1px solid rgba(33, 84, 147, 0.3)',
                                    '&:last-child': {
                                        borderBottom: 'none',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        '.MuiTypography-root': {
                                            fontSize: '12px',
                                            lineHeight: '16px',
                                            color: '#1C1E21',
                                            mb: '4px',
                                        },
                                    }}
                                    display="flex"
                                    alignItems="center"
                                    paddingRight="16px"
                                >
                                    <img
                                        style={{
                                            width: '90px',
                                            height: '90px',
                                            borderRadius: '4px',
                                        }}
                                        src={
                                            item?.working_log_images?.[0]
                                                ?.images?.[0]?.image_path ||
                                            process.env.PUBLIC_URL +
                                                '/img/login-background.png'
                                        }
                                        alt="working_log_image"
                                    />
                                    <Box marginLeft="16px">
                                        <Typography>
                                            {item?.worked_at}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '14px !important',
                                                lineHeight: '20px !important',
                                                fontWeight: '500',
                                            }}
                                        >
                                            {item.plan.name}
                                        </Typography>
                                        <Typography>{item.name}</Typography>
                                        <Typography>{item.note}</Typography>
                                    </Box>
                                </Box>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    sx={{
                                        button: {
                                            borderRadius: '6px',
                                            padding: '4px 20px',
                                            fontSize: '12px',
                                            lineHeight: '16px',
                                            fontWeight: '500',
                                            boxShadow:
                                                '0px 1px 2px rgba(16, 24, 40, 0.05)',
                                        },
                                    }}
                                >
                                    <Link
                                        to={`/sp/working_logs/create/${id}?detail=true&workingLogId=${item.id}`}
                                    >
                                        <Button
                                            sx={{
                                                backgroundColor:
                                                    '#215493 !important',
                                            }}
                                            variant="contained"
                                        >
                                            詳細
                                        </Button>
                                    </Link>
                                    <Link
                                        to={`/sp/working_logs/create/${id}?workingLogId=${item.id}`}
                                    >
                                        <Button
                                            sx={{
                                                my: '8px',
                                                backgroundColor:
                                                    '#215493 !important',
                                            }}
                                            variant="contained"
                                        >
                                            編集
                                        </Button>
                                    </Link>
                                    <Button
                                        onClick={() => {
                                            openDelete();
                                            setDeleteID(item.id);
                                        }}
                                        sx={{
                                            backgroundColor:
                                                '#FF5045 !important',
                                        }}
                                        variant="contained"
                                    >
                                        削除
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Button
                    onClick={() => {
                        navigate(`/sp/projects/${id}/plans`);
                    }}
                    sx={{
                        padding: '10px 67.75px',
                        border: '1px solid rgba(33, 84, 147, 0.3)',
                        borderRadius: '8px',
                        filter: 'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))',
                        fontSize: '16px',
                        fontWeight: '500',
                        width: 'fit-content',
                        mb: '8px',
                    }}
                    variant="outlined"
                >
                    戻る
                </Button>
            </Box>
        </Box>
    );
};
export default WorkingLogSP;
