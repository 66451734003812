/*
 * function convert workers to dropdown options
 *
 */
export const convertErrorMessagesToObject = (
    fields: Object = {},
    errorMessage: string = '',
): any => {
    const errors = {};
    errorMessage.split(',').map((error: string) => {
        Object.keys(fields).map((key: string) => {
            const field = fields[key];
            if (error.includes(key) || error.includes(field)) {
                errors[key] = error;
            }
        });
    });
    return errors;
};

export const validateFullWhitespace = (stringValue: string): boolean => {
    return /^\s*$/.test(stringValue);
};
