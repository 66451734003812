import styled from 'styled-components'



export const Main = styled.div`
  .searchRow {
    margin-bottom: 24px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .actionArea {
      width: 100%;
      display: flex;
      align-items: center;
      margin-left: auto;
      justify-content: flex-end;

      .button-create {
        display: flex;
        justify-content: center;
        align-items: center;
        border-color: #215493;
        font-family: 'Zen Kaku Gothic Antique';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #215493;
        padding: 10px 18px;
        gap: 8px;
        width: 130px;
        height: 32px;
        border: 1px solid rgba(33, 84, 147, 0.4);
        filter: drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05));
        border-radius: 6px;
      }
    }
  }

  .button-tb {
    margin-left: 8px !important;
  }
    
`