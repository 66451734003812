import { configureStore } from '@reduxjs/toolkit';
// import todoSlice from './todo-slice'
import authReducer from './auth/auth';
import messageReducer from './message/message';
import { persistStore, persistReducer } from 'redux-persist';
import usersReducer from './user/user';
import positionReducer from './position/position';
import departmentReducer from './department/department';
import companyReducer from './clientCompany/clientCompany';
import workingReportReducer from './workingReport/workingReport';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['auth']
};

const reducer = combineReducers({
    auth: authReducer,
    message: messageReducer,
    users: usersReducer,
    positions: positionReducer,
    deparments: departmentReducer,
    company: companyReducer,
    workingReport: workingReportReducer,
});

// const reducer = {
//     auth: authReducer,
//     message: messageReducer,
//     users: usersReducer,
//     positions: positionReducer,
//     deparments: departmentReducer,
//     company: companyReducer,
//     workingReport: workingReportReducer,
// };

const persistedReducer = persistReducer(persistConfig, reducer);

const store = configureStore({
    reducer: persistedReducer,
    devTools: true,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
