import styled from 'styled-components'



export const Main = styled.div`
  .container-form-estimation {
    padding: 28px;

    .form-create-estimation {
      background: #FFFFFF;
      border-radius: 12px;
      align-items: center;
      width: 100%;
      padding: 28px;

      .title {
        width: 100%;
        height: 10%;
        font-family: 'Zen Kaku Gothic Antique';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        color: #1C1E21;
        margin-bottom: 29px;
      }

      .form-create-estimation-form {
        width: 100%;
        height: 70%;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #CDD1D5;
        border-radius: 8px;
        border-left: none;

        .input-add-estimation {
          width: 100%;
          justify-content: flex-start;
          align-items: flex-start;

          p {
            width: 200px;
            min-height: 57px;
            background: #C7DCF5;
            margin-bottom: 0;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            padding-left: 12px;
            align-items: center;
          }

          .content-right {
            width: 100%;
            align-items: center;
            padding: 10px 0;
            padding-left: 12px;
            min-height: 56px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: #1C1E21;
            border-bottom: 1px solid #F5F5F5;
          }
        }

        .dropdown-control-plan {
          width: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;
          height: 38px;
          background: #FFFFFF;
          border: 1px solid #CDD1D5 !important;
          border-radius: 8px !important;
        }
      }

      .group-buttons {
        width: 100%;
        height: 20%;
        align-items: center;
        margin-top: 20px;
      }
    }
  }



  .bnt{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #215493;
    background: #FFFFFF;
    width: 167.5px;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 8px;
    border-radius: 8px;
  }

  .bnt-cancel {
      border: 1px solid rgba(33, 84, 147, 0.3);
  }

  .bnt-submit {
      background: #215493 !important;
      color: #FFFFFF !important;
  }

  .modal-create-plan-button-add {
      justify-content: center;
      align-items: center;
      padding: 8px 18px;
      gap: 8px;
      width: 100%;
      background: #215493;
      border-radius: 8px;
      outline: none;
      border-color: #215493;
      color: #FFFFFF;
      outline: none;
      border: none
  }

  .modal-dropdown-create-estimation {
      width: 350px;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: 8px;
      height: 36px;
      background: #FFFFFF;
      font-family: "FontAwesome";
  }

  .datepicker-wrap {
      width: 350px;
      /* width: 100%; */
      border: 1px solid #CDD1D5;
      border-radius: 8px;
      height: 36px;
  }

  .datepicker-wrap>.target-datepicker-wrap>.input-date>.react-datepicker-wrapper>.react-datepicker__input-container {
      z-index: 1;
  }

  .datepicker-wrap>.target-datepicker-wrap>.input-date>.react-datepicker-wrapper>.react-datepicker__input-container>input {
      height: 36px;
      background: transparent;
      padding: 10px;
      width: 100%;
      cursor: default;
  }

  .image-preview {
      width: 122px;
      height: 100px;
      margin-right: 8px;
      position: relative;
  }

  .image-preview img {
      width: 122px;
      height: 100px;
  }

  .image-container {
      display: flex;
      flex-wrap: wrap;
  }

  .remove {
      position: absolute;
      width: 24px !important;
      height: 24px !important;
      top: 0px;
      right: 0px;
  }

  .bnt.Mui-disabled {
      background: rgb(0 0 0 / 13%) !important;
  }

  .disable-link {
      pointer-events: none;
      border: 1px solid rgb(0 0 0 / 13%) !important;
      color: rgba(0, 0, 0, 0.26) !important;
  }

  .input-add-estimation:first-child>p {
    border-top-left-radius: 8px;
  }

  .input-add-estimation:last-child>p {
    border-bottom-left-radius: 8px;
  }

  .datepicker-wrap{
    border: none;
  }
`