// Lib
import { emailFormat, minLength, required } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const updateUserValidation = (t: TypeOfT) => ({
    email: () => ({
        required: required('email', t),
        validate: {
            emailFormat: emailFormat('email', t),
        },
    }),
    password: () => ({
        minLength: minLength('password', 6, t),
    }),
    worker: () => ({
        required: required('employee', t),
    }),
});

export default updateUserValidation;
