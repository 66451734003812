import Api from './api/chatApi';
import pick from 'lodash/pick';

const getChats = async (input?: any) => {
    let data = pick(input, ['page', 'per_page', 'free_word']);
    const params = new URLSearchParams(data);

    try {
        return await Api()
            .get(`?${params.toString()}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const getChat = async (id: string | number) => {
    try {
        return await Api()
            .get(`${id}`)
            .then((response) => {
                if (response.data) {
                    return response.data;
                }
            });
    } catch (error) {
        // window.location.href = '/login';
        return null;
    }
};

const createChat = async (payload: any) => {
    return await Api()
        .post('', payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const updateChat = async (id: string | number, payload: any) => {
    return await Api()
        .put(`${id}`, payload, {})
        .then((response) => {
            if (response.data) {
                return response.data;
            }
        });
};

const deleteChat = async (id: number) => {
    return await Api().delete(`${id}`);
};

const chatService = {
    updateChat,
    getChats,
    getChat,
    createChat,
    deleteChat,
};

export default chatService;
