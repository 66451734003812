import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import WorkingLogAdditionalsService from 'services/workinglogAdditionals';
import { useModal } from 'react-hooks-use-modal';

export type ReceivedProps = Record<string, never>;

const useJob = (props: ReceivedProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>([]);
    const [deleteData, setDeleteData] = useState<any>({});
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: true,
        },
    );

    const fetchWorkingLogsAdditionals = async () => {
        try {
            const { working_log_additionals }: any =
                await WorkingLogAdditionalsService.getListWorkingLogsAdditionals(
                    {
                        page: 1,
                        per_page: 0,
                    },
                );
            setData(working_log_additionals);
        } catch (error) {
            //
        }
    };

    const handleDelete = async () => {
        try {
            await WorkingLogAdditionalsService.deleteWorkingLog(
                deleteData.id,
                deleteData?.project?.id,
            );
            fetchWorkingLogsAdditionals();
            closeDelete();
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchWorkingLogsAdditionals();
    }, []);

    return {
        ...props,
        data,
        navigate,
        handleDelete,
        setDeleteData,
        ModalDelete,
        openDelete,
        closeDelete,
    };
};

export type Props = ReturnType<typeof useJob>;

export default useJob;
