import styled from 'styled-components';

export const StylesWorkingTimes = styled.div`
    margin: 22px 28px;
    background-color: #fff;
    border-radius: 8px;
    padding: 34px 29px;
    .working_times--title {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .headerBox {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 19px 20px;
        .pageTitle {
          margin: 0;
        }
    
        .importBtn {
          background: none;
          padding: 3px 7px;
          color: #000000;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          border: 1px solid #bcbcbc;
          border-radius: 6px;
          position: relative;
          overflow: hidden;
          cursor: pointer;
          input {
            cursor: pointer;
            z-index: 2;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: 0;
          }
        }
      }
    
      .wrapPageContent {
        display: flex;
        flex-wrap: wrap;
      }
`;
