import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Dialog, Autocomplete, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

import createManifestValidation from 'utils/validation/registers/manifests/createValidation';
import calendarIcon from 'assets/icons/calendar.png';
import StyledManifestForm from '../manifestFormStyle';
import IndustriesWatesService from 'services/industriesWatesService';
import manifestService from 'services/manifestService';

interface IProps {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    orderItemOptions: any[];
    wasteTypes: any[];
    companyOptions: any[];
    project_id: any;
    onFinishCreate: () => void;
}

const CreateManifestModal = (props: IProps) => {
    const {
        open,
        setOpen,
        orderItemOptions,
        wasteTypes,
        companyOptions,
        project_id,
        onFinishCreate,
    } = props;
    const {
        register,
        control,
        handleSubmit,
        setError,
        getValues,
        watch,
        setValue,
        formState: { errors },
    } = useForm();
    const { t } = useTranslation();
    const [hasOrderItemSelected, setHasOrderItemSelected] = useState(false);

    const onCreateManifest = async (values) => {
        // console.log('values submit', values);
        let input = new FormData();

        if (project_id) {
            input.append('project_id', project_id);
        }
        if (values.index) {
            input.append('index', values.index);
        }
        if (values.date_at) {
            const dateAtString = moment(values.date_at).format('YYYY-MM-DD');
            input.append('date_at', dateAtString);
        }
        if (values.manifest_no) {
            input.append('manifest_no', values.manifest_no);
        }
        if (values.order_item) {
            input.append('order_item[id]', values.order_item.value);
        }
        if (values.final_disposal_at) {
            const finalDisposalAtString = moment(
                values.final_disposal_at,
            ).format('YYYY-MM-DD');
            input.append('final_disposal_at', finalDisposalAtString);
        }
        if (values.step) {
            input.append('step', values.step);
        }

        if (values.waste_type) {
            input.append('order_item[waste_type]', values.waste_type.value);
        }
        if (values.amount) {
            input.append('order_item[amount]', values.amount);
        }
        if (values.unit) {
            input.append('order_item[unit]', values.unit);
        }
        if (values.volume) {
            input.append('order_item[volume]', values.volume);
        }
        if (values.collect_company) {
            input.append(
                'order_item[collect_company_id]',
                values.collect_company.value,
            );
        }
        if (values.disposal_company) {
            input.append(
                'order_item[disposal_company_id]',
                values.disposal_company.value,
            );
        }
        if (values.final_disposal_company) {
            input.append(
                'order_item[final_disposal_company_id]',
                values.disposal_company.value,
            );
        }

        try {
            const data = await manifestService.createManifest(input);
            if (data) {
                onFinishCreate();
            }
        } catch (error) {
            // empty
        }
    };

    const fetchOrderItemDetail = async (orderId) => {
        try {
            const response: any =
                await IndustriesWatesService.getOrderItemDetail(orderId, {
                    project_id: project_id,
                });
            // console.log('response fetch order detail', response);
            if (response && response.order_item) {
                const responseOrderItem = response.order_item;
                setValue(
                    'waste_type',
                    wasteTypes.find(
                        (item) => item.value === responseOrderItem.waste_type,
                    ),
                );
                setValue('amount', responseOrderItem.amount);
                setValue('unit', responseOrderItem.unit);

                if (
                    responseOrderItem.collect_company &&
                    responseOrderItem.collect_company.id
                ) {
                    setValue(
                        'collect_company',
                        companyOptions.find(
                            (item) =>
                                item.value ===
                                String(responseOrderItem.collect_company.id),
                        ),
                    );
                    // console.log(companyOptions.find((item) => item.value === String(responseOrderItem.collect_company.id)))
                }

                if (
                    responseOrderItem.disposal_company &&
                    responseOrderItem.disposal_company.id
                ) {
                    setValue(
                        'disposal_company',
                        companyOptions.find(
                            (item) =>
                                item.value ===
                                String(responseOrderItem.disposal_company.id),
                        ),
                    );
                    // console.log(companyOptions.find((item) => item.value === String(responseOrderItem.collect_company.id)))
                }
            }
        } catch (error) {
            // error
        }
    };

    useEffect(() => {
        if (!!getValues('order_item')) {
            setHasOrderItemSelected(true);
        } else {
            setHasOrderItemSelected(false);
        }
    }, [watch('order_item')]);

    useEffect(() => {
        if (!!getValues('order_item')) {
            const orderId = getValues('order_item').value;
            if (orderId) {
                fetchOrderItemDetail(orderId);
            }
        }
    }, [watch('order_item')]);

    return (
        <Dialog open={open} onClose={() => setOpen(false)} disableEscapeKeyDown>
            <StyledManifestForm onSubmit={handleSubmit(onCreateManifest)}>
                <h2 className="formTitle">マニフェスト新規作成</h2>

                <div className="formFieldRow">
                    <p>整理 番号</p>
                    <input
                        type="number"
                        autoComplete="off"
                        {...register(
                            'index',
                            createManifestValidation(t).index(),
                        )}
                        className="textfield"
                    />
                    {errors.index && (
                        <span className="error">
                            {errors.index.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>日付</p>
                    <div className="datepicker-wrapper">
                        <Controller
                            name="date_at"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    id="date_at"
                                    selected={field.value}
                                    {...register(
                                        'date_at',
                                        createManifestValidation(t).date_at(),
                                    )}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <label>
                            <img
                                className="calendar-datepicker-icon"
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                    {errors.date_at && (
                        <span className="error">
                            {errors.date_at?.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>マニフェスト No</p>
                    <input
                        type="number"
                        autoComplete="off"
                        min="0"
                        {...register(
                            'manifest_no',
                            createManifestValidation(t).index(),
                        )}
                        className="textfield"
                    />
                    {errors.manifest_no && (
                        <span className="error">
                            {errors.manifest_no.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>委託内容</p>

                    <Controller
                        name="order_item"
                        control={control}
                        render={({ field }) => (
                            <Dropdown
                                className="dropdown"
                                controlClassName="dropdownControl"
                                options={orderItemOptions}
                                {...register(
                                    'order_item',
                                    createManifestValidation(t).order_item(),
                                )}
                                {...field}
                                placeholder=""
                            />
                        )}
                    />
                    {errors.order_item && (
                        <span className="error">
                            {errors.order_item?.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>産廃物種類</p>

                    <Controller
                        name="waste_type"
                        control={control}
                        render={({ field }) => (
                            <Dropdown
                                className="dropdown"
                                controlClassName="dropdownControl"
                                options={wasteTypes}
                                disabled={!hasOrderItemSelected}
                                {...register(
                                    'waste_type',
                                    createManifestValidation(t).waste_type(),
                                )}
                                {...field}
                                placeholder=""
                            />
                        )}
                    />
                    {errors.waste_type && (
                        <span className="error">
                            {errors.waste_type?.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>数量</p>
                    <input
                        type="number"
                        autoComplete="off"
                        min="0"
                        disabled={!hasOrderItemSelected}
                        {...register(
                            'amount',
                            createManifestValidation(t).amount(),
                        )}
                        className="textfield"
                    />
                    {errors.amount && (
                        <span className="error">
                            {errors.amount.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>単位</p>
                    <input
                        type="text"
                        autoComplete="off"
                        {...register(
                            'unit',
                            createManifestValidation(t).unit(),
                        )}
                        className="textfield"
                        disabled={!hasOrderItemSelected}
                    />
                    {errors.unit && (
                        <span className="error">
                            {errors.unit.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>体積</p>
                    <input
                        type="number"
                        autoComplete="off"
                        {...register(
                            'volume',
                            createManifestValidation(t).volume(),
                        )}
                        className="textfield"
                    />
                    {errors.volume && (
                        <span className="error">
                            {errors.volume.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>状態</p>
                    <input
                        type="text"
                        autoComplete="off"
                        {...register(
                            'step',
                            createManifestValidation(t).step(),
                        )}
                        className="textfield"
                    />
                    {errors.step && (
                        <span className="error">
                            {errors.step.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>収集運搬</p>
                    <Controller
                        name="collect_company"
                        control={control}
                        render={({ field }) => {
                            return (
                                <Dropdown
                                    className="dropdown"
                                    controlClassName="dropdownControl"
                                    options={companyOptions}
                                    disabled={!hasOrderItemSelected}
                                    {...register(
                                        'collect_company',
                                        createManifestValidation(
                                            t,
                                        ).collect_company(),
                                    )}
                                    {...field}
                                    placeholder=""
                                />
                                // <Autocomplete
                                //     disablePortal
                                //     id="combo-box-demo"
                                //     options={companyOptions.map((item) => ({
                                //         value: item.id,
                                //         label: item.name,
                                //     }))}
                                //     isOptionEqualToValue={(option, value) => option.value === value.value}
                                //     {...register(
                                //         'collect_company',
                                //         createManifestValidation(t).collect_company()
                                //     )}
                                //     onChange={(event, newValue) => {
                                //         setValue('worker', newValue);
                                //     }}
                                //     classes={{
                                //         root: 'autocomplete-select-style',
                                //     }}
                                //     disabled={!hasOrderItemSelected}
                                //     renderInput={(params) => (
                                //         <TextField {...params} label="" />
                                //     )}
                                //     noOptionsText="該当なし"
                                //     value={value}
                                // />
                            );
                        }}
                    />
                    {errors.collect_company && (
                        <span className="error">
                            {errors.collect_company.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>処分業者</p>
                    <Controller
                        name="disposal_company"
                        control={control}
                        render={({ field }) => (
                            <Dropdown
                                className="dropdown"
                                controlClassName="dropdownControl"
                                options={companyOptions}
                                disabled={!hasOrderItemSelected}
                                {...register(
                                    'disposal_company',
                                    createManifestValidation(
                                        t,
                                    ).disposal_company(),
                                )}
                                {...field}
                                placeholder=""
                            />
                            // <Autocomplete
                            //     disablePortal
                            //     id="combo-box-demo"
                            //     options={companyOptions.map((item) => ({
                            //         value: item.id,
                            //         label: item.name,
                            //     }))}
                            //     isOptionEqualToValue={(option, value) => option.value === value.value}
                            //     {...register(
                            //         'disposal_company',
                            //         createManifestValidation(t).disposal_company()
                            //     )}
                            //     onChange={(event, newValue) => {
                            //         setValue('worker', newValue);
                            //     }}
                            //     disabled={!hasOrderItemSelected}
                            //     classes={{
                            //         root: 'autocomplete-select-style',
                            //     }}
                            //     renderInput={(params) => (
                            //         <TextField {...params} label="" />
                            //     )}
                            //     noOptionsText="該当なし"
                            //     value={value}
                            // />
                        )}
                    />
                    {errors.disposal_company && (
                        <span className="error">
                            {errors.disposal_company.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formFieldRow">
                    <p>最終処分場</p>
                    <Controller
                        name="final_disposal_company"
                        control={control}
                        render={({ field }) => (
                            <Dropdown
                                className="dropdown"
                                controlClassName="dropdownControl"
                                options={companyOptions}
                                {...register(
                                    'final_disposal_company',
                                    // createManifestValidation(t).final_disposal_company()
                                )}
                                {...field}
                                placeholder=""
                            />
                            // <Autocomplete
                            //     disablePortal
                            //     id="combo-box-demo"
                            //     options={companyOptions.map((item) => ({
                            //         value: item.id,
                            //         label: item.name,
                            //     }))}
                            //     {...register(
                            //         'final_disposal_company',
                            //         createManifestValidation(t).final_disposal_company()
                            //     )}
                            //     isOptionEqualToValue={(option, value) => option.value === value.value}
                            //     onChange={(event, newValue) => {
                            //         setValue('worker', newValue);
                            //     }}
                            //     classes={{
                            //         root: 'autocomplete-select-style',
                            //     }}
                            //     renderInput={(params) => (
                            //         <TextField {...params} label="" />
                            //     )}
                            //     noOptionsText="該当なし"
                            //     value={value}
                            // />
                        )}
                    />
                    {/* {errors.final_disposal_company && (
                <span className="error">{errors.final_disposal_company.message?.toString()}</span>
            )} */}
                </div>

                <div className="formFieldRow">
                    <p>最終処分日</p>
                    <div className="datepicker-wrapper">
                        <Controller
                            name="final_disposal_at"
                            control={control}
                            render={({ field }) => (
                                <DatePicker
                                    id="final_disposal_at"
                                    selected={field.value}
                                    {...register(
                                        'final_disposal_at',
                                        createManifestValidation(
                                            t,
                                        ).final_disposal_at(),
                                    )}
                                    {...field}
                                    onChange={(date) => {
                                        field?.onChange(date);
                                    }}
                                    dateFormat="yyyy-MM-dd"
                                    autoComplete="off"
                                    locale="ja"
                                />
                            )}
                        />
                        <label htmlFor="final_disposal_at">
                            <img
                                className="calendar-datepicker-icon"
                                src={calendarIcon}
                            ></img>
                        </label>
                    </div>
                    {errors.final_disposal_at && (
                        <span className="error">
                            {errors.final_disposal_at?.message?.toString()}
                        </span>
                    )}
                </div>

                <div className="formButtonGroup">
                    <button
                        type="button"
                        className="cancelBtn"
                        onClick={() => setOpen(false)}
                    >
                        戻る
                    </button>
                    <button className="registerBtn" type="submit">
                        登録
                    </button>
                </div>
            </StyledManifestForm>
        </Dialog>
    );
};

export default CreateManifestModal;
