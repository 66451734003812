/*
 * function convert clientCompanies to dropdown options
 *
 */
// export const convertClientCompaniesToDropdownOptions = (
//     array: Array<any> = [],
// ): Array<{
//     value: string;
//     label: string;
// }> => {
//     return array.map((item) => ({
//         value: item.id,
//         label: item.company?.name,
//     }));
// };

export const convertClientCompaniesToDropdownOptions = (
    array: Array<any> = [],
): Array<{
    value: string;
    name: string;
    label: string;
}> => {
    return array.map((item) => ({
        value: String(item.company?.id),
        name: item.company?.name,
        label: item.company?.name,
    }));
};

export const convertClientCompaniesToDropdownOptionsCreateProject = (
    array: Array<any> = [],
): Array<{
    value: string;
    name: string;
    label: string;
}> => {
    return array.map((item) => ({
        value: item?.id,
        name: item.company?.name,
        label: item.company?.name,
    }));
};


export const convertCompaniesToDropdownOptions = (
    array: Array<any> = [],
): Array<{
    value: string;
    name: string;
    label: string;
}> => {
    return array.map((item) => ({
        value: item.id,
        name: item?.name,
        label: item?.name,
    }));
};

export const removeUndefinedCompany = (arr) => {
    arr.map((item, id) => {
        item.value === undefined && arr.splice(id, 1)
    })
    return arr.filter((v,i,a)=>a.findIndex(v2=>(v2.value===v.value))===i)
}