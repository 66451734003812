import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSearchParams } from 'react-router-dom';

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface IProjectTabsProps {
    titles: string[];
    contents: JSX.Element[];
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ProjectTabs({ titles, contents }: IProjectTabsProps) {
    const [value, setValue] = React.useState(0);
    let [searchParams] = useSearchParams();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    React.useEffect(() => {
        const tabParam = Number(searchParams.get('tab'));
        if (tabParam === 6) {
            setValue(3);
        }
    }, []);

    return (
        <Box sx={{ width: '100%', mt: '24px' }}>
            <Box sx={{ borderBottom: 1, borderColor: '#CDD1D5' }}>
                <Tabs
                    sx={{
                        minHeight: 'auto',
                        '.Mui-selected': {
                            color: '#215493 !important',
                        },
                        '.MuiTabs-indicator': {
                            backgroundColor: '#215493',
                            height: 2,
                        },
                    }}
                    value={value}
                    onChange={handleChange}
                >
                    {titles?.map((title, index) => (
                        <Tab
                            sx={{
                                padding: '0px 20px 3px 20px',
                                minHeight: 'auto',
                                color: '#58606A',
                                fontWeight: 700,
                                fontSize: '18px',
                                lineHeight: '26px',
                            }}
                            label={title}
                            {...a11yProps(index)}
                            tabIndex={index + 2}
                        />
                    ))}
                </Tabs>
            </Box>
            {contents?.map((content, index) => (
                <TabPanel
                    key={index}
                    index={index}
                    value={value}
                    children={content}
                />
            ))}
        </Box>
    );
}
