import useCreateJob, { ReceivedProps, Props } from './hook';
import { Grid, Button } from '@mui/material';
import { FC } from 'react';
import { Add, Close } from '@mui/icons-material';
import { getFilePreview } from 'utils/helper/file';
import { StylesCreateJob } from './styled';

const CreatePRLayout: FC<Props> = ({
    fileUpload,
    removeImage,
    getRootProps,
    navigate,
    getInputProps,
    formik,
    id,
}) => {
    const files = fileUpload.map((file, index: number) => (
        <Grid position="relative" item xs={6} key={file.path}>
            <img
                className="dropzone--image__preview"
                src={
                    typeof file?.url === 'string'
                        ? file?.url
                        : getFilePreview(file)
                }
                alt="image_preview_job"
            />
            {!id && (
                <Close
                    onClick={() => removeImage(index)}
                    sx={{ position: 'absolute', top: '20px', right: '4px' }}
                />
            )}
        </Grid>
    ));
    return (
        <StylesCreateJob>
            <p className="job--title">{id ? formik?.values?.name : '新規登録'}</p>
            <form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
                <div className="formFieldRow mt-[12px]">
                    <p className="mb-2">タイトル</p>
                    <input
                        disabled={!!id}
                        autoComplete="off"
                        type="name"
                        name="name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        className="textfield"
                    />
                </div>

                <div className="formFieldRow mt-[12px]">
                    <p className="mb-2">内容</p>
                    <textarea
                        disabled={!!id}
                        autoComplete="off"
                        className="textarea"
                        value={formik.values.content}
                        onChange={formik.handleChange}
                        name="content"
                    />
                </div>
                <Grid sx={{ mt: '8px' }} container spacing={2}>
                    {files}
                    {!id && (
                        <Grid item xs={6}>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <Add
                                    sx={{
                                        color: '#808184',
                                        width: '29px',
                                        height: '29px',
                                    }}
                                />
                                <p className="dropzone--text">
                                    写真をアップロード
                                </p>
                            </div>
                        </Grid>
                    )}
                </Grid>
               
                
                <Grid marginTop="25px" container>
                    {id ? (
                        <Grid item display="flex" justifyContent="center" xs={12}>
                            <Button
                                type="button"
                                onClick={() => navigate('/sp/purchase-request')}
                                sx={{
                                    color: '#215493',
                                    width: '166px',
                                }}
                                variant="outlined"
                            >
                                戻る
                            </Button>
                        </Grid>
                    ) : (
                        <>
                            <Grid item xs={6}>
                                <Button
                                    type="button"
                                    onClick={() => navigate('/sp/purchase-request')}
                                    sx={{
                                        color: '#215493',
                                        width: '166px',
                                    }}
                                    variant="outlined"
                                >
                                    戻る
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    disabled={
                                        !formik.isValid ||
                                        !fileUpload?.length
                                    }
                                    type="submit"
                                    sx={{
                                        color: '#fff',
                                        width: '166px',
                                        background: '#225594',
                                    }}
                                    variant="contained"
                                >
                                    承認
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            </form>
        </StylesCreateJob>
    );
};

const CreatePR: FC<ReceivedProps> = (props) => {
    return <CreatePRLayout {...useCreateJob(props)} />;
};

export default CreatePR;
