import CloseIcon from '@mui/icons-material/Close';
import { EDIT_SUGOROKU_MODE, VIEW_SUGOROKU_MODE } from 'utils/constants';
import { divideByTen, roundDown } from 'utils/helper/numberHelper';
import StyledStepDialogHeader from './styles';

interface IProps {
  step: number;
  title?: string;
  onClose?: any;
  mode: string;
  setMode: any;
  completed?: boolean;
}

const DialogHeader = (props: IProps) => {
  const { step, title, onClose, mode, setMode, completed } = props;

  return (
    <StyledStepDialogHeader>
        <div
            className={
                completed
                    ? 'step-number-icon complete-theme'
                    : 
                    'step-number-icon incomplete-theme'
            }
        >
            {roundDown(divideByTen(step), 1)}
        </div>
        <p>{title}</p>
        <div>
          {
            mode === VIEW_SUGOROKU_MODE && (
              <button className="step-edit-button" onClick={() => setMode(EDIT_SUGOROKU_MODE)}>
                  編集
              </button>
            )
          }
          <CloseIcon
              fontSize="small"
              onClick={onClose}
              style={{ cursor: 'pointer' }}
          />
        </div>
    </StyledStepDialogHeader>
  )
}

export default DialogHeader;