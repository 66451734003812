import styled from "styled-components";
import { alpha } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";
import InputBase from "@mui/material/InputBase";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Input } from "antd";

export const ShoeiInputText = styled(InputBase)(({ theme }) => ({
  width: "100%",

  "& .MuiInputBase-input": {
    background: "#FFFFFF",
    border: "1px solid #CDD1D5",
    borderRadius: 8,
    padding: "0px 12px",
    height: 36,
    fontSize: 14,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#C7DCF5",
    color: "#1C1E21",
  },
  [`&.${tableCellClasses.body}`]: {
    fontFamily: "Zen Kaku Gothic Antique",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    color: "#1C1E21",
    backgroundColor: "#FFFFFF",
    padding: "4px 16px",
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "rgba(0, 0, 0, 0.04)",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const Main = styled.div`
  background: #ffffff;
  padding: 28px;
  width: 100%;
  border-bottom-left-radius: 12px;

  .text-overflow1 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
  }

  .bnt-add {
    align-items: center;
    background: #ffffff;
    width: 108px;
    color: #1c1e21;
    padding: 0;
    border: 1px solid rgba(28, 30, 33, 0.4);
    justify-content: space-evenly;
    height: 36px;
    margin: 0 !important;
    margin-top: 16px !important;
  }

  &.modal {
    border-radius: 12px;
    width: 450px;
    padding: 28px 0px !important;

    .title {
      color: #1c1e21;
      font-family: "Zen Kaku Gothic Antique";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      margin-bottom: 24px;
    }

    .contronler-box-input-label {
      margin-bottom: 12px;
      width: 100%;

      .label-input {
        color: #344054;
        font-family: "Zen Kaku Gothic Antique";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 6px;
      }

      .error {
        font-size: 11.5px;
        margin-top: 0px;
        font-style: italic;
      }
    }
  }

  .input-text-antd {
    background: rgb(255, 255, 255);
    border: 1px solid rgb(205, 209, 213) !important;
    border-radius: 8px;
    padding: 0px 12px;
    height: 38px;
    font-size: 14px;
    box-shadow: unset !important;
  }
  .bnt {
    width: 100%;
    margin-right: 0;

    &.bnt-line {
      color: #215493;
      border: 1px solid rgba(33, 84, 147, 0.3);
      border-radius: 8px;
    }

    &.bnt-back {
      width: 167.5px;
      margin-bottom: 12px;
      height: 40px;
    }

    &.bnt-submit {
      background: #215493 !important;
      color: #ffffff !important;
    }
  }

  .total_price {
    font-family: "Zen Kaku Gothic Antique";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #1c1e21;
    text-align: right;
  }

  .group-buttons {
    margin-top: 24px;
  }

  .gap-input {
    gap: 10px;
  }

  .is-parent {
    background: #f2f2f2;
    border-radius: 6px;
    padding: 10px;
  }

  .check-parent {
    svg {
      color: #3ddd74;
    }
  }
  .MuiTypography-root {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
  }
`;
