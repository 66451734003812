import { FC } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { BiBox } from 'react-icons/bi';
import { useModal } from 'react-hooks-use-modal';
import { useNavigate } from 'react-router-dom';

import CustomPagination from 'components/CustomPagination';
import useChat, {
    HEADER_HEIGHT,
    Props,
    ReceivedProps,
    ROW_HEIGHT,
} from './hook';
import { Styles, TableWrapper } from './styled';

const ChatListLayout: FC<Props> = ({
    gridRef,
    rowData,
    columnDefs,
    containerStyle,
    gridStyle,
    defaultColDef,
    pageQueries,
    total,
    onDeleteChat,
    setChatId,
    setPageQueries,
    isFullWidthRow,
    onVirtualColumnsChanged,
    onGridReady,
}) => {
    const navigate = useNavigate();
    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            //closeOnOverlayClick: false,
        },
    );

    return (
        <Styles>
            <div className="table-wrapper">
                <TableWrapper>
                    <div style={containerStyle}>
                        <div style={gridStyle} className="ag-theme-alpine">
                            <AgGridReact
                                headerHeight={HEADER_HEIGHT}
                                rowHeight={ROW_HEIGHT}
                                ref={gridRef}
                                rowData={rowData}
                                pagination={true}
                                paginationPageSize={50}
                                getRowNodeId={(e: any) => e.id}
                                columnDefs={[
                                    ...columnDefs,
                                    {
                                        minWidth: 180,
                                        width: 180,
                                        cellClass: 'grid-cell-centered',
                                        field: '操作',
                                        suppressMovable: true,
                                        cellRenderer: (e: any) => (
                                            <div className="group-btn-actions">
                                                <button
                                                    className="button-tb"
                                                    onClick={() =>
                                                        navigate(
                                                            `/chats/detail/${e.data.id}`,
                                                            // {
                                                            //     state: {
                                                            //         id: e.data
                                                            //             .id,
                                                            //     },
                                                            // },
                                                        )
                                                    }
                                                >
                                                    詳細
                                                </button>
                                                <button
                                                    className="button-tb btn-delete"
                                                    onClick={() => {
                                                        setChatId(e.data.id);
                                                        openDelete();
                                                    }}
                                                >
                                                    削除
                                                </button>
                                            </div>
                                        ),
                                    },
                                ]}
                                onGridReady={onGridReady}
                                isFullWidthRow={isFullWidthRow}
                                defaultColDef={defaultColDef}
                                onVirtualColumnsChanged={
                                    onVirtualColumnsChanged
                                }
                                noRowsOverlayComponent={() => (
                                    <div className="empty-data">
                                        <BiBox size={28} />
                                        <div>データがありません。</div>
                                    </div>
                                )}
                            />
                        </div>
                    </div>
                </TableWrapper>
            </div>

            {total > pageQueries.per_page && (
                <CustomPagination
                    totalCount={total}
                    pageQueries={pageQueries}
                    setPageQueries={setPageQueries}
                    fetchList={() => {}}
                />
            )}

            <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={() => {
                                closeDelete();
                                setChatId(null);
                            }}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            style={{ background: '#215493' }}
                            onClick={() => onDeleteChat(closeDelete)}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
        </Styles>
    );
};

const ChatList: FC<ReceivedProps> = (props) => (
    <ChatListLayout {...useChat(props)} />
);

export default ChatList;
