import styled from "styled-components";

export const Main = styled.div`
  .form-edit-estimation {
    background: #ffffff;
    align-items: center;
    width: 100%;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    padding: 28px;

    .dropdown-control-plan {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      height: 38px;
      background: #ffffff;
      border: 1px solid #cdd1d5 !important;
      border-radius: 8px !important;
    }
  }

  .form-edit-estimation-title {
    width: 100%;
    height: 10%;
    font-family: "Zen Kaku Gothic Antique";
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #1c1e21;
    /* margin-bottom: 29px; */
  }

  .form-create-estimation-form {
    width: 100%;
    // height: 70%;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #cdd1d5;
    border-radius: 8px;
    border-left: none;
  }

  .input-add-estimation {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .input-add-estimation > p {
    width: 200px;
    min-height: 57px;
    background: #c7dcf5;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-left: 12px;
    align-items: center;
  }

  .input-add-estimation:first-child > p {
    border-top-left-radius: 8px;
  }

  .input-add-estimation:last-child > p {
    border-bottom-left-radius: 8px;
  }

  .input-add-estimation > .content-right {
    width: 100%;
    align-items: center;
    padding: 10px 0;
    padding-left: 12px;
    min-height: 56px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #1c1e21;
    border-bottom: 1px solid #f5f5f5;
  }

  .input-add-estimation:last-child > .content-right {
    border: none;
  }

  .form-edit-estimation > .group-buttons {
    width: 100%;
    height: 20%;
    align-items: center;
    margin-top: 0 !important;
  }

  .input-add-plan input {
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 100%;
    height: 36px;
    background: #ffffff;
    border: 1px solid #cdd1d5;
    border-radius: 8px;
  }

  .input-add-plan textarea {
    justify-content: flex-start;
    align-items: flex-start;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
    width: 100%;
    height: 84px;
    background: #ffffff;
    border: 1px solid #cdd1d5;
    border-radius: 8px;
    resize: vertical;
  }

  .input-add-plan p {
    width: 100%;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: "Zen Kaku Gothic Antique";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin: 0;
    margin-bottom: 4px;
    margin-top: 12px;
  }

  .dropdown-edit-plan {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 38px;
    background: #ffffff;
  }

  .dropdown-control-plan {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    height: 38px;
    background: #ffffff;
    border: 1px solid #cdd1d5 !important;
    border-radius: 8px !important;
  }

  .bnt {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #215493;
    background: #ffffff;
    width: 167.5px;
    align-items: center;
    justify-content: center;
    height: 44px;
    margin-right: 8px;
    border-radius: 8px;
  }

  .bnt-cancel {
    border: 1px solid rgba(33, 84, 147, 0.3);
  }

  .bnt-submit {
    background: #215493;
    color: #ffffff;
  }

  .modal-edit-plan-button-add {
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 8px;
    width: 100%;
    background: #215493;
    border-radius: 8px;
    outline: none;
    border-color: #215493;
    color: #ffffff;
    outline: none;
    border: none;
  }

  .modal-dropdown-edit-estimation {
    width: 350px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;
    height: 36px;
    background: #ffffff;
    font-family: "FontAwesome";
  }

  .datepicker-wrap {
    width: 350px;
    /* width: 100%; */
    border: 1px solid #cdd1d5;
    border-radius: 8px;
    height: 36px;
  }

  .datepicker-wrap
    > .target-datepicker-wrap
    > .input-date
    > .react-datepicker-wrapper
    > .react-datepicker__input-container
    > input {
    height: 36px;
    background: transparent;
    padding: 10px;
  }

  .image-preview {
    width: 122px;
    height: 100px;
    margin-right: 8px;
    position: relative;
  }

  .image-preview img {
    width: 122px;
    height: 100px;
  }

  .image-container {
    display: flex;
    flex-wrap: wrap;
  }

  .remove {
    position: absolute;
    width: 24px !important;
    height: 24px !important;
    top: 0px;
    right: 0px;
  }

  .modal-dropdown-create-estimation {
    width: 350px;
    box-sizing: border-box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-align-items: flex-start;
    -webkit-box-align: flex-start;
    -ms-flex-align: flex-start;
    align-items: flex-start;
    gap: 8px;
    height: 36px;
    background: #ffffff;
    font-family: "FontAwesome";
  }

  .datepicker-wrap {
    border: none;
  }
`;
