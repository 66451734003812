import styled from 'styled-components'

const StyledCardWrapper = styled.div`
  margin: 0;
  width: calc((100% - (28px * 3))/4);
  cursor: pointer;

  .projectImage {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 4px;
  }

  .projectName {
    font-weight: 600;
  }
  @media only screen and (max-width: 992px) {
    width: calc((100% - (28px * 2))/3);
  }
  @media only screen and (max-width: 768px) {
    width: calc((100% - 28px)/2);
  }
  @media only screen and (max-width: 576px) {
    width: 100%;
  }
`;

export default StyledCardWrapper