import Pagination from '@mui/material/Pagination';
import CustomPaginationStyle from './style';
import { Dispatch, FC, SetStateAction } from 'react';
import { IPageQueries } from 'models';

interface IProps {
  totalCount: number,
  pageQueries: IPageQueries
  setPageQueries: Dispatch<SetStateAction<IPageQueries>>
  fetchList: any,
}

const CustomPagination: FC<IProps> = ({ totalCount = 0, pageQueries, setPageQueries, fetchList }) => {
  return (
    <CustomPaginationStyle>
      <Pagination 
        shape='rounded' 
        onChange={(e, page) => {
          e.preventDefault();
          setPageQueries((prevState) => ({...prevState, page}))
          fetchList(page)
        }} 
        count={Math.ceil(totalCount / pageQueries.per_page)} 
        page={pageQueries.page} />
    </CustomPaginationStyle>
  )
}

export default CustomPagination;