import { useEffect, useState } from 'react';
import './WorkingReport.css';
import plus from '../../../../assets/icons/Plus.png';
import { RootState, useAppDispatch } from '../../../../store/index';
import { useSelector } from 'react-redux';
import {
    setFilter,
    setShowModalCreate,
    setWorkers,
    setWorkingLogs,
    setTempFilterReset,
    setShowModalUpdate,
    setShowModalDetail,
} from 'store/workingReport/workingReport';
import DatePicker from 'react-datepicker';
import calendarIcon from '../../../../assets/icons/calendar.png';
import Dropdown from 'react-dropdown';
import wokersService from 'services/wokersService';
import workingLogService from 'services/workingLogService';
import { useParams, useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';

const initialTempFilter = {
    from_at: '',
    to_at: '',
    start_published_at: '',
    end_published_at: '',
    start_submitted_at: '',
    end_submitted_at: '',
    working_log: null,
};

const WorkingReportFilterSP = () => {
    let { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { workingLogs = [], tempFilterReset = false } = useSelector(
        (state: RootState) => state.workingReport,
    );
    const [tempFilter, setTempFilter] = useState<any>(initialTempFilter);

    const openCreate = () => {
        dispatch(setShowModalCreate(true));
    };

    const onFilter = (event) => {
        event.preventDefault();
        dispatch(setFilter(tempFilter));
    };

    const getListWorkers = async () => {
        try {
            const response = await wokersService.getListWorkers({
                project_id: id,
            });
            if (response !== null && response !== undefined) {
                dispatch(setWorkers(response?.workers || []));
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const getListWorkingLogs = async () => {
        try {
            const response = await workingLogService.getListWorkingLogs({
                project_id: id,
            });
            if (response !== null && response !== undefined) {
                dispatch(setWorkingLogs(response?.working_logs || []));
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const covertOptions = (workingLogs) => {
        return workingLogs?.map((log) => {
            return {
                label: log?.name,
                value: log?.id,
            };
        });
    };

    useEffect(() => {
        getListWorkers();
        getListWorkingLogs();
    }, []);

    useEffect(() => {
        if (tempFilterReset) {
            dispatch(setTempFilterReset(false));
            dispatch(setFilter({}));
            setTempFilter(initialTempFilter);
        }
    }, [tempFilterReset]);

    return (
        <>
            <form onSubmit={onFilter}>
                <div className="w-full px-[16px]">
                    <div className="flex justify-between mt-[18px] mb-[12px]">
                        <label className="text-[18px]">週報</label>
                        <button
                            className="border-main border-[1px] text-main px-[20px] py-[4px] rounded-[6px] flex items-center"
                            onClick={() => {
                                dispatch(setShowModalCreate(true));
                                dispatch(setShowModalDetail(false));
                                dispatch(setShowModalUpdate(false));
                                navigate(
                                    `/sp/project/${id}/weekly-report/create`,
                                );
                            }}
                        >
                            <img src={plus} alt="plus" />
                            <span>新規作成</span>
                        </button>
                    </div>
                    <div className="flex items-end w-full justify-between gap-x-[12px] mb-[16px]">
                        <div className="flex-1 relative">
                            <label className="custom-label" htmlFor="from_at">
                                作業日
                            </label>
                            <DatePicker
                                className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                selected={tempFilter?.from_at}
                                onChange={(date) => {
                                    setTempFilter({
                                        ...tempFilter,
                                        from_at: date,
                                    });
                                }}
                                dateFormat="yyyy/MM/dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <img
                                className="absolute right-[9px] bottom-[10px]"
                                width={'16px'}
                                height={'16px'}
                                src={calendarIcon}
                            />
                        </div>
                        <span className="flex-0 pb-[8px] text-[#1C1E21]">
                            ~
                        </span>
                        <div className="flex-1 relative">
                            <label
                                className="custom-label"
                                htmlFor="from_at"
                            ></label>
                            <DatePicker
                                className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                selected={tempFilter?.to_at}
                                onChange={(date) => {
                                    setTempFilter({
                                        ...tempFilter,
                                        to_at: date,
                                    });
                                }}
                                dateFormat="yyyy/MM/dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <img
                                className="absolute right-[9px] bottom-[10px]"
                                width={'16px'}
                                height={'16px'}
                                src={calendarIcon}
                            />
                        </div>
                    </div>
                    <div className="flex items-end w-full justify-between gap-x-[12px] mb-[16px]">
                        <div className="flex-1 relative">
                            <label className="custom-label" htmlFor="from_at">
                                発行日
                            </label>
                            <DatePicker
                                className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                selected={tempFilter?.start_published_at}
                                onChange={(date) => {
                                    setTempFilter({
                                        ...tempFilter,
                                        start_published_at: date,
                                    });
                                }}
                                dateFormat="yyyy/MM/dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <img
                                className="absolute right-[9px] bottom-[10px]"
                                width={'16px'}
                                height={'16px'}
                                src={calendarIcon}
                            />
                        </div>
                        <span className="flex-0 pb-[8px] text-[#1C1E21]">
                            ~
                        </span>
                        <div className="flex-1 relative">
                            <label
                                className="custom-label"
                                htmlFor="from_at"
                            ></label>
                            <DatePicker
                                className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                selected={tempFilter?.end_published_at}
                                onChange={(date) => {
                                    setTempFilter({
                                        ...tempFilter,
                                        end_published_at: date,
                                    });
                                }}
                                dateFormat="yyyy/MM/dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <img
                                className="absolute right-[9px] bottom-[10px]"
                                width={'16px'}
                                height={'16px'}
                                src={calendarIcon}
                            />
                        </div>
                    </div>
                    <div className="flex items-end w-full justify-between gap-x-[12px] mb-[16px]">
                        <div className="flex-1 relative">
                            <label className="custom-label" htmlFor="from_at">
                                送信日
                            </label>
                            <DatePicker
                                className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                selected={tempFilter?.start_submitted_at}
                                onChange={(date) => {
                                    setTempFilter({
                                        ...tempFilter,
                                        start_submitted_at: date,
                                    });
                                }}
                                dateFormat="yyyy/MM/dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <img
                                className="absolute right-[9px] bottom-[10px]"
                                width={'16px'}
                                height={'16px'}
                                src={calendarIcon}
                            />
                        </div>
                        <span className="flex-0 pb-[8px] text-[#1C1E21]">
                            ~
                        </span>
                        <div className="flex-1 relative">
                            <label
                                className="custom-label"
                                htmlFor="from_at"
                            ></label>
                            <DatePicker
                                className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                selected={tempFilter?.end_submitted_at}
                                onChange={(date) => {
                                    setTempFilter({
                                        ...tempFilter,
                                        end_submitted_at: date,
                                    });
                                }}
                                dateFormat="yyyy/MM/dd"
                                autoComplete="off"
                                locale="ja"
                            />
                            <img
                                className="absolute right-[9px] bottom-[10px]"
                                width={'16px'}
                                height={'16px'}
                                src={calendarIcon}
                            />
                        </div>
                    </div>
                </div>
                <div className="w-full px-[16px]">
                    <label className="custom-label" htmlFor="from_at">
                        作業内容
                    </label>
                    <Dropdown
                        className="dropdown-create-user1 height36"
                        controlClassName="dropdown-control-user height36"
                        options={covertOptions(workingLogs)}
                        value={tempFilter.working_log || null}
                        placeholder=""
                        onChange={(option) => {
                            const selectedWorkingLog: any =
                                tempFilter.working_log;
                            if (
                                option &&
                                selectedWorkingLog &&
                                selectedWorkingLog?.value === option.value
                            ) {
                                setTempFilter((prevState) => ({
                                    ...prevState,
                                    working_log: null,
                                }));
                            } else {
                                setTempFilter((prevState) => ({
                                    ...prevState,
                                    working_log: option,
                                }));
                            }
                        }}
                    />
                </div>
                <div className="w-full px-[16px] mt-[27px]">
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="bg-main px-[50px] py-[7px] rounded-[6px] text-white"
                        >
                            検索
                        </button>
                    </div>
                </div>
            </form>
        </>
    );
};

export default WorkingReportFilterSP;
