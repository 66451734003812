import styled from 'styled-components';

const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 399px;
    min-height: 152px;
    background: #ffffff;
    border-radius: 12px;
    padding: 28px;
    justify-content: center;
    align-items: center;

    .formTitle {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 10%;
        justify-content: center;
        align-items: center;
        font-family: 'Zen Kaku Gothic Antique';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        text-align: center;
        color: #1c1e21;
    }

    .fieldRow {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: flex-start;
        align-items: flex-start;

        p {
            width: 100%;
            font-family: 'Zen Kaku Gothic Antique';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
            margin: 0;
            margin-bottom: 4px;
            margin-top: 12px;
        }

        input {
            justify-content: flex-start;
            box-sizing: border-box;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 12px;
            gap: 8px;
            width: 100%;
            height: 36px;
            background: #ffffff;
            border: 1px solid #cdd1d5;
            border-radius: 8px;
        }
    }

    .buttonGroup {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 20%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        .cancelBtn,
        .registerBtn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 18px;
            gap: 8px;
            width: 49%;
            height: 44px;
            border-radius: 8px;
            border-color: #215493;
            outline: none;
        }

        .cancelBtn {
            box-shadow: 0px 1px 2px rgb(16 24 40 / 5%);
            border: 1px solid #215493;
            color: #215493;
        }

        .registerBtn {
            background: #215493;
            color: #ffffff;
            border: none;
        }
    }
`;

export default StyledForm;
