import { Box, Typography, CardMedia } from '@mui/material';
import { getUser } from '../../store/user/user';
import { useAppDispatch } from '../../store/index';
import { useEffect, useState } from 'react';
import { logoutSP } from 'store/auth/auth';
import { useLocation, useNavigate } from 'react-router-dom';
interface ISPHeader {
    isOpenMenu: boolean;
    setIsOpenMenu: (isOpenMenu: boolean) => void;
}
const SPHeader = ({ setIsOpenMenu, isOpenMenu }: ISPHeader) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [headerText, setHeaderText] = useState<string>(`${process.env.REACT_APP_PROJECT_TITLE}`);
    const getUserProfile = async () => {
        try {
            dispatch(getUser());
        } catch (error) { }
    };

    const handleLogout = () => {
        dispatch(logoutSP());
    };

    useEffect(() => {
        if (!location || !location.pathname) {
            return;
        }

        if (location.pathname.includes("sp/inspection-records")) {
            setHeaderText("車両系建設機械始業前点検表");
        } else if (location.pathname.includes("sp/quote/create")) {
            setHeaderText("見積依頼");
        } else if (location.pathname.includes("sp/quote/history")) {
            setHeaderText("見積履歴");
        } else if (location.pathname.includes('/quote/list/invoice')) {
            setHeaderText('請求書発行');
        } else if (location.pathname.includes("sp/quote/list")) {
            setHeaderText("見積管理");
        } else if (location.pathname.includes("sp/quote/edit")) {
            setHeaderText("編集");
        } else if (location.pathname.includes("sp/quote/payment")) {
            setHeaderText("決済入力");
        } else {
            setHeaderText(`${process.env.REACT_APP_PROJECT_TITLE}`);
        }
    }, [location.pathname]);

    useEffect(() => {
        getUserProfile();
    }, []);
    if (window?.location?.pathname == '/login') return <></>;
    if (location?.pathname === '/') return <></>;
    return (
        <Box
            bgcolor="#215493"
            height={44}
            display="flex"
            alignItems="center"
            position="relative"
            justifyContent="center"
            padding="10px 16px"
            sx={{
                '@media only screen and (min-width: 992px)': {
                    display: 'none',
                },
            }}
        >
            <CardMedia
                component="img"
                onClick={() => navigate('/')}
                sx={{
                    width: '22px',
                    height: '20px',
                    cursor: 'pointer',
                    position: 'absolute',
                    left: '16px',
                }}
                src={process.env.PUBLIC_URL + '/img/back.ico'}
                alt="open_menu_icon"
            />
            <Typography
                fontWeight={500}
                fontFamily="Zen Kaku Gothic Antique"
                color="#fff"
            >
                {headerText}
            </Typography>
            {/* <div/> */}
            {/* <CardMedia
                component="img"
                onClick={handleLogout}
                sx={{
                    width: '22px',
                    height: '20px',
                    cursor: 'pointer',
                }}
                src={process.env.PUBLIC_URL + '/img/logout_ic.svg'}
                alt="open_menu_icon"
            /> */}
        </Box>
    );
};
export default SPHeader;
