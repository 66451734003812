import {
    FC,
    memo,
    useMemo,
    useCallback,
    useEffect,
    useState,
    useRef,
} from 'react';
import { isArray, isEmpty } from 'lodash';
import {
    Widget,
    renderCustomComponent,
    toggleWidget,
    dropMessages,
} from 'react-chat-widget';
import moment from 'moment';
import 'react-chat-widget/lib/styles.css';
import { ActionCableConsumer } from 'react-actioncable-provider';
import { BiImageAdd, BiSend } from 'react-icons/bi';
import { TiCancel } from 'react-icons/ti';
import { Main } from './style';
import useChatBox, { Props, ReceivedProps } from './hook';
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
} from '@chatscope/chat-ui-kit-react';

const ChatBoxLayout: FC<Props> = ({
    suggestion,
    workerId,
    listMessage,
    onSubmit,
    handleFileChange,
    listImageMessage,
    refInput,
    removeImage,
    message,
    setMessage,
}) => {
    // const [messageInputValue, setMessageInputValue] = useState('');

    // const submit = () => {
    //     onSubmit(message);
    //     setMessage('');
    // };

    const messageInputRef: any = useRef(null);

    useEffect(() => {
        const element = document.getElementsByClassName('cs-button--send');
        element[0]?.removeAttribute('disabled');
        // element[0]?.addEventListener('click', () => onSubmit());
    }, []);

    const handleButtonClick = () => {
        const enterKeyEvent = new KeyboardEvent('keydown', { key: 'Enter' });
        messageInputRef?.current?.inputRef?.current.dispatchEvent(
            enterKeyEvent,
        );
    };

    const listMessageItem = useMemo(() => {
        return listMessage?.map((message) => (
            <>
                <Message
                    key={message.id}
                    model={{
                        message: message.body,
                        sender: message?.sender?.name,
                        sentTime: message?.publish_at,
                        direction: message?.is_owner ? 'incoming' : 'outgoing',
                        position: 'first',
                        type: 'custom',
                    }}
                >
                    <Message.CustomContent>
                        <div className="message">
                            <p>{message?.body || ''}</p>

                            {message?.images &&
                                Object.values(message?.images).map(
                                    (img: any, id) => (
                                        <img
                                            src={img?.image_path}
                                            style={{
                                                marginTop: '5px',
                                                width: '324px',
                                            }}
                                            key={id}
                                        />
                                    ),
                                )}
                        </div>
                    </Message.CustomContent>
                </Message>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: message?.is_owner ? 'start' : 'end',
                    }}
                    className="message-footer"
                >
                    <p className='mr-2'>{message?.sender?.name || ''}</p>
                    <p>
                        {message.publish_at &&
                            moment(message.publish_at)
                                .zone('+09:00')
                                .format('YYYY/MM/DD HH:mm:ss')}
                    </p>
                </div>
            </>
        ));
    }, [listMessage]);

    return (
        <Main className="chat-box" style={{ position: 'relative' }}>
            <div style={{ position: 'relative', height: '500px' }}>
                <MainContainer>
                    <ChatContainer>
                        <MessageList>{listMessageItem}</MessageList>
                        <MessageInput
                            onKeyPress={(e) => {
                                e.code === 'Enter' && onSubmit(message);
                            }}
                            attachButton={false}
                            placeholder="メッセージを入力..."
                            value={message}
                            ref={messageInputRef}
                            onChange={(val) => setMessage(val)}
                            onSend={() => onSubmit}
                        />
                    </ChatContainer>
                </MainContainer>
                <label className="upload-image-chat" htmlFor="fileInput">
                    <input
                        onChange={(e) => handleFileChange(e)}
                        id="fileInput"
                        name="file"
                        type="File"
                        multiple
                        ref={refInput}
                        accept="image/*"
                        style={{ display: 'none' }}
                    ></input>
                    <BiImageAdd size={20} />
                </label>
                <button
                    style={{
                        position: 'absolute',
                        bottom: '16px',
                        zIndex: 100000,
                        right: '16px',
                    }}
                    type="button"
                    onClick={() => onSubmit(message)}
                >
                    <BiSend size={25} />
                </button>
                <div className="preview-img-upload">
                    {listImageMessage?.length > 0 &&
                        listImageMessage.map((item, id) => {
                            return (
                                <div className="img-upl-ct">
                                    <img
                                        src={URL.createObjectURL(item)}
                                        key={id}
                                    />
                                    <TiCancel
                                        onClick={() => removeImage(id)}
                                        size={15}
                                        className="delete-image-upl"
                                    />
                                </div>
                            );
                        })}
                </div>
            </div>
        </Main>
    );
};

const ChatBox: FC<ReceivedProps> = (props) => (
    <ChatBoxLayout {...useChatBox(props)} />
);

export default memo(ChatBox);
