import CompanyInfoSettingForm from './components/form';

const CompanyInfoSetting: React.FC = () => {
    return (
        <div className="container-worker d-flex flex-row">
            <div className="worker-content d-flex flex-column !w-[500px] !px-[6px] !py-[18px]">
                <CompanyInfoSettingForm />
            </div>
        </div>
    );
};

export default CompanyInfoSetting;
