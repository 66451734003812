import Close from '@mui/icons-material/Close';
import {
    Box,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Paper,
    TableBody,
    Button,
    CircularProgress,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import manifestService from 'services/manifestService';
import { OrderWasteType } from 'utils/enums';
import { convertDateToJapan } from 'utils/helper/convertDate';
import { checkManifestLogProgress, convertManifestLogsArrayToObject } from 'utils/helper/manifestHelper';
// import { maniFestPopupData } from '../constant';

const TopTable = ({ selectedManifest }) => {
    const topTableTitles = [
        '整理番号',
        '日付',
        'マニフェストNo',
        '産廃の種類',
        '数量',
        '単位',
        '体積',
    ];
    return (
        <TableContainer
            table-layout={'auto'}
            sx={{
                padding: '0px',
                boxShadow: 'none',
                borderRadius: '8px',
                border: '1px solid #E1E1E1',
            }}
            component={Paper}
        >
            <Table
                sx={{
                    width: '100%',
                    'th, td': {
                        fontFamily: 'Zen Kaku Gothic Antique',
                        borderRight: '1px solid #E1E1E1',
                    },
                    'th:last-child, td:last-child': {
                        borderRight: 0,
                    },
                    'th:first-child': {
                        width: '95px',
                    },
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            bgcolor: '#EFF3F8',
                            th: {
                                color: '#1C1E21',
                                fontWeight: '500',
                                fontSize: '14px',
                            },
                        }}
                    >
                        {topTableTitles?.map((title) => (
                            <TableCell
                                sx={{
                                    width: 'fit-content',
                                    padding: '10px',
                                }}
                                key={title}
                                align="center"
                            >
                                {title}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow
                        sx={{
                            td: {
                                borderBottom: 'none',
                                textAlign: 'center',
                            },
                        }}
                    >
                        <TableCell>{selectedManifest.index}</TableCell>
                        <TableCell>{selectedManifest.date_at ? convertDateToJapan(selectedManifest.date_at) : ""}</TableCell>
                        <TableCell>{selectedManifest.manifest_no}</TableCell>
                        <TableCell>{selectedManifest.order_item?.waste_type ? OrderWasteType[selectedManifest.order_item.waste_type] : ""}</TableCell>
                        <TableCell>{selectedManifest.order_item?.amount || ""}</TableCell>
                        <TableCell>{selectedManifest.order_item?.unit || ""}</TableCell>
                        <TableCell>{selectedManifest.order_item?.volume || ""}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const ContentTable = ({ manifestLogsList, onSave, stepCol, setStepCol, currentProject, selectedManifest }) => {
    const onMoveToNextStep = (step) => {
      setStepCol(step);
    }
    const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
    const [logType, setLogType] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState("");

    useEffect(() => {
      if (!openSaveDialog) {
        setLogType(null);
        setModalMessage("");
      }
    }, [openSaveDialog])

    return (
        <>
          <Dialog open={openSaveDialog} onClose={() => setOpenSaveDialog(false)}>
                <div
                    className="modal-create-user d-flex flex-column"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        {modalMessage}
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={() => setOpenSaveDialog(false)}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            onClick={() => {
                                if (['2-C2', '2-C1', '2-D', '2-E', '1-E'].includes(logType)) {
                                  onSave(logType, true);
                                } else {
                                  onSave(logType);
                                }
                            }}
                        >
                            登録
                        </button>
                    </div>
                </div>
            </Dialog>

            <TableContainer
            table-layout={'auto'}
            sx={{
                padding: '0px',
                boxShadow: 'none',
                mt: '28px',
                borderRadius: '8px',
                border: '1px solid #E1E1E1',
            }}
            component={Paper}
        >
            <Table
                sx={{
                    width: '100%',
                    'th, td': {
                        fontFamily: 'Zen Kaku Gothic Antique',
                    },
                }}
            >
                <TableHead>
                    <TableRow
                        sx={{
                            th: {
                                color: '#1C1E21',
                                fontWeight: '500',
                                fontSize: '14px',
                                textAlign: 'center',
                                verticalAlign: 'top',
                                paddingY: '10px',
                                borderRight: '1px solid #E1E1E1',
                            },
                            'th:last-child': {
                                borderRight: 'none',
                            },
                        }}
                    >
                        <TableCell />
                        <TableCell />
                        <TableCell width="13%">
                            <Typography>排出業者</Typography>
                            {/* <Typography>CRS</Typography> */}
                            <Typography>{currentProject.client_company?.company?.name || ""}</Typography>
                            {
                              stepCol === 1 && (
                                <Typography>⭐</Typography>
                              )
                            }
                        </TableCell>
                        <TableCell width="13%">
                            <Typography>収集運搬 </Typography>
                            {/* <Typography>(株) 章栄</Typography> */}
                            <Typography>{selectedManifest.order_item?.collect_company?.name || ""}</Typography>
                            {
                              stepCol === 2 && (
                                <Typography>⭐</Typography>
                              )
                            }
                            {
                              stepCol === 1 && (
                                <Button
                                    sx={{ p: '0.5px 15px', mt: '2.5px' }}
                                    variant="outlined"
                                    onClick={() => onMoveToNextStep(2)}
                                    disabled={!manifestLogsList['1-A']}
                                >
                                    移行
                                </Button>
                              )
                            }
                        </TableCell>
                        <TableCell width="13%">
                            <Typography>中間業者</Typography>
                            {/* <Typography>木材開発 (株)️</Typography> */}
                            <Typography>{selectedManifest.order_item?.disposal_company?.name || ""}</Typography>
                            {
                              stepCol === 3 && (
                                <Typography>⭐</Typography>
                              )
                            }
                            {
                              stepCol === 2 && (
                                <Button
                                    sx={{ p: '0.5px 15px', mt: '2.5px' }}
                                    variant="outlined"
                                    onClick={() => onMoveToNextStep(3)}
                                    disabled={!manifestLogsList['1-B1'] && !manifestLogsList['1-B2']}
                                >
                                    移行
                                </Button>
                              )
                            }
                        </TableCell>
                        <TableCell width="13%">
                            <Typography>収集運搬</Typography>
                            {/* <Typography>(株) 章栄</Typography> */}
                            <Typography>{selectedManifest.order_item?.collect_company?.name || ""}</Typography>
                            {
                              stepCol === 4 && (
                                <Typography>⭐</Typography>
                              )
                            }
                            {
                              stepCol === 3 && (
                                <Button
                                    sx={{ p: '0.5px 15px', mt: '2.5px' }}
                                    variant="outlined"
                                    onClick={() => onMoveToNextStep(4)}
                                    disabled={(!manifestLogsList['1-C1'] || !manifestLogsList['2-A'])
                                    && (!manifestLogsList['1-C2'] || !manifestLogsList['1-D'])}
                                >
                                    移行
                                </Button>
                              )
                            }
                        </TableCell>
                        <TableCell width="13%">
                            <Typography>最終処分場</Typography>
                            {/* <Typography>まつえ環境の森</Typography> */}
                            <Typography>{selectedManifest.order_item?.final_disposal_company?.name || ""}</Typography>
                            {
                              stepCol === 6 && (
                                <Typography>⭐ [完了]</Typography>
                              )
                            }
                            {
                              stepCol === 5 && (
                                <Typography>⭐</Typography>
                              )
                            }
                            {
                              stepCol === 4 && (
                                <Button
                                    sx={{ p: '0.5px 15px', mt: '2.5px' }}
                                    variant="outlined"
                                    onClick={() => onMoveToNextStep(5)}
                                    disabled={!manifestLogsList['2-B1'] && !manifestLogsList['2-B2']}
                                >
                                    移行
                                </Button>
                              )
                            }
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody
                    sx={{
                        td: {
                            borderBottom: 'none',
                            borderLeft: '1px solid #E1E1E1',
                            height: '40px',
                            paddingY: '7.5px',
                        },
                        tr: {
                            borderBottom: '1px solid #E1E1E1',
                        },
                        'tr:last-child': {
                            borderBottom: 'none',
                        },
                    }}
                >
                    {/* {maniFestPopupData?.map((manifest) => ( */}
                        <>
                            <TableRow>
                                <TableCell
                                    component="th"
                                    rowSpan={8}
                                    align="center"
                                    sx={{
                                        writingMode: 'tb',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        borderBottom: 'none',
                                        width: '72px',
                                        letterSpacing: '1.4px',
                                    }}
                                >
                                    第 一 次 マ ニ フ ェ ス ト
                                </TableCell>
                            </TableRow>

                            {/* 1-A */}
                            <TableRow>
                                <TableCell align="left">
                                  A(発行控え)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    manifestLogsList['1-A'] ? (
                                      <span>{`取得 : ${manifestLogsList['1-A'].submitted_at}`}</span>
                                    ) : (
                                      <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('1-A');
                                            setModalMessage(`A取得を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                      >
                                          取得
                                      </Button>
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            {/* 1-B1 */}
                            <TableRow>
                                <TableCell align="left">
                                  B1(収集運搬終了控え)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                {
                                  stepCol >= 2 && (
                                    manifestLogsList['1-B1'] ? (
                                      <span>{`取得 : ${manifestLogsList['1-B1'].submitted_at}`}</span>
                                    ) : (
                                      <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('1-B1');
                                            setModalMessage(`B1取得を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                      >
                                          取得
                                      </Button>
                                    )
                                  )
                                }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            {/* 1-B2 */}
                            <TableRow>
                                <TableCell align="left">
                                B2(収集運搬終了報告)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 2 && (
                                      manifestLogsList['1-B2'] ? (
                                        <span>{`発送 : ${manifestLogsList['1-B2'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('1-B2');
                                            setModalMessage(`B2発送を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                        >
                                            発送
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            {/* 1-C1 */}
                            <TableRow>
                                <TableCell align="left">
                                C1(中間処理完了控え)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 3 && (
                                      manifestLogsList['1-C1'] ? (
                                        <span>{`取得 : ${manifestLogsList['1-C1'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#215493',
                                                padding: '0.5px 15px',
                                                color: '#fff',
                                                fontWeight: '500',
                                                lineHeight: '24px',
                                            }}
                                            onClick={() => {
                                              setLogType('1-C1');
                                              setModalMessage(`C1取得を登録しますか？`);
                                              setOpenSaveDialog(true);
                                            }}
                                        >
                                            取得
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            {/* 1-C2 */}
                            <TableRow>
                                <TableCell align="left">
                                C2(中間処理完了報告)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 3 && (
                                      manifestLogsList['1-C2'] ? (
                                        <span>{`発送 : ${manifestLogsList['1-C2'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('1-C2');
                                            setModalMessage(`C2発送を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                        >
                                            発送
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            {/* 1-D */}
                            <TableRow>
                                <TableCell align="left">
                                D(中間処理完了報告)	
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 3 && (
                                      manifestLogsList['1-D'] ? (
                                        <span>{`発送 : ${manifestLogsList['1-D'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('1-D');
                                            setModalMessage(`D発送を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                        >
                                            発送
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            {/* 1-E */}
                            <TableRow>
                                <TableCell align="left">
                                E(最終処分完了報告)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 5 && (
                                      manifestLogsList['1-E'] ? (
                                        <span>{`発送 : ${manifestLogsList['1-E'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('1-E');
                                            setModalMessage(`E発送を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                        >
                                            発送
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            <TableRow>
                                <TableCell
                                    component="th"
                                    rowSpan={8}
                                    align="center"
                                    sx={{
                                        writingMode: 'tb',
                                        fontSize: '16px',
                                        lineHeight: '24px',
                                        borderBottom: 'none',
                                        width: '72px',
                                        letterSpacing: '1.4px',
                                    }}
                                >
                                    第 二 次 マ ニ フ ェ ス ト
                                </TableCell>
                            </TableRow>

                            {/* 2-A */}
                            <TableRow>
                                <TableCell align="left">
                                  A(発行控え)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 3 && (
                                      manifestLogsList['2-A'] ? (
                                        <span>{`取得 : ${manifestLogsList['2-A'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#215493',
                                                padding: '0.5px 15px',
                                                color: '#fff',
                                                fontWeight: '500',
                                                lineHeight: '24px',
                                            }}
                                            onClick={() => {
                                              setLogType('2-A');
                                              setModalMessage(`A取得を登録しますか？`);
                                              setOpenSaveDialog(true);
                                            }}
                                        >
                                            取得
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            {/* 2-B1 */}
                            <TableRow>
                                <TableCell align="left">
                                B1(収集運搬終了控え)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#fff' }}
                                >
                                  {
                                    stepCol >= 4 && (
                                      manifestLogsList['2-B1'] ? (
                                        <span>{`取得 : ${manifestLogsList['2-B1'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#215493',
                                                padding: '0.5px 15px',
                                                color: '#fff',
                                                fontWeight: '500',
                                                lineHeight: '24px',
                                            }}
                                            onClick={() => {
                                              setLogType('2-B1');
                                              setModalMessage(`B1取得を登録しますか？`);
                                              setOpenSaveDialog(true);
                                            }}
                                        >
                                            取得
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            {/* 2-B2 */}
                            <TableRow>
                                <TableCell align="left">
                                B2(収集運搬終了報告)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 4 && (
                                      manifestLogsList['2-B2'] ? (
                                        <span>{`発送 : ${manifestLogsList['2-B2'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('2-B2');
                                            setModalMessage(`B2発送を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                        >
                                            発送
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#fff' }}
                                >
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                            </TableRow>

                            {/* 2-C1 */}
                            <TableRow>
                                <TableCell align="left">
                                C1(中間処理完了控え)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#fff' }}
                                >
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 5 && (
                                      manifestLogsList['2-C1'] ? (
                                        <span>{`取得 : ${manifestLogsList['2-C1'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                            variant="contained"
                                            sx={{
                                                backgroundColor: '#215493',
                                                padding: '0.5px 15px',
                                                color: '#fff',
                                                fontWeight: '500',
                                                lineHeight: '24px',
                                            }}
                                            onClick={() => {
                                              setLogType('2-C1');
                                              setModalMessage(`C1取得を登録しますか？`);
                                              setOpenSaveDialog(true);
                                            }}
                                        >
                                            取得
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                            </TableRow>

                            {/* 2-C2 */}
                            <TableRow>
                                <TableCell align="left">
                                C2(中間処理完了報告)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#fff' }}
                                >
                                  {
                                    stepCol >= 5 && (
                                      manifestLogsList['2-C2'] ? (
                                        <span>{`発送 : ${manifestLogsList['2-C2'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('2-C2');
                                            setModalMessage(`C2発送を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                        >
                                            発送
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                </TableCell>
                            </TableRow>
                            
                            {/* 2-D */}
                            <TableRow>
                                <TableCell align="left">
                                D(中間処理完了報告)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 5 && (
                                      manifestLogsList['2-D'] ? (
                                        <span>{`発送 : ${manifestLogsList['2-D'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('2-D');
                                            setModalMessage(`D発送を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                        >
                                            発送
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#fff' }}
                                >
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                </TableCell>
                            </TableRow>

                            {/* 2-E */}
                            <TableRow>
                                <TableCell align="left">
                                E(最終処分完了報告)
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#B2B6BB' }}
                                />
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                  {
                                    stepCol >= 5 && (
                                      manifestLogsList['2-E'] ? (
                                        <span>{`発送 : ${manifestLogsList['2-E'].submitted_at}`}</span>
                                      ) : (
                                        <Button
                                          variant="contained"
                                          sx={{
                                              backgroundColor: '#215493',
                                              padding: '0.5px 15px',
                                              color: '#fff',
                                              fontWeight: '500',
                                              lineHeight: '24px',
                                          }}
                                          onClick={() => {
                                            setLogType('2-E');
                                            setModalMessage(`E発送を登録しますか？`);
                                            setOpenSaveDialog(true);
                                          }}
                                        >
                                            発送
                                        </Button>
                                      )
                                    )
                                  }
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor:  '#fff' }}
                                >
                                </TableCell>
                                <TableCell
                                    align="center"
                                    sx={{ backgroundColor: '#fff' }}
                                >
                                </TableCell>
                            </TableRow>
                        </>
                    {/* ))} */}
                </TableBody>
            </Table>
        </TableContainer>
        </>
        
    );
};

export default function ManifestPopup({ isOpen = false, setIsOpen, selectedManifest, currentProject }) {
    const handleClose = () => {
        setIsOpen(false);
    };
    const [fetchLoading, setFetchLoading] = useState<boolean>(false);
    const [manifestLogsList, setManifestLogsList] = useState<any>(null);
    const [stepCol, setStepCol] = useState<number | null>(null);
    const { id } = useParams();
    // console.log('selectedManifest', selectedManifest);

    const fetchManifestLogs = async () => {
      try {
        setFetchLoading(true);
        const data = await manifestService.getManifestLogs(selectedManifest?.id, id);
        if (data) {
          setManifestLogsList(convertManifestLogsArrayToObject(data.manifests));
        }
      } catch (error) {
        // error
      } finally {
        setFetchLoading(false);
      }
    }

    const fetchAndGetStep = async () => {
      try {
        setFetchLoading(true);
        const data = await manifestService.getManifestLogs(selectedManifest?.id, id);
        if (data) {
          setManifestLogsList(convertManifestLogsArrayToObject(data.manifests));
          const currentStep = checkManifestLogProgress(convertManifestLogsArrayToObject(data.manifests));
          setStepCol(currentStep);
        }
      } catch (error) {
        // error
      } finally {
        setFetchLoading(false);
      }
    }

    const handleCreateManifestLog = async (logType, checkCompleteStep) => {
      const formData = new FormData();
      formData.append('log_type', logType);
      try {
        const data = await manifestService.createManifestLog(selectedManifest?.id, id, formData);
        if (data) {
          if (checkCompleteStep) {
            fetchAndGetStep();
          } else {
            fetchManifestLogs();
          }
        }
      } catch (error) {
        // empty
      }
    }

    useEffect(() => {
      fetchAndGetStep();
    }, [])

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={isOpen}
                maxWidth="xl"
                fullWidth={true}
                sx={{
                    '.MuiDialog-container': {
                        marginX: '10vw',
                    },
                    '.MuiPaper-root': {
                        borderRadius: '12px',
                    },
                }}
            >
                <DialogTitle
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '28px 28px 24px 28px',
                    }}
                >
                    <Box />
                    <Typography fontSize="18px" fontWeight="700">
                        マニフェスト状況
                    </Typography>
                    <IconButton aria-label="close" onClick={handleClose}>
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{ padding: '0px 28px 28px 28px' }}>
                    {
                      selectedManifest && (
                        <TopTable selectedManifest={selectedManifest} />
                      )
                    }
                    {
                      fetchLoading || !stepCol ? (
                        <CircularProgress />
                      ) : (
                        <ContentTable 
                          manifestLogsList={manifestLogsList} 
                          onSave={handleCreateManifestLog} 
                          stepCol={stepCol}
                          setStepCol={setStepCol}
                          currentProject={currentProject}
                          selectedManifest={selectedManifest}
                        />
                      )
                    }
                </DialogContent>
            </Dialog>
        </div>
    );
}
