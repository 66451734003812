import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';

import 'react-step-progress/dist/index.css';
import { useModal } from 'react-hooks-use-modal';
import { useNavigate } from 'react-router-dom';

import estimationService from '../../../../../services/estimationService';
import plus from '../../../../../assets/icons/Plus.png';
import { useLogicEstimate } from '../hooks';
import ListBox from './ListBox';
import { Main } from './style';
import CustomPagination from 'components/CustomPagination';
import { IPageQueries } from 'models';
import { Button } from 'antd';
import { Controller, useForm } from 'react-hook-form';
import Dropdown from 'react-dropdown';
import budgetService from 'services/budgetService';

const EstimationList = (props) => {
    const navigate = useNavigate();

    const { statusOptions, id } = useLogicEstimate();
    const [estimations, setEstimation] = useState<any[]>([]);
    const [deleteRow, setDeleteRow] = useState<any>({});
    const [searchObject, setSearchObject] = useState<any>({
        plan_id: '',
        worker_id: '',
        start_at: null,
        end_at: null,
        free_word: '',
        project_id: id,
    });
    const [totalCount, setTotalCount] = useState<number>(0);
    const [pageQueries, setPageQueries] = useState<IPageQueries>({
        page: 1,
        per_page: 20,
    });

    const [ModalDelete, openDelete, closeDelete, isOpenDelete] = useModal(
        'root',
        {
            preventScroll: true,
            // //closeOnOverlayClick: false,
        },
    );

    const getEstimations = async ({ page = 1, per_page = 0 }) => {
        try {
            const responseEstimation =
                await estimationService.getListEstimation(Number(id), {
                    page,
                    per_page,
                });
            if (
                responseEstimation !== null &&
                responseEstimation !== undefined
            ) {
                setTotalCount(responseEstimation.meta.total_count);
                setEstimation(responseEstimation.estimates);
            }
        } catch (err) {}
    };

    useEffect(() => {
        getEstimations({
            page: pageQueries.page,
            per_page: pageQueries.per_page,
        });
    }, []);

    const handleDeleteEstimate = async () => {
        const response = await estimationService.deleteEstimate(
            deleteRow.id,
            deleteRow?.project?.id,
        );
        if (response !== null && response !== undefined) {
            if (pageQueries.page !== 1) {
                setPageQueries((prevState) => ({ ...prevState, page: 1 }));
            }
            getEstimations({ page: 1, per_page: pageQueries.per_page });
            closeDelete();
        }
    };

    const [
        ModalComparison,
        openModalCompare,
        closeModalCompare,
        isOpenComparison,
    ] = useModal('root', {
        preventScroll: true,
    });

    const [budgetsListOptions, setBudgetListOptions] = useState<any>([]);
    const [estimatesCompare, setEstimatesCompare] = useState<any>(null);

    useEffect(() => {
        fetchListBudget();
    }, []);

    const fetchListBudget = async () => {
        try {
            const response = await budgetService.getListBudget({
                page: 1,
                per_page: 0,
                project_id: id,
            });
            setBudgetListOptions(
                response.budgets?.map((item) => ({
                    label: item.name,
                    value: item.id,
                })),
            );
        } catch (error) {}
    };

    const [budgetSelectItem, setBudgetSelectItem] = useState<any>({});

    const {
        control,
        formState: { errors },
    } = useForm();

    return (
        <Main>
            <div className="d-flex flex-column">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row searchRow">
                        <div className="actionArea">
                            <Link
                                className="button-create"
                                to={`/projects/estimation/${id}/create`}
                            >
                                <img
                                    width={'16px'}
                                    height={'16px'}
                                    src={plus}
                                ></img>
                                新規作成
                            </Link>
                        </div>
                    </div>
                    <ListBox
                        data={estimations}
                        id={id}
                        dataStatus={statusOptions}
                        openDelete={(row) => {
                            setDeleteRow(row);
                            openDelete();
                        }}
                        openModalCompare={(row) => {
                            setEstimatesCompare(row);
                            openModalCompare();
                        }}
                    />
                </div>
                {estimations && estimations.length > 0 && (
                    <CustomPagination
                        totalCount={totalCount}
                        pageQueries={pageQueries}
                        setPageQueries={setPageQueries}
                        fetchList={(page) =>
                            getEstimations({
                                page,
                                per_page: pageQueries.per_page,
                            })
                        }
                    />
                )}
                <ModalDelete>
                    <div
                        className="modal-create-user d-flex flex-column"
                        style={{ height: '152px' }}
                    >
                        <div className="modal-create-user-title d-flex flex-row">
                            本当に削除しますか?
                        </div>
                        <div
                            className="modal-create-user-buttons d-flex flex-row"
                            style={{ marginTop: '50px' }}
                        >
                            <button
                                className="modal-create-user-button1 d-flex flex-row"
                                onClick={closeDelete}
                            >
                                キャンセル
                            </button>
                            <button
                                className="modal-create-user-button2 d-flex flex-row"
                                onClick={handleDeleteEstimate}
                            >
                                はい
                            </button>
                        </div>
                    </div>
                </ModalDelete>
                <ModalComparison>
                    <form>
                        <div className="modal-create-user d-flex flex-column !p-[0px] !w-[387px] !h-[220px] !justify-start">
                            <div className="!text-[white] modal-create-user-title d-flex flex-row bg-[#215493] !p-[17px] !h-[47px] !justify-between">
                                <div>利益</div>
                                <Button
                                    className="!bg-[#215493] !text-[white] !border-0"
                                    onClick={closeModalCompare}
                                >
                                    X
                                </Button>
                            </div>
                            <div className="search-workers-row-dropdown d-flex flex-column !px-[17px] pt-[20px] pb-[10px] !w-full !h-auto">
                                <div className="input-add-user d-flex flex-column !max-w-none">
                                    <p style={{ marginTop: 0 }}>予実管理</p>
                                    <Controller
                                        name="budget"
                                        control={control}
                                        render={({ field }) => (
                                            <Dropdown
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={[
                                                    ...budgetsListOptions,
                                                ]}
                                                {...field}
                                                placeholder=""
                                                onChange={(e) =>
                                                    setBudgetSelectItem(e)
                                                }
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="modal-create-user-buttons d-flex flex-row !px-[17px]">
                                <button
                                    className="modal-create-user-button1 d-flex flex-row"
                                    onClick={closeModalCompare}
                                >
                                    キャンセル
                                </button>
                                <button
                                    onClick={() =>
                                        Object.keys(budgetSelectItem).length > 0
                                            ? navigate('/compare', {
                                                  state: {
                                                      estimateData:
                                                          estimatesCompare,
                                                      budgetData:
                                                          budgetSelectItem,
                                                      project: props.project,
                                                  },
                                              })
                                            : closeModalCompare()
                                    }
                                    className="modal-create-user-button2 d-flex flex-row"
                                >
                                    比較
                                </button>
                            </div>
                        </div>
                    </form>
                </ModalComparison>
            </div>
        </Main>
    );
};

export default EstimationList;
