import { UploadProps } from 'antd';
import { useFormik } from 'formik';
import { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { alertSuccess } from 'utils/helper/appHelper';

export type ReceivedProps = {
  screenType: "create" | "edit";
};
const useQuoteForm = (props: ReceivedProps) => {
    const params = useParams();
    const { id } = params;
    const { control, handleSubmit } = useForm();
    const submitData = (value) => {
      console.log("submitData", value);
      alertSuccess("見積書が正常に送信されました");
    };
    const dragProps: UploadProps = {
      name: "file",
      onChange(info) {
        console.log("onChange info", info);
      },
      onDrop(e) {
        console.log("Dropped files", e.dataTransfer.files);
      },
    };
    const dummyRequest: UploadProps["customRequest"] = async ({
      file,
      onSuccess
    }) => {
      setTimeout(() => {
        if (onSuccess) {
          onSuccess("ok");
        }
      }, 0);
    };
    return {
        ...props,
        handleSubmit,
        submitData,
        control,
        dragProps,
        dummyRequest,
    };
};

export type Props = ReturnType<typeof useQuoteForm>;

export default useQuoteForm;
