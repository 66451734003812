// Lib
import { max, min, required } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createWorkingReportValidation = (t: TypeOfT) => ({
    manager_worker_id: () => ({
        required: required('manager_worker_id', t),
    }),
    published_at: () => ({
        required: required('published_at', t),
    }),
    submitted_at: () => ({
        required: required('submitted_at', t),
    }),
    from_at: () => ({
        required: required('from_at', t),
    }),
    to_at: () => ({
        required: required('to_at', t),
    }),
});

export default createWorkingReportValidation;
