import { FC } from 'react';
import { Checkbox } from 'antd';
import { StylesTodoList } from './styled';
import useTodolist, { ReceivedProps, Props } from './hook';
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg';
import moment from 'moment';

const TodoListLayout: FC<Props> = ({ data, type, handleOpen }) => {
    return (
        <StylesTodoList>
            {data?.map((todo) => (
                <div className="todoList">
                    <input
                        type="checkbox"
                        className="rounded-checkbox mr-6"
                        id="checkbox"
                    />
                    <div className='w-[82%]' onClick={() => handleOpen(todo)}>
                        <p className="mb-0 todo-title truncate">{todo?.title}</p>
                        <p className="mb-0 todo-description w-[260px] truncate ...">
                            {todo?.content}
                        </p>
                        <div className="flex items-center">
                            <Calendar />
                            <span className="todo-time ml-1 mt-[1px]">
                                {moment(todo?.end_date).format(
                                    type !== 1 ? 'MM日DD月' : 'MM日DD月',
                                )}
                            </span>
                        </div>
                    </div>
                </div>
            ))}
        </StylesTodoList>
    );
};
const TodoList: FC<ReceivedProps> = (props) => {
    return <TodoListLayout {...useTodolist(props)} />;
};
export default TodoList;
