import { FC } from 'react';
import { Button, Box, Grid } from '@mui/material';
import usePR, { ReceivedProps, Props } from './hook';
import { Add } from '@mui/icons-material';
import TrashIcon from 'assets/icons/delete_ic.svg';
import ViewIcon from 'assets/icons/view_ic.svg';
import { StylesPR } from './styled';
import { IPurchase } from './type';

const PRLayout: FC<Props> = ({
    data,
    navigate,
    setDeleteData,
    handleDelete,
    ModalDelete,
    openDelete,
    closeDelete
}) => {

    return (
        <StylesPR>
            <ModalDelete>
                <div
                    className="modal-create-user d-flex flex-column !w-[80vw]"
                    style={{ height: '152px' }}
                >
                    <div className="modal-create-user-title d-flex flex-row">
                        本当に削除しますか?
                    </div>
                    <div
                        className="modal-create-user-buttons d-flex flex-row"
                        style={{ marginTop: '50px' }}
                    >
                        <button
                            className="modal-create-user-button1 d-flex flex-row"
                            onClick={closeDelete}
                        >
                            キャンセル
                        </button>
                        <button
                            className="modal-create-user-button2 d-flex flex-row"
                            onClick={handleDelete}
                        >
                            はい
                        </button>
                    </div>
                </div>
            </ModalDelete>
            <div className='flex justify-between mt-[16px] mb-[16px]'>
                <p className="job--title">経費要望</p>
                <Button
                    onClick={() => navigate('create')}
                    startIcon={<Add />}
                    sx={{
                        color: '#215493',
                        fontWeight: '500',
                        lineHeight: '20px',
                        maxHeight: '35px'
                    }}
                    variant="outlined"
                >
                    新規作成
                </Button>
            </div>
            <Box>
                <Box
                    sx={{
                        border: '1px solid #CDD1D5 ',
                        '.MuiGrid-container': {
                            borderBottom: '1px solid #F5F5F5',
                        },
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#C7DCF5',
                            padding: '14px 17px',
                            fontWeight: 'bold',
                        }}
                    >
                        経費要望
                    </Box>
                    {data?.map((item: IPurchase) => (
                        <Grid
                            key={item.id}
                            sx={{ padding: '14px 17px' }}
                            container
                        >
                            <Grid xs={9} item className={item?.status !== "approved" ? 'text-[#e01310]' : ''}>
                                {item?.name} {item?.status === "denied" ? <span className='font-semibold'>却下</span> : ''}
                            </Grid>
                            <Grid
                                display="flex"
                                justifyContent="end"
                                alignItems="center"
                                xs={3}
                                item
                            >
                                <Box
                                    onClick={() =>
                                        navigate(
                                            `/sp/purchase-request/detail/${item.id}`,
                                        )
                                    }
                                    sx={{
                                        backgroundColor: '#215493',
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={ViewIcon} />
                                </Box>
                                <Box
                                    onClick={() => {
                                        setDeleteData(item);
                                        openDelete();
                                    }}
                                    sx={{
                                        backgroundColor: '#FF5045',
                                        width: 24,
                                        height: 24,
                                        borderRadius: '50%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        ml: '6px',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <img src={TrashIcon} />
                                </Box>
                            </Grid>
                        </Grid>
                    ))}
                </Box>
                <Button
                    onClick={() => navigate(`/`)}
                    sx={{
                        color: '#215493',
                        mt: '42px',
                        width: '166px',
                    }}
                    variant="outlined"
                >
                    戻る
                </Button>
            </Box>
        </StylesPR>
    );
};

const PurchaseRequest: FC<ReceivedProps> = (props) => {
    return <PRLayout {...usePR(props)} />;
};

export default PurchaseRequest;
