import styled from 'styled-components';

export const StylesInternalOJT = styled.div`
    background-color: #fff;
    margin: 28px;
    padding: 25px 28px;
    border-radius: 8px;
    .vehicle-title {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }
    .ant-table-container table > thead > tr:last-child th:last-child {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
    .ant-table-thead {
        .ant-table-cell {
            background-color: #c7dcf5;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &::before {
                display: none;
            }
        }
    }
    .vehicle-name {
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
    .input-search {
        border: 1px solid #cdd1d5;
        border-radius: 8px;
        margin-bottom: 14.5px;
        padding-left: 14px;
    }
    .MuiTextField-root {
        border: 1px solid #cdd1d5;
        width: 342px;
        border-radius: 8px;
        margin-right: 12.5px;
    }
    .MuiInputBase-input {
        height: 35px;
        padding: 0px !important;
    }
    .ant-empty-description {
        display: none;
    }
`;
