import styled from 'styled-components';

export const StylesTodo = styled.div`
    padding: 15px;
    .progress-wrapper {
        margin-bottom: 16px;
        background: #215493;
        border-radius: 8px;
        padding: 11px 13px;
    }
    .ant-progress-bg {
        height: 9px !important;
    }
    .ant-tabs-nav {
        margin: 0px !important;
    }
    .ant-progress-inner {
        outline: 1px solid white;
    }
    .ant-tabs-tab {
        .ant-tabs-tab-btn {
            color: #666;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
    .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
            color: #215493 !important;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }
    }
    .ant-tabs-ink-bar {
        background: #215493 !important;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
        border-bottom: 1px solid #8d95a0;
    }
`;
