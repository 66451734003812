export type ReceivedProps = {
    type: number;
    handleOpen: (todo: any) => void;
    data: any;
};

const useTodoList = (props: ReceivedProps) => {
    return {
        ...props,
    };
};

export type Props = ReturnType<typeof useTodoList>;

export default useTodoList;
