import Api from './api/departments';

const getListDepartments = async() => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if (user.access_token !== undefined && user.access_token !== null ){
        try {
            return await Api().get('', { headers: {Authorization: `Bearer ${user.access_token}`} }).then(response => {
                if(response.data) {
                    return response.data;
                }
            })
        } catch (err) {
            window.location.href = '/login';
            return null
        }
    } else {
        window.location.href = '/login';
    }
}

const createDepartments = async(body) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null)){
        return await Api().post('', body, { headers: {Authorization: `Bearer ${user.access_token}`} }).then(response => {
            if(response.data) {
                return response.data;
            }
        })
    } else {
        window.location.href = '/login';
    }
}
  
const updateDepartments = async(body, id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    if ((user.access_token !== undefined && user.access_token !== null)){
        return await Api().put(`${id}`, body, { headers: {Authorization: `Bearer ${user.access_token}`}}).then(response => {
            if(response.data) {
                return response.data;
            }
        })
    } else {
        window.location.href = '/login';
    }
}

const reorderDepartments = async(body) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    console.log(body)
    if ((user.access_token !== undefined && user.access_token !== null)){
        try {
            return await Api().put('reorder', body, { headers: {Authorization: `Bearer ${user.access_token}`} }).then(response => {
                if(response.data) {
                    return response.data;
                }
            })
        } catch (err) {
            // window.location.href = '/login';
            console.log(err)
            return null
        }
    } else {
        window.location.href = '/login';
    }
}

const deleteDepartments = async(id) => {
    let storage = localStorage.getItem("user")
    const user = storage !== null ? JSON.parse(storage) : null;
    const params = { 
        id: id
    };
    if ((user.access_token !== undefined && user.access_token !== null)){
        return await Api().delete(`${id}`, { headers: {Authorization: `Bearer ${user.access_token}`}}).then(response => {
            if(response.data) {
                return response.data;
            }
        })
    } else {
        window.location.href = '/login';
    }
}

const departmentsService = {
    getListDepartments,
    createDepartments,
    updateDepartments,
    reorderDepartments,
    deleteDepartments
};


export default departmentsService;