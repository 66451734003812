import { useEffect, useRef, useState } from 'react';
import { ProjectTableRow } from 'components/projectTable';
import printer from '../../assets/icons/Printer.svg';
// import jspreadsheet from "jexcel";
import Breadcrumb from '../../components/Breadcrumbs';
import jspreadsheet, {
    CellValue,
    JspreadsheetInstance,
    JspreadsheetInstanceElement,
} from 'jspreadsheet-ce';
import { getCompare, postCompare } from 'services/compareService';
import './compare.css';
import { Alert, Button, CircularProgress, Snackbar } from '@mui/material';
import { Body } from './types';
import * as Yup from 'yup';
import keydownControls from './keydownControls';
import { useLocation } from 'react-router-dom';
// import '/node_modules/jspreadsheet-ce/dist/jspreadsheet.css';

const budgetItemValidationSchema = Yup.object()
    .shape(
        {
            name: Yup.string().required(),
            supplier: Yup.string().optional(),
            unit: Yup.string().optional(),
            unit_price: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .when('amount', {
                    is: (value?: number) => value,
                    then: (schema) => schema.required(),
                    otherwise: (schema) => schema.optional(),
                }),
            amount: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .when('unit_price', {
                    is: (value?: number) => value,
                    then: (schema) => schema.required(),
                    otherwise: (schema) => schema.optional(),
                }),
            summary: Yup.string().nullable(),
            note: Yup.string().nullable(),
        },
        [['unit_price', 'amount']],
    )
    .nullable();
const estimateItemValidationSchema = Yup.object()
    .shape(
        {
            name: Yup.string().required(),
            unit: Yup.string().optional(),
            unit_price: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .when('amount', {
                    is: (value?: number) => value,
                    then: (schema) => schema.required(),
                    otherwise: (schema) => schema.optional(),
                }),
            amount: Yup.number()
                .transform((value) => (Number.isNaN(value) ? null : value))
                .nullable()
                .when('unit_price', {
                    is: (value?: number) => value,
                    then: (schema) => schema.required(),
                    otherwise: (schema) => schema.optional(),
                }),
            summary: Yup.string().nullable(),
            note: Yup.string().nullable(),
        },
        [['unit_price', 'amount']],
    )
    .nullable();

const validationSchema = Yup.object().shape({
    budget_id: Yup.number().required(),
    estimate_id: Yup.number().required(),
    project_id: Yup.number().required(),
    compare_items: Yup.array().of(
        Yup.object().shape(
            {
                budget_item: budgetItemValidationSchema.when('estimate_item', {
                    is: (value: any = {}) =>
                        !(value?.unit_price && value?.amount),
                    then: (schema) =>
                        schema.test(
                            'budget_item',
                            "estimate_item or budget_item can't be not null",
                            function () {
                                const budget_item = this.parent.budget_item;

                                return (
                                    budget_item.unit_price && budget_item.amount
                                );
                            },
                        ),
                    otherwise: (schema) => schema,
                }),
                estimate_item: estimateItemValidationSchema.when(
                    'budget_item',
                    {
                        is: (value: any = {}) =>
                            !(value?.unit_price && value?.amount),
                        then: (schema) =>
                            schema.test(
                                'estimate_item',
                                "estimate_item or budget_item can't be not null",
                                function () {
                                    const estimate_item =
                                        this.parent.estimate_item;

                                    return (
                                        estimate_item.unit_price &&
                                        estimate_item.amount
                                    );
                                },
                            ),
                        otherwise: (schema) => schema,
                    },
                ),
                children: Yup.array().of(
                    Yup.object().shape({
                        budget_item: budgetItemValidationSchema,
                        estimate_item: estimateItemValidationSchema,
                    }),
                ),
            },
            [['budget_item', 'estimate_item']],
        ),
    ),
});

const header = [
    {
        cellName: 'A1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'B1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'C1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'D1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'E1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'F1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'G1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'H1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'I1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'J1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'K1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'L1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'M1',
        style: 'font-weight: 700; color: unset; text-align: center',
    },
    {
        cellName: 'C2',
        style: 'color: unset; text-align: center',
    },
    {
        cellName: 'D2',
        style: 'color: unset; text-align: center',
    },
    {
        cellName: 'E2',
        style: 'color: unset; text-align: center',
    },
    {
        cellName: 'F2',
        style: 'color: unset; text-align: center; font-weight: unset;',
    },
    {
        cellName: 'G2',
        style: 'color: unset; text-align: center; font-weight: unset;',
    },
    {
        cellName: 'H2',
        style: 'color: unset; text-align: center',
    },
    {
        cellName: 'I2',
        style: 'color: unset; text-align: center',
    },
    {
        cellName: 'J2',
        style: 'color: unset; text-align: center; font-weight: unset;',
    },
    {
        cellName: 'K2',
        style: 'color: unset; text-align: center; font-weight: unset;',
    },
    {
        cellName: 'A3',
        style: 'color: unset; text-align: center; font-weight: unset;',
    },
];

const Compare = () => {
    const location: any = useLocation();
    const estimateData = location?.state?.estimateData;
    const budgetData = location?.state?.budgetData;
    const project = location?.state?.project;

    const jRef = useRef<any>(null);
    const table = useRef<any>(null);

    const [dataJsPreadSheet, setDataJsPreadSheet] = useState<any>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [openAlertError, setOpenAlertError] = useState<boolean>(false);
    const [openAlertSuccess, setOpenAlertSuccess] = useState<boolean>(false);
    const [totalMarginPrice, setTotalMarginPrice] = useState<number>();
    const [totalPriceEstimate, setTotalPriceEstimate] = useState<number>();
    const [errorMessage, setErrorMessage] = useState<number>();

    const handleConvertData = (array: any, child: boolean, result: any) => {
        array.forEach((item: any) => {
            if (child) {
                result[result.length] = [
                    1,
                    item?.name || null,
                    item?.estimate_item?.unit || null,
                    Number(item?.estimate_item?.unit_price) || null,
                    item?.estimate_item?.amount || null,
                    item?.estimate_item?.total_price || null,
                    item?.budget_item?.supplier || null,
                    item?.budget_item?.unit || null,
                    Number(item?.budget_item?.unit_price) || null,
                    item?.budget_item?.amount || null,
                    item?.budget_item?.total_price || null,
                    handleNumberFormat(item?.margin_price) || null,
                    `${Number(item?.margin_percent).toFixed(2)}%`,
                    item?.budget_item?.name || null,
                    item?.budget_item?.summary || null,
                    item?.budget_item?.note || null,
                    item?.estimate_item?.name || null,
                    item?.estimate_item?.summary || null,
                    item?.estimate_item?.note || null,
                ];
            } else {
                result[result.length] = [
                    0,
                    item?.name || null,
                    item?.estimate_item?.unit || null,
                    Number(item?.estimate_item?.unit_price) || null,
                    item?.estimate_item?.amount || null,
                    item?.estimate_item?.total_price || null,
                    item?.budget_item?.supplier || null,
                    item?.budget_item?.unit || null,
                    Number(item?.budget_item?.unit_price) || null,
                    item?.budget_item?.amount || null,
                    item?.budget_item?.total_price || null,
                    handleNumberFormat(item?.margin_price) || null,
                    `${Number(item?.margin_percent).toFixed(2)}%`,
                    item?.budget_item?.name || null,
                    item?.budget_item?.summary || null,
                    item?.budget_item?.note || null,
                    item?.estimate_item?.name || null,
                    item?.estimate_item?.summary || null,
                    item?.estimate_item?.note || null,
                ];
            }

            if (item?.children?.length) {
                handleConvertData(item?.children, true, result);
            }
        });
    };

    const handleTotalDataMarginPrices = (jexcel: JspreadsheetInstance) => {
        const dataMarginPrices = jexcel.getColumnData(11);
        const column0 = jexcel.getColumnData(0);
        const totalDataMarginPrices = dataMarginPrices.reduce(
            (a: any, b: any, index: number) => {
                if (index > 1 && !column0[index]) {
                    return Number(a) + Number(b.replace(/[^0-9-]/g, '') || 0);
                }
                return a;
            },
            0,
        );
        setTotalMarginPrice(totalDataMarginPrices);
    };
    const handleTotalDataPriceEstimate = (jexcel: JspreadsheetInstance) => {
        const dataPriceEstimates = jexcel.getColumnData(5);
        const column0 = jexcel.getColumnData(0);
        const totalDataPriceEstimates = dataPriceEstimates.reduce(
            (a: any, b: any, index: number) => {
                if (index > 1 && !column0[index]) {
                    return Number(a) + (Number(b) || 0);
                }
                return a;
            },
            0,
        );
        setTotalPriceEstimate(totalDataPriceEstimates);
    };

    const getApiCompare = () => {
        setLoading(true);
        getCompare({
            project_id: estimateData?.project?.id,
            budget_id: budgetData?.value,
            estimate_id: estimateData?.id,
        })
            .then((res: any) => {
                let convertData: any = [];
                handleConvertData(res?.result, false, convertData);
                setDataJsPreadSheet(convertData);
                setLoading(false);
            })
            .catch((e) => {
                console.log(e);
                setDataJsPreadSheet([]);
                setLoading(false);
            });
    };

    useEffect(() => {
        getApiCompare();

        if (!jRef.current.jspreadsheet) {
            table.current = jspreadsheet(jRef.current, {
                data: [
                    [
                        '番号',
                        '名称',
                        '見積',
                        '',
                        '',
                        '',
                        '予算',
                        '',
                        '',
                        '',
                        '',
                        '差額',
                        '粗利率',
                    ],
                    [
                        '',
                        '',
                        '単位',
                        '単価',
                        '数量',
                        '小計',
                        '仕入先',
                        '単位',
                        '単価',
                        '数量',
                        '小計',
                    ],
                    ...dataJsPreadSheet,
                ],
                columns: [
                    { width: 55, type: 'checkbox' },
                    { width: 230, align: 'left' },
                    { width: 76 },
                    { width: 76, align: 'right' },
                    { width: 76, align: 'right' },
                    { width: 76, align: 'right' },
                    { width: 60 },
                    { width: 60 },
                    { width: 70, align: 'right' },
                    { width: 71, align: 'right' },
                    { width: 73, align: 'right' },
                    { width: 99, align: 'right' },
                    { width: 92, align: 'right' },
                    { type: 'hidden' },
                    { type: 'hidden' },
                    { type: 'hidden' },
                    { type: 'hidden' },
                    { type: 'hidden' },
                    { type: 'hidden' },
                ],
                mergeCells: {
                    A1: [1, 2],
                    B1: [1, 2],
                    C1: [4, 1],
                    G1: [5, 1],
                    L1: [1, 2],
                    M1: [1, 2],
                },

                minDimensions: [13, 14],
                defaultRowHeight: 56,
                allowDeleteRow: true,
                allowDeleteColumn: false,
                rowDrag: false,
                columnSorting: false,
                allowComments: true,

                updateTable: function (el, cell, x, y, source, value, id) {
                    if (x === 5 || x === 10 || x === 11 || x === 12) {
                        cell.classList.add('readonly');
                        cell.classList.add('color-unset');
                    }
                },

                onload(_element, instance) {
                    const A1Cell = instance.getCell('A1');
                    A1Cell.firstElementChild?.setAttribute(
                        'style',
                        'display: none;',
                    );
                    A1Cell.firstElementChild?.setAttribute('readonly', 'true');
                    const title = document.createElement('span');
                    title.innerHTML = '番号';
                    A1Cell.appendChild(title);

                    header.forEach((head) => {
                        instance.setReadOnly(head.cellName, true);
                        const cell = instance.getCell(head.cellName);
                        cell.setAttribute('style', head.style);
                    });
                },

                contextMenu: function (
                    obj: JspreadsheetInstance,
                    x: string | null,
                    y: string | null,
                    e: PointerEvent,
                ) {
                    var items: any = [];

                    if (y === null) {
                        e.preventDefault();
                    } else {
                        if (
                            obj.options.allowInsertRow === true &&
                            parseInt(y) !== 0 &&
                            parseInt(y) !== 1
                        ) {
                            items.push({
                                title: 'の前に新しい行を挿入する',
                                onclick: function () {
                                    obj.insertRow(1, parseInt(y), 1);
                                },
                            });

                            items.push({
                                title: 'の後に新しい行を挿入する',
                                onclick: function () {
                                    obj.insertRow(1, parseInt(y));
                                },
                            });

                            items.push({
                                title: '選択した行を削除する',
                                onclick: function () {
                                    obj.deleteRow(parseInt(y), 1);
                                },
                            });
                        }
                    }
                    return items;
                },

                onselection(
                    element,
                    borderLeftIndex,
                    borderTopIndex,
                    borderRightIndex,
                    borderBottomIndex,
                    origin,
                ) {
                    if (borderBottomIndex <= 1 || borderTopIndex <= 1) {
                        element.jexcel.resetSelection();
                    }
                },

                onbeforechange: (
                    element: JspreadsheetInstanceElement,
                    cell: HTMLTableCellElement,
                    colIndex: string | number,
                    rowIndex: string | number,
                    newValue: CellValue,
                ) => {
                    const numberCol = Number(colIndex);
                    if (newValue) {
                        if (numberCol === 3 || numberCol === 8) {
                            return (
                                Number(`${newValue}`.replace(/[^\d.-]/g, '')) ??
                                ''
                            );
                        }
                        if (numberCol === 4 || numberCol === 9) {
                            return (
                                Number(`${newValue}`.replace(/[^\d]/g, '')) ??
                                ''
                            );
                        }
                        if (
                            numberCol === 1 ||
                            numberCol === 2 ||
                            numberCol === 6 ||
                            numberCol === 7
                        ) {
                            return `${newValue}`.trim();
                        }
                    }
                    return newValue;
                },

                onbeforepaste: (element, copiedText, colIndex, rowIndex) => {
                    const arrayCopiedText = copiedText.split('\t');

                    if (
                        Number(colIndex) === 0 &&
                        arrayCopiedText.length &&
                        arrayCopiedText[0] === 'true'
                    ) {
                        table.current
                            .getCell(`B${Number(rowIndex) + 1}`)
                            .classList.add('child-name');
                    } else {
                        table.current
                            .getCell(`B${Number(rowIndex) + 1}`)
                            .classList.remove('child-name');
                    }

                    return copiedText;
                },

                ondeleterow(element, rowIndex, numOfRows, deletedCells) {
                    handleTotalDataMarginPrices(element.jexcel);
                    handleTotalDataPriceEstimate(element.jexcel);
                },

                onundo: (element, historyRecord) => {
                    // const checkAction =
                    //   table.current?.history?.length &&
                    //   table.current?.history[table.current?.history?.length - 1]
                    //     .action === "Backspace"
                    //     ? true
                    //     : false;

                    // let records = [];
                    // if (checkAction) {
                    //   records =
                    //     table.current?.history[table.current?.history?.length - 1]
                    //       .records;
                    // } else {
                    const records = historyRecord?.records;
                    // }

                    records?.forEach((item: any) => {
                        const numberRow = Number(item?.row);
                        const numberCol = Number(item?.col);
                        const oldValue = Number(item?.oldValue);

                        if (
                            (numberRow > 1 && numberCol === 3) ||
                            (numberRow > 1 && numberCol === 4) ||
                            (numberRow > 1 && numberCol === 8) ||
                            (numberRow > 1 && numberCol === 9)
                        ) {
                            const d =
                                numberCol === 3
                                    ? oldValue
                                    : element.jexcel.getLabel(
                                          `D${numberRow + 1}`,
                                      );
                            const e =
                                numberCol === 4
                                    ? oldValue
                                    : element.jexcel.getLabel(
                                          `E${numberRow + 1}`,
                                      );
                            const i =
                                numberCol === 8
                                    ? oldValue
                                    : element.jexcel.getLabel(
                                          `I${numberRow + 1}`,
                                      );
                            const j =
                                numberCol === 9
                                    ? oldValue
                                    : element.jexcel.getLabel(
                                          `J${numberRow + 1}`,
                                      );

                            element.jexcel.ignoreEvents = true;
                            table.current.ignoreHistory = true;

                            element.jexcel.setValue(
                                `F${numberRow + 1}`,
                                !e && !d ? '' : Number(e) * Number(d),
                                true,
                            );

                            element.jexcel.setValue(
                                `K${numberRow + 1}`,
                                !i && !j ? '' : Number(i) * Number(j),
                                true,
                            );

                            element.jexcel.setValue(
                                `L${numberRow + 1}`,
                                !e && !d && !i && !j
                                    ? ''
                                    : handleNumberFormat(
                                          Number(e) * Number(d) -
                                              Number(i) * Number(j),
                                      ),
                                true,
                            );
                            element.jexcel.setValue(
                                `M${numberRow + 1}`,
                                !e && !d && !i && !j
                                    ? ''
                                    : `${(
                                          ((Number(e) * Number(d) -
                                              Number(i) * Number(j)) *
                                              100) /
                                              (Number(e) * Number(d) || 1) || 0
                                      ).toFixed(2)}%`,
                                true,
                            );

                            handleTotalDataMarginPrices(element.jexcel);
                            handleTotalDataPriceEstimate(element.jexcel);
                            table.current.ignoreHistory = false;
                            element.jexcel.ignoreEvents = false;
                        }
                        if (numberRow > 1 && numberCol === 0) {
                            handleTotalDataMarginPrices(element.jexcel);
                            handleTotalDataPriceEstimate(element.jexcel);
                        }
                    });
                },

                onchange(
                    element,
                    cell,
                    colIndex,
                    rowIndex,
                    newValue,
                    oldValue,
                ) {
                    const numberCol = Number(colIndex);
                    const numberRow = Number(rowIndex);
                    if (colIndex === '0') {
                        if (newValue) {
                            table.current
                                .getCell(`B${Number(rowIndex) + 1}`)
                                .classList.add('child-name');
                        } else {
                            table.current
                                .getCell(`B${Number(rowIndex) + 1}`)
                                .classList.remove('child-name');
                        }
                    }

                    if (
                        (numberRow > 1 && numberCol === 3) ||
                        (numberRow > 1 && numberCol === 4) ||
                        (numberRow > 1 && numberCol === 8) ||
                        (numberRow > 1 && numberCol === 9)
                    ) {
                        const d =
                            numberCol === 3
                                ? newValue
                                : element.jexcel.getLabel(`D${numberRow + 1}`);
                        const e =
                            numberCol === 4
                                ? newValue
                                : element.jexcel.getLabel(`E${numberRow + 1}`);
                        const i =
                            numberCol === 8
                                ? newValue
                                : element.jexcel.getLabel(`I${numberRow + 1}`);
                        const j =
                            numberCol === 9
                                ? newValue
                                : element.jexcel.getLabel(`J${numberRow + 1}`);

                        element.jexcel.ignoreEvents = true;
                        table.current.ignoreHistory = true;

                        element.jexcel.setValue(
                            `F${numberRow + 1}`,
                            !e && !d ? '' : Number(e) * Number(d),
                            true,
                        );
                        element.jexcel.setValue(
                            `K${numberRow + 1}`,
                            !i && !j ? '' : Number(i) * Number(j),
                            true,
                        );

                        element.jexcel.setValue(
                            `L${numberRow + 1}`,
                            !e && !d && !i && !j
                                ? ''
                                : handleNumberFormat(
                                      Number(e) * Number(d) -
                                          Number(i) * Number(j),
                                  ),
                            true,
                        );
                        element.jexcel.setValue(
                            `M${numberRow + 1}`,
                            !e && !d && !i && !j
                                ? ''
                                : `${(
                                      ((Number(e) * Number(d) -
                                          Number(i) * Number(j)) *
                                          100) /
                                          (Number(e) * Number(d) || 1) || 0
                                  ).toFixed(2)}%`,
                            true,
                        );
                        handleTotalDataMarginPrices(element.jexcel);
                        handleTotalDataPriceEstimate(element.jexcel);
                        table.current.ignoreHistory = false;
                        element.jexcel.ignoreEvents = false;
                    }
                    if (numberRow > 1 && numberCol === 0) {
                        handleTotalDataMarginPrices(element.jexcel);
                        handleTotalDataPriceEstimate(element.jexcel);
                    }
                },
            });
        }
    }, []);

    const handleNumberFormat = (number: number) => {
        return new Intl.NumberFormat('ja-JP', {
            style: 'currency',
            currency: 'JPY',
        }).format(number);
    };

    useEffect(() => {
        table.current.setData([
            [
                '番号',
                '名称',
                '見積',
                '',
                '',
                '',
                '予算',
                '',
                '',
                '',
                '',
                '差額',
                '粗利率',
            ],
            [
                '',
                '',
                '単位',
                '単価',
                '数量',
                '小計',
                '仕入先',
                '単位',
                '単価',
                '数量',
                '小計',
            ],
            ...dataJsPreadSheet,
        ]);

        dataJsPreadSheet?.forEach((item: any, index: number) => {
            if (item[0]) {
                table.current
                    .getCell(`B${index + 3}`)
                    .classList.add('child-name');
            } else {
                table.current
                    .getCell(`B${index + 3}`)
                    .classList.remove('child-name');
            }
        });

        handleTotalDataMarginPrices(table.current);
        handleTotalDataPriceEstimate(table.current);

        document.addEventListener('keydown', function (e) {
            keydownControls(e, table);
        });
        return () => {
            document.removeEventListener('keydown', function (e) {
                keydownControls(e, table);
            });
        };
    }, [dataJsPreadSheet]);

    function handleBackspace(event) {
        event.preventDefault();
        if (event.key === 'Backspace' || event.keyCode === 8) {
            if (
                !table.current.edition &&
                table.current.edition[0].innerText == ''
            ) {
                table.current.setValue(table.current.highlighted, '');
            }
        }

        // if (event.key === "Backspace" || event.keyCode === 8) {
        //   if (!table.current.edition) {
        // const row = table.current.getSelectedRows(true);
        // const column = table.current.getSelectedColumns();
        // // const records: any = [];
        // // table.current.ignoreHistory = true;
        // if (column.length > 1) {
        //   row.forEach((x) => {
        //     column.forEach((y) => {
        //       if (x > 1) {
        //         // records.push({
        //         //   x,
        //         //   y,
        //         //   col: x,
        //         //   row: y,
        //         //   newValue: "",
        //         //   oldValue: table.current.getValueFromCoords(y, x),
        //         // });
        //         table.current.setValueFromCoords(y, x, "", true);
        //       }
        //     });
        //   });
        // }
        // // table.current.ignoreHistory = false;
        // // if (column.length && row.length) {
        // //   table.current.history.push({
        // //     action: "Backspace",
        // //     records,
        // //     selection: [
        // //       column[0],
        // //       row[0],
        // //       column[column.length - 1],
        // //       row[row.length - 1],
        // //     ],
        // //   });
        // // }
        // event.preventDefault();
        //   }
        // }
    }

    const handleSubmit = async () => {
        const dataCompareItems: Body['compare_items'] = [];
        const arrayIndex = [1, 2, 3, 4, 6, 7, 8, 9];

        table.current
            .getData()
            .filter(
                (item1: Array<string | number | boolean>, index1: number) => {
                    let checkFilterData = false;
                    item1.forEach((v, i) => {
                        if (arrayIndex.includes(i)) {
                            if (v) {
                                checkFilterData = true;
                            }
                        }
                    });
                    if (index1 !== 0 && index1 !== 1 && checkFilterData)
                        return true;
                    return false;
                },
            )
            .forEach((item2: Array<string | number | boolean>) => {
                const object: Body['compare_items'][0] = {};
                let checkChild = false;

                item2.forEach((item: any, index: number) => {
                    switch (index) {
                        case 0:
                            if (!item) {
                                checkChild = false;
                                object.children = [];
                            } else {
                                checkChild = true;
                            }
                            break;
                        case 1:
                            object.estimate_item = { name: item };
                            object.budget_item = { name: item };
                            break;
                        case 2:
                            object.estimate_item = {
                                ...object.estimate_item,
                                unit: item,
                            };
                            break;
                        case 3:
                            object.estimate_item = {
                                ...object.estimate_item,
                                unit_price: item,
                            };
                            break;
                        case 4:
                            object.estimate_item = {
                                ...object.estimate_item,
                                amount: item,
                            };
                            break;
                        case 6:
                            object.budget_item = {
                                ...object.budget_item,
                                supplier: item,
                            };
                            break;
                        case 7:
                            object.budget_item = {
                                ...object.budget_item,
                                unit: item,
                            };
                            break;
                        case 8:
                            object.budget_item = {
                                ...object.budget_item,
                                unit_price: item,
                            };
                            break;
                        case 9:
                            object.budget_item = {
                                ...object.budget_item,
                                amount: item,
                            };
                            break;
                        case 14:
                            object.budget_item = {
                                ...object.budget_item,
                                summary: item,
                            };
                            break;
                        case 15:
                            object.budget_item = {
                                ...object.budget_item,
                                note: item,
                            };
                            break;
                        case 17:
                            object.estimate_item = {
                                ...object.estimate_item,
                                summary: item,
                            };
                            break;
                        case 18:
                            object.estimate_item = {
                                ...object.estimate_item,
                                note: item,
                            };
                            break;
                        default:
                            break;
                    }
                });

                let checkBudgetItem = false;
                let checkEstimateItem = false;

                object.budget_item &&
                    Object.keys(object.budget_item).forEach((item) => {
                        if (item === 'amount' || item === 'unit_price') {
                            if (
                                object.budget_item &&
                                object.budget_item[item]
                            ) {
                                checkBudgetItem = true;
                            }
                        }
                    });
                object.estimate_item &&
                    Object.keys(object.estimate_item).forEach((item) => {
                        if (item === 'amount' || item === 'unit_price') {
                            if (
                                object.estimate_item &&
                                object.estimate_item[item]
                            ) {
                                checkEstimateItem = true;
                            }
                        }
                    });

                const convertDataObject = {
                    ...object,
                    estimate_item: checkEstimateItem
                        ? object.estimate_item
                        : null,
                    budget_item: checkBudgetItem ? object.budget_item : null,
                };

                if (checkChild) {
                    dataCompareItems[
                        dataCompareItems.length - 1
                    ]!.children!.push(convertDataObject);
                } else {
                    dataCompareItems.push(convertDataObject);
                }
            });

        const body = {
            project_id: estimateData?.project?.id,
            budget_id: budgetData?.value,
            estimate_id: estimateData?.id,
            compare_items: dataCompareItems,
        };

        const isValidBody = validationSchema.isValidSync(body, {
            abortEarly: false,
        });

        if (!isValidBody) {
            setOpenAlertError(true);
        } else {
            try {
                const res: any = await postCompare({
                    project_id: estimateData?.project?.id,
                    budget_id: budgetData?.value,
                    estimate_id: estimateData?.id,
                    compare_items: dataCompareItems,
                });

                setOpenAlertSuccess(true);
                let convertData: any = [];
                handleConvertData(res?.data?.result, false, convertData);
                setDataJsPreadSheet(convertData);
                setLoading(false);
            } catch (error) {
                setOpenAlertError(true);
                setLoading(false);
                setErrorMessage(error?.error || 'error');
            } finally {
                setLoading(false);
            }
        }
    };

    const handleCloseAlertError = () => {
        setOpenAlertError(false);
    };

    const handleCloseAlertSuccess = () => {
        setOpenAlertSuccess(false);
    };

    return (
        <div>
            {loading && (
                <div className="compare-loading">
                    <div className="coating"></div>
                    <CircularProgress />
                </div>
            )}
            <Breadcrumb
                title="利益率表"
                data={[]}
                // data={[
                //   { name: "予実管理一覧", url: "" },
                //   { name: "予実管理詳細", url: "" },
                //   { name: "プロジェクト詳細", url: "" },
                //   { name: "利益率表", url: "" },
                // ]}
            />

            <div className="p-[24px] d-flex flex-column bg-[#e3e6e8] w-full">
                <div className="d-flex flex-row justify-between w-full items-end items-end">
                    <div className="bg-white p-[10px] w-[682px] rounded-[16px]">
                        <div className="project-detail-inf d-flex flex-column">
                            <ProjectTableRow
                                titles={['顧客', '業種']}
                                values={[
                                    project?.client_company?.company?.name,
                                    project?.business_type,
                                ]}
                            />
                            <ProjectTableRow
                                titles={['場所', '工期']}
                                values={[
                                    project?.location_address,
                                    [project?.start_at, project?.end_at]
                                        .filter(Boolean)
                                        .join(' ~ '),
                                ]}
                            />
                            <ProjectTableRow
                                titles={['金額', '見積担当']}
                                values={[
                                    project?.total_amount,
                                    project?.estimate_assigned_worker?.name,
                                ]}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-[20px] wrap-excel">
                    <div className="flex gap-3 mr-1.5 mb-1.5">
                        <Button onClick={handleSubmit} variant="contained">
                            更新
                        </Button>
                        <button className="button-export">
                            <img
                                width={'24px'}
                                height={'24px'}
                                src={printer}
                                alt=""
                            ></img>
                            <span>印刷</span>
                        </button>
                    </div>
                    <div ref={jRef} />
                    <div className="summary sticky w-44 text-base rounded  bottom-0 mr-1.5 mb-2 box-border py-1 px-1.5">
                        <div className="box-border p-2">まとめ</div>
                        <hr />
                        <div className="flex justify-between box-border p-2">
                            <div>差額</div>
                            <div>
                                {handleNumberFormat(
                                    Number(totalMarginPrice) || 0,
                                )}
                            </div>
                        </div>
                        <div className="flex justify-between box-border p-2">
                            <div>粗利率</div>
                            <div>
                                {(
                                    (Number(totalMarginPrice) * 100) /
                                        (Number(totalPriceEstimate) || 1) || 0
                                ).toFixed(2)}
                                %
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Snackbar
                open={openAlertError}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleCloseAlertError}
                autoHideDuration={3000}
                disableWindowBlurListener={true}
            >
                <Alert variant="filled" severity="error">
                    {errorMessage
                        ? errorMessage
                        : '更新に失敗しました。もう一度やり直してください'}
                </Alert>
            </Snackbar>
            <Snackbar
                open={openAlertSuccess}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={handleCloseAlertSuccess}
            >
                <Alert variant="filled" severity="success">
                    更新しました。
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Compare;
