// Lib
import {
    emailFormat,
    minLength,
    onlyNumbers,
    regex,
    required,
    passwordEqualToConfirm,
} from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createUserValidation = (t: TypeOfT) => ({
    email: () => ({
        required: required('email', t),
        validate: {
            emailFormat: emailFormat('email', t),
        },
    }),
    password: () => ({
        required: required('password', t),
        minLength: minLength('password', 6, t),
    }),
    password_confirmation: () => ({
        required: required('password_confirmation', t),
    }),
    worker: () => ({
        required: required('employee', t),
    }),
    role: () => ({
        required: required('role', t),
    }),
});

export default createUserValidation;
