import pick from 'lodash/pick';

/*
 * function convert clientCompanies to dropdown options
 *
 */
export const convertUserConfigToDropdownOptions = (
    object: any = {},
): Array<{
    value: string;
    label: string;
}> => {
    return Object.keys(object).map((key) => ({
        value: key,
        label: object[key] != 'none' ? object[key]  : 'なし',
    }));
};

export const getToken = () => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    return user?.access_token || null;
};


export const getRole = () => {
    let storage = localStorage.getItem('user');
    const user = storage !== null ? JSON.parse(storage) : null;
    return user?.role || null;
};
