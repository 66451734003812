import styled from 'styled-components';

export const StylesTarget = styled.div`
    padding: 15px;
    button {
        background: #215493;
        color: white;
        border-radius: 6px;
        border: none;
        width: 72px;
        height: 32px;
    }

    ,
    .flex-grow-1 {
        flex-grow: 1;
    }

    .date-wrapper {
        display: flex;
        justify-content: center;
        align-content: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
    }

    .title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 15px;
    }

    .btn-back {
        background: white;
        border: 1px solid rgba(33, 84, 147, 0.3);
        color: #225594;
        width: auto;
        height: auto;
        padding: 11px 68px;
        font-size: 16px;
        font-weight: 400;
    }
`;
