import styled from "styled-components";

const CommonStyledSugorkuDialogWrapper = styled.div`
  width: 380px;
  background: #ffffff;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 12px;
  padding: 20px 24px 24px 24px;
  display: flex;
  flex-direction: column;

  .dialog-field-row {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 0.5rem;

    .field-label {
      justify-content: center;
      align-items: center;
      height: 40px;
      font-family: 'Zen Kaku Gothic Antique';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      color: #1c1e21;
      min-width: 30%;
      background-color: #c7dcf5;
      border-bottom: 1px solid #f5f5f5;
      margin-right: 0.5rem;
    }

    .value {
      width: 100%;
      display: flex;
      align-items: center;
      padding-left: 5px;
    }

    .datepicker-wrapper {
      width: 100%;
      position: relative;

      input {
        justify-content: flex-start;
        align-items: flex-start;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px 12px;
        gap: 8px;
        width: 100%;
        height: 38px;
        background: #FFFFFF;
        border: 1px solid #CDD1D5;
        border-radius: 8px;
      }

      .calendar-datepicker-icon {
        position: absolute;
        right: 15px;
        top: 10px;
      }
    }

    .autocomplete-wrapper {
      width: 100%;

      .autocomplete-select-style {
        width: 100%;
        height: 100%;
        margin-right: 16px;
        padding: 0px;
        .MuiFormControl-root .MuiTextField-root {
          height: 38px;
        }
        .MuiOutlinedInput-root {
          height: 100%;
          padding: 0px;
          padding-left: 10px;

          fieldset {
            border-radius: 8px;
          }
        }
      }
    }

    .textfield-style {
      width: 100%;
      height: 100%;
      .MuiOutlinedInput-notchedOutline {
        border-radius: 8px;
      }
      input {
        height: 38px;
        padding-top: 0px;
        padding-bottom: 0px;
        padding-right: 0px;
        width: 210px;
        border-radius: 8px;
      }
    }
  }

  .action-section {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 16px;
    gap: 16px;
    .submit-step-edit-button {
      background-color: #215493;
      color: white;
      padding: 8px 24px;
      border-radius: 4px;
    }
  }
`;

export default CommonStyledSugorkuDialogWrapper;