import Decimal from 'decimal.js';

export const addByDecimal = (a: number, b: number) => {
  const aDecimal = new Decimal(a);
  const bDecimal = new Decimal(b);

  return aDecimal.plus(bDecimal).toNumber();
};

export const minusByDecimal = (a: number, b: number) => {
  const aDecimal = new Decimal(a);
  const bDecimal = new Decimal(b);

  return aDecimal.minus(bDecimal).toNumber();
};

export const timesByDecimal = (a: number, b: number) => {
  const aDecimal = new Decimal(a);
  const bDecimal = new Decimal(b);

  return aDecimal.times(bDecimal).toNumber();
};

export const divideByDecimal = (a: number, b: number) => {
  const aDecimal = new Decimal(a);
  const bDecimal = new Decimal(b);

  return aDecimal.dividedBy(bDecimal).toNumber();
};
