import DatePicker from 'react-datepicker';
import moment from 'moment';
import classNames from 'classnames';
import { get } from 'lodash';

import calendarIcon from '../../../../assets/icons/calendar.png';
import { DatePickerWrapper } from '../style';

const DatePickerField = ({ name, locale = 'ja', formik, placeholder, disabled = false }) => {
    const { values, errors, touched } = formik;

    return (
        <DatePickerWrapper>
            <div
                className={classNames(
                    get(touched, name) && !!get(errors, name) ? 'error' : '',
                    'innerItem',
                )}
            >
                <img src={calendarIcon} />
                <DatePicker
                    locale={locale}
                    disabled={disabled}
                    placeholder={placeholder}
                    dateFormat="yyyy/MM/dd"
                    value={
                        get(values, name) ? new Date(get(values, name)) : null
                    }
                    selected={
                        get(values, name) ? new Date(get(values, name)) : null
                    }
                    onChange={(date) => {
                        if (!date) {
                            if (name.includes('.')) {
                                const splitPath = name.split('.');
                                const path = splitPath[0];
                                const temp = { ...values[path] };
                                temp[splitPath[1]] = undefined;
                                formik.setFieldValue(path, temp);
                            } else {
                                formik.setFieldValue(name, undefined);
                            }
                        } else {
                            const getDate = moment(date).format('YYYY/MM/DD');
                            const getCurrentTime = moment().format('HH:mm:ss');
                            const value = moment(
                                `${getDate} ${getCurrentTime}`,
                                'YYYY/MM/DD HH:mm:ss',
                            ).format();

                            formik.setFieldValue(name, new Date(value));

                            if (name.includes('.')) {
                                const splitPath = name.split('.');
                                const path = splitPath[0];
                                const temp = { ...values[path] };

                                temp[splitPath[1]] = value;
                                formik.setFieldValue(path, temp);
                            } else {
                                formik.setFieldValue(name, new Date(value));
                            }
                        }
                    }}
                    portalId="html"
                />
            </div>
            {get(touched, name) && !!get(errors, name) && (
                <p className="datePickerErrorText">{get(errors, name)}</p>
            )}
        </DatePickerWrapper>
    );
};

export default DatePickerField;
