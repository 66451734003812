import styled from "styled-components";

export const StyledUserListTable = styled.div`
    .classSticky {
      position: sticky;
      left: 0;
      background: white;
      border-right: 2px solid black;
      z-index: 1;
    }
    .ant-picker-input > input {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.87);
    }
    .ant-picker-suffix {
        color: rgba(0, 0, 0, 0.5);
    }
`;

export const StylesStockNotes = styled.div`
    background-color: #fff;
    margin: 28px;
    padding: 25px 28px;
    border-radius: 8px;
    .vehicle-title {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .input-search {
        border: 1px solid #cdd1d5;
        border-radius: 8px;
        margin-bottom: 14.5px;
        padding-left: 14px;
    }
`;
