import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { validation } from './schema';
import { ReceivedProps, IFormData } from '../type';
import InternalOJTService from 'services/internalOJTService';
import { useEffect, useState } from 'react';

const useCreateInternalOJT = (props: ReceivedProps) => {
    const [internalOJT, setInternalOJT] = useState<any>({});
    const [currentData, setCurrenData] = useState<File>();
    const [fileData, setFileData] = useState<string | null>();
    const [typeFile, setTypeFile] = useState("");

    const navigate = useNavigate();
    const initialValues: IFormData = {
        subject: '',
        content: '',
        files: '',
    };
    const { id } = useParams();

    const handleCreateInternalOJT = async (data: IFormData) => {
        try {
            const formData = new FormData();
            formData.append('subject', data.subject);
            formData.append('content', data.content);
            formData.append('files[]', data.files);
            if (id) {
                const files = new FormData();
                files.append('files[]', data.files);
                await InternalOJTService.updateInternalOJT(id, formData);
                if (!data.files.signed_id) {
                    await InternalOJTService.deleteInternalOJTFile(id, {
                        signed_id: internalOJT?.files?.[0]?.signed_id,
                    });
                    await InternalOJTService.addInternalOJTFile(id, files);
                }
            } else {
                await InternalOJTService.createInternalOJT(formData);
            }
            navigate('/internal-ojt');
        } catch (error) {}
    };

    const getInternalOJTDetail = async () => {
        if (!id) return;
        try {
            const { internal_ojt }: any =
                await InternalOJTService.getInternalOJTDetail(id);
            setInternalOJT(internal_ojt);
            formik.resetForm({
                values: {
                    subject: internal_ojt?.subject,
                    content: internal_ojt.content,
                    files: {
                        ...internal_ojt.files[0],
                        name: internal_ojt?.files?.[0]?.file_name,
                    },
                },
            });
        } catch (error) {}
    };
    const formik = useFormik({
        initialValues,
        validationSchema: validation,
        onSubmit: (values) => handleCreateInternalOJT(values),
    });

    const handleFileUpload = (e) => {
        formik.setFieldValue('files', e.target.files[0]);
    };

    const handleViewFile = async (values) => {
        try {
            setCurrenData(values);
            setTypeFile(values?.type || values?.file_type)
            const response = await fetch(values?.image_path);
            const data = await response.blob();
            const file = window.URL.createObjectURL(data);
            values?.image_path ? setFileData(file) : setFileData(URL.createObjectURL(values));
        } catch (error) {}
    };


    useEffect(() => {
        getInternalOJTDetail();
    }, []);

    return {
        ...props,
        formik,
        navigate,
        handleFileUpload,
        handleViewFile,
        currentData,
        fileData,
        setFileData,
        id,
        typeFile,
    };
};
export type Props = ReturnType<typeof useCreateInternalOJT>;

export default useCreateInternalOJT;
