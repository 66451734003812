import { useState, useCallback, useRef, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import ProjectService from 'services/projectServiceSP';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { validation } from './schema';
import WorkingLogAdditionalsService from 'services/workinglogAdditionals';
import { ObjectToFormData } from 'utils/helper/helpers';

export type ReceivedProps = Record<string, never>;

const useCreateJob = (props: ReceivedProps) => {
    const [fileUpload, setFileUpload] = useState<any[]>([]);
    const [hasUploadedImagesDeleted, setHasUploadedImagesDeleted] =
        useState<boolean>(false);
    const navigate = useNavigate();
    const onDrop = useCallback(async (acceptedFiles) => {
        setFileUpload((preState) => [...preState, ...acceptedFiles]);
    }, []);
    const [searchParams] = useSearchParams();
    const [signatureChange, setSignatureChange] = useState(false);
    const [projectOptions, setProjectOptions] = useState<any[]>([]);
    const [signatureBase64, setSignatureBase64] = useState();
    const signatureRef: any = useRef();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        onDrop,
    });
    const [initialValues, setInitialValues] = useState({
        is_agree_additional: false,
        project_id: {
            label: '',
            value: '',
        },
        name: '',
        note: '',
    });
    const { id } = useParams();
    const project_id = searchParams?.get('project_id');
    const handleCreateWorkinglogAdditonnal = async (values) => {
        try {
            const params = ObjectToFormData({
                ...values,
                project_id: values?.project_id?.value,
                signature: signatureRef.current.getTrimmedCanvas().toDataURL(),
            });
            fileUpload?.forEach((file) => {
                params.append('working_log_images[]', file);
            });
            await WorkingLogAdditionalsService.createWorkingLogAdditionnal(
                params,
            );
            navigate('/sp/jobs');
        } catch (error) {
            //
        }
    };

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validation,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleCreateWorkinglogAdditonnal(values);
        },
    });
    const getListProjects = async ({ page = 1, per_page = 0, data = {} }) => {
        try {
            const { projects }: any = await ProjectService.getProjectLists({
                page,
                per_page,
                ...data,
            });
            const options = projects?.map((project) => ({
                label: project.name,
                value: project.id,
            }));
            formik.setFieldValue('project_id', options[0]);
            setProjectOptions(options);
        } catch (err) {}
    };

    const handleRemoveUploadedImage = (signed_id) => {
        setFileUpload((prev) => {
            const newFilesArray = [...prev];
            let target = newFilesArray.find(
                (item) => item.signed_id === signed_id,
            );
            const targetIndex = newFilesArray.findIndex(
                (item) => item.signed_id === signed_id,
            );
            target = { ...target, type: 'destroy' };
            newFilesArray[targetIndex] = target;
            return newFilesArray;
        });

        if (!hasUploadedImagesDeleted) {
            setHasUploadedImagesDeleted(true);
        }
    };

    const removeImage = (idx) => {
        let current = [...fileUpload];
        current = current.filter((item, index) => index !== idx);
        setFileUpload(current);
    };

    const handleUploadFile = async (files) => {
        let current = fileUpload ? [...fileUpload] : [];
        if (files.length > 0) {
            if (current) {
                current.push({ file: files[0], type: 'upload' });
            } else {
                current = [{ file: files[0], type: 'upload' }];
            }
            setFileUpload(current);
        }
    };

    const fetchWorkingLogsAdditionalDetail = async () => {
        if(!id || !project_id) return
        try {
            const { working_log }: any =
                await WorkingLogAdditionalsService.getWorkingLogAdditionnalDetail(
                    Number(id),
                    { project_id },
                );
            setInitialValues({
                is_agree_additional: working_log?.is_agree_additional,
                project_id: {
                    label: working_log?.project?.id,
                    value: working_log?.project?.name,
                },
                name: working_log?.name,
                note: working_log?.note,
            });
            setFileUpload(working_log?.working_log_images?.[0]?.images);
            setSignatureBase64(working_log?.signature_file?.image_path);
        } catch (error) {
            console.log(error);
        }
    };
    const onCreate = () => {};

    useEffect(() => {
        getListProjects({ page: 1, per_page: 0 });
        fetchWorkingLogsAdditionalDetail();
    }, []);

    useEffect(() => {}, [signatureChange]);
    return {
        ...props,
        onCreate,
        signatureRef,
        fileUpload,
        setFileUpload,
        handleUploadFile,
        removeImage,
        handleRemoveUploadedImage,
        acceptedFiles,
        getRootProps,
        getInputProps,
        setSignatureChange,
        formik,
        projectOptions,
        navigate,
        id,
        signatureBase64,
    };
};

export type Props = ReturnType<typeof useCreateJob>;

export default useCreateJob;
