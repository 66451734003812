import fetch from './api/config';

const API = {
    GET_TOP_PAGE_NEWS: () => `/sp/top_page/news`,
    GET_TOP_PAGE_REQUEST: () => `/sp/top_page/requests`,
    GET_TOP_PAGE_PROGRESS: () => `/sp/top_page/progress`,
};

export default class TopPageService {
    static getTopPageNews = () =>
        fetch.get(API.GET_TOP_PAGE_NEWS(), {});
    static getTopPageProgress = () => fetch.get(API.GET_TOP_PAGE_PROGRESS(), {});
    static getTopPageRequest = () => fetch.get(API.GET_TOP_PAGE_REQUEST(), {});
}
