// Lib
import { required } from '../validator';

// Type
import { TypeOfT } from 'utils/type/translations/Translation.type';

const createProjectValidation = (t: TypeOfT) => ({
    name: () => ({
        required: required('project_name', t),
    }),
    business_type: () => ({
        required: required('business_type', t),
    }),
    client_company_id: () => ({
        required: required('client_company_id', t),
    }),
    manager_worker_id: () => ({
        required: required('manager_worker_id', t),
    }),
    estimate_assigned_worker_id: () => ({
        required: required('estimate_assigned_worker_id', t),
    }),
    start_at: () => ({
        required: required('start_at', t),
    }),
    end_at: () => ({
        required: required('end_at', t),
    }),
    location_address: () => ({
        required: required('location_address', t),
    }),
    location_zipcode: () => ({
        required: required('location_zipcode', t),
    }),
    status: () => ({
        required: required('status', t),
    }),
});

export default createProjectValidation;
