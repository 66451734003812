import { useEffect, useState } from 'react';
import './WorkingReport.css';
import workingLogService from 'services/workingLogService';
import { formatDateTime } from 'utils/helper/dateHelper';
import calendarIcon from '../../../../assets/icons/calendar.png';
import DatePicker from 'react-datepicker';
import Dropdown from 'react-dropdown';
import { Controller, useForm } from 'react-hook-form';
import {
    Table,
    Paper,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Checkbox,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import createWorkingReportValidation from 'utils/validation/registers/workingReports/createWorkingReportValidation';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setLogs } from 'store/workingReport/workingReport';
import { RootState } from 'store';
import PlanServiceSP from 'services/planServiceSP';
import WorkingLogServiceSP from 'services/workingLogServiceSP';
import CloseIcon from '@mui/icons-material/Close';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#C7DCF5',
        color: '#1C1E21',
    },
    [`&.${tableCellClasses.body}`]: {
        fontFamily: 'Zen Kaku Gothic Antique',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '24px',
        color: '#1C1E21',
        padding: '6px 16px',
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    border: '1px solid #CDD1D5',
    '&:nth-of-type(odd)': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const checkBoxStyles = {
    color: '#1C1E21',
    width: '24px',
    height: '24px',
};

const WorkingReportLogSP = ({ id, project_id, onClose, onAdd }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        register,
        control,
        handleSubmit,
        setValue,
        reset,
        getValues,
        formState: { errors },
    } = useForm();
    const { logs = [] as any[] } = useSelector(
        (state: RootState) => state.workingReport,
    );
    const [loadedLogs, setLoadedLogs] = useState<any[]>([]);
    const [selectedLogs, setSelectedLogs] = useState<any[]>([]);
    const [plans, setPlans] = useState<any[]>([]);

    const onSubmit = () => {
        onAdd();
        dispatch(setLogs(_.uniqWith(_.concat(selectedLogs, logs), _.isEqual)));
    };

    const footer = (
        <div className="flex justify-end mt-[24px] mb-[28px]">
            <div className="flex justify-end">
                <button
                    type="button"
                    // TODO: this button is register and sent, intergrate more API
                    className="w-[149px] h-[36px] bg-main rounded-[6px] text-white whitespace-nowrap text-center font-medium"
                    onClick={onSubmit}
                >
                    追加
                </button>
            </div>
        </div>
    );

    const covertOptions = (plans) => {
        return plans?.map((plan: any) => {
            return {
                label: plan?.name,
                value: plan?.id,
            };
        });
    };

    const getPlans = async () => {
        if (project_id) {
            const responsePlan: any = await PlanServiceSP.getListPlans({
                page: 1,
                per_page: 0,
                project_id: project_id,
            });
            if (responsePlan !== null && responsePlan !== undefined) {
                setPlans(responsePlan.plans);
            }
        }
    };

    const getListWorkingLog = async (page = 1, data = {}) => {
        try {
            const response: any = await WorkingLogServiceSP.getListWorkingLogs({
                page,
                ...data,
            });
            if (response !== null && response !== undefined) {
                setLoadedLogs(response?.working_logs || []);
            }
        } catch (err) {
            console.log('err', err);
        }
    };

    const handleSearch = () => {
        const { start_at, end_at, plan_id, free_word } = getValues();
        getListWorkingLog(1, {
            project_id: project_id,
            start_at: start_at || '',
            end_at: end_at || '',
            plan_id: plan_id?.value || '',
            free_word: free_word || '',
        });
    };

    useEffect(() => {
        getPlans();
    }, []);

    useEffect(() => {
        setSelectedLogs(logs);
    }, [logs]);

    return (
        <div className="bg-transparents flex flex-col rounded-[12px] justify-center items-end h-full w-full">
            <div className="bg-[#ffffff] flex flex-col rounded-[12px] justify-start items-center px-[20px] mx-auto h-[95%] fixed bottom-0 right-0 left-0 w-full overflow-auto">
                <div className="modal-create-user-title !h-[70px] !min-h-[70px] d-flex flex-row relative">
                    作業記録追加
                    <CloseIcon
                        className="absolute right-0 top-[23px]"
                        fontSize="small"
                        onClick={onClose}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <form className="w-full">
                    <div className="w-full flex flex-col overflow-auto">
                        <div className="w-full">
                            <div>
                                <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                    作業日
                                </p>
                                <div className="flex items-center justify-between gap-x-[8px] mb-[16px]">
                                    <div className="flex flex-col">
                                        <Controller
                                            // rules={{ required: true }}
                                            name="start_at"
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    <div className="relative">
                                                        <DatePicker
                                                            {...field}
                                                            {...register(
                                                                'start_at',
                                                            )}
                                                            className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                                            selected={getValues(
                                                                'start_at',
                                                            )}
                                                            onChange={(
                                                                date,
                                                            ) => {
                                                                setValue(
                                                                    'start_at',
                                                                    date,
                                                                );
                                                            }}
                                                            dateFormat="yyyy/MM/dd"
                                                            autoComplete="off"
                                                            locale="ja"
                                                        />
                                                        <img
                                                            className="absolute-bottom"
                                                            width={'16px'}
                                                            height={'16px'}
                                                            src={calendarIcon}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                        />
                                    </div>
                                    <span className="flex-0 pb-[6px] text-[#1C1E21]">
                                        ~
                                    </span>
                                    <div className="flex flex-col">
                                        <Controller
                                            name="end_at"
                                            // rules={{ required: true }}
                                            control={control}
                                            render={({ field }) => (
                                                <div className="flex-1 possition-relative">
                                                    <label htmlFor="end_at"></label>
                                                    <DatePicker
                                                        {...field}
                                                        {...register('end_at')}
                                                        className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                                        selected={getValues(
                                                            'end_at',
                                                        )}
                                                        onChange={(date) => {
                                                            setValue(
                                                                'end_at',
                                                                date,
                                                            );
                                                        }}
                                                        dateFormat="yyyy/MM/dd"
                                                        autoComplete="off"
                                                        locale="ja"
                                                    />
                                                    <img
                                                        className="absolute-bottom"
                                                        width={'16px'}
                                                        height={'16px'}
                                                        src={calendarIcon}
                                                    />
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <Controller
                                    name="plan_id"
                                    // rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                        <div className="flex-1">
                                            <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                                工程
                                            </p>
                                            <Dropdown
                                                {...register(
                                                    'plan_id',
                                                    createWorkingReportValidation(
                                                        t,
                                                    ).manager_worker_id(),
                                                )}
                                                {...field}
                                                className="dropdown-create-user1 height36"
                                                controlClassName="dropdown-control-user height36"
                                                options={covertOptions(plans)}
                                                placeholder=""
                                            />
                                        </div>
                                    )}
                                />
                            </div>
                        </div>
                        <div className="w-full mt-[16px]">
                            <Controller
                                name="free_word"
                                control={control}
                                render={({ field }) => (
                                    <div className="w-full flex flex-col">
                                        <p className="text-[14px] mb-[4px] font-medium text-[#344054]">
                                            作業名
                                        </p>
                                        <input
                                            {...register('free_word')}
                                            {...field}
                                            className="w-full border-[1px] border-[#CDD1D5] rounded-[8px] px-[12px] py-[6px]"
                                        />
                                    </div>
                                )}
                            />
                            <div className="justify-end w-full flex items-end mt-[20px]">
                                <button
                                    type="button"
                                    className="w-[149px] h-[36px] border-main border-[1px] bg-main text-white rounded-[6px] text-center font-medium"
                                    onClick={handleSearch}
                                >
                                    検索
                                </button>
                            </div>
                            {errors.submitted_at && (
                                <span className="error">
                                    {errors.submitted_at.message?.toString()}
                                </span>
                            )}
                        </div>
                        <span className="font-bold text-[16px] mt-[2px]">
                            作業記録
                        </span>
                        <div className="table-workers d-flex flex-column mt-[16px]">
                            <div className="table-content d-flex flex-column">
                                <TableContainer
                                    component={Paper}
                                    table-layout={'auto'}
                                >
                                    <Table aria-label="customized table">
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    <Checkbox
                                                        checked={
                                                            loadedLogs.length ===
                                                                selectedLogs?.length &&
                                                            selectedLogs?.length >
                                                                0
                                                        }
                                                        onChange={() => {
                                                            setSelectedLogs(
                                                                loadedLogs.length ===
                                                                    selectedLogs?.length
                                                                    ? []
                                                                    : loadedLogs,
                                                            );
                                                        }}
                                                        icon={
                                                            <CheckBoxOutlineBlankOutlinedIcon
                                                                sx={
                                                                    checkBoxStyles
                                                                }
                                                            />
                                                        }
                                                        checkedIcon={
                                                            <CheckBoxOutlinedIcon
                                                                sx={
                                                                    checkBoxStyles
                                                                }
                                                            />
                                                        }
                                                    />
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    工程
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    作業名
                                                </StyledTableCell>
                                                <StyledTableCell
                                                    className="text-overflow"
                                                    align="center"
                                                >
                                                    作業日
                                                </StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {loadedLogs !== null &&
                                                loadedLogs?.map(
                                                    (record, ind) => (
                                                        <StyledTableRow
                                                            key={ind}
                                                        >
                                                            <StyledTableCell
                                                                className="text-overflow"
                                                                align="center"
                                                                // scope="row"
                                                            >
                                                                <Checkbox
                                                                    onChange={() => {
                                                                        const check =
                                                                            selectedLogs?.find(
                                                                                (
                                                                                    log,
                                                                                ) =>
                                                                                    log?.id ===
                                                                                    record?.id,
                                                                            );
                                                                        setSelectedLogs(
                                                                            check
                                                                                ? selectedLogs?.filter(
                                                                                      (
                                                                                          item,
                                                                                      ) =>
                                                                                          !_.isEqual(
                                                                                              item?.id,
                                                                                              record?.id,
                                                                                          ),
                                                                                  )
                                                                                : [
                                                                                      ...selectedLogs,
                                                                                      record,
                                                                                  ],
                                                                        );
                                                                    }}
                                                                    checked={
                                                                        !!selectedLogs?.find(
                                                                            (
                                                                                log,
                                                                            ) =>
                                                                                log?.id ===
                                                                                record?.id,
                                                                        )
                                                                    }
                                                                    icon={
                                                                        <CheckBoxOutlineBlankOutlinedIcon
                                                                            sx={
                                                                                checkBoxStyles
                                                                            }
                                                                        />
                                                                    }
                                                                    checkedIcon={
                                                                        <CheckBoxOutlinedIcon
                                                                            sx={
                                                                                checkBoxStyles
                                                                            }
                                                                        />
                                                                    }
                                                                />
                                                                {/* )} */}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow"
                                                                align="center"
                                                            >
                                                                {
                                                                    record?.plan
                                                                        ?.name
                                                                }
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow"
                                                                align="center"
                                                            >
                                                                {record?.name}
                                                            </StyledTableCell>
                                                            <StyledTableCell
                                                                className="text-overflow"
                                                                align="center"
                                                            >
                                                                {formatDateTime(
                                                                    record?.worked_at,
                                                                    'YYYY/MM/DD',
                                                                )}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    ),
                                                )}
                                            {/* {loadedLogs?.length === 0 && (
                                                <StyledTableRow>
                                                    <StyledTableCell
                                                        className="text-overflow"
                                                        align="center"
                                                        colSpan={4}
                                                    >
                                                        データなし
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )} */}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        </div>
                    </div>
                    {footer}
                </form>
            </div>
        </div>
    );
};

export default WorkingReportLogSP;
