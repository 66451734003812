import styled from 'styled-components';
import vector from '../../../assets/icons/Vector.png';

export const StylesWorkingTimesManagementCreate = styled.div`
    margin: 22px 28px;
    background-color: #fff;
    border-radius: 8px;
    padding: 34px 29px;
    .working_times--title {
        color: #000;
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    .react-datepicker-wrapper {
        input {
            height: 30px;
            background-color: #f3f3f3;
        }
    }
    .ant-empty-description {
        display: none;
    }
    .ant-table-container table > thead > tr:first-child th:first-child {
        border-top-left-radius: 8px !important;
        border-bottom-left-radius: 8px !important;
    }
    .ant-table-container table > thead > tr:last-child th:last-child {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
    }
    .ant-table-thead {
        .ant-table-cell {
            background-color: #c7dcf5;
            color: #000;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            &::before {
                display: none;
            }
        }
    }
    .filterBoxTop {
        .wrapSelectField {
            margin: 0;
            width: 300px;
            max-width: 100%;

            .MuiFormControl-root {
                .MuiFormHelperText-root {
                    margin-left: 0;
                    color: #d32f2f !important;
                }
            }

            &.multipleSelect {
                .MuiOutlinedInput-root.MuiInputBase-root {
                    height: 30px;

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        right: 10px;
                        top: 12px;
                        width: 13px;
                        height: 13px;
                        background: url('${vector}') no-repeat center;
                        transform: rotate(90deg);
                        background-size: contain;
                        pointer-events: none;
                    }

                    .MuiAutocomplete-tag {
                        height: 24px;
                    }

                    .MuiChip-root {
                        .MuiSvgIcon-root {
                            display: initial;
                            font-size: 16px;
                        }
                    }
                }
            }

            .MuiSelect-select {
                font-weight: 400;
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
            }

            .MuiOutlinedInput-root.MuiInputBase-root {
                height: 30px;
                background: #f3f3f3;
                position: relative;

                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    right: 10px;
                    top: 8px;
                    width: 13px;
                    height: 13px;
                    background: url('${vector}') no-repeat center;
                    transform: rotate(90deg);
                    background-size: contain;
                    pointer-events: none;
                }

                fieldset {
                    border: none;
                }

                .MuiSvgIcon-root {
                    display: none;
                }
            }
            .MuiAutocomplete-root {
                .MuiInputBase-root {
                    padding: 0px 15px;
                }
            }
            .MuiAutocomplete-input {
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
            }
            .MuiOutlinedInput-root {
                .MuiAutocomplete-input {
                    padding: 0;
                }
            }
        }
        .fieldLabel {
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            width: 110px;
            color: #1c1e21;
            display: block;
            margin: 0 0 4px;
        }

        .wrapSelectField {
            width: 100%;
        }

        .wrapTextField {
            width: 400px;
            margin-right: 10px;
            .MuiInputBase-input {
                padding: 3.5px 12px !important;
            }

            textarea {
                background: #f3f3f3;
                border-radius: 4px;
                min-height: 60px;
                height: 60px !important;
                color: #1c1e21;
                font-weight: 400;
                font-size: 14px;
            }

            .MuiOutlinedInput-root.MuiInputBase-formControl {
                input.MuiInputBase-input {
                    background: #f3f3f3;
                    border-radius: 4px;
                    border: 0;
                }
            }
        }
    }
`;
