import Modal from 'react-modal';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        backgroundColor: '#FFFFFF',
        position: 'fixed',
        maxHeight: '95vh',
        padding: '0px',
        borderRadius: '12px',
    },
};

interface IWorkerErrorPopup {
    isOpen: boolean;
    message?: string;
    onClose: () => void;
}

const WorkerErrorPopup = (props: IWorkerErrorPopup) => {
    return (
        <Modal
            isOpen={props?.isOpen}
            style={customStyles}
            contentLabel="Worker Error"
        >
            <div className="modal-create-user d-flex flex-column">
                <div
                    style={{ color: 'red' }}
                    className="modal-create-user-title d-flex flex-row"
                >
                    {props?.message?.toString()}
                </div>
                <div
                    className="modal-create-user-buttons d-flex flex-row"
                    style={{ marginTop: '2rem', justifyContent: 'center' }}
                >
                    <button
                        onClick={props?.onClose}
                        className="modal-create-user-button2 d-flex flex-row"
                    >
                        はい
                    </button>
                </div>
            </div>
        </Modal>
    );
};
export default WorkerErrorPopup;
