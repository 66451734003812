import axios from "axios";
const lineToken = localStorage.getItem('line:accessToken');

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_SERVER_API_LINE,
  headers: {
    'content-type': 'application/json',
    'Device-Type': 'Webapp',
    version: 9999,
    Authorization: `Bearer ${lineToken}`,
  }
});

axiosClient.interceptors.response.use(
  (response: any) => {
    return response
  },
  (error: any) => {
    // Handle errors
    let errorMessage = error
    if (error.response) {
      if (error?.response?.status === 401) {
        localStorage.removeItem('line:accessToken')
        window.location.href = `${process.env.REACT_APP_SERVER_API_LINE}/auth/line`
      }
      errorMessage =
        error.response.data?.message || error.response.data?.messages
    }
    const err = new Error(errorMessage)
    err.name = ''
    throw err
  }
);

class LineCustomerApi {
  updateInfo = (body: FormData) => {
    const url = `${process.env.REACT_APP_SERVER_API_LINE_2}/customers`;
    return axiosClient.put(url, body);
  };
  getInfo = () => {
    const url = `${process.env.REACT_APP_SERVER_API_LINE_2}/customers`;
    return axiosClient.get(url);
  }
}

const lineCustomerApi = new LineCustomerApi();

export default lineCustomerApi;