import useWorkingTimesManagementsCreate, { Props } from './hook';
import { StylesWorkingTimesManagementCreate } from './styled';
import DatePickerField from 'pages/schedules/FormField/DatePicker';
import DateTimePickerField from 'pages/schedules/FormField/DateTimePicker';
import {
    Button,
    TextField,
    Autocomplete,
    Grid,
    Box,
    CircularProgress,
} from '@mui/material';
import DatePicker, { registerLocale } from 'react-datepicker';
import ja from 'date-fns/locale/ja';
registerLocale('ja', ja);

const WorkingTimesManagementCreateLayout = ({
    formik,
    workerOptions,
    navigate,
    loading,
    isDetail,
}: Props) => {
    return (
        <StylesWorkingTimesManagementCreate>
            <Box
                sx={{
                    '.react-datepicker__input-container': {
                        input: {
                            color: isDetail && 'rgba(0, 0, 0, 0.38)',
                        },
                    },
                }}
            >
                <p className="working_times--title mb-0">勤怠管理</p>
                {loading ? (
                    <div className="flex justify-center items-center mt-4 max-w-[400px] min-h-[354px]">
                        <CircularProgress size={30} />
                    </div>
                ) : (
                    <form onSubmit={formik.handleSubmit}>
                        <div className="filterBoxTop mt-4">
                            <div className="flex items-center">
                                <p className="fieldLabel  !w-[102px]">名前</p>
                                <div className="wrapSelectField !w-[400px]">
                                    <Autocomplete
                                        disabled={isDetail}
                                        value={formik.values.worker_id}
                                        onChange={(event, newValue) => {
                                            formik.setFieldValue(
                                                'worker_id',
                                                newValue,
                                            );
                                        }}
                                        className="max-w-[400px]"
                                        noOptionsText="該当なし"
                                        options={workerOptions}
                                        getOptionLabel={(option) =>
                                            option.label
                                        }
                                        renderInput={(params) => (
                                            <TextField {...params} />
                                        )}
                                    />
                                </div>
                            </div>
                            <div className="flex mt-4 items-center">
                                <p className="fieldLabel !w-[102px]">日付</p>
                                <div className="w-[400px]">
                                    <DatePickerField
                                        name="submit_date"
                                        placeholder=""
                                        disabled={isDetail}
                                        locale="ja"
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="flex mt-4 items-center">
                                <p className="fieldLabel !w-[102px]">
                                    出勤時間
                                </p>
                                <div className="w-[400px]">
                                    <DateTimePickerField
                                        name="start_time"
                                        placeholder=""
                                        disabled={isDetail}
                                        locale="ja"
                                        dateFormat="h:mm aa"
                                        showTimeSelectOnly
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center mt-4">
                                <p className="fieldLabel !w-[102px]">位置</p>
                                <div className="wrapTextField">
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        disabled={isDetail}
                                        name="checkin_location"
                                        type="text"
                                        variant="outlined"
                                        onChange={formik.handleChange}
                                        value={formik.values.checkin_location}
                                        error={
                                            formik.touched.checkin_location &&
                                            !!formik.errors.checkin_location
                                        }
                                        helperText={
                                            formik.touched.checkin_location &&
                                            formik.errors.checkin_location
                                        }
                                    />
                                </div>
                            </div>
                            <div className="flex mt-4 items-center">
                                <p className="fieldLabel !w-[102px]">
                                    退勤時間
                                </p>
                                <div className="w-[400px]">
                                    <DateTimePickerField
                                        name="end_time"
                                        disabled={isDetail}
                                        placeholder=""
                                        locale="ja"
                                        dateFormat="h:mm aa"
                                        showTimeSelectOnly
                                        formik={formik}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center mt-4">
                                <p className="fieldLabel !w-[102px]">位置</p>
                                <div className="wrapTextField">
                                    <TextField
                                        fullWidth
                                        hiddenLabel
                                        name="checkout_location"
                                        type="text"
                                        disabled={isDetail}
                                        variant="outlined"
                                        onChange={formik.handleChange}
                                        value={formik.values.checkout_location}
                                        error={
                                            formik.touched.checkout_location &&
                                            !!formik.errors.checkout_location
                                        }
                                        helperText={
                                            formik.touched.checkout_location &&
                                            formik.errors.checkout_location
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                        <div
                            className="modal-create-user-buttons !w-[300px] d-flex flex-row"
                            style={{ marginTop: '50px' }}
                        >
                            <button
                                onClick={() =>
                                    navigate('/working_times_management')
                                }
                                className="modal-create-user-button1 d-flex flex-row !px-[3px] !py-[9px]"
                            >
                                戻る
                            </button>
                            {!isDetail && (
                                <button className="modal-create-user-button2 d-flex flex-row">
                                    登録
                                </button>
                            )}
                        </div>
                    </form>
                )}
            </Box>
        </StylesWorkingTimesManagementCreate>
    );
};

const WorkingTimesManagementCreate = (props: any) => {
    return (
        <WorkingTimesManagementCreateLayout
            {...useWorkingTimesManagementsCreate(props)}
        />
    );
};

export default WorkingTimesManagementCreate;
