const paymentValidation = () => ({
    cardNumber: () => ({
        required: 'カード番号を入力してください。',
        minLength: {
            value: 16,
            message: 'カード番号の形式が正しくありません。'
        }
    }),
    holderName: () => ({
        required: 'カード名義を入力してください。',
    }),
    dateExpired : () =>({
        required: '有効期限を入力してください。',
        minLength: {
            value: 5,
            message: '有効期限を入力してください。'
        }
    }),
    securityCode: () => ({
        required: 'セキュリティコードを入力してください。',
        minLength: {
            value: 3,
            message: 'セキュリティコードの形式が正しくありません。'
        }
    }),
    date: () => ({
        required: '日付を入力してください。',
    }),
    customer: () => ({
        required: 'お客様を入力してください。',
    }),
    address2: () => ({
        required: '住所を選択してください。',
    }),
    callNum: () => ({
        required: '請求番号を選択してください。',
    }),
});

export default paymentValidation;
