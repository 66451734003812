import styled from 'styled-components';

export const SPCustomerInfoFormStyles = styled.div`
    padding: 10px;
    form {
        width: 100%;
    }

    .textBox {
        width: 100%;
        gap: 8px;
        padding: 10px 12px;
        width: 100%;
        height: 36px;
        background: #ffffff;
        border: 1px solid #cdd1d5;
        border-radius: 2px;
    }

    .searchAddressBtn {
        width: 45%;
        background: #215493;
        border-radius: 8px;
        border-color: #215493;
        color: #ffffff;
        outline: none;
        border: none;
        margin-left: 15px;
    }
`;
