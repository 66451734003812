export const loginValidation = (values: any) => {
  let errors = {};

  if (!values.email) {
    errors = {
      ...errors,
      email: 'Eメールは必須です。'
    }
  }

  if (!values.password) {
    errors = {
      ...errors,
      password: 'パスワードは必須です。'
    }
  }

  return errors;
}