import useSPStockNotes, { Props } from './hook';
import { ReceivedProps, DataType } from './type';
import { StylesStockNotesSP } from './styled';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { Box, Button } from '@mui/material';
import ViewIcon from 'assets/icons/view_ic.svg';
import Share from 'assets/icons/share.svg';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Worker, Viewer, OpenFile } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { getFilePlugin, RenderDownloadProps } from '@react-pdf-viewer/get-file';
import { SpecialZoomLevel } from "@react-pdf-viewer/core";
const StockNotesSPLayout = ({
    navigate,
    handleShare,
    stockNotesList,
    pdfData,
    currentData,
    handleViewPdf,
    setPdfData,
}: Props) => {
    const getFilePluginInstance = getFilePlugin({
        fileNameGenerator: (file: OpenFile) => {
            const fileName = file.name.substring(
                file.name.lastIndexOf('/') + 1,
            );
            return currentData?.name!;
        },
    });
    const { Download } = getFilePluginInstance;
    const columns: ColumnsType<DataType> = [
        {
            title: 'タイトル',
            dataIndex: 'name',
            key: 'name',
            render: (text) => <span className="vehicle-name">{text}</span>,
            ellipsis: true,
        },
        {
            title: '',
            key: 'action',
            align: 'right',
            className: 'custom-column',
            width: 80,
            render: (_, record) => (
                <div className="flex justify-end items-center gap-[8px]">
                    <Box
                        onClick={() => handleViewPdf(record)}
                        sx={{
                            backgroundColor: '#215493',
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img src={ViewIcon} />
                    </Box>
                    <Box
                        onClick={() => handleShare(record)}
                        sx={{
                            backgroundColor: '#215493',
                            width: 24,
                            height: 24,
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <img src={Share} />
                    </Box>
                </div>
            ),
        },
    ];

    return (
        <StylesStockNotesSP>
            {pdfData && (
                <Dialog
                    onClose={() => setPdfData(null)}
                    sx={{
                        width: '100%',
                        '.MuiPaper-root': {
                            width: '100%',
                            height: '100%',
                            margin: '0px',
                            maxHeight: 'unset'
                        },
                        '.MuiDialogContent-root': {
                            padding: '0px 0px 20px 0px',
                        },
                        '.rpv-core__inner-container': {
                            paddingBottom: '30px !important',
                        },
                    }}
                    open={!!pdfData}
                >
                    <DialogTitle className='!py-[16px] !px-[9px]'>
                        <button
                            type="button"
                            className="border-main border-[1px] text-sm px-[10px] py-[5px] rounded-[6px] whitespace-nowrap"
                            style={{ color: '#215493' }}
                            onClick={() => setPdfData(null)}
                        >
                            閉じる
                        </button>
                        <Download>
                            {(props: RenderDownloadProps) => (
                                <button
                                    type="button"
                                    className="border-main ml-2 bg-main border-[1px] text-white text-sm px-[10px] py-[5px] rounded-[6px] whitespace-nowrap"
                                    // onClick={handleDownloadPdf}
                                    onClick={props.onClick}
                                >
                                    ダウンロード
                                </button>
                            )}
                        </Download>
                    </DialogTitle>
                    <DialogContent>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer
                                plugins={[getFilePluginInstance]}
                                fileUrl={pdfData}
                                defaultScale={SpecialZoomLevel.PageWidth}
                            />
                        </Worker>
                    </DialogContent>
                </Dialog>
            )}

            <p className="vehicle-inspection-title">納品書一覧</p>
            <Table
                rowKey="id"
                columns={columns}
                pagination={false}
                dataSource={stockNotesList}
            />
            <Button
                onClick={() => navigate('/')}
                sx={{
                    padding: '6px 18px',
                    height: '44.5px',
                    width: '166.5px',
                    marginTop: '43px',
                    borderColor: 'rgba(33, 84, 147, 0.30)',
                    color: '#215493',
                    borderRadius: '8px',
                    marginRight: '4px',
                }}
                variant="outlined"
            >
                戻る
            </Button>
        </StylesStockNotesSP>
    );
};

const StockNoteSP = (props: ReceivedProps) => {
    return <StockNotesSPLayout {...useSPStockNotes(props)} />;
};

export default StockNoteSP;
