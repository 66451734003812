import { FC, useState } from 'react';
import { StyledSlider } from './styled';
import { Settings } from 'react-slick';
import styled from 'styled-components';
interface Props {
    images?: string[];
    settings?: Settings;
}

const BgText = styled.div`
    background: rgba(0, 0, 0, 0.5);
`;

const ImageCarousel: FC<Props> = ({ images, settings }) => {
    const defaultSettings: Settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        responsive: [
            {
                breakpoint: 480, // breakpoint from mobile to show slick-dots
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };
    return (
        <StyledSlider {...(settings ?? defaultSettings)}>
            {images
                ? images.map((img, idx: number) => {
                      return (
                          <>
                              <div key={idx}>
                                  <img
                                      src={img}
                                      alt={`Slide ${idx}`}
                                      className="rounded-2xl z-[-1px]"
                                  />
                                  <div className="text-[white] flex mt-[-35px] justify-end mr-[35px] ">
                                      <BgText className="min-w-[41px] py-[2px] px-[4px] rounded-[10px] bg-test">
                                          {idx + 1}/{images?.length}
                                      </BgText>
                                  </div>
                              </div>
                          </>
                      );
                  })
                : []}
        </StyledSlider>
    );
};

export default ImageCarousel;
