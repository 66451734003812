import { FC } from "react";
import useSPQuoteList, { Props, ReceivedProps } from "./hook";
// import { QuoteHistoryStyles } from "./styled";
import { Box } from "@mui/material";
import type { ColumnsType } from "antd/es/table";
// import { DataType } from "./type";
import { useNavigate } from "react-router-dom";
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import { QuoteListStyles } from "./styled";
import { QuoteStatusEnum } from "utils/enums";
import RedTrash from "assets/icons/Trash.svg";
import PencilIcon from "assets/icons/PencilSimpleLine.svg";
import EyeIcon from 'assets/icons/Eye.svg';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#C7DCF5',
      color: '#1C1E21',
      fontWeight: 600,
      padding: '8px',
  },
  [`&.${tableCellClasses.body}`]: {
      fontFamily: 'Zen Kaku Gothic Antique',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      color: '#1C1E21',
      padding: '8px 14px',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // '&:nth-of-type(odd)': {
  //     backgroundColor: theme.palette.action.hover,
  // },
  // hide last border
  '&:last-child td, &:last-child th': {
      border: 0,
  },
}))

const SPQuoteListLayout: FC<Props> = ({
  quoteList
}) => {
  const navigate = useNavigate();
  return (
    <QuoteListStyles>
      <div className="table-workers d-flex flex-column">
        <div className="table-content d-flex flex-column">
          <TableContainer component={Paper} table-layout={'auto'} >
            <Table
              sx={{ minWidth: 1100 }}
              aria-label="customized table"
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    className="text-overflow1"
                    style={{ width: '20%' }}
                  >
                    <div>Lineアカウント</div>
                  </StyledTableCell>
                  
                  <StyledTableCell
                    className="text-overflow1"
                    style={{ width: '5%' }}
                  >
                    <div>タイプ</div>
                  </StyledTableCell>

                  <StyledTableCell
                    className="text-overflow1"
                    style={{ width: '40%' }}
                  >
                    <div>住所</div>
                  </StyledTableCell>

                  <StyledTableCell
                    className="text-overflow1"
                    style={{ width: '5%' }}
                  >
                    <div>ステータス</div>
                  </StyledTableCell>

                  <StyledTableCell
                    className="text-overflow1"
                    style={{ width: '10%' }}
                  >
                    <div>作成日</div>
                  </StyledTableCell>

                  <StyledTableCell
                    className="text-overflow1"
                    style={{ width: '20%' }}
                  >
                    <div>操作</div>
                  </StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {quoteList.map((row, ind) => {
                  return (
                    <StyledTableRow key={ind}>
                      <StyledTableCell
                        align="left"
                        component="th"
                        scope="row"
                        style={{ width: '20%' }}
                      >
                        <div
                          style={{ 
                            whiteSpace: 'nowrap',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <img 
                            src={row.avatar} 
                            alt={`${row.account_name} profile photo`} 
                            style={{ 
                              width: "40px", 
                              height: "40px",
                              marginRight: "5px",
                            }} 
                          />
                          <span>{row.account_name}</span>
                        </div>
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{ width: '5%' }}
                      >
                        <div
                          className="text-overflow1"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {row.type}
                        </div>
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{ width: '40%' }}
                      >
                        <div
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {row.address}
                        </div>
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{ width: '5%' }}
                      >
                        <div
                          className="text-overflow1"
                          style={{ 
                            whiteSpace: 'nowrap',
                            color: QuoteStatusEnum[row.status]?.textColor,
                            backgroundColor: QuoteStatusEnum[row.status]?.bgColor,
                            borderRadius: '15px',
                            padding: '4px 9px'
                          }}
                        >
                          {QuoteStatusEnum[row.status]?.title}
                        </div>
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{ width: '10%' }}
                      >
                        <div
                          className="text-overflow1"
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {row.created_at}
                        </div>
                      </StyledTableCell>

                      <StyledTableCell
                        align="center"
                        style={{ width: '20%' }}
                      >
                        <div className="flex justify-center items-center">
                          <Box sx={{
                            cursor: "pointer",
                            width: 24,
                            height: 24,
                            margin: "0px 4px",
                          }} onClick={() => navigate(`/sp/quote/edit/${row.id}`)}>
                            <img src={PencilIcon} alt="pencil icon" />
                          </Box>
                          <Box sx={{
                            cursor: "pointer",
                            width: 24,
                            height: 24,
                            margin: "0px 4px",
                          }} onClick={() => navigate(`/sp/quote/detail/${row.id}`)}>
                            <img src={EyeIcon} alt="eye icon" />
                          </Box>
                          <Box sx={{
                            cursor: "pointer",
                            width: 24,
                            height: 24,
                            margin: "0px 4px",
                          }} onClick={() => {}}>
                            <img src={RedTrash} alt="red trash icon" />
                          </Box>
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </QuoteListStyles>
  );
};

const SPQuoteList: FC<ReceivedProps> = (props) => {
  return <SPQuoteListLayout {...useSPQuoteList(props)} />;
};

export default SPQuoteList;