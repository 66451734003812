import { Box, Button, Grid, Typography } from '@mui/material';

const ConfirmPopup = ({
    closeDelete,
    handleSubmit,
    text = '本当に削除しますか?',
}: any) => {
    return (
        <Box
            minHeight="152px"
            padding="28px"
            bgcolor="#fff"
            borderRadius="12px"
            width="400px"
            maxWidth="90vw"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-between"
            sx={{
                '.MuiTypography-root, button': {
                    fontFamily: 'Zen Kaku Gothic Antique',
                },
            }}
        >
            <Typography fontSize="18px" fontWeight="700">
                {text}
            </Typography>
            {handleSubmit ? (
                <Grid
                    width="100%"
                    sx={{
                        button: {
                            padding: '10px 18px',
                            width: '100%',
                            borderRadius: '8px',
                            fontSize: '16px',
                            height: '44px',
                            borderColor: 'rgb(33, 84, 147)',
                        },
                    }}
                    columnSpacing={2}
                    container
                >
                    <Grid item xs={6}>
                        <Button
                            onClick={closeDelete}
                            sx={{ color: '#215493' }}
                            variant="outlined"
                        >
                            キャンセル
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            onClick={handleSubmit}
                            sx={{ backgroundColor: 'rgb(33, 84, 147)' }}
                            variant="contained"
                        >
                            はい
                        </Button>
                    </Grid>
                </Grid>
            ) : (
                <Box
                    sx={{
                        button: {
                            padding: '10px 18px',
                            width: '100%',
                            borderRadius: '8px',
                            minWidth: '140px',
                            fontSize: '16px',
                            height: '44px',
                            borderColor: 'rgb(33, 84, 147)',
                        },
                    }}
                >
                    <Button
                        onClick={closeDelete}
                        sx={{ backgroundColor: 'rgb(33, 84, 147)' }}
                        variant="contained"
                    >
                        はい
                    </Button>
                </Box>
            )}
        </Box>
    );
};
export default ConfirmPopup;
