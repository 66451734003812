/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useFormik } from 'formik';
import _ from 'lodash';

import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';

import { validate } from './validation';
import { Main, ShoeiInputText } from './style';
import { Input, Radio } from 'antd';
import { Controller, useForm } from 'react-hook-form';

const ModalCreateNew = ({
    onClose,
    submitForm,
    item,
    showDataBudgetItem,
    listBudgetItem,
    actionBudgetItem,
}) => {
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            budget_id: 0,
            name: '',
            summary: '',
            amount: null,
            unit: null,
            unit_price: null,
            price: 0,
            note: '',
            is_parent: true,
            item: item,
            parent_budget_item_id: { label: '', value: '' },
            supplier: '',
        },
        validate,
        onSubmit: (values) => {
            !isSameName ? submitForm(values) : null;
        },
    });
    const [nameParentBudget, setNameParentBudget] = useState('');
    useEffect(() => {
        if (Object.keys(actionBudgetItem?.dataItem).length > 0) {
            const value = { ...actionBudgetItem?.dataItem };

            formik.setValues({
                ...value,
            });
            const parent_budget_item = listBudgetItem?.find(
                (item) =>
                    item.id ==
                    actionBudgetItem?.dataItem?.parent_budget_item_id,
            );
            setNameParentBudget(parent_budget_item?.name);
        }
    }, [actionBudgetItem?.dataItem, listBudgetItem]);

    const [dataOptionBudgetItem, setDataOptionBudgetItem] = useState([]);
    const [listNameBudget, setListNameBudget] = useState<string[]>([]);

    useEffect(() => {
        setDataOptionBudgetItem(
            listBudgetItem?.map((budget) => ({
                label: budget.name,
                value: budget.id,
            })),
        );
        let result = listBudgetItem.reduce((names, item) => {
            if (item.name) names.push(item.name.toLowerCase());
            if (Array.isArray(item.child_budget_items))
                names.push(
                    ...item.child_budget_items.map((child) =>
                        child.name.toLowerCase(),
                    ),
                );
            return names;
        }, []);
        setListNameBudget(result);
    }, [listBudgetItem]);

    const [dataOptionFinshed, setDataOptionFinshed] = useState([]);
    useEffect(() => {
        if (dataOptionBudgetItem) {
            setDataOptionFinshed(
                dataOptionBudgetItem.filter(
                    (item: any) =>
                        item?.value !== actionBudgetItem?.dataItem?.id,
                ),
            );
        }
    }, [actionBudgetItem?.dataItem, dataOptionBudgetItem]);

    const { control } = useForm();
    const [isSameName, setIsSameName] = useState(false);
    useEffect(() => {
        if (
            (formik.values.name && actionBudgetItem?.type == 'create') ||
            (formik.values.name &&
                actionBudgetItem?.type == 'edit' &&
                formik.values.name != actionBudgetItem?.dataItem?.name)
        ) {
            setIsSameName(
                listNameBudget.includes(
                    formik.values.name.trim().toLowerCase(),
                ),
            );
        }
    }, [formik.values.name]);

    return (
        <Main className="modal">
            <form onSubmit={formik.handleSubmit}>
                <InputLabel className="title">新規作成</InputLabel>
                <div className="px-[28px] max-h-[500px] overflow-auto">
                    <div className="contronler-box-input-label">
                        <InputLabel className="label-input">
                            {' '}
                            仕入先{' '}
                        </InputLabel>
                        {/* <ShoeiInputText
              fullWidth */}
                        <Input
                            className="input-text-antd"
                            name="supplier"
                            value={formik.values.supplier}
                            onChange={formik.handleChange}
                            // error={formik.touched.supplier && Boolean(formik.errors.supplier)}
                            onBlur={formik.handleBlur}
                            maxLength={255}
                        />
                        {formik.touched.supplier && formik.errors.supplier && (
                            <span className="error">
                                {String(formik.errors.supplier)}
                            </span>
                        )}
                    </div>
                    <div className="contronler-box-input-label">
                        <InputLabel className="label-input"> 名称 </InputLabel>
                        <Input
                            className="input-text-antd"
                            // fullWidth
                            name="name"
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            // error={formik.touched.name && Boolean(formik.errors.name)}
                            onBlur={formik.handleBlur}
                            maxLength={255}
                        />
                        {formik.touched.name && formik.errors.name && (
                            <span className="error">
                                {String(formik.errors.name)}
                            </span>
                        )}
                        {isSameName && (
                            <span className="error">
                                名称がすでに使用されています
                            </span>
                        )}
                    </div>

                    <div className="contronler-box-input-label">
                        <InputLabel className="label-input"> 摘要 </InputLabel>
                        <Input
                            className="input-text-antd"
                            maxLength={255}
                            name="summary"
                            value={formik.values.summary}
                            onChange={formik.handleChange}
                            // error={formik.touched.summary && Boolean(formik.errors.summary)}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.summary && formik.errors.summary && (
                            <span className="error">
                                {String(formik.errors.summary)}
                            </span>
                        )}
                    </div>

                    <div
                        className={`${
                            !showDataBudgetItem ? 'is-parent mb-2' : ''
                        }`}
                    >
                        <div className="d-flex gap-input">
                            <div className="contronler-box-input-label">
                                <InputLabel className="label-input">
                                    {' '}
                                    数量{' '}
                                </InputLabel>
                                <Input
                                    className="input-text-antd"
                                    maxLength={15}
                                    // type="number"
                                    name="amount"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    value={formik.values.amount ?? ''}
                                    onChange={(e) => {
                                        const { unit_price = 0 } =
                                            formik.values;
                                        const val =
                                            (unit_price || 0) *
                                            Number(e.target.value || 0);

                                        formik.setFieldValue(
                                            'price',
                                            val.toLocaleString('en-US'),
                                        );
                                        formik.handleChange(e);
                                    }}
                                    // error={formik.touched.amount && Boolean(formik.errors.amount)}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.amount &&
                                    formik.errors.amount && (
                                        <span className="error">
                                            {String(formik.errors.amount)}
                                        </span>
                                    )}
                            </div>

                            <div className="contronler-box-input-label">
                                <InputLabel className="label-input">
                                    {' '}
                                    単位{' '}
                                </InputLabel>
                                <Input
                                    className="input-text-antd"
                                    maxLength={255}
                                    name="unit"
                                    value={formik.values.unit ?? ''}
                                    onChange={formik.handleChange}
                                    // error={formik.touched.unit && Boolean(formik.errors.unit)}
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.unit && formik.errors.unit && (
                                    <span className="error">
                                        {String(formik.errors.unit)}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="d-flex gap-input">
                            <div className="contronler-box-input-label">
                                <InputLabel className="label-input">
                                    {' '}
                                    単価{' '}
                                </InputLabel>
                                <Input
                                    className="input-text-antd"
                                    type="number"
                                    maxLength={15}
                                    name="unit_price"
                                    value={formik.values.unit_price ?? ''}
                                    onChange={(e) => {
                                        if (e.target.value.length > 15) {
                                            e.preventDefault();
                                        } else {
                                            const { amount = 0 } =
                                                formik.values;
                                            const val =
                                                (amount || 0) *
                                                Number(e.target.value || 0);

                                            formik.setFieldValue(
                                                'price',
                                                val.toLocaleString('en-US'),
                                            );
                                            formik.setFieldValue(
                                                'unit_price',
                                                e.target.value
                                                    ? Number(
                                                          Number(
                                                              e.target.value,
                                                          ).toFixed(4),
                                                      )
                                                    : null,
                                            );
                                        }
                                    }}
                                    // error={
                                    //   formik.touched.unit_price &&
                                    //   Boolean(formik.errors.unit_price)
                                    // }
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.unit_price &&
                                    formik.errors.unit_price && (
                                        <span className="error">
                                            {String(formik.errors.unit_price)}
                                        </span>
                                    )}
                            </div>

                            <div className="contronler-box-input-label">
                                <InputLabel className="label-input">
                                    {' '}
                                    金額{' '}
                                </InputLabel>
                                <ShoeiInputText
                                    fullWidth
                                    disabled
                                    // type="number"
                                    name="price"
                                    value={formik.values.price}
                                    error={
                                        formik.touched.price &&
                                        Boolean(formik.errors.price)
                                    }
                                    onBlur={formik.handleBlur}
                                />
                                {formik.touched.price &&
                                    formik.errors.price && (
                                        <span className="error">
                                            {String(formik.errors.price)}
                                        </span>
                                    )}
                            </div>
                        </div>
                    </div>
                    <div className="contronler-box-input-label">
                        <Radio.Group
                            // onChange={onChange} value={valueSelect}
                            value={formik.values.is_parent}
                            onChange={() => {
                                formik.setFieldValue(
                                    'is_parent',
                                    !formik.values.is_parent,
                                );
                            }}
                            disabled={
                                actionBudgetItem?.dataItem?.is_parent &&
                                actionBudgetItem?.dataItem?.child_budget_items
                                    .length > 0
                            }
                        >
                            <Radio value={true}>大項目</Radio>
                            <Radio value={false}>小項目</Radio>
                        </Radio.Group>
                    </div>
                    {!formik.values.is_parent && (
                        <div className="contronler-box-input-label">
                            <InputLabel className="label-input">
                                {' '}
                                大項目{' '}
                            </InputLabel>
                            <Controller
                                control={control}
                                name="parent_budget_item_id"
                                render={() => (
                                    <Dropdown
                                        className="modal-dropdown-create-estimation height36"
                                        controlClassName="dropdown-control-plan height36"
                                        options={dataOptionFinshed}
                                        value={nameParentBudget}
                                        onChange={(value: any) =>
                                            formik.setFieldValue(
                                                'parent_budget_item_id',
                                                {
                                                    ...value,
                                                },
                                            )
                                        }
                                        placeholder=""
                                    />
                                )}
                            />

                            {formik.touched.parent_budget_item_id &&
                                formik.errors.parent_budget_item_id && (
                                    <span className="error ml-3">
                                        {String(
                                            formik.errors.parent_budget_item_id,
                                        )}
                                    </span>
                                )}
                        </div>
                    )}
                    <div className="contronler-box-input-label">
                        <InputLabel className="label-input"> 備考 </InputLabel>
                        <Input.TextArea
                            className="input-text-antd !h-auto"
                            rows={3}
                            name="note"
                            value={formik?.values?.note ?? ''}
                            onChange={formik.handleChange}
                            // error={formik.touched.note && Boolean(formik.errors.note)}
                            maxLength={255}
                            onBlur={formik.handleBlur}
                        />
                        {formik.touched.note && formik.errors.note && (
                            <span className="error">
                                {String(formik.errors.note)}
                            </span>
                        )}
                    </div>

                    <div className="group-buttons d-flex flex-row gap-input">
                        <Button
                            className="bnt bnt-line d-flex flex-row"
                            onClick={onClose}
                        >
                            キャンセル
                        </Button>
                        <Button
                            className="bnt bnt-submit d-flex flex-row"
                            type="submit"
                        >
                            登録
                        </Button>
                    </div>
                </div>
            </form>
        </Main>
    );
};

export default ModalCreateNew;
