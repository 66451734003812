import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import orderIcon from '../../../assets/icons/line/order.png';
import historyIcon from '../../../assets/icons/line/history.png';
import phoneIcon from '../../../assets/icons/line/phone.png';
import settingIcon from 'assets/icons/line/setting.png';
import "./style.scss";

const WebTop = () => {
  const hostURL = `${window.location.protocol}//${window.location.host}`;
  const moveToContact = () => {
    window.location.href = `${hostURL}/sp/contact`;
  }
  return (
    <>
      <div className="web-top-wrapper d-flex align-items-center hihi">
        <Row className="flex-grow-1">
          <Col xs={6} className="d-flex justify-content-end" style={{ paddingRight: '4px' }}>
            <Link
              className="app-menu-item flex-column align-items-center justify-content-center order"
              to="/sp/quote/create?top=true"
            >
              <div className="my-1">
                <img src={orderIcon} />
              </div>
              <h4 className="mt-2 font-weight-bold">見積依頼</h4>
            </Link>
          </Col>
          <Col xs={6} align="left" style={{ paddingLeft: '4px' }}>
            <Link
              className="app-menu-item flex-column align-items-center justify-content-center order-history"
              to="/sp/quote/history?top=true"
            >
              <div className="my-1 mr-2">
                <img src={historyIcon} />
              </div>
              <h4 className="mt-2 font-weight-bold">見積履歴</h4>
            </Link>
          </Col>
          <Col xs={6} className="mt-2 d-flex justify-content-end" style={{ paddingRight: '4px' }}>
            <Link
              className="app-menu-item flex-column align-items-center justify-content-center make-call"
              to="/sp/contact?top=true"
            >
              <div className="my-1">
                <img src={phoneIcon} />
              </div>
              <h4 className="mt-2 font-weight-bold">お問い合わせ</h4>
            </Link>
          </Col>
          <Col xs={6} className="mt-2" style={{ paddingLeft: '4px' }}>
            <Link
              className="app-menu-item flex-column align-items-center justify-content-center customer-config"
              to="/line/customer-info?top=true"
            >
              <div className="my-1">
                <img src={settingIcon} />
              </div>
              <h4 className="mt-2 font-weight-bold">アカウント</h4>
            </Link>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default WebTop;
