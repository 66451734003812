import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import CompanyService from "../../services/clientCompanyService";

export const getListCompanies = createAsyncThunk(
  "departments",
  async () => {
    const response = await CompanyService.getListCompanies();
    return response;
  }
);

const initialState = {
    companyList: []
}

const departmentSlice = createSlice({
    name: "companies",
    initialState,
    extraReducers: (builder) => {
        builder
        .addCase(getListCompanies.fulfilled, (state, action) => {
            state.companyList = action.payload.departments
        })

        .addCase(getListCompanies.rejected,(state, action) => {
            state.companyList = [];
        })
    },
    reducers: {},
});

const { reducer } = departmentSlice;
export default reducer;
